import { translate, type ParameterlessTranslationKey } from '@orus.eu/translations'
import { z } from 'zod'

export const subscriptionBreadcrumbs = [
  'activity',
  'insurance',
  'quote',
  'wrap_up',
  'personalized_offer',
  'coverage',
  'activity_split',
  'statement',
  'company',
  'identity',
  'payment',
] as const

export const subscriptionBreadcrumbSchema = z.enum(subscriptionBreadcrumbs)

export type SubscriptionBreadcrumb = z.infer<typeof subscriptionBreadcrumbSchema>

export function isSubscriptionBreadcrumb(value: string): value is SubscriptionBreadcrumb {
  return (subscriptionBreadcrumbs as readonly string[]).includes(value)
}

/**
 * @deprecated for backward compatibility only
 */
export function getBreadcrumbByFrenchTranslation(translation: string): SubscriptionBreadcrumb | undefined {
  return subscriptionBreadcrumbs.find(
    (breadcrumb) => translate(subscriptionBreadcrumbsTranslationKeys[breadcrumb], 'fr') === translation,
  )
}

export type SubscriptionBreadcrumbsPath =
  | readonly [SubscriptionBreadcrumb]
  | readonly [SubscriptionBreadcrumb, SubscriptionBreadcrumb]

export const subscriptionBreadcrumbsTranslationKeys: Record<SubscriptionBreadcrumb, ParameterlessTranslationKey> = {
  activity: 'subscription_breadcrumbs_activity',
  insurance: 'subscription_breadcrumbs_insurance',
  quote: 'subscription_breadcrumbs_quote',
  wrap_up: 'subscription_breadcrumbs_wrap_up',
  personalized_offer: 'subscription_breadcrumbs_personalized_offer',
  coverage: 'subscription_breadcrumbs_coverage',
  activity_split: 'subscription_breadcrumbs_activity_split',
  statement: 'subscription_breadcrumbs_statement',
  company: 'subscription_breadcrumbs_company',
  identity: 'subscription_breadcrumbs_identity',
  payment: 'subscription_breadcrumbs_payment',
}
