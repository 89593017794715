import { BooleanDimension } from './boolean-dimension.js'

export const documentsLockConfirmedDimension = new BooleanDimension({
  name: 'documentsLockConfirmed',
  displayValues: { name: 'Verrouillage des documents confirmé' },
  tags: ['RCDA'],
})

export const documentsValidatedDimension = new BooleanDimension({
  name: 'documentsValidated',
  displayValues: { name: 'Documents validés' },
  tags: ['RCDA'],
})
