import { OptionsListDimension } from '../options-list-dimension.js'

export const mrphDisplayableOptionTypesNames = ['PE', 'RCAL', 'RCE', 'PJ', 'ASSISTANCE'] as const
export const mrphOptionTypeNames = [...mrphDisplayableOptionTypesNames, 'FREEDOM_ADVANTAGE'] as const
export type MrphOptionType = (typeof mrphOptionTypeNames)[number]
export type MrpDisplayableOptionalGuaranteeType = Exclude<MrphOptionType, 'FREEDOM_ADVANTAGE'>

export function changeOptionNameToLowerCase(
  type: MrpDisplayableOptionalGuaranteeType,
): Lowercase<MrpDisplayableOptionalGuaranteeType> {
  switch (type) {
    case 'ASSISTANCE':
      return 'assistance'
    case 'PE':
      return 'pe'
    case 'PJ':
      return 'pj'
    case 'RCAL':
      return 'rcal'
    case 'RCE':
      return 'rce'
  }
}

export const mrphOptionsDimension = new OptionsListDimension<'mrphOptions', MrphOptionType>({
  name: 'mrphOptions',
  oldNames: ['options'],
  displayValues: { name: 'Active options' },
  tags: ['MRPH'],
} as const)
