import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Allows monitoring whether an element is overflowing its container (with the presence of a scrollbar on dektop)
 * @returns A ref callback to attach to the element to monitor, and a boolean indicating if the element currently has a scrollbar
 */
export function useIsOwerflowing(): { ref: (element: HTMLElement | null) => void; isOverflowing: boolean } {
  const currentElement = useRef<HTMLElement | null>(null)
  const [isOverflowing, setHasScrollbar] = useState(false)

  const resizeObserver = useRef(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const entry = entries[0]
      if (!entry) return
      const { target } = entry
      setHasScrollbar(target.scrollHeight > target.clientHeight)
    }),
  )

  const ref = useCallback((newElement: HTMLElement | null) => {
    if (currentElement.current) {
      resizeObserver.current.unobserve(currentElement.current)
    }

    if (newElement) {
      resizeObserver.current.observe(newElement)
    }

    currentElement.current = newElement
  }, [])

  useEffect(() => {
    return () => {
      if (currentElement.current) {
        // > If this ref points to a node rendered by React...
        // No, this is not a node rendered by React, we manage it ourselves
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.current.unobserve(currentElement.current)
      }
    }
  }, [])

  return { isOverflowing, ref }
}
