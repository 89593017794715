import { useCallback, useEffect, useRef, useState } from 'react'
import { z } from 'zod'

export type UpdateState = 'idle' | 'up-to-date' | 'available' | 'force'

/**
 * Sets up the application update logic by setting up the service worker and monitoring for updates.
 *
 * @returns State and method to update the application
 */
export function useApplicationUpdate(): {
  /** Whether an update is available */
  updateState: UpdateState
  /** Triggers an update */
  update: () => void
} {
  const [updateState, setUpdateState] = useState<UpdateState>(import.meta.env.DEV ? 'up-to-date' : 'idle')

  const checkIntervalRef = useRef<number>()
  const forceTimeoutRef = useRef<number>()

  const update = useCallback(() => {
    window.location.reload()
  }, [])

  function checkUpdate() {
    ;(async () => {
      if (forceTimeoutRef.current != null) return

      const now = Date.now()
      const res = await fetch(`/latest-version.json?ts=${now}`)
      if (!res.ok) {
        console.error('The server did not return a successful response', res)
        return
      }

      const json = await res.json()
      const payload = LatestVersionSchema.parse(json)
      // If the manifest version is greater than we support, there's necessarily an update available
      // We force it
      if (payload.v > 1) {
        console.log('Forcing update because the manifest version is greater than we support')
        setUpdateState('force')
        return
      }

      if (payload.latestVersion == null) {
        console.error('The server did not return a valid payload, ignoring updates', payload)
        return
      }

      if (payload.latestVersion === __CODE_VERSION_ID__) {
        setUpdateState('up-to-date')
        return
      }

      const latestVersionMatch = VERSION_REGEX.exec(payload.latestVersion)

      if (latestVersionMatch == null) {
        console.error('The server did not return the expected version shape, forcing update', payload)
        setUpdateState('force')
        return
      }

      const [, , releaseTimestampString] = latestVersionMatch

      const releaseTimestampMs = parseInt(releaseTimestampString, 10) * 1_000
      const delta = Math.max(now - releaseTimestampMs, 0)

      if (delta > MAX_VERSION_AGE_MS) {
        console.log('Forcing update because the version is too old')
        setUpdateState('force')
        return
      }

      setUpdateState('available')

      window.clearInterval(checkIntervalRef.current)

      forceTimeoutRef.current = window.setTimeout(() => {
        console.log('Forcing update because the version is too old')
        setUpdateState('force')
      }, MAX_VERSION_AGE_MS - delta)
    })().catch((err) => {
      console.error('Failed to check for updates:', err)
    })
  }

  useEffect(() => {
    if (import.meta.env.DEV) return

    checkUpdate()
    checkIntervalRef.current = window.setInterval(checkUpdate, UPDATE_CHECK_INTERVAL_MS)

    return () => {
      if (checkIntervalRef.current != null) {
        window.clearInterval(checkIntervalRef.current)
        checkIntervalRef.current = undefined
      }

      if (forceTimeoutRef.current != null) {
        window.clearTimeout(forceTimeoutRef.current)
        forceTimeoutRef.current = undefined
      }
    }
  }, [])

  return { updateState, update }
}

const UPDATE_CHECK_INTERVAL_MS = 5 * 60 * 1_000
const MAX_VERSION_AGE_MS = 24 * 60 * 60 * 1_000

const LatestVersionSchema = z.object({
  v: z.number(),
  latestVersion: z.string().optional(),
})

const VERSION_REGEX = /^(\d+\.\d+\.\d+)-ts(\d{10})\+git([a-f0-9]+)$/
