import type { TextVariant } from './variant.js'

export type TextCssProps = {
  fontSize: string
  fontWeight: string
  fontFamily: string
  lineHeight: string
  paragraphSpacing?: string
  textTransform?: 'uppercase'
  letterSpacing?: string
}

export const cssPropsPerScreenVariantPerTextVariant: {
  [typoVariant in TextVariant]: { desktop: TextCssProps; mobile: TextCssProps; backoffice: TextCssProps }
} = {
  h1: {
    desktop: {
      fontSize: '56px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '60px',
      letterSpacing: '-1.2px',
      fontFamily: 'var(--skin-font-title)',
    },
    mobile: {
      fontSize: '40px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '46px',
      letterSpacing: '-1.2px',
      fontFamily: 'var(--skin-font-title)',
    },
    backoffice: {
      fontSize: '40px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '46px',
      letterSpacing: '-1.2px',
      fontFamily: 'var(--skin-font-title)',
    },
  },
  h2: {
    desktop: {
      fontSize: '44px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '52px',
      letterSpacing: '-0.8px',
      fontFamily: 'var(--skin-font-title)',
    },
    mobile: {
      fontSize: '32px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '40px',
      letterSpacing: '-0.8px',
      fontFamily: 'var(--skin-font-title)',
    },
    backoffice: {
      fontSize: '32px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '40px',
      letterSpacing: '-0.8px',
      fontFamily: 'var(--skin-font-title)',
    },
  },
  h3: {
    desktop: {
      fontSize: '36px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '44px',
      letterSpacing: '-0.8px',
      fontFamily: 'var(--skin-font-title)',
    },
    mobile: {
      fontSize: '28px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '36px',
      letterSpacing: '-0.8px',
      fontFamily: 'var(--skin-font-title)',
    },
    backoffice: {
      fontSize: '28px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '36px',
      letterSpacing: '-0.8px',
      fontFamily: 'var(--skin-font-title)',
    },
  },
  h4: {
    desktop: {
      fontSize: '32px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '40px',
      letterSpacing: '-0.4px',
      fontFamily: 'var(--skin-font-title)',
    },
    mobile: {
      fontSize: '24px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '32px',
      letterSpacing: '-0.4px',
      fontFamily: 'var(--skin-font-title)',
    },
    backoffice: {
      fontSize: '24px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '32px',
      letterSpacing: '-0.4px',
      fontFamily: 'var(--skin-font-title)',
    },
  },
  h5: {
    desktop: {
      fontSize: '28px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '36px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-title)',
    },
    mobile: {
      fontSize: '22px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '30px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-title)',
    },
    backoffice: {
      fontSize: '22px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '30px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-title)',
    },
  },
  h6: {
    desktop: {
      fontSize: '24px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '32px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-title)',
    },
    mobile: {
      fontSize: '20px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '28px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-title)',
    },
    backoffice: {
      fontSize: '20px',
      fontWeight: 'var(--skin-font-weight-title)',
      lineHeight: '28px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-title)',
    },
  },
  subtitle1: {
    desktop: {
      fontSize: '21px',
      fontWeight: 'var(--skin-font-weight-subtitle1)',
      lineHeight: '28px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-subtitle1)',
    },
    mobile: {
      fontSize: '19px',
      fontWeight: 'var(--skin-font-weight-subtitle1)',
      lineHeight: '26px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-subtitle1)',
    },
    backoffice: {
      fontSize: '19px',
      fontWeight: 'var(--skin-font-weight-subtitle1)',
      lineHeight: '26px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font-subtitle1)',
    },
  },
  subtitle2: {
    desktop: {
      fontSize: '18px',
      fontWeight: 'var(--skin-font-weight-subtitle2)',
      lineHeight: '24px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '18px',
      fontWeight: 'var(--skin-font-weight-subtitle2)',
      lineHeight: '24px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '18px',
      fontWeight: 'var(--skin-font-weight-subtitle2)',
      lineHeight: '24px',
      letterSpacing: '-0.2px',
      fontFamily: 'var(--skin-font)',
    },
  },
  body1: {
    desktop: {
      fontSize: '16px',
      fontWeight: 'var(--skin-font-weight-body1)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '16px',
      fontWeight: 'var(--skin-font-weight-body1)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '15px',
      fontWeight: 'var(--skin-font-weight-body1)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
  },
  body1Medium: {
    desktop: {
      fontSize: '16px',
      fontWeight: 'var(--skin-font-weight-body1-medium)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '16px',
      fontWeight: 'var(--skin-font-weight-body1-medium)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '15px',
      fontWeight: 'var(--skin-font-weight-body1-medium)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
  },
  body1Semibold: {
    desktop: {
      fontSize: '16px',
      fontWeight: 'var(--skin-font-weight-body1-semibold)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '16px',
      fontWeight: 'var(--skin-font-weight-body1-semibold)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '15px',
      fontWeight: 'var(--skin-font-weight-body1-semibold)',
      lineHeight: '22px',
      fontFamily: 'var(--skin-font)',
    },
  },
  body2: {
    desktop: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-body2)',
      lineHeight: '20px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-body2)',
      lineHeight: '20px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '13px',
      fontWeight: 'var(--skin-font-weight-body2)',
      lineHeight: '18px',
      fontFamily: 'var(--skin-font)',
    },
  },
  body2Medium: {
    desktop: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-body2-medium)',
      lineHeight: '20px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-body2-medium)',
      lineHeight: '20px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '13px',
      fontWeight: 'var(--skin-font-weight-body2-medium)',
      lineHeight: '18px',
      fontFamily: 'var(--skin-font)',
    },
  },
  body2Semibold: {
    desktop: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-body2-semibold)',
      lineHeight: '20px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-body2-semibold)',
      lineHeight: '20px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '13px',
      fontWeight: 'var(--skin-font-weight-body2-semibold)',
      lineHeight: '18px',
      fontFamily: 'var(--skin-font)',
    },
  },
  caption: {
    desktop: {
      fontSize: '12px',
      fontWeight: 'var(--skin-font-weight-caption)',
      lineHeight: '16px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '12px',
      fontWeight: 'var(--skin-font-weight-caption)',
      lineHeight: '18px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '12px',
      fontWeight: 'var(--skin-font-weight-caption)',
      lineHeight: '16px',
      fontFamily: 'var(--skin-font)',
    },
  },
  captionMedium: {
    desktop: {
      fontSize: '12px',
      fontWeight: 'var(--skin-font-weight-caption-medium)',
      lineHeight: '16px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '12px',
      fontWeight: 'var(--skin-font-weight-caption-medium)',
      lineHeight: '18px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '12px',
      fontWeight: 'var(--skin-font-weight-caption-medium)',
      lineHeight: '16px',
      fontFamily: 'var(--skin-font)',
    },
  },
  button: {
    desktop: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-button)',
      lineHeight: '24px',
      fontFamily: 'var(--skin-font-button)',
    },
    mobile: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-button)',
      lineHeight: '24px',
      fontFamily: 'var(--skin-font-button)',
    },
    backoffice: {
      fontSize: '13px',
      fontWeight: 'var(--skin-font-weight-button)',
      lineHeight: '24px',
      fontFamily: 'var(--skin-font-button)',
    },
  },
  input: {
    desktop: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-input)',
      lineHeight: '16px',
      fontFamily: 'var(--skin-font)',
    },
    mobile: {
      fontSize: '14px',
      fontWeight: 'var(--skin-font-weight-input)',
      lineHeight: '24px',
      fontFamily: 'var(--skin-font)',
    },
    backoffice: {
      fontSize: '13px',
      fontWeight: 'var(--skin-font-weight-input)',
      lineHeight: '16px',
      fontFamily: 'var(--skin-font)',
    },
  },
}
