import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import type { LocalizableMessage } from '@orus.eu/translations'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class TextDimension<NAME extends string> extends AbstractDimension<NAME, string> {
  readonly type = 'text'

  override validateData(value: LooselyTypedValue): Result<string, DimensionValidationProblem> {
    return typeof value === 'string' ? success(value) : dimensionValidationFailure(`Field ${this.name} is not a string`)
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<string> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.string(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: string | null | undefined): string | null => {
      return stateValue ?? null
    },
  }
}

export class LocalizableTextDimension<NAME extends string> extends AbstractDimension<NAME, LocalizableMessage> {
  readonly type = 'localizable-text'
}
