import { OptionsListDimension } from '../options-list-dimension.js'

export const rcphDisplayableOptionTypesNames = ['MONDE', 'RCE', 'CYBER', 'TDMI', 'mrpSmallOffice'] as const
export const rcphOptionTypeNames = [...rcphDisplayableOptionTypesNames, 'FREEDOM_ADVANTAGE'] as const
export type RcphOptionType = (typeof rcphOptionTypeNames)[number]
export type RcphDisplayableOptionalGuaranteeType = Exclude<RcphOptionType, 'FREEDOM_ADVANTAGE'>

export const rcphOptionsDimension = new OptionsListDimension<'rcphOptions', RcphOptionType>({
  name: 'rcphOptions',
  displayValues: { name: 'Active options' },
  tags: ['RCPH'],
} as const)
