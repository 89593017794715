import { newSubscribable, type Observable } from '@orus.eu/observable'

export type ThrownValue = {
  /** The raw, thrown value */
  value: unknown
  /** A stringified representation of the value (fail-safe JSON.stringify) */
  stringified: string
  /** An error wrapping the stringified representation of the value. Useful to get the stacktrace */
  error: Error
}

const thrownValuesSubscribable = newSubscribable<ThrownValue>({
  value: undefined,
  stringified: 'undefined',
  error: new Error(),
})

export const thrownValuesObservable: Observable<ThrownValue> = thrownValuesSubscribable

/**
 * Given a potential error object, return an error.
 * If the given value is an error, this function returns the error,
 * otherwise it wraps the stringified representation of the value in an error and returns it instead.
 *
 * When passing a non-error value, this functions pushes this value, its stringified representation
 * and its wrapping error to `thrownValuesObservable`, which might be useful for external logging.
 *
 * @param value - A potential error object
 *
 * @returns The original or created error
 */
export function ensureError(value: unknown): Error {
  if (value instanceof Error) return value

  let stringified = '[Unable to stringify the thrown value]'
  try {
    stringified = JSON.stringify(value)
    // eslint-disable-next-line no-empty
  } catch {}

  const error = new Error(`This value was thrown as is, not through an Error: ${stringified}`)
  thrownValuesSubscribable.setValue({ value, stringified, error })
  return error
}
