import styled from '@emotion/styled'
import { failure, success } from '@orus.eu/result'
import { electronicFormatIBAN, friendlyFormatIBAN, isValidIBAN } from 'ibantools'
import { memo, useCallback, useState } from 'react'
import { spacing } from '../../../foundation/spacing-tokens'
import { useTranslate } from '../../../localization'
import { FlexColumn, FlexRow, Text, type ValidatedTypeMapper } from '../../atoms'
import { Button } from '../../button'
import { ValidatedTextFieldFormField } from '../../molecules'

export type CustomerData = {
  firstName: string
  lastName: string
  email: string
  address: string
  city: string
  postalCode: string
}
export type IbanData = CustomerData & { iban: string }

type IbanWizardProps = {
  initialFirstName: string | undefined
  initialLastName: string | undefined
  initialEmail: string | undefined
  initialAddress: string | undefined
  initialCity: string | undefined
  initialPostalCode: string | undefined

  onValidate: (data: undefined | IbanData) => void
}

export const IbanWizard = memo(function IbanWizard({
  onValidate,
  initialFirstName,
  initialLastName,
  initialEmail,
  initialAddress,
  initialCity,
  initialPostalCode,
}: IbanWizardProps) {
  const translate = useTranslate()
  const [firstName, setFirstName] = useState<string | undefined>(initialFirstName)
  const [lastName, setLastName] = useState<string | undefined>(initialLastName)
  const [email, setEmail] = useState<string | undefined>(initialEmail)
  const [address, setAddress] = useState<string | undefined>(initialAddress)
  const [city, setCity] = useState<string | undefined>(initialCity)
  const [postalCode, setPostalCode] = useState<string | undefined>(initialPostalCode)
  const [iban, setIban] = useState<string | undefined>()

  const [step, setStep] = useState<'fill' | 'review'>('fill')
  const continueEnabled = step === 'review' || (firstName && lastName && email && address && city && postalCode && iban)

  const handleContinue = useCallback(() => {
    setStep('review')

    if (!firstName || !lastName || !email || !address || !city || !postalCode || !iban) {
      return
    }

    onValidate({
      firstName: firstName,
      lastName: lastName,
      email: email,
      address: address,
      city: city,
      postalCode: postalCode,
      iban: iban,
    })
  }, [firstName, lastName, email, address, city, postalCode, iban, onValidate])

  const goBackToFill = useCallback(() => {
    setStep('fill')

    onValidate(undefined)
  }, [onValidate])

  const gocardlessConfidentialityLink = (
    <a href="https://gocardless.com/fr-fr/privacy/" target="_blank" rel="noopener noreferrer">
      {translate('gocardless_confidentiality_link_text')}
    </a>
  )

  return (
    <Container>
      <Text variant="subtitle1">{translate('subscription_funnel_checkout_iban_wizard_title')}</Text>

      {step === 'fill' ? (
        <>
          <Fields>
            <ValidatedTextFieldFormField
              size="large"
              label="IBAN"
              value={iban}
              placeholder={translate('IBAN_placeholder')}
              onChange={setIban}
              mapper={IBAN_MAPPER}
            />

            <InputContainer>
              <ValidatedTextFieldFormField
                size="large"
                label={translate('dimension_name_first_name')}
                value={firstName}
                placeholder={translate('dimension_placeholder_first_name')}
                mapper={STRING_MAPPER}
                onChange={setFirstName}
              />
              <ValidatedTextFieldFormField
                size="large"
                label={translate('dimension_name_last_name')}
                value={lastName}
                placeholder={translate('dimension_placeholder_last_name')}
                mapper={STRING_MAPPER}
                onChange={setLastName}
              />
            </InputContainer>

            <ValidatedTextFieldFormField
              size="large"
              label={translate('dimension_name_email')}
              value={email}
              placeholder={translate('dimension_placeholder_email')}
              mapper={STRING_MAPPER}
              onChange={setEmail}
            />

            <ValidatedTextFieldFormField
              size="large"
              label={translate('address_street')}
              value={address}
              placeholder={translate('address_street_placeholder')}
              mapper={STRING_MAPPER}
              onChange={setAddress}
            />

            <InputContainer>
              <ValidatedTextFieldFormField
                size="large"
                label={translate('address_postcode')}
                value={postalCode}
                placeholder={translate('address_postcode_placeholder')}
                mapper={STRING_MAPPER}
                onChange={setPostalCode}
              />

              <ValidatedTextFieldFormField
                size="large"
                label={translate('address_city')}
                value={city}
                placeholder={translate('address_city_placeholder')}
                mapper={STRING_MAPPER}
                onChange={setCity}
              />
            </InputContainer>
          </Fields>

          <Disclaimer gocardlessConfidentialityLink={gocardlessConfidentialityLink} />
        </>
      ) : null}

      {step === 'review' ? (
        <>
          <Fields>
            <Text variant="subtitle2">Récapitulatif mandat de prélèvement SEPA</Text>
            <Text variant="body1">
              <strong>Date de mise en place :</strong> aujourd’hui
            </Text>
            <Text variant="body1">
              <strong>Type :</strong> récurrent
            </Text>
            <Text variant="body1">
              <strong>Créancier :</strong> Orus (FR9305V85966A), 5 Av. du Général de Gaulle, 94160 Saint-Mandé, France
            </Text>
            <Text variant="body1">
              <strong>Payeur :</strong> {firstName} {lastName} ({iban})
            </Text>

            <Text variant="caption">
              Nous vous avertirons par e-mail au moins 3 jours ouvrables à l’avance en cas de changement de la date, de
              la fréquence ou du montant de vos paiements.
            </Text>

            <Text variant="caption">
              En signant ce mandat de prélèvement, vous autorisez (A) Orus à envoyer des instructions à votre banque
              pour débiter votre compte, et (B) votre banque à débiter votre compte conformément aux instructions
              d’Orus. Vous bénéficiez d’un droit de remboursement par votre banque selon les conditions décrites dans la
              convention que vous avez passée avec elle. Toute demande de remboursement doit être présentée dans les 8
              semaines suivant la date de débit de votre compte. Vos droits sont expliqués dans un document que vous
              pouvez obtenir auprès de votre banque.
            </Text>
          </Fields>
        </>
      ) : null}

      <ButtonContainer>
        {step === 'review' ? (
          <Button variant="secondary" onClick={goBackToFill}>
            Corriger
          </Button>
        ) : null}
        {step === 'fill' ? (
          <Button variant="primary" disabled={!continueEnabled} onClick={handleContinue}>
            {translate('continue')}
          </Button>
        ) : null}
      </ButtonContainer>
    </Container>
  )
})

const Container = styled(FlexColumn)`
  gap: ${spacing[70]};
`

const Fields = styled(FlexColumn)`
  gap: ${spacing[40]};
  margin: 0;
`

const InputContainer = styled(FlexRow)`
  gap: ${spacing[40]};

  margin: 0;

  * {
    flex: 1;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: ${spacing[40]};
`

const IBAN_MAPPER: ValidatedTypeMapper<string> = {
  inputType: 'text',
  formatFailingText: (value) => friendlyFormatIBAN(value) ?? value.trim(),
  format: (value) => friendlyFormatIBAN(value) ?? value.trim(),
  formatPlaceholder: (value) => value.trim(),
  parse: (text) => {
    const electronicFormat = electronicFormatIBAN(text)
    return electronicFormat && isValidIBAN(electronicFormat)
      ? success(electronicFormat)
      : failure("L'IBAN renseigné est invalide")
  },
}

const STRING_MAPPER: ValidatedTypeMapper<string> = {
  inputType: 'text',
  format: (value) => value,
  formatPlaceholder: (value) => value,
  parse: (text) => success(text),
}

const Disclaimer = memo<{ gocardlessConfidentialityLink: React.ReactNode }>(function Disclaimer({
  gocardlessConfidentialityLink,
}) {
  const translate = useTranslate()
  const text = translate('gocardless_confidentialty_disclaimer', { gocardlessConfidentialityLink: '__LINK__' })
  const chunks = text.split('__LINK__')
  if (text.endsWith('__LINK__')) {
    return (
      <Text variant="caption">
        {chunks[0]}
        {gocardlessConfidentialityLink}
      </Text>
    )
  } else if (text.startsWith('__LINK__')) {
    return (
      <Text variant="caption">
        {gocardlessConfidentialityLink}
        {chunks[0]}
      </Text>
    )
  } else {
    return (
      <Text variant="caption">
        {chunks[0]}
        {gocardlessConfidentialityLink}
        {chunks[1]}
      </Text>
    )
  }
})
