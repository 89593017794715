import styled from '@emotion/styled'
import React, { cloneElement, isValidElement, memo, useRef, useState, type ReactElement, type ReactNode } from 'react'
import { colorTokens, spacing } from '../../foundation'

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  type Placement,
} from '@floating-ui/react'
import { Text } from '../atoms'

type ArrowVerticalPosition = 'top' | 'middle' | 'bottom'
type ArrowHorizontalPosition = 'left' | 'middle' | 'right'

const ARROW_HEIGHT = 10
const ARROW_WIDTH = 18
const GAP = 6

export type TooltipProps = {
  title: ReactNode
  children: ReactNode
  arrowHorizontalPosition?: ArrowHorizontalPosition
  arrowVerticalPosition?: ArrowVerticalPosition
  className?: string
}

export const Tooltip = memo<TooltipProps>(function Tooltip(props: TooltipProps) {
  const {
    title,
    children,
    arrowHorizontalPosition = 'middle',
    arrowVerticalPosition = 'middle',
    className,
    ...otherProps
  } = props
  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: floatingUIArrowPosition[arrowVerticalPosition][arrowHorizontalPosition],
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
      arrow({
        element: arrowRef,
      }),
      offset(ARROW_HEIGHT + GAP),
    ],
  })

  // Event listeners to change the open state
  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  // Role props for screen readers
  const role = useRole(context, { role: 'tooltip' })

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

  const child = React.Children.only(children) // Assuming only one child
  const childStyles = isValidElement(child) ? child?.props.style : {} // Get child styles
  const childClassName = isValidElement(child) ? child?.props.className : '' // Get child className
  const childCss = isValidElement(child) ? child?.props.css : {} // Get child css

  return (
    <>
      <ChildrenContainer
        ref={refs.setReference}
        {...getReferenceProps()}
        style={childStyles}
        className={childClassName}
        css={childCss}
      >
        {isValidElement(children)
          ? cloneElement(children as ReactElement, {
              ...otherProps,
            })
          : children}
      </ChildrenContainer>

      {isOpen && title ? (
        <FloatingPortal>
          <TooltipContainer ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()} className={className}>
            <Text variant="body2">{title}</Text>
            <FloatingArrow
              ref={arrowRef}
              context={context}
              width={ARROW_WIDTH}
              height={ARROW_HEIGHT}
              fill={colorTokens['color-bg-base-inverse']}
            />
          </TooltipContainer>
        </FloatingPortal>
      ) : null}
    </>
  )
})
const ChildrenContainer = styled.div`
  display: inherit;
  cursor: pointer;
`

const TooltipContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: ${spacing[100]};
  max-width: 240px;

  padding: ${spacing[30]} ${spacing[40]};
  border-radius: ${spacing[30]};

  color: ${colorTokens['color-text-base-primary']};
  background: ${colorTokens['color-bg-base-inverse']};
`

type FloatingUIArrowPositionByArrowPosition = {
  [key in ArrowVerticalPosition]: { [key in ArrowHorizontalPosition]: Placement }
}

const floatingUIArrowPosition: FloatingUIArrowPositionByArrowPosition = {
  top: {
    left: 'bottom-start',
    middle: 'bottom',
    right: 'bottom-end',
  },
  middle: {
    left: 'right',
    middle: 'bottom',
    right: 'left',
  },
  bottom: {
    left: 'top-start',
    middle: 'top',
    right: 'top-end',
  },
}
