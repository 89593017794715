import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { memo, useMemo } from 'react'
import { borderRadius, colorTokens, spacing } from '../../foundation'
import { Avatar, AvatarContext, Text, type CompoundIconName } from '../atoms'

export type OrusTabSize = 'small' | 'medium' | 'large'

export type TabProps = {
  children: React.ReactNode
  className?: string
  size?: OrusTabSize
  disabled?: boolean
  active?: boolean
  avatar?: React.ReactNode
  icon?: CompoundIconName
  badge?: React.ReactNode
  onClick?: () => void
}

export const Tab = memo<TabProps>(function Checkbox(props) {
  const { children, size = 'small', disabled = false, active = false, onClick, avatar, icon, badge } = props

  const setupAvatar = useMemo(() => {
    const avatarContext = { size: '10' } as const

    return avatar || icon ? (
      <AvatarContext.Provider value={avatarContext}>{avatar || <Avatar icon={icon} />}</AvatarContext.Provider>
    ) : (
      <></>
    )
  }, [avatar, icon])
  return (
    <TabContainer
      role="tab"
      size={size}
      active={active}
      onClick={() => !disabled && onClick && onClick()}
      aria-selected={active}
    >
      <TabContent size={size} disabled={disabled} tabIndex={active ? 0 : -1}>
        {setupAvatar}
        <Text variant="body2">{children}</Text>
        {badge}
      </TabContent>
    </TabContainer>
  )
})

const TabContainer = styled.div<{ size: OrusTabSize; active: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding-bottom: ${({ size }) => containerPaddingBottomPerSize[size]};

  min-width: ${spacing[70]};

  background-color: white;

  border-bottom: ${({ active }) => (active ? `2px solid ${colorTokens['color-bg-base-active']}` : 'none')};
`

const TabContent = styled.button<{ size: OrusTabSize; disabled: boolean }>`
  appearance: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing[30]};
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;

  padding: ${({ size }) => contentPaddingPerSize[size]};
  border-radius: ${borderRadius[10]};

  color: ${colorTokens['color-text-base-main']};
  cursor: pointer;

  &:hover {
    background-color: ${colorTokens['color-bg-base-hover']};
  }

  &:focus-visible {
    border: 1px solid ${colorTokens['color-stroke-base-focus']};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colorTokens['color-text-base-disable']};
      cursor: default;
      pointer-events: none;
    `}
`

const contentPaddingPerSize: Record<OrusTabSize, string> = {
  small: `${spacing[10]} ${spacing[30]}`,
  medium: `${spacing[20]} ${spacing[30]}`,
  large: `${spacing[30]} ${spacing[30]}`,
}

const containerPaddingBottomPerSize: Record<OrusTabSize, string> = {
  small: spacing[10],
  medium: spacing[20],
  large: spacing[20],
}
