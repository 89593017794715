import { ensureError } from '@orus.eu/error'
import type { DependencyList } from 'react'
import { useCallback } from 'react'
import { useCrash } from './use-crash'

/**
 * Like useCallback, but takes a function that returns a promise, and handles error management
 *
 * A TechnicalError is thrown if the asyncCallback
 *
 * @param asyncCallback
 * @param deps
 * @param context included in the thrown TechnicalError
 */
export function useAsyncCallback<ARGS extends unknown[]>(
  asyncCallback: (...args: ARGS) => Promise<void>,
  deps: DependencyList,
): (...args: ARGS) => void {
  const crash = useCrash()

  return useCallback(
    (...args: ARGS) => {
      asyncCallback(...args).catch((err: unknown) => {
        crash({ type: 'unexpected-error', err: ensureError(err) })
      })
    },

    // "deps" is the right array of dependencies because :
    //   1. Dependencies array from calling functionprotected by a custom config
    //      of the rule "react-hooks/exhaustive-deps", the sync callback will be recreated
    //      by a change in "deps", so no need to react to every change in asyncCallback.
    //   2. by design useCrash always returns the same function, so no need to include it
    //      in the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  )
}
