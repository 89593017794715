import { type FinancialRate, financialRateZodSchema, newFinancialRate, ratesEqual } from '@orus.eu/amount'
import { type Result, success } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  type DimensionValidationProblem,
  dimensionValidationFailure,
  getParmeterlessKeyTranslations,
} from '../abstract-dimension'

export const allBrokerDiscountCodes = ['broker-10'] as const
export type BrokerDiscountCode = (typeof allBrokerDiscountCodes)[number]
export const brokerDiscountCodeZodSchema = z.enum(allBrokerDiscountCodes)

export const brokerDiscountRatePerCode: Record<BrokerDiscountCode, FinancialRate> = {
  'broker-10': newFinancialRate('0.1'),
}

export const brokerDiscountZodSchema = z.object({
  code: brokerDiscountCodeZodSchema,
  rate: financialRateZodSchema,
})

export type BrokerDiscount = z.infer<typeof brokerDiscountZodSchema>

export const allBrokerDiscounts: readonly BrokerDiscount[] = Object.entries(brokerDiscountRatePerCode).map(
  ([code, rate]) => ({
    code,
    rate,
  }),
) as readonly BrokerDiscount[]

export const brokerDiscountCodes = allBrokerDiscounts.map((discount) => discount.code)

export const brokerDiscountCodeLabels: { [key in BrokerDiscountCode]: string } = {
  'broker-10': 'Discount (approx. -10%)',
}

export class BrokerDiscountDimension<NAME extends string> extends AbstractDimension<NAME, BrokerDiscount | 'none'> {
  override readonly placeholders = getParmeterlessKeyTranslations('placeholder_no_discount')

  override validateData(value: unknown): Result<BrokerDiscount | 'none', DimensionValidationProblem> {
    if (value === 'none') return success('none')

    const brokerDiscountResult = brokerDiscountZodSchema.safeParse(value)

    if (!brokerDiscountResult.success) return dimensionValidationFailure(`Field ${this.name} is not a valid discount`)

    const brokerDiscount = brokerDiscountResult.data

    const { code, rate } = brokerDiscount

    const definedRate = brokerDiscountRatePerCode[code as BrokerDiscountCode]

    if (!rate || !ratesEqual(definedRate, rate))
      return dimensionValidationFailure(`Field ${this.name} is not a valid discount`)

    return success(brokerDiscount)
  }
}

export const brokerDiscountDimension = new BrokerDiscountDimension({
  name: 'brokerDiscount',
  displayValues: { name: 'Discount courtier' },
} as const)
