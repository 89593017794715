import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class DocumentsDimension<NAME extends string> extends AbstractDimension<NAME, Documents> {
  override validateData(value: LooselyTypedValue): Result<Documents, DimensionValidationProblem> {
    const parsingResult = SCHEMA.safeParse(value)

    if (!parsingResult.success) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }

    return success(parsingResult.data)
  }
}

const SCHEMA = z.object({
  version: z.literal(1),
  documents: z.record(
    z.object({
      name: z.string(),
      description: z.string(),
    }),
  ),
})

export type Documents = z.infer<typeof SCHEMA>
