import type { TemporaryAPIProblem } from '@orus.eu/backend/src/lib/temporary-api-failure'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export type GloballyHandledProblem = TemporaryAPIProblem

export type GlobalStateState = {
  globalProblem: GloballyHandledProblem | null
}

const initialState: GlobalStateState = {
  globalProblem: null,
}

export const globalStateSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    handleProblem: (state, action: PayloadAction<GloballyHandledProblem>) => {
      state.globalProblem = action.payload
    },
  },
})

export const globalStateActions = globalStateSlice.actions
