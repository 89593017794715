export type ColorScale = {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

type ColorDecorative = {
  light: string
  dark: string
  gradient: string
}

export type Color = string

export const colors = {
  gray: {
    100: 'var(--skin-gray-100)',
    200: 'var(--skin-gray-200)',
    300: 'var(--skin-gray-300)',
    400: 'var(--skin-gray-400)',
    500: 'var(--skin-gray-500)',
    600: 'var(--skin-gray-600)',
    700: 'var(--skin-gray-700)',
    800: 'var(--skin-gray-800)',
    900: 'var(--skin-gray-900)',
  },
  blue: {
    100: 'var(--skin-blue-100)',
    200: 'var(--skin-blue-200)',
    300: 'var(--skin-blue-300)',
    400: 'var(--skin-blue-400)',
    500: 'var(--skin-blue-500)',
    600: 'var(--skin-blue-600)',
    700: 'var(--skin-blue-700)',
    800: 'var(--skin-blue-800)',
    900: 'var(--skin-blue-900)',
  },
  green: {
    100: 'var(--skin-green-100)',
    200: 'var(--skin-green-200)',
    300: 'var(--skin-green-300)',
    400: 'var(--skin-green-400)',
    500: 'var(--skin-green-500)',
    600: 'var(--skin-green-600)',
    700: 'var(--skin-green-700)',
    800: 'var(--skin-green-800)',
    900: 'var(--skin-green-900)',
  },
  yellow: {
    100: 'var(--skin-yellow-100)',
    200: 'var(--skin-yellow-200)',
    300: 'var(--skin-yellow-300)',
    400: 'var(--skin-yellow-400)',
    500: 'var(--skin-yellow-500)',
    600: 'var(--skin-yellow-600)',
    700: 'var(--skin-yellow-700)',
    800: 'var(--skin-yellow-800)',
    900: 'var(--skin-yellow-900)',
  },
  orange: {
    100: 'var(--skin-orange-100)',
    200: 'var(--skin-orange-200)',
    300: 'var(--skin-orange-300)',
    400: 'var(--skin-orange-400)',
    500: 'var(--skin-orange-500)',
    600: 'var(--skin-orange-600)',
    700: 'var(--skin-orange-700)',
    800: 'var(--skin-orange-800)',
    900: 'var(--skin-orange-900)',
  },
  red: {
    100: 'var(--skin-red-100)',
    200: 'var(--skin-red-200)',
    300: 'var(--skin-red-300)',
    400: 'var(--skin-red-400)',
    500: 'var(--skin-red-500)',
    600: 'var(--skin-red-600)',
    700: 'var(--skin-red-700)',
    800: 'var(--skin-red-800)',
    900: 'var(--skin-red-900)',
  },

  periwinkle: {
    dark: 'var(--skin-periwinkle-dark)',
    light: 'var(--skin-periwinkle-light)',
    gradient: 'var(--skin-periwinkle-gradient)',
  },
  sky: {
    dark: 'var(--skin-sky-dark)',
    light: 'var(--skin-sky-light)',
    gradient: 'var(--skin-sky-gradient)',
  },
  mindaro: {
    dark: 'var(--skin-mindaro-dark)',
    light: 'var(--skin-mindaro-light)',
    gradient: 'var(--skin-mindaro-gradient)',
  },
  jasmine: {
    dark: 'var(--skin-jasmine-dark)',
    light: 'var(--skin-jasmine-light)',
    gradient: 'var(--skin-jasmine-gradient)',
  },
  peach: {
    dark: 'var(--skin-peach-dark)',
    light: 'var(--skin-peach-light)',
    gradient: 'var(--skin-peach-gradient)',
  },

  white: 'var(--skin-white)',
  black: 'var(--skin-black)',
  linen: 'var(--skin-linen)',
} as const satisfies Record<string, Color | ColorScale | ColorDecorative>
