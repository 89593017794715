import { z } from 'zod'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'

export const mutaSocialSecurityRegimesTypes = ['GENERAL-TNS', 'GENERAL', 'AGRICOLE', 'ALSACE-MOSELLE'] as const
export const mutaSocialSecurityRegimeTypeSchema = z.enum(mutaSocialSecurityRegimesTypes)
export type MutaSocialSecurityRegimeType = z.infer<typeof mutaSocialSecurityRegimeTypeSchema>

export const mutaSocialSecurityRegimeEntries: readonly [
  MutaSocialSecurityRegimeType,
  { label: string; tooltip: string },
][] = [
  [
    'GENERAL-TNS',
    {
      label: 'Régime général (TNS)',
      tooltip: 'Le régime général de la sécurité sociale pour les auto-entrepreneurs et les entreprises individuelles.',
    },
  ],
  [
    'GENERAL',
    {
      label: 'Régime général (salarié)',
      tooltip: 'Le régime général de la sécurité sociale pour les salariés.',
    },
  ],
  ['AGRICOLE', { label: 'Régime agricole', tooltip: 'Le régime dédié aux salariés et exploitants agricoles.' }],
  [
    'ALSACE-MOSELLE',
    {
      label: 'Régime Alsace-Moselle',
      tooltip: 'Le régime spécifique aux entreprises situées dans le Haut-Rhin, le Bas-Rhin et la Moselle.',
    },
  ],
]

export const mutaSocialSecurityRegimeDimension = new StringsWithDataEnumDimension({
  name: 'mutaSocialSecurityRegime',
  displayValues: { name: 'Votre régime social' },
  entries: mutaSocialSecurityRegimeEntries,
  tags: ['MUTA'],
} as const)
