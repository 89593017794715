import { getIntlMessages, type Language } from '@orus.eu/translations'
import { IntlProvider } from 'react-intl'
import { LanguageProvider } from './language-context'

/**
 * Use this to define the language of a part of the application.
 */
export const LocalizationProvider: React.FC<{ children: React.ReactNode; language: Language }> = ({
  children,
  language,
}) => {
  return (
    <LanguageProvider value={language}>
      <IntlProvider messages={getIntlMessages(language)} locale={localesByLanguage[language]} defaultLocale="fr-FR">
        {children}
      </IntlProvider>
    </LanguageProvider>
  )
}

const localesByLanguage: Record<Language, string> = { fr: 'fr', es: 'es' }
