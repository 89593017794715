export class ExclusiveOperationsQueue {
  private pendingOperationPromise: Promise<void> | null = null
  private queue = new Array<() => Promise<void>>()

  private startNextOperationIfNeeded(): void {
    if (this.pendingOperationPromise) {
      // operation runnning, the finally block will trigger next operation when needed
      return
    }
    if (this.queue.length === 0) {
      // nothing to start
      return
    }
    const [operation] = this.queue.splice(0, 1)
    this.pendingOperationPromise = operation()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.pendingOperationPromise.finally(() => {
      this.pendingOperationPromise = null
      this.startNextOperationIfNeeded()
    })
  }

  run<T>(asyncFunction: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.queue.push(() => asyncFunction().then(resolve, reject))
      this.startNextOperationIfNeeded()
    })
  }
}
