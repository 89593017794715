import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'
import { colors } from '../../../colors.js'
import { borderRadius } from '../../../foundation/border-radius-tokens.js'
import { colorTokens } from '../../../foundation/color-tokens.js'
import { shadow } from '../../../foundation/shadow-tokens.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { useScreenType } from '../../../hooks/index.js'
import { mobileSideSpace } from '../../../theme.js'

export type ResponsiveCardProps = {
  children: ReactNode
  className?: string
}
export const ResponsiveCard = memo<ResponsiveCardProps>(function ResponsiveCard(props: ResponsiveCardProps) {
  const { children, className } = props
  const screenType = useScreenType()

  return (
    <div
      css={
        screenType === 'desktop'
          ? css`
              padding: ${spacing[70]};
              border: 1px ${colorTokens['color-stroke-base']} solid;
              border-radius: ${borderRadius[30]};
              box-shadow: ${shadow.bottom[10]};
              background-color: ${colors.white};
            `
          : css`
              padding: ${spacing[70]} ${mobileSideSpace} ${spacing[60]} ${mobileSideSpace};
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
              box-shadow: ${shadow.bottom[10]};
              background-color: ${colors.white};
            `
      }
      className={className}
    >
      {children}
    </div>
  )
})
