import type { TypeOfDimension } from '../abstract-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'

export const rcdaBossExperienceDimension = new StringsWithDataEnumDimension({
  name: 'rcdaBossExperience',
  displayValues: {
    name: 'Expérience du dirigeant',
    hint: 'Les années complètes d’expérience, en incluant les apprentissages, les alternances (50 % en entreprise) et les stages de plus de 3 mois',
    placeholder: '1-3',
  },
  entries: [
    ['0-1', { label: 'Moins d’1 an' }],
    ['1-3', { label: '1 à 3 ans' }],
    ['4-5', { label: '3 à 5 ans' }],
    ['6-10', { label: '5 à 10 ans' }],
    ['10+', { label: 'Plus de 10 ans' }],
  ],
  tags: ['RCDA'],
} as const)

export type RcdaBossExperience = TypeOfDimension<typeof rcdaBossExperienceDimension>
