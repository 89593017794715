import { type Capitalization, type DimensionOptions, type GramaticalGender } from './abstract-dimension.js'
import { StringsEnumDimension } from './strings-enum-dimension.js'

export class PaymentRecurrenceDimension<NAME extends string> extends StringsEnumDimension<
  NAME,
  readonly ['monthly', 'yearly']
> {
  constructor(options: DimensionOptions<NAME>) {
    super({ ...options, values: ['monthly', 'yearly'] })
  }
}
export type PaymentRecurrence = 'monthly' | 'yearly'

export const paymentRecurrenceLabel: {
  [key in GramaticalGender]: { [key in Capitalization]: { [key in PaymentRecurrence]: string } }
} = {
  feminine: {
    capitalized: {
      monthly: 'Mensuelle',
      yearly: 'Annuelle',
    },
    normal: {
      monthly: 'mensuelle',
      yearly: 'annuelle',
    },
  },
  masculine: {
    capitalized: {
      monthly: 'Mensuel',
      yearly: 'Annuel',
    },
    normal: {
      monthly: 'mensuel',
      yearly: 'annuel',
    },
  },
}
