import styled from '@emotion/styled'
import { Children, cloneElement, memo, type ReactElement } from 'react'
import { spacing } from '../../foundation'
import { type OrusTabSize, type TabProps } from './tab'

type TabbarProps = {
  children: ReactElement<TabProps>[]
  size: OrusTabSize
  onClick: (index: number) => void
  activeTabIndex: number
}

export const Tabbar = memo<TabbarProps>(function Tabbar(props) {
  const { children, size, onClick, activeTabIndex } = props

  return (
    <TabbarContainer role="tablist">
      {Children.map(children, (child, index) =>
        cloneElement(child, {
          onClick: () => onClick(index),
          active: index === activeTabIndex,
          size: size,
        }),
      )}
    </TabbarContainer>
  )
})

const TabbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[40]};
`
