import { TechnicalError } from '@orus.eu/error'

export function isProductionEnvironment(): boolean {
  return getEnvironmentName() === 'production'
}

export function isReviewEnvironment(): boolean {
  const environmentName = getEnvironmentName()
  return environmentName !== 'dev' && environmentName !== 'production'
}

export function isReviewOrProductionEnvironment(): boolean {
  return isProductionEnvironment() || isReviewEnvironment()
}

export function isLocalDevEnvironment(): boolean {
  return getEnvironmentName() === 'dev'
}

export function getEnvironmentName(): string {
  if (origin === 'https://app.orus.eu' || origin === 'https://orus-prod-app.herokuapp.com') {
    return 'production'
  }

  if (origin === 'https://app.orustest.eu') {
    return 'staging'
  }

  if (origin.startsWith('http://localhost') || origin.endsWith('.localhost.orustest.eu')) {
    return 'dev'
  }

  const reviewEnvName = extractReviewEnvironmentName(origin)
  if (reviewEnvName) {
    return reviewEnvName
  }

  throw new TechnicalError('Could not detect environment')
}

function extractReviewEnvironmentName(originString: string): string | undefined {
  const start = 'https://'
  const end = '.orustest.eu'
  return originString.startsWith(start) && originString.endsWith(end)
    ? originString.substring(start.length, originString.length - end.length)
    : undefined
}
