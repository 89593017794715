import { z } from 'zod'

type GroupsCategoryMetadata = {
  visible: boolean
  displayName: string
  placeHolder: string
}

export const groupsCategoryIds = [
  'consultingServices',
  'construction',
  'wellnessBeauty',
  'restaurantsCoffee',
  'tech',
  'retailCraft',
  'securityTransport',
  'cultureMediaTourism',
] as const
export type GroupsCategory = (typeof groupsCategoryIds)[number]
export const groupsCategoryZodSchema = z.enum(groupsCategoryIds)

export function isGroupsCategory(value: string): value is GroupsCategory {
  return groupsCategoryZodSchema.safeParse(value).success
}

export const groupsCategories: Record<GroupsCategory, GroupsCategoryMetadata> = {
  consultingServices: {
    displayName: 'Conseil et services professionnels',
    visible: true,
    placeHolder: 'Exemple : Agence de courtage en prêt...',
  },
  wellnessBeauty: {
    displayName: 'Bien-être, beauté et développement personnel',
    visible: true,
    placeHolder: 'Exemple : Apithérapie...',
  },
  restaurantsCoffee: {
    displayName: 'Restaurants, bars et cafés',
    visible: true,
    placeHolder: 'Exemple : Traiteur...',
  },
  tech: {
    displayName: 'Informatique et technologie',
    visible: true,
    placeHolder: 'Exemple : Data engineer...',
  },
  retailCraft: {
    displayName: 'Commerce et artisanat',
    visible: true,
    placeHolder: 'Exemple : Laverie automatique...',
  },
  securityTransport: {
    displayName: 'Sécurité et transport',
    visible: true,
    placeHolder: 'Exemple : Télésurveillance...',
  },
  cultureMediaTourism: {
    displayName: 'Culture, média et tourisme',
    visible: true,
    placeHolder: 'Exemple : Acteur...',
  },
  construction: {
    displayName: 'Construction et BTP',
    visible: true,
    placeHolder: 'Exemple : Maçon...',
  },
}
