import {
  activityAddressCityDimension,
  activityAddressPostCodeDimension,
  activityAddressStreetDimension,
  companyCreationDateDimension,
  companyIdNumberDimension,
  companyNameDimension,
  placeNameDimension,
  placeSearchQueryDimension,
  placeSearchResultsDimension,
  siretDimension,
} from '../dimension'
import type { SiretSearchSubscriptionUiElement } from './subscription-ui-element'

export const siretSearchSubscriptionBodyElement: SiretSearchSubscriptionUiElement = {
  type: 'siret-search',
  cityFormat: 'wakam',
  searchText: 'Rechercher mon entreprise (nom, SIREN, SIRET)',
  cantFindText: 'Établissement en cours de création / je ne trouve pas mon établissement',
  dimensions: {
    placeSearchQuery: placeSearchQueryDimension,
    placeSearchResults: placeSearchResultsDimension,
    siret: siretDimension,
    companyIdNumber: companyIdNumberDimension,
    placeName: placeNameDimension,
    activityAddressStreet: activityAddressStreetDimension,
    activityAddressPostCode: activityAddressPostCodeDimension,
    activityAddressCity: activityAddressCityDimension,
    companyName: companyNameDimension,
    companyCreationDate: companyCreationDateDimension,
  },
}
