import styled from '@emotion/styled'
import { memo, type ReactElement, type ReactNode } from 'react'
import { spacing } from '../../foundation'
import type { Switch } from '../atoms'
import type { Select, TextField } from '../inputs'

export type FormInput = ReactElement<typeof TextField> | ReactElement<typeof Switch> | ReactElement<typeof Select>

export type FormRowProps = {
  children: ReactNode
  isSingleMini?: boolean
}

export const FormRow = memo<FormRowProps>(function FormRow(props: FormRowProps) {
  const { children, isSingleMini = false } = props

  return <FormRowContainer isSingleMini={isSingleMini}>{children}</FormRowContainer>
})

const FormRowContainer = styled.div<{ isSingleMini?: boolean }>`
  --gap: ${spacing[50]};
  --element-min-width: 176px;

  display: flex;
  flex-flow: row wrap;
  gap: var(--gap);

  & > * {
    min-width: var(--element-min-width);
    max-width: ${({ isSingleMini }) => (isSingleMini ? '50%' : '100%')};
    padding-inline-end: ${({ isSingleMini }) => (isSingleMini ? 'var(--gap)' : '0')};
    flex: 1 1 var(--element-min-width);
  }
`
