import { type SerializedStyles } from '@emotion/react'
import { memo } from 'react'
import { Select, type SelectProps } from '../../../inputs/index.js'
import { TextInputLabelWrapper, type TextInputLabelWrapperProps } from '../../input-label-wrapper/index.js'

export type DropdownFormFieldProps = SelectProps &
  Omit<TextInputLabelWrapperProps, 'children'> & { fieldClassName?: SerializedStyles }

export const DropdownFormField = memo<DropdownFormFieldProps>(function DropdownFormField(props) {
  return (
    <TextInputLabelWrapper
      className={props.className}
      variant={props.variant}
      label={props.label}
      badge={props.badge}
      caption={props.caption}
      infoTooltip={props.infoTooltip}
      required={props.required}
    >
      <Select {...props} css={props.fieldClassName} />
    </TextInputLabelWrapper>
  )
})
