import { isActivity, type Activity } from '@orus.eu/activity'
import { failure, success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  BaseDimension,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export type ActivitySpecificQuestionShape = {
  readonly id: string
  readonly affirmation: string
  /**
   * Use this field to generate an exclusion when the answer to the question is true. The value passed to the field is the description of the excluded places. Example: "les cafés ayant une activité de bureau de tabac".
   */
  readonly excludedOnTrue?: string
  readonly tagOnTrue?: string
  readonly tagOnFalse?: string
  readonly if?: ActivitySpecificQuestionCondition
}

export type ActivitySpecificQuestionCondition =
  | string
  | { readonly and: readonly ActivitySpecificQuestionCondition[] }
  | { readonly or: readonly ActivitySpecificQuestionCondition[] }
  | { readonly not: ActivitySpecificQuestionCondition }

const hasMoreThanHalfRevenuePizzaQuestion = {
  id: 'hasMoreThanHalfRevenuePizza',
  affirmation: 'La vente de pizzas génère plus de 50% de votre CA',
} as const satisfies ActivitySpecificQuestionShape

const woodOvenQuestion = {
  id: 'hasWoodOven',
  affirmation: 'Votre établissement utilise un four à bois',
  excludedOnTrue: 'les pizzeria servant des pizzas cuites dans un four à bois',
} as const satisfies ActivitySpecificQuestionShape

const woodOvenQuestionForRequalifiable = {
  ...woodOvenQuestion,
  excludedOnTrue: 'les restaurants servant des pizzas cuites dans un four à bois',
  if: 'hasMoreThanHalfRevenuePizza',
} as const satisfies ActivitySpecificQuestionShape

const pizzeriaRequalificationQuestions = [
  hasMoreThanHalfRevenuePizzaQuestion,
  woodOvenQuestionForRequalifiable,
] as const

const wholesalingExclusionQuestion = {
  id: 'doesWholesaleing',
  affirmation: 'Votre entreprise a une activité de grossiste',
  excludedOnTrue: 'les commerçants vendant en gros dans votre domaine',
} as const satisfies ActivitySpecificQuestionShape
const wholesalingExclusionQuestions = [wholesalingExclusionQuestion]

const wellnessSaleOfProductsExclusionQuestion = {
  id: 'sellsProductsOrPrescriptions',
  affirmation: 'Votre entreprise vend ou prescrit des produits ou des compléments alimentaires',
  excludedOnTrue: 'les activités de votre domaine vendant ou prescrivant des produits ou des compléments alimentaires',
} as const satisfies ActivitySpecificQuestionShape
const wellnessSaleOfProductsExclusionQuestions = [wellnessSaleOfProductsExclusionQuestion]

const wellnessMedicalActsExclusionQuestion = {
  id: 'doesMedicalActs',
  affirmation: 'Votre entreprise réalise des prestations médicales ou paramédicales',
  excludedOnTrue: 'les activités de votre domaine réalisant des prestations médicales ou paramédicales',
} as const satisfies ActivitySpecificQuestionShape

const cinemaAndTvProductionsExclusionQuestion = {
  id: 'worksForCinemaAndTvProductions',
  affirmation: 'Votre entreprise intervient sur des productions d’œuvres destinées au cinéma et à la télévision',
  excludedOnTrue:
    'les activités de votre domaine intervenant pour des productions d’œuvres destinées au cinéma et à la télévision',
} as const satisfies ActivitySpecificQuestionShape
const cinemaAndTvProductionsExclusionQuestions = [cinemaAndTvProductionsExclusionQuestion]

const notLoiBorlooExclusionQuestion = {
  id: 'isNotLoiBorloo',
  affirmation: 'Votre entreprise n’intervient pas dans le cadre de la loi Borloo',
  excludedOnTrue: 'votre activité si elle n’intervient pas dans le cadre de la loi Borloo',
} as const satisfies ActivitySpecificQuestionShape
const notLoiBorlooExclusionQuestions = [notLoiBorlooExclusionQuestion]

const doesAcupunctureExclusionQuestion = {
  id: 'doesAcupuncture',
  affirmation: 'Votre entreprise fait de l’acupuncture',
  excludedOnTrue: 'votre activité si elle procède à de l’acupuncture',
} as const satisfies ActivitySpecificQuestionShape
const doesAcupunctureExclusionQuestions = [doesAcupunctureExclusionQuestion]

const doesAcupunctureWithoutADoctorExclusionQuestion = {
  id: 'doesAcupunctureWithoutADoctor',
  affirmation:
    'Votre entreprise fait réaliser des séances d’acupuncture par des personnes qui ne sont ni docteur en médecine ni kinésithérapeute',
  excludedOnTrue:
    'votre activité si elle fait réaliser des séances d’acupuncture par des personnes qui ne sont ni docteur en médecine ni kinésithérapeute',
} as const satisfies ActivitySpecificQuestionShape
const doesAcupunctureWithoutADoctorExclusionQuestions = [doesAcupunctureWithoutADoctorExclusionQuestion]

const travelBookingExclusionQuestion = {
  id: 'doesTravelBookingForThirdParty',
  affirmation: 'Votre entreprise effectue des réservations de voyages ou séjours pour le compte de tiers',
  excludedOnTrue: 'votre activité si elle inclue la vente des voyages ou séjours',
} as const satisfies ActivitySpecificQuestionShape
const travelBookingExclusionQuestions = [travelBookingExclusionQuestion]

const colonHydrotherapyExclusionQuestion = {
  id: 'doesColonhydrotherapy',
  affirmation: 'Votre entreprise effectue des hydrothérapies du colon',
  excludedOnTrue: 'votre activité si elle procède à des hydrothérapies du colon',
} as const satisfies ActivitySpecificQuestionShape
const colonHydrotherapyExclusionQuestions = [colonHydrotherapyExclusionQuestion]

const networkingInConstructionExclusionQuestion = {
  id: 'doesNetworkingInConstruction',
  affirmation: 'Votre entreprise fait du courtage ou de la mise en relation pour des travaux de bâtiment',
  excludedOnTrue: 'les entreprises de mise en relation pour des travaux de bâtiment',
} as const satisfies ActivitySpecificQuestionShape

const isLoiBorlooExclusionQuestion = {
  id: 'isLoiBorloo',
  affirmation: 'Votre entreprise intervient dans le cadre de la loi Borloo',
  excludedOnTrue: 'votre activité si elle intervient dans le cadre de la loi Borloo',
} as const satisfies ActivitySpecificQuestionShape
const isLoiBorlooExclusionQuestions = [isLoiBorlooExclusionQuestion]

const isRealEstateAgentOrDoesNetworking = {
  id: 'doesNetworkingInRealEstate',
  affirmation:
    'Votre entreprise procède à des prestations d’agent immobilier ou effectue des mises en relation dans la location/vente de biens immobiliers',
  excludedOnTrue:
    'votre activité si elle réalise des prestations d’agent immobilier ou de mise en relation dans la location ou vente de biens immobiliers',
} as const satisfies ActivitySpecificQuestionShape

const industrialDesignExclusionQuestion = {
  id: 'doesIndustrialDesign',
  affirmation: 'Votre entreprise conçoit des produits ou intervient sur du design industriel',
  excludedOnTrue: 'votre activité si elle procède à du design industriel ou de la conception de produits',
} as const satisfies ActivitySpecificQuestionShape
const industrialDesignExclusionQuestions = [industrialDesignExclusionQuestion]

export const strictlyTypedActivitySpecificQuestions = {
  coffeeBar: [
    {
      id: 'hasTobacco',
      affirmation: 'Votre établissement vend du tabac',
      excludedOnTrue: 'les établissements qui vendent du tabac',
    },
    ...pizzeriaRequalificationQuestions,
  ],
  traditionalRestaurant: pizzeriaRequalificationQuestions,
  pizzeria: [woodOvenQuestion],
  fastFood: [
    ...pizzeriaRequalificationQuestions,
    {
      id: 'hasCookingEquipment',
      affirmation: 'Votre restaurant utilise du matériel de cuisson (fours, friteuses, marmites et feux vifs)',
      tagOnTrue: 'HAS_COOKING_EQUIPMENT',
      tagOnFalse: 'NOT_HAS_COOKING_EQUIPMENT',
      if: {
        // make the question disapear if they say the do pizza (they are requalified, so the question
        // becomse irrelevant)
        not: 'hasMoreThanHalfRevenuePizza',
      },
    },
    {
      id: 'hasKebab',
      affirmation: 'Votre restaurant prépare des kebabs',
      tagOnTrue: 'ACTIVITY_KEBAB',
      if: {
        and: [
          {
            // make the question disapear if they say the do pizza (they are requalified, so the question
            // becomse irrelevant)
            not: 'hasMoreThanHalfRevenuePizza',
          },
          // Ask only if they have cooking equipment, because you can't make kebabs without cooking equipement
          'hasCookingEquipment',
        ],
      },
    },
  ],
  sushi: [
    {
      id: 'hasCookingEquipmentExceptForRice',
      affirmation: 'Votre restaurant utilise du matériel de cuisson autre que le matériel pour faire cuire du riz',
    },
  ],
  caterer: [
    {
      id: 'lessThanSixtyPercentOfRevenueInside',
      affirmation:
        "Votre établissement génère moins de 60% de son chiffre d'affaires dans un local professionnel accueillant du public",
      excludedOnTrue:
        'les traiteurs pour lesquels l’accueil du public dans leur local professionnel représente moins de 60% du chiffre d’affaire',
    },
    ...pizzeriaRequalificationQuestions,
  ],
  '6687c8b4-df16-428f-bee5-a4a38011a3a5': wholesalingExclusionQuestions,
  '93fa60cf-f6c4-499a-b855-bc58b036b573': wholesalingExclusionQuestions,
  '77dcf20b-5213-4da0-892d-c35d6cf44bea': [
    {
      id: 'sellsTobacco',
      affirmation: 'Votre entreprise vend du tabac',
      excludedOnTrue: 'Nous ne pouvons pas encore assurer les libraries et papeteries vendant du tabac.',
    },
    wholesalingExclusionQuestion,
  ],
  'afc4279f-536e-466c-97d2-4f376485be10': wholesalingExclusionQuestions,
  '5b181507-f061-4edb-a87d-9f0516ecf2be': wholesalingExclusionQuestions,
  '83ca94fd-568e-4a75-90dc-7aede3bfe7c7': wholesalingExclusionQuestions,
  '3cb47245-519c-4b77-8843-35aae8c18a7b': wholesalingExclusionQuestions,
  'a74c6e08-8124-43de-a7e6-762707a97825': wholesalingExclusionQuestions,
  'd391d169-8868-45ad-a451-8cfb9a4a5967': wholesalingExclusionQuestions,
  '9567632f-dc48-4edd-a78e-2897495ccaf0': wholesalingExclusionQuestions,
  'b6545179-344f-428f-897c-e9ad2f476eec': wholesalingExclusionQuestions,
  '54c62891-a276-4051-8723-1f254624a856': wholesalingExclusionQuestions,
  '93dcbb77-bd68-4605-8413-dc2990a4c8a8': wholesalingExclusionQuestions,
  '99d56449-c6c0-42fe-9cc4-937d41e57525': wholesalingExclusionQuestions,
  'c5d89007-1585-4bff-8e82-cbc0e713f19b': wholesalingExclusionQuestions,
  '336c569a-ef6e-4a50-b25a-71d76f1a41db': wholesalingExclusionQuestions,
  'a53a68c4-0788-4ae0-9ecc-c06994f38bd0': wholesalingExclusionQuestions,
  '8d2d4320-d61e-48eb-a65f-027543f916cc': wholesalingExclusionQuestions,
  '71db9e3f-35ec-490d-a134-f56aa0cca710': wholesalingExclusionQuestions,
  '7ac250c9-2c04-4df5-94e0-a17086be02a1': wholesalingExclusionQuestions,
  '07a5e870-78ad-45e2-b65e-fad0de2a7c40': wholesalingExclusionQuestions,
  '1bf42674-0977-4d82-8218-feac24bc9862': wholesalingExclusionQuestions,
  'd15dd219-f0ac-4619-b322-bb872d49ae99': wholesalingExclusionQuestions,
  'a0bfd71e-083c-42f1-8b6f-519fc770e0c5': wholesalingExclusionQuestions,
  'b84a0575-a5e6-4f9c-8850-237b453d40da': wellnessSaleOfProductsExclusionQuestions,
  'c595ea7d-69e2-448e-aa3a-ee9f11a40594': wellnessSaleOfProductsExclusionQuestions,
  '501a691a-db2b-4363-b113-a4af79784410': wellnessSaleOfProductsExclusionQuestions,
  'fe9bc333-e922-480b-bbdd-dd5c52a91d69': wellnessSaleOfProductsExclusionQuestions,
  '0adc0451-4dd0-4c1a-927c-e971095b2056': wellnessSaleOfProductsExclusionQuestions,
  '103610e4-036a-49fd-ba83-ffa355f2bb14': wellnessSaleOfProductsExclusionQuestions,
  '970958b4-d8bf-4906-bb41-1a2685019f3b': wellnessSaleOfProductsExclusionQuestions,
  '33a66c5d-8b1d-4dce-9fc5-801596086693': wellnessSaleOfProductsExclusionQuestions,
  '19306980-ca28-4215-a745-b4abaf202355': wellnessSaleOfProductsExclusionQuestions,
  '1cae5926-2a7b-4040-85d1-c40ed876dca1': wellnessSaleOfProductsExclusionQuestions,
  '9bebe46c-febb-4d33-913d-7eb5b79baed6': wellnessSaleOfProductsExclusionQuestions,
  'f2c85c1b-49ba-4533-b0d5-0ecfda4fce32': wellnessSaleOfProductsExclusionQuestions,
  '750cb518-c100-4147-882a-6c4cb72602d2': [wellnessMedicalActsExclusionQuestion, notLoiBorlooExclusionQuestion],
  'dcd78c31-f6d3-450a-aeb8-8d76cb28d537': cinemaAndTvProductionsExclusionQuestions,
  '86a5dd36-031b-4377-ae8c-e8b9c8fb99d5': cinemaAndTvProductionsExclusionQuestions,
  '4d1ee304-d3e3-4ba3-b761-b00216496583': cinemaAndTvProductionsExclusionQuestions,
  '46620837-649e-49d5-b3c4-16039d434427': cinemaAndTvProductionsExclusionQuestions,
  '8f6cd5da-bc9a-4240-b4db-519aaaba4f2a': [
    {
      id: 'preparesChemicalProductsOrHasLab',
      affirmation: 'Votre entreprise prépare des produits chimiques ou possède un laboratoire',
      excludedOnTrue: 'les droguistes préparant des produits chimiques ou possédant un laboratoire',
    },
    wholesalingExclusionQuestion,
  ],
  '25600391-c705-4034-a79e-b58b5f9a9ce7': [
    {
      id: 'sellsShoesOrLeatherGoods',
      affirmation: 'Votre entreprise vend des chaussures ou de la maroquinerie',
      excludedOnTrue: 'les cordonniers vendant des chaussures ou de la maroquinerie',
    },
  ],
  'a1f46975-2c24-4123-8a15-a470ec54d84d': [
    {
      id: 'sellsJewelry',
      affirmation: 'Votre magasin vend des bijoux',
      excludedOnTrue: 'les magasins d’accessoires d’habillement vendant des bijoux',
    },
    wholesalingExclusionQuestion,
  ],
  '26b8b554-6caa-4d72-96a3-d143055dcaad': [
    {
      id: 'sellsOfficeOrComputerEquipment',
      affirmation: 'Votre magasin vend du matériel informatique / bureautique',
      excludedOnTrue: 'les magasins de fournitures de bureaux vendant du matériel informatique / bureautique',
    },
    wholesalingExclusionQuestion,
  ],
  'f6aa7928-8f3a-441a-96c2-f7882b937382': [
    {
      id: 'hasFuneralServiceActivity',
      affirmation: 'Votre magsin a une activité de pompes funèbres',
      excludedOnTrue: 'les magasins de vente d’articles funéraires ayant une activité de pompes funèbres',
    },
    wholesalingExclusionQuestion,
  ],
  '6a58fe26-a769-4d9c-ade9-c4dfb9de37f5': [
    {
      id: 'sellsJewelryAbove300euros',
      affirmation: 'Votre magasin vend des bijoux d’une valeur supérieure à 300 €',
      excludedOnTrue: 'les magasins de vente de bijoux vendant des bijoux d’une valeur supérieure à 300 €',
    },
    wholesalingExclusionQuestion,
  ],
  'a05eaa4e-9c78-474b-878b-6c0c64dd6a80': [
    {
      id: 'sellsSportShoesAbove20Percent',
      affirmation: 'Votre magasin vend des chaussures de sport et leur valeur est plus grande que 20% du stock',
      excludedOnTrue:
        'les magasins de chaussures vendant des chaussures de sport dont la valeur excède 20% de la valeur totale des marchandises',
    },
    wholesalingExclusionQuestion,
  ],
  'b5aa18b7-93e4-4fbf-820c-001463e161a4': [
    {
      id: 'sellsAudiovisualOrMultimediaEquipment',
      affirmation: 'Votre magasin vend des produits audiovisuels, multimédias ou de la téléphonie',
      excludedOnTrue: 'les magasins de vente de luminaires vendant des produits audiovisuels ou multimedia',
    },
    wholesalingExclusionQuestion,
  ],
  'ced37235-bc0d-4a81-bdfd-4bc99f2576b8': [
    {
      id: 'sellsNonEUOrNonCompliantDietProducts',
      affirmation:
        'Votre magasin vend des produits fabriqués hors de l’Union Européenne ou non-conformes aux normes en vigueur en France',
      excludedOnTrue:
        'les magasins de vente de produits diététiques vendant des produits fabriqués hors de l’Union Européenne ou non conformes aux normes en vigueur en France',
    },
  ],
  '0c9a9824-1def-49e4-81b0-f1c0100fafd7': [
    {
      id: 'sellsLeatherGoodsAbove20Percent',
      affirmation: 'Votre magasin vend des articles en cuirs et peaux excédant 20% de la valeur totale du stock',
      excludedOnTrue:
        'les magasins de vêtements vendant des articles en cuir et peaux dont la valeur excède 20% de la valeur totale des marchandises',
    },
    wholesalingExclusionQuestion,
  ],
  'ae7076f5-60a9-46dd-ab2c-9d624ca98fb5': [
    {
      id: 'sellsAudioVisualsOrMultimediaOrTelephony',
      affirmation: 'Votre magasin vend des produits audiovisuels, multimédias ou de la téléphonie',
      excludedOnTrue:
        "les magasins d'appareils électroménagers vendant des produits audiovisuels, multimédias ou de la téléphonie",
    },
    wholesalingExclusionQuestion,
  ],
  '783fe15a-e3be-41ff-a3b0-fc2bc3b9b5b3': [
    {
      id: 'designsSoftware',
      affirmation: 'Votre entreprise conçoit des logiciels',
      excludedOnTrue: 'les détaillants de matériel bureautique ou informatique qui conçoivent des logiciels',
    },
    wholesalingExclusionQuestion,
  ],
  'b0551f7e-9a0c-4f6a-93a5-d075cab26d85': [
    {
      id: 'coachesExtremeOrMechanicalSportsOrHorseRiding',
      affirmation: 'Votre entreprise encadre des sports mécaniques ou extrêmes ou de l’équitation',
      excludedOnTrue: 'les coaches sportifs encadrant des sports mécaniques ou extrêmes ou de l’équitation',
    },
    {
      id: 'managesAGym',
      affirmation: 'Votre entreprise exploite un établissement ou des équipements sportifs',
      excludedOnTrue: 'les coaches sportifs exploitant un établissement ou des équipements sportifs',
    },
  ],
  '430707b1-8bd8-471e-bbfc-b038f6e3d7a2': [
    {
      id: 'usesTreePruningTechniquesAbove3Meters',
      affirmation: 'Votre entreprise utilise des techniques d’élagage de plus de 3 mètres',
      excludedOnTrue:
        "les entreprises d'entretien des bureaux et/ou des espaces verts utilisant des techniques d'élagage de plus de 3 mètres",
    },
  ],
  '85b06227-a5e9-499b-95a4-d130215700be': [
    {
      id: 'manufacturesPromotionalItems',
      affirmation: 'Votre entreprise fabrique des objets publicitaires',
      excludedOnTrue: "les commerces de vente d'objets publicitaires qui fabriquent des objets publicitaires",
    },
  ],
  miseEnRelationPro: [
    {
      id: 'doesNetworkingInRegulatedSectors',
      affirmation:
        'Votre entreprise fait du courtage ou de la mise en relation pour des professions ou prestations réglementées',
      excludedOnTrue: 'les entreprises de mise en relation de professions réglementées ou prestations réglementées',
    },
    {
      id: 'doesNetworkingInTourism',
      affirmation:
        'Votre entreprise fait du courtage ou de la mise en relation dans le domaine touristique ou le voyage',
      excludedOnTrue: 'les entreprises de mise en relation dans le domaine touristique (y.c courtier en voyage)',
    },
    isRealEstateAgentOrDoesNetworking,
    networkingInConstructionExclusionQuestion,
    {
      id: 'doesNetworkingInCollaborativeFinancing',
      affirmation:
        'Votre entreprise fait du courtage ou de la mise en relation pour des opérations de financement participatif',
      excludedOnTrue: 'les entreprises de mise en relation pour des opérations de financement participatif',
    },
  ],
  'd5ba35cc-69b0-40b4-9687-88eed1e152df': [
    {
      id: 'usesEquitherapyTechniques',
      affirmation:
        "Votre entreprise utilise des techniques d'équithérapie, notamment le handisport, l'équihandi, l'équicie, l'hippothérapie; ou encadre de l'équitation",
      excludedOnTrue:
        "les entreprises de médiation équine qui utilisent des techniques d'équithérapie, notamment le handisport, l'équihandi, l'équicie, l'hippothérapie; ou encadre de l'équitation",
    },
  ],
  '9e49a432-3dd1-4a16-98f0-040356a89c49': [
    {
      id: 'usesVenom',
      affirmation: 'Votre entreprise utilise des venins',
      excludedOnTrue: 'les entreprises d’apithérapie qui utilisent des venins',
    },
  ],
  consultantRGPDDPO: [
    {
      id: 'isALawyer',
      affirmation: "Votre entreprise est un cabinet d'avocat",
      excludedOnTrue: 'les avocats exerçant l’activité de DPO / consultant RGPD',
    },
  ],
  'c91b1355-4f86-4574-89da-ef6c75add8e4': colonHydrotherapyExclusionQuestions,
  'e1822767-cfd7-4712-b8d3-d2fb1ae76806': colonHydrotherapyExclusionQuestions,
  'f0a88acd-4634-4c47-8c2e-d42d2ef717e4': [
    {
      id: 'doesOstheopathie',
      affirmation: 'Votre entreprise pratique l’ostéopathie',
      excludedOnTrue: 'les activités d’harmonisation vitale incluant de l’ostéopathie',
    },
  ],
  '6b28b437-6138-47dc-b1d4-06eeaee968ee': notLoiBorlooExclusionQuestions,
  '619b8bd1-ee93-4fd8-bfef-5110c83c2932': notLoiBorlooExclusionQuestions,
  '6c8e77af-cf96-4ae4-bdb2-d988d528a4ad': notLoiBorlooExclusionQuestions,
  '29572dc7-daa1-4361-aead-e6c8e6712fce': notLoiBorlooExclusionQuestions,
  'e7bae985-24d2-497d-a105-c1747eca5d5b': notLoiBorlooExclusionQuestions,
  'e2d6f076-7e21-4991-8fb4-e078e4cd1b2b': notLoiBorlooExclusionQuestions,
  '88c2dcc2-49b6-45ad-8b36-fd631c1eabfc': notLoiBorlooExclusionQuestions,
  '98c75acc-854b-4d86-8a56-67146127f142': notLoiBorlooExclusionQuestions,
  '257b2b08-d7b3-422d-8b17-14dbcc030390': notLoiBorlooExclusionQuestions,
  'a4d8d64f-be9b-48ac-86e2-3d39bf21e596': notLoiBorlooExclusionQuestions,
  '4fd86a3c-f1ad-42a9-8dd8-dfe3a1cbaf72': notLoiBorlooExclusionQuestions,
  '6b3d93f0-db09-40e6-bd5e-18f32e84a4ae': notLoiBorlooExclusionQuestions,
  '20e75920-92c2-4312-9a49-30bc1b8af349': notLoiBorlooExclusionQuestions,
  '177525af-e88f-4232-ab8f-34d9216d4851': notLoiBorlooExclusionQuestions,
  'bed12471-710e-4ff0-8a1f-ce942d7cabfa': notLoiBorlooExclusionQuestions,
  'e82b4fd0-e41a-45f7-93ef-cfc0eb877177': notLoiBorlooExclusionQuestions,
  'ed7c2ef9-60b2-4086-9866-2d8b85a9d156': notLoiBorlooExclusionQuestions,
  'f53e837c-41b8-48a0-9e11-fab4f91be2d9': notLoiBorlooExclusionQuestions,
  'b4623aca-3d0f-46b1-a56e-5e82936d3140': notLoiBorlooExclusionQuestions,
  '6202c99e-2b12-4fa1-af77-837921f73f79': notLoiBorlooExclusionQuestions,
  '9287ff36-17ba-464a-885f-08b8cd499ea1': notLoiBorlooExclusionQuestions,
  '85204fe3-3e4b-4ba8-868c-2a694b412985': [isLoiBorlooExclusionQuestion, wellnessMedicalActsExclusionQuestion],
  '6307e24b-1a24-4372-9056-5b93c7aee02d': isLoiBorlooExclusionQuestions,
  '4952ba32-8343-4e34-b7df-04f36583005e': doesAcupunctureExclusionQuestions,
  '97e886af-9398-4b9d-bac9-c9854ae9cf1f': doesAcupunctureExclusionQuestions,
  '71146750-193a-4c05-b00c-795a40aec421': doesAcupunctureExclusionQuestions,
  'e83ca9b3-f4ed-48e2-996a-cd08d1e86599': doesAcupunctureWithoutADoctorExclusionQuestions,
  '5201c629-20a0-46b8-811e-7b22c56b97ff': doesAcupunctureWithoutADoctorExclusionQuestions,
  '5cb73a55-905e-4724-b629-ec0ec68f640a': doesAcupunctureWithoutADoctorExclusionQuestions,
  '5b400d56-2734-4d41-a6a4-e5818fa20ebe': [
    {
      id: 'worksForIndustryClients',
      affirmation: 'Votre entreprise intervient pour des clients dans l’industrie',
      excludedOnTrue: 'votre activité si elle intervient pour des clients dans l’industrie',
    },
    {
      id: 'worksForConstructionClients',
      affirmation: 'Votre entreprise intervient pour des clients dans la construction et le BTP',
      excludedOnTrue: 'votre activité si elle intervient pour des clients le BTP',
    },
  ],
  'f31d491d-90de-4db3-8928-74743e373bf1': [
    {
      id: 'isBankOrBankingAgency',
      affirmation: 'Votre entreprise est une agence ou un établissement bancaire',
      excludedOnTrue: 'les agences ou établissements bancaires',
    },
  ],
  '89cccb7c-6dea-40cc-a96d-192e5e02561d': [
    {
      id: 'worksForRegulatedIndustriesOrTravelOrConstruction',
      affirmation:
        'Votre entreprise intervient pour des prestations réglementées ou en tant que courtier en voyage ou mise en relation dans le bâtiment',
      excludedOnTrue: 'les agents commerciaux et apporteurs d’affaires intervenant dans ces secteurs réglementés',
    },
  ],
  '75bdf0d8-983b-4465-aaac-548f29c9c8af': [
    {
      id: 'doesHomeImprovementForMoreThanTwoHoursOrElectricityOrGasOrWater',
      affirmation:
        'Votre entreprise réalise des bricolages de plus de 2h ou intervient sur des équipements de gaz, d’eau ou d’électricité',
      excludedOnTrue:
        'les activités de bricolage durant plus de 2h ou intervenant sur des équipements de gaz, eau ou électricité',
    },
  ],
  '9af8a0a2-cb93-4384-9224-106271f055b8': [
    {
      id: 'doesEmergencyCalls',
      affirmation: 'Votre entreprise intervient pour des appels d’urgence',
      excludedOnTrue: 'les activités de centre d’appels intervenant pour des appels d’urgence',
    },
  ],
  '7c3871b1-6b46-41ec-9e6d-2115cf79e5ad': travelBookingExclusionQuestions,
  '10235cb0-5bbf-4c35-8f93-3f645b3a443d': [
    {
      id: 'organizesEvents',
      affirmation: 'Votre entreprise organise des évènements, ateliers ou visites',
      excludedOnTrue: 'les conférenciers bien-être organisant des ateliers, évènements ou visites',
    },
  ],
  '13941adb-7fca-4e6c-b474-45244a9fe5de': [
    {
      id: 'doesLegalMediation',
      affirmation: 'Votre entreprise intervient dans des médiations judiciaires / pénales',
      excludedOnTrue: ' l’activité de médiation familiale intervenant dans des médiations judiciaires ou pénale',
    },
  ],
  'a42d9dd3-06ee-46a2-bb0d-13771a19ee20': [
    {
      id: 'doesLaborTaxAdvisory',
      affirmation:
        'Votre entreprise réalise des prestations de conseil en optimisation des charges fiscales et sociales',
      excludedOnTrue:
        'les activités de conseil en achat et réduction des coûts réalisant des conseils en optimisation des charges fiscales et sociales',
    },
  ],
  conseilCybersecurite: [
    {
      id: 'doesLongProjectManagementForMoreThanAYear',
      affirmation: 'Votre entreprise gère des projets durant plus d’un an',
      excludedOnTrue: 'les activités de conseil en cybersécurité gérant des projets de plus d’un an',
    },
  ],
  '5e131a6f-85c2-40ca-8bca-3b94e72b90a4': [
    {
      id: 'doesRegulatedFinancialAdvice',
      affirmation:
        'Votre entreprise procède à des prestations relevant d’une profession réglementée, de la prospective financière ou du conseil en investissement financier',
      excludedOnTrue:
        'les activités de conseil en fusion et acquisition procédant à des opérations relavant de professions réglementées',
    },
  ],
  'f781f184-611f-4453-b97f-f71e9ddadc67': [
    {
      id: 'doesRegulatedLegalAdvice',
      affirmation: 'Votre entreprise fournit des conseils juridiques',
      excludedOnTrue: 'l’activité de conseil en immigration procédant à des conseils juridiques',
    },
  ],
  'e1363838-241f-4332-9002-e9e04d153f37': [
    isRealEstateAgentOrDoesNetworking,
    networkingInConstructionExclusionQuestion,
    travelBookingExclusionQuestion,
  ],
  'f419908e-0c2d-41a3-ab49-443dd8eaebde': [
    {
      id: 'counselsAirports',
      affirmation: 'Votre entreprise conseille des aéroports',
      excludedOnTrue: "l'activité de conseil en sûreté quand elle conseille des aéroports",
    },
    {
      id: 'counselsNuclearSites',
      affirmation: 'Votre entreprise conseille des sites nucléaires',
      excludedOnTrue: "l'activité conseil en sûreté quand elle conseille des sites nucléaires",
    },
  ],
  'f8225c87-e1e0-44f1-bf35-ff019c346db3': [
    {
      id: 'sellsFurnituresOrDecoration',
      affirmation: 'Votre entreprise vend du mobilier ou des décorations',
      excludedOnTrue: "l'activité Conseil en Feng-Shui / Home staging si elle vend du mobilier ou des décorations",
    },
  ],
  '3793ab81-8200-4cb3-a440-174d6a8fac73': [
    {
      id: 'doesCNAPStrainingForSecurity',
      affirmation: 'Votre entreprise forme aux activités privées de sécurité soumises à agrément du CNAPS',
      excludedOnTrue:
        "l'activité formation en sécurité si elle forme aux activités privées de sécurité soumises à agrément du CNAPS",
    },
  ],
  '08274061-16a0-4101-afc0-b430a6393adf': [
    {
      id: 'isAKennel',
      affirmation: 'Votre entreprise est un chenil',
      excludedOnTrue: 'les chenils',
    },
  ],
  '376ed418-b3cb-492f-b29c-256b9d2ddeff': [
    {
      id: 'doesTelesurveillance',
      affirmation: 'Votre entreprise intervient pour de la télésurveillance',
      excludedOnTrue: 'votre activité de sécurité si elle intervient pour de la télésurveillance',
    },
  ],
  '0e2858bf-9f6c-416a-8da9-07cb93bb5c60': [
    {
      id: 'writesTechnicalManuals',
      affirmation: 'Votre entreprise intervient sur des notices techniques ou des manuels utilisateurs',
      excludedOnTrue: "l'activité de traducteur si elle intervient pour des notices techniques ou manuels utilisateurs",
    },
  ],
  'fd83d729-2e1a-49f0-8abc-c182ffb1ddea': [
    {
      id: 'doesPrunning',
      affirmation: "Votre entreprise procède à des élagages ou abattages d'arbres et/ou travaux de terrassement",
      excludedOnTrue:
        "l'activité de jardinage si elle procède à des élagages/abattages d'arbres et/ou travaux de terrassement",
    },
  ],
  '9bd629fc-950a-42a1-8d9a-bf592f7c06f5': [
    {
      id: 'isMediaJournalist',
      affirmation: "Vous ou votre entreprise intervenez en tant que reporter d'image",
      excludedOnTrue: "l'activité de Journaliste / Pigiste si elle intervient en tant que reporter d'image",
    },
  ],
  '896d1383-38fc-43af-aeef-688246989f6e': [
    {
      id: 'operatesASpa',
      affirmation: 'Votre entreprise exploite un spa',
      excludedOnTrue: "l'activité de massage bien être / modelage si elle exploite un spa",
    },
    wellnessMedicalActsExclusionQuestion,
  ],
  '015bfe2f-a508-4887-9f51-52e2490cc929': [
    {
      id: 'doesPrivateSecurity',
      affirmation: 'Votre entreprise fournit des prestations de sécurité privée',
      excludedOnTrue:
        "l'activité de mise à disposition d'agents et hôtesses d'accueil si elle procède à des prestations de sécurité privée",
    },
  ],
  'a74a9321-4c44-441a-9237-d3f05410091a': [
    {
      id: 'providesLongTermOffices',
      affirmation: 'Votre entreprise met à disposition des bureaux pour de longues durées',
      excludedOnTrue:
        "l'activité de mise à disposition de bureaux et d'espaces de travail si elle met à disposition des bureaux pour de longues durées",
    },
  ],
  '7630b1f1-f053-41b8-ac36-cfefeec3df9c': travelBookingExclusionQuestions,
  'db787f2e-fd15-4e85-a824-71d917eb6a05': [
    {
      id: 'usesHyperbaricChambers',
      affirmation: 'Votre entreprise utilise des caissons hyperbares',
      excludedOnTrue: "l'activité d'oxygénothérapie non médicale si elle utilise des caissons hyperbares",
    },
  ],
  'cc823640-e4c5-4c8f-a338-31d7e7475798': industrialDesignExclusionQuestions,
  'f71f6971-a490-4a08-a648-f6fecf9628d7': [
    {
      id: 'doesLiveTv',
      affirmation: 'Votre entreprise intervient pour le cinéma et la télévision en direct',
      excludedOnTrue:
        "l'activité de régisseur pour le compte de productions audiovisuelles et événementielles si elle concerne le cinéma ou la télévision en direct",
    },
  ],
  '013543ed-a18f-430f-b27d-d7d718a5f4c8': [
    {
      id: 'doesPornMovies',
      affirmation: 'Votre entreprise intervient sur des films pornographiques',
      excludedOnTrue:
        "l'activité de sous-titrage / doublage de films et/ou Sur-titrage de spectacles vivants si elles concernent des films pornographiques",
    },
  ],
  '2c6fe08e-c158-47f7-8dbf-bcaa0dcd8315': [
    {
      id: 'isAnAccountant',
      affirmation: 'Votre entreprise intervient en tant qu’expert comptable',
      excludedOnTrue: "l'activité de tenue des livres de comptes si elle est réalisée par un expert comptable",
    },
  ],
  'e351f5f5-4b1c-4a74-95ae-847568686b77': [
    {
      id: 'sellsFurnituresAndBeds',
      affirmation: 'Votre entreprise vend des meubles et de la literie',
      excludedOnTrue:
        "l'activité de vente d'objet de décoration intérieure, luminaires et petits mobiliers si elle inclut la vente de meubles ou de literie",
    },
  ],
  '8c1f2afd-90f0-4743-b312-739fe0984323': [
    {
      id: 'createsCaricatures',
      affirmation: 'Votre entreprise crée des caricatures ou des images provocatrices',
      excludedOnTrue: "les activités de créateurs d'images qui réalisent des caricatures ou des images provocatrices",
    },
  ],
  '41adaeba-8e62-477f-803e-5da9073ab331': [
    {
      id: 'makesOrInstallsScenes',
      affirmation: 'Votre entreprise fabrique ou installe les éléments conçus sans sous-traiter',
      excludedOnTrue:
        "les activités de design d'exposition ou scénographie qui fabriquent ou installent les éléments conçus sans sous-traiter",
    },
  ],
  'f552596e-0dee-4d4b-a511-49fb126e7e4c': industrialDesignExclusionQuestions,
  '614a1ac5-b709-4892-a459-629f36f21013': [
    {
      id: 'doesChineseTraditionalMedicine',
      affirmation: 'Votre entreprise pratique la médecine traditionnelle chinoise',
      excludedOnTrue: "l'énergétique chinoise si elle pratique  la médecine traditionnelle chinoise",
    },
    doesAcupunctureWithoutADoctorExclusionQuestion,
    wellnessSaleOfProductsExclusionQuestion,
  ],
  '077cc4c9-c052-489c-90af-9ad5f7fd1588': [
    {
      id: 'isAFrenchNationalEducationTeacher',
      affirmation: 'Vous enseignez dans l’Éducation Nationale',
      excludedOnTrue: "les enseignants de l'Education Nationale",
    },
  ],
  '491470c3-0a2d-4615-907a-2a70c33da849': [
    {
      id: 'usesAtlasProFilax',
      affirmation: 'Votre entreprise utilise la technique AtlasPROfilax',
      excludedOnTrue: 'votre activité si elle utilise la technique AtlasPROfilax',
    },
    wellnessSaleOfProductsExclusionQuestion,
  ],
  '8f3bc7b7-54fc-4d6f-b26b-e5c080f6d94f': [
    {
      id: 'usesPulsativeLightOrLaser',
      affirmation: 'Votre entreprise effectue des épilations à la lumière pulsée ou au laser',
      excludedOnTrue: "l'activité d'esthéticienne si elle utilise de la lumière pulsée ou du laser",
    },
  ],
  '851f776b-54b4-495a-8b32-03fefb036864': [
    {
      id: 'producesMedicalOrFinancialOrScientificContent',
      affirmation: 'Votre entreprise produit du contenu en matière financière, médicale ou scientifique',
      excludedOnTrue:
        "l'activité d'édition ou de publication de magazine si elle intervient dans en matière financière ou médicale ou scientifique",
    },
  ],
} as const satisfies { readonly [key in Activity]?: readonly ActivitySpecificQuestionShape[] }

export type ActivitySpecificQuestionId<ACTIVITY extends Activity = Activity> =
  ACTIVITY extends keyof typeof strictlyTypedActivitySpecificQuestions
    ? (typeof strictlyTypedActivitySpecificQuestions)[ACTIVITY][number]['id']
    : never

export type ActivitySpecificQuestion = ActivitySpecificQuestionShape & {
  readonly id: ActivitySpecificQuestionId
}

export const activitySpecificQuestions: { [key in string]: readonly ActivitySpecificQuestion[] } =
  strictlyTypedActivitySpecificQuestions

function isValidQuestionId<ACTIVITY extends Activity = Activity>(
  activity: Activity,
  questionId: string,
): questionId is ActivitySpecificQuestionId<ACTIVITY> {
  const activityQuestions = activitySpecificQuestions[activity]
  return activityQuestions ? activityQuestions.some((question) => question.id === questionId) : false
}

type ActivitySpecificAnswersV1Inner<ACTIVITY extends Activity = Activity> =
  ACTIVITY extends keyof typeof strictlyTypedActivitySpecificQuestions
    ? { [key in (typeof strictlyTypedActivitySpecificQuestions)[ACTIVITY][number]['id']]?: boolean | undefined }
    : Record<string, never>

const activitySpecificAnswersV2Schema = z.object({
  _version: z.literal(2),
  answers: z.record(z.string(), z.boolean().nullable().or(z.undefined())),
})

export type ActivitySpecificAnswersV2 = z.infer<typeof activitySpecificAnswersV2Schema>

type ActivitySpecificAnswers =
  | {
      [key in Activity]?: ActivitySpecificAnswersV1Inner<key> | undefined
    }
  | ActivitySpecificAnswersV2

export class ActivitySpecificAnswersDimension extends AbstractDimension<
  'activitySpecificAnswers',
  ActivitySpecificAnswers
> {
  override validateData(
    unvalidatedValue: LooselyTypedValue,
  ): Result<ActivitySpecificAnswers, DimensionValidationProblem> {
    const v2Parsing = activitySpecificAnswersV2Schema.safeParse(unvalidatedValue)

    if (v2Parsing.success) {
      return success(v2Parsing.data)
    }

    if (typeof unvalidatedValue !== 'object' || unvalidatedValue === null) {
      return failure({
        type: 'dimension-validation-problem',
        explanation: 'Invalid data type : activitySpecificAnswers root must be an object',
      })
    }
    const output: ActivitySpecificAnswers = {}
    for (const [activity, activityAnswers] of Object.entries(unvalidatedValue)) {
      if (!isActivity(activity)) {
        return failure({
          type: 'dimension-validation-problem',
          explanation: `Invalid data type : invalid activity "${activity}" in activitySpecificAnswers`,
        })
      }
      if (typeof activityAnswers !== 'object' || activityAnswers === null) {
        return failure({
          type: 'dimension-validation-problem',
          explanation: `Invalid data type : invalid answers for activity "${activity}": not an object`,
        })
      }
      const activityAnswsers: Record<string, boolean> = {}

      for (const [questionId, answserValue] of Object.entries(activityAnswers)) {
        if (!isValidQuestionId(activity, questionId)) {
          return failure({
            type: 'dimension-validation-problem',
            explanation: `Invalid data type : invalid question id "${questionId}" for activity "${activity}" in activitySpecificAnswers`,
          })
        }
        if (answserValue == undefined) {
          continue
        }
        if (typeof answserValue !== 'boolean') {
          return failure({
            type: 'dimension-validation-problem',
            explanation: `Invalid data type : invalid answer type for "${questionId}" for activity "${activity}" in activitySpecificAnswers : not a boolean`,
          })
        }
        activityAnswsers[questionId] = answserValue
      }

      output[activity] = activityAnswers
    }
    return success(output)
  }
}

export const activitySpecificQuestionsDimension = new BaseDimension<
  'activitySpecificQuestions',
  readonly ActivitySpecificQuestion[]
>({
  name: 'activitySpecificQuestions',
  displayValues: { name: "Questions specifiques à l'activitié" },
})

export const activitySpecificAnswersDimension = new ActivitySpecificAnswersDimension({
  name: 'activitySpecificAnswers',
  displayValues: { name: "Responses aux questions specifiques à l'activitié" },
})

export function evaluateQuestionCondition(
  activityAnswers: ActivitySpecificAnswersV1Inner,
  condition: ActivitySpecificQuestionCondition | undefined,
): boolean | undefined {
  if (condition == undefined) return true

  if (typeof condition === 'string') return (activityAnswers as Record<string, boolean>)[condition]

  if ('and' in condition) {
    return condition.and.every((subCondition) => evaluateQuestionCondition(activityAnswers, subCondition) === true)
  }

  if ('or' in condition) {
    return condition.or.some((subCondition) => evaluateQuestionCondition(activityAnswers, subCondition) === true)
  }

  return !evaluateQuestionCondition(activityAnswers, condition.not)
}

export function normalizeActivitySpecificAnswers(
  activitySpecificAnswers: ActivitySpecificAnswers,
  activities: Activity[],
): ActivitySpecificAnswersV2 {
  if ('_version' in activitySpecificAnswers && activitySpecificAnswers._version === 2) return activitySpecificAnswers

  return {
    _version: 2,
    answers: Object.fromEntries(
      activities.flatMap((activity) => {
        const answers = (activitySpecificAnswers as Record<string, boolean | undefined>)[activity] ?? {}
        return Object.entries(answers)
      }),
    ),
  }
}

/**
 * Return the activity specific answer that are relevant to the given activities. Useful to only take
 * into account answers to questions that are relevant to the activities.
 */
export function getRelevantActivitySpecificAnswers(
  activities: Activity[],
  activitySpecificAnswers: ActivitySpecificAnswers,
): ActivitySpecificAnswersV2['answers'] {
  const { answers } = normalizeActivitySpecificAnswers(activitySpecificAnswers, activities)
  const relevantQuestionsIds = getRelevantQuestionIds(activities)
  return Object.fromEntries(Object.entries(answers).filter(([questionId]) => relevantQuestionsIds.has(questionId)))
}

function getRelevantQuestionIds(activities: Activity[]): Set<string> {
  const relevantQuestionsIds = new Set<string>()
  for (const activity of activities) {
    const activityQuestions = activitySpecificQuestions[activity]
    if (activityQuestions) {
      for (const question of activityQuestions) {
        relevantQuestionsIds.add(question.id)
      }
    }
  }
  return relevantQuestionsIds
}
