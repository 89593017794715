import { type CalendarDate } from '@orus.eu/calendar-date'
import { countriesSchema } from '@orus.eu/countries'
import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from '../abstract-dimension.js'
import { AmountDimension } from '../amount-dimension.js'
import { BooleanDimension } from '../boolean-dimension.js'
import { mutaSocialSecurityRegimeTypeSchema } from './muta-regime-dimension.js'

export const mutaBeneficiaryTypes = ['PARTNER', 'CHILD', 'OTHER'] as const
export const mutaBeneficiaryTypeSchema = z.enum(mutaBeneficiaryTypes)
export type MutaBeneficiaryType = z.infer<typeof mutaBeneficiaryTypeSchema>

export const mutaBaseBeneficiarySchema = z.object({
  id: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  zipCode: z.string().optional(),
  birthDate: z.custom<CalendarDate>().optional(),
  socialSecurityNumber: z.string().optional(),
  regime: mutaSocialSecurityRegimeTypeSchema.optional(),
  birthCity: z.string().optional(),
  birthCountry: countriesSchema.optional(),
  socialSecurityOrganismNumber: z.string().optional(),
})

export const mutaBeneficiarySchema = z
  .discriminatedUnion('type', [
    z.object({
      type: z.enum([mutaBeneficiaryTypeSchema.Values.CHILD, mutaBeneficiaryTypeSchema.Values.OTHER]),
      isDisabledAdult: z.boolean().optional(),
      isLinkedToPartner: z.boolean().optional(),
      socialSecurityRank: z.number().optional(),
    }),
    z.object({
      type: z.enum([mutaBeneficiaryTypeSchema.Values.PARTNER]),
    }),
  ])
  .and(mutaBaseBeneficiarySchema)

export type MutaBeneficiary = z.infer<typeof mutaBeneficiarySchema>

export const mutaBeneficiariesSchema = z.array(mutaBeneficiarySchema)

export type MutaBeneficiaries = z.infer<typeof mutaBeneficiariesSchema>

export class MutaBeneficiariesDimension<NAME extends string> extends AbstractDimension<NAME, MutaBeneficiaries> {
  override validateData(unvalidatedValue: LooselyTypedValue): Result<MutaBeneficiaries, DimensionValidationProblem> {
    const parseResult = mutaBeneficiariesSchema.safeParse(unvalidatedValue)
    if (parseResult.success) {
      if (parseResult.data.filter((beneficiary) => beneficiary.type === 'PARTNER')) {
        dimensionValidationFailure(`Field ${this.name} has more than one partner`)
      }
      return success(parseResult.data)
    }
    return dimensionValidationFailure(parseResult.error.message)
  }
}

export const mutaBeneficiaryYearlyTotalPremiumDimension = new AmountDimension({
  name: 'mutaBeneficiaryYearlyTotalPremium',
  displayValues: { name: 'Cotisation annuelle TTC par bénéficiaire' },
  tags: ['MUTA'],
} as const)

export const mutaBeneficiariesDimension = new MutaBeneficiariesDimension({
  name: 'mutaBeneficiaries',
  displayValues: { name: 'Bénéficiaires de la mutuelle santé' },
  tags: ['MUTA'],
} as const)

export const mutaHasBeneficiariesCompletedDimension = new BooleanDimension({
  name: 'mutaHasBeneficiariesCompleted',
  displayValues: { name: 'Les bénéficiaires complétés' },
  tags: ['MUTA'],
})

export const mutaHasBeneficiariesDimension = new BooleanDimension({
  name: 'mutaHasBeneficiaries',
  displayValues: { name: 'Souhaitez-vous couvrir des membres de votre famille ?' },
  tags: ['MUTA'],
})
