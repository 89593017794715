import type { FinancialRate } from '@orus.eu/amount'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  type DimensionOptions,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from '../abstract-dimension'
import { FinancialRateDimension } from '../financial-rate-dimension'

export type PartnerManagementFeeRate = FinancialRate | 'none'

export class PartnerManagementFeeRateDimension<NAME extends string> extends AbstractDimension<
  NAME,
  PartnerManagementFeeRate
> {
  private readonly financialRateDimension: FinancialRateDimension<NAME>
  constructor(options: DimensionOptions<NAME>) {
    super(options)
    this.financialRateDimension = new FinancialRateDimension<NAME>(options)
  }

  override validateData(value: LooselyTypedValue): Result<PartnerManagementFeeRate, DimensionValidationProblem> {
    if (value === 'none') return success(value)
    return this.financialRateDimension.validateData(value)
  }
}

export const partnerManagementFeeRateDimension = new PartnerManagementFeeRateDimension({
  name: 'partnerManagementFeeRate',
  displayValues: { name: 'Frais de gestion' },
} as const)
