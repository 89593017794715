import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'
import { useSize } from 'react-use'
import { colors } from '../../colors.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { useScreenType } from '../../hooks/index.js'
import { useTranslate } from '../../localization/language-context.js'
import { Button, type ButtonSize } from '../button/index.js'

export type CheckoutLayoutProps = {
  documents: ReactNode
  payment: ReactNode
  goBack: (() => void) | undefined
  className?: string
  contractColumnWidth: string
}

const documentBreakPoint = 1325

export const CheckoutLayout = memo<CheckoutLayoutProps>(function CheckoutLayout(props: CheckoutLayoutProps) {
  const { documents, payment, goBack, className } = props
  const screenType = useScreenType()
  const [documentElement] = useSize(({ width }) => {
    return (
      <div
        css={
          screenType === 'desktop'
            ? css`
                background-color: ${colors.linen};
                padding-top: ${spacing[80]};
                padding-right: ${spacing[80]};
                padding-left: 0;
                flex-basis: 50%;
              `
            : undefined
        }
      >
        <div
          css={
            screenType === 'desktop'
              ? css`
                  display: flex;
                  flex-direction: row;
                  justify-content: ${width >= documentBreakPoint ? 'space-between' : 'flex-end'};
                  gap: min(${spacing[80]});
                `
              : undefined
          }
        >
          {goBack && screenType === 'desktop' && width >= documentBreakPoint ? (
            <GoBackButton goBack={goBack} size="medium" />
          ) : (
            <></>
          )}
          <div
            css={
              screenType === 'desktop'
                ? css`
                    width: ${props.contractColumnWidth};
                  `
                : undefined
            }
          >
            {goBack && screenType === 'desktop' && width < documentBreakPoint ? (
              <div
                css={css`
                  margin-bottom: ${spacing[70]};
                `}
              >
                <GoBackButton goBack={goBack} size="medium" />
              </div>
            ) : (
              <></>
            )}
            {documents}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div
      className={className}
      data-e2e-scroll-content
      css={css`
        display: flex;
        flex-direction: ${screenType === 'desktop' ? 'row' : 'column'};
        flex: ${screenType === 'desktop' ? 1 : undefined};
      `}
    >
      {documentElement}
      <div
        css={
          screenType === 'desktop'
            ? css`
                background-color: ${colors.white};
                padding-left: ${spacing[80]};
                padding-top: ${spacing[80]};
                flex-basis: 50%;
                position: relative;
                overflow: visible;

                /*
                 * The block below is a bit of a hack to achieve a two colored columns layout, but right now:
                 *   - Only the checkout step has this layout
                 *   - The necessary level of abstraction in the generic step layout to allow this
                 * would introduce much more complexity.
                 * So I can live with this hack.
                 */
                &::before {
                  content: ' ';
                  position: absolute;
                  top: -3px;
                  min-height: calc(100vh - 65px);
                  left: 0;
                  border-left: 1px solid ${colors.gray[100]};
                  right: -1000px;
                  background-color: white;
                }
              `
            : undefined
        }
      >
        <div
          css={
            screenType === 'desktop'
              ? css`
                  width: 508px;
                  height: fit-content;
                  position: relative;
                  z-index: 1;
                `
              : undefined
          }
        >
          {payment}
        </div>
      </div>

      {goBack && screenType === 'mobile' ? <GoBackButton goBack={goBack} size="large" /> : <></>}
    </div>
  )
})

export const GoBackButton = memo<{ goBack: () => void; size: ButtonSize | undefined }>(function GoBackButton({
  goBack,
  size,
}) {
  const screenType = useScreenType()
  const translate = useTranslate()
  return (
    <Button
      variant="tertiary"
      css={css`
        margin-top: ${screenType === 'mobile' ? spacing[50] : '0px'};
      `}
      size={size}
      icon="arrow-left-regular"
      avatarPosition="left"
      onClick={goBack}
    >
      {translate('back')}
    </Button>
  )
})
