import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { spacing } from '../../foundation'
import { Avatar, FlexRow, Text } from '../atoms'
import type { CompoundIconName } from '../atoms/icon/names'
import { Badge } from '../badge'
import { ButtonGroup } from '../button/button-group'

export type SectionTitleProps = {
  title: string
  icon?: CompoundIconName
  badgeLabel?: string
  button?: ReactNode
  buttonGroup?: ReactNode
}

export const SectionTitle = memo<SectionTitleProps>(function SectionTitle(props: SectionTitleProps) {
  const { title, icon, badgeLabel, button, buttonGroup } = props

  return (
    <SectionTitleContainer>
      {icon ? <Avatar icon={icon} size="30" /> : null}
      {badgeLabel ? <Badge>{badgeLabel}</Badge> : null}
      <SectionTitleText variant="body1Medium">{title}</SectionTitleText>
      {button}
      <ButtonGroup>{buttonGroup}</ButtonGroup>
    </SectionTitleContainer>
  )
})

const SectionTitleContainer = styled(FlexRow)`
  width: 100%;
  align-items: center;
  gap: ${spacing[40]};
`

const SectionTitleText = styled(Text)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
