import type { CalendarDate } from '@orus.eu/calendar-date'
import { failure, type Failure, type Result } from '@orus.eu/result'
import { BaseDimension } from './abstract-dimension.js'
import { TextDimension } from './text-dimension.js'

export const placeSearchQueryDimension = new TextDimension({
  name: 'placeSearchQuery',
  displayValues: { name: "Texte utilisé pour la recherche de l'établissement" },
} as const)

export const temporaryInseeProblem = 'temporary-insee-failure'
export type TemporaryInseeProblem = typeof temporaryInseeProblem
export const temporaryInseeFailure: Failure<TemporaryInseeProblem> = failure(temporaryInseeProblem)

export const placeSearchResultsDimension = new BaseDimension<
  'placeSearchResults',
  Result<PlaceData[], TemporaryInseeProblem>
>({
  name: 'placeSearchResults',
  displayValues: { name: 'Etablissements trouvés' },
} as const)

export type PlaceData = {
  siret: string
  postCode: string
  name?: string
  street?: string | undefined
  city?: string
  companyName?: string
  registrationDate?: CalendarDate
}
