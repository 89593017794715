import { z } from 'zod'

/**
 * List come from :
 * https://docs.google.com/spreadsheets/d/1i2SOzvbP-a8MBKfw-mrfImZn9XfC8sEi/edit#gid=2085805639
 * It should not change anytime soon, besides Social Security reform
 * PostCode logic can only be applied to GENERAL regime (01)
 */
export const mutaSocialSecurityOrganism = [
  '01_011_000',
  '01_021_000',
  '01_031_000',
  '01_041_000',
  '01_051_000',
  '01_061_000',
  '01_072_000',
  '01_081_000',
  '01_091_000',
  '01_101_000',
  '01_111_000',
  '01_121_000',
  '01_131_000',
  '01_141_000',
  '01_151_000',
  '01_161_000',
  '01_171_000',
  '01_181_000',
  '01_191_000',
  '01_201_000',
  '01_202_000',
  '01_211_000',
  '01_221_000',
  '01_231_000',
  '01_241_000',
  '01_251_000',
  '01_261_000',
  '01_271_000',
  '01_281_000',
  '01_291_000',
  '01_301_000',
  '01_311_000',
  '01_321_000',
  '01_331_000',
  '01_342_000',
  '01_351_000',
  '01_361_000',
  '01_371_000',
  '01_381_000',
  '01_391_000',
  '01_401_000',
  '01_411_000',
  '01_422_000',
  '01_431_000',
  '01_441_000',
  '01_451_000',
  '01_461_000',
  '01_471_000',
  '01_481_000',
  '01_491_000',
  '01_501_000',
  '01_511_000',
  '01_521_000',
  '01_531_000',
  '01_542_000',
  '01_551_000',
  '01_561_000',
  '01_571_000',
  '01_581_000',
  '01_594_000',
  '01_595_000',
  '01_597_000',
  '01_599_000',
  '01_601_000',
  '01_611_000',
  '01_623_000',
  '01_624_000',
  '01_631_000',
  '01_641_000',
  '01_642_000',
  '01_651_000',
  '01_661_000',
  '01_673_000',
  '01_682_000',
  '01_691_000',
  '01_701_000',
  '01_711_000',
  '01_721_000',
  '01_731_000',
  '01_741_000',
  '01_751_000',
  '01_763_000',
  '01_764_000',
  '01_771_000',
  '01_781_000',
  '01_791_000',
  '01_801_000',
  '01_811_000',
  '01_821_000',
  '01_831_000',
  '01_841_000',
  '01_851_000',
  '01_861_000',
  '01_871_000',
  '01_881_000',
  '01_891_000',
  '01_901_000',
  '01_911_000',
  '01_921_000',
  '01_931_000',
  '01_941_000',
  '01_951_000',
  '01_971_000',
  '01_972_000',
  '01_973_000',
  '01_974_000',
  '01_976_000',
  '02_011_000',
  '02_015_000',
  '02_021_000',
  '02_025_000',
  '02_031_000',
  '02_035_000',
  '02_051_000',
  '02_055_000',
  '02_061_000',
  '02_065_000',
  '02_071_000',
  '02_075_000',
  '02_081_000',
  '02_085_000',
  '02_091_000',
  '02_095_000',
  '02_101_000',
  '02_105_000',
  '02_111_000',
  '02_115_000',
  '02_121_000',
  '02_125_000',
  '02_131_000',
  '02_135_000',
  '02_141_000',
  '02_145_000',
  '02_151_000',
  '02_155_000',
  '02_161_000',
  '02_165_000',
  '02_171_000',
  '02_175_000',
  '02_181_000',
  '02_185_000',
  '02_191_000',
  '02_195_000',
  '02_201_000',
  '02_205_000',
  '02_211_000',
  '02_215_000',
  '02_221_000',
  '02_225_000',
  '02_231_000',
  '02_235_000',
  '02_241_000',
  '02_245_000',
  '02_251_000',
  '02_255_000',
  '02_261_000',
  '02_265_000',
  '02_271_000',
  '02_275_000',
  '02_281_000',
  '02_285_000',
  '02_291_000',
  '02_295_000',
  '02_301_000',
  '02_305_000',
  '02_311_000',
  '02_315_000',
  '02_321_000',
  '02_325_000',
  '02_331_000',
  '02_335_000',
  '02_341_000',
  '02_345_000',
  '02_351_000',
  '02_355_000',
  '02_361_000',
  '02_365_000',
  '02_371_000',
  '02_375_000',
  '02_381_000',
  '02_385_000',
  '02_391_000',
  '02_395_000',
  '02_401_000',
  '02_405_000',
  '02_411_000',
  '02_415_000',
  '02_421_000',
  '02_425_000',
  '02_431_000',
  '02_435_000',
  '02_441_000',
  '02_445_000',
  '02_451_000',
  '02_455_000',
  '02_461_000',
  '02_465_000',
  '02_471_000',
  '02_475_000',
  '02_481_000',
  '02_485_000',
  '02_491_000',
  '02_495_000',
  '02_501_000',
  '02_505_000',
  '02_511_000',
  '02_515_000',
  '02_521_000',
  '02_525_000',
  '02_531_000',
  '02_535_000',
  '02_541_000',
  '02_545_000',
  '02_551_000',
  '02_555_000',
  '02_561_000',
  '02_565_000',
  '02_571_000',
  '02_575_000',
  '02_581_000',
  '02_585_000',
  '02_591_000',
  '02_595_000',
  '02_601_000',
  '02_605_000',
  '02_611_000',
  '02_615_000',
  '02_621_000',
  '02_625_000',
  '02_631_000',
  '02_635_000',
  '02_641_000',
  '02_645_000',
  '02_651_000',
  '02_655_000',
  '02_661_000',
  '02_665_000',
  '02_671_000',
  '02_675_000',
  '02_681_000',
  '02_685_000',
  '02_691_000',
  '02_695_000',
  '02_701_000',
  '02_705_000',
  '02_711_000',
  '02_715_000',
  '02_721_000',
  '02_725_000',
  '02_731_000',
  '02_735_000',
  '02_741_000',
  '02_745_000',
  '02_751_000',
  '02_755_000',
  '02_761_000',
  '02_765_000',
  '02_771_000',
  '02_775_000',
  '02_791_000',
  '02_795_000',
  '02_801_000',
  '02_805_000',
  '02_811_000',
  '02_815_000',
  '02_821_000',
  '02_825_000',
  '02_831_000',
  '02_835_000',
  '02_841_000',
  '02_845_000',
  '02_851_000',
  '02_855_000',
  '02_861_000',
  '02_865_000',
  '02_871_000',
  '02_875_000',
  '02_881_000',
  '02_885_000',
  '02_891_000',
  '02_895_000',
  '02_941_000',
  '02_971_000',
  '04_110_000',
  '06_200_000',
  '08_756_000',
  '08_758_000',
  '10_980_000',
  '90_100_000',
  '91_000_506',
  '91_976_501',
  '93_789_000',
  '95_459_000',
  '99_609_000',
  '99_691_505',
  '99_751_505',
] as const

export const mutaSocialSecurityOrganismSchema = z.enum(mutaSocialSecurityOrganism)
export type MutaSocialSecurityOrganism = z.infer<typeof mutaSocialSecurityOrganismSchema>
