import { createContext, useContext, type ContextType } from 'react'

import { colorTokens } from './color-tokens.json'

type SkinName = keyof typeof colorTokens

const skinContext = createContext<{ setSkin: (skin: SkinName) => void; skin: SkinName | null }>({
  skin: null,
  setSkin: () => {},
})
export const SkinContextProvider = skinContext.Provider

export function usePharaohSkin(): ContextType<typeof skinContext> {
  return useContext(skinContext)
}

export const isPharaohSkin = (skin: string): skin is SkinName => skin in colorTokens
