export default {
  // Activity that serves as an exclusion sink
  excludedActivity: { riskCarrierLabel: 'Activité exclue' },
  // Activity for insurance product that are not activity related
  '22ef9ae0-d6d0-454b-81be-1747934287c1': { riskCarrierLabel: 'Autre activité' },

  //#region MRP Restaurant Wakam
  coffeeBar: { riskCarrierLabel: 'Bar-café' },
  creperie: { riskCarrierLabel: 'Crêperie' },
  iceCream: { riskCarrierLabel: 'Glacier' },
  teaRoom: { riskCarrierLabel: 'Salon de thé ou tarterie' },
  traditionalRestaurant: { riskCarrierLabel: 'Restaurant traditionnel (hors pizzeria)' },
  pizzeria: { riskCarrierLabel: 'Pizzeria' },
  camping: { riskCarrierLabel: 'Camping' },
  guestHouse: { riskCarrierLabel: "Gîte ou chambre d'hôtes" },
  hotel: { riskCarrierLabel: 'Hôtel' },
  fastFood: { riskCarrierLabel: 'Restauration rapide' },
  sushi: { riskCarrierLabel: 'Sushi' },
  caterer: { riskCarrierLabel: 'Traiteur' },
  delicatessen: { riskCarrierLabel: 'Épicerie fine' },
  notARestaurant: { riskCarrierLabel: 'Activité hors restauration' },
  //#endregion

  administrateurBDD: { riskCarrierLabel: 'Administrateur / architecte base de données (BDD)' },
  hebergementDonnees: { riskCarrierLabel: 'Hébergement de données (stockage, sauvegarde, etc.)' },
  iaasPaas: { riskCarrierLabel: 'Infrastructure & Platform as a Service (IaaS / PaaS)' },
  hebergementSiteAppWeb: { riskCarrierLabel: 'Hébergements de site/application Web' },
  installationConfInfo: { riskCarrierLabel: 'Installation, configuration, paramétrage de matériel informatique' },
  venteLocationMaterielInfo: { riskCarrierLabel: 'Vente, location matériel informatique' },
  architecteSys: { riskCarrierLabel: 'Architecte systèmes / réseaux / cloud' },
  administrationSysReseaux: { riskCarrierLabel: 'Administration systèmes, réseaux' },
  devOps: { riskCarrierLabel: 'DevOps' },
  integrationSysReseaux: { riskCarrierLabel: 'Intégration systèmes / réseaux' },
  conseilTelecoms: { riskCarrierLabel: 'Conseil en télécoms' },
  serviceTelecoms: { riskCarrierLabel: 'Services télécoms (VPN, Ethernet, IP/VOIP, email, visioconférence...)' },
  operateurTelecoms: { riskCarrierLabel: 'Opérateur télécoms' },
  venteLocationMaterielTelecom: { riskCarrierLabel: 'Vente, location matériel télécoms' },
  securisationTelephonie: { riskCarrierLabel: 'Sécurisation du système de téléphonie' },
  installationConfTelecoms: { riskCarrierLabel: 'Installation, configuration, paramétrage de matériel télécoms' },
  developpementAppSpecifiques: {
    riskCarrierLabel: "Développement, programmation d'applications spécifiques / à façon",
  },
  consultantErpBI: { riskCarrierLabel: 'Consultant ERP, CRM, BI, Datamining (ex.: Oracle, SAP, Microsoft)' },
  editionLogiciel: { riskCarrierLabel: 'Edition de logiciels / progiciels' },
  venteInstallationParamLogiciel: { riskCarrierLabel: 'Vente, installation, paramétrage de logiciels / progiciels' },
  integrationLogiciel: { riskCarrierLabel: 'Intégration de logiciels / progiciels' },
  editionLogicielSaas: { riskCarrierLabel: 'Edition de logiciels / progiciels en mode SaaS' },
  developpementAppLogiciel: { riskCarrierLabel: 'Développement, programmation de logiciels / progiciels' },
  testRecetteLogiciel: { riskCarrierLabel: 'Test / recettes de logiciels / progiciels' },
  architecteLogiciel: { riskCarrierLabel: 'Architecte logiciel' },
  gameUxGameplay: { riskCarrierLabel: 'Game design, UX, gameplay' },
  animation2d3d: { riskCarrierLabel: 'Animation 2D/3D' },
  realiteAugmenteeLogiciel: { riskCarrierLabel: 'Réalité augmentée / virtuelle' },
  editionJeuxVideo: { riskCarrierLabel: 'Edition de jeux vidéo' },
  developpementTestJeuxVideo: { riskCarrierLabel: 'Développement / tests de jeux vidéo' },
  webdesign: { riskCarrierLabel: 'Webdesign, intégration (dont UX/UI)' },
  developpementECommerce: { riskCarrierLabel: 'Développement, intégration de solutions e-Commerce' },
  developpementWebAppMobile: { riskCarrierLabel: 'Développement web / application mobile' },
  editionAppWebMobile: { riskCarrierLabel: "Edition d'applications web / mobile" },
  creationAchatNomsDomaine: { riskCarrierLabel: 'Création, achat, gestion de noms de domaine' },
  integrationCms: { riskCarrierLabel: 'Intégration CMS' },
  referencement: { riskCarrierLabel: 'Référencement (dont SEO, traffic management, etc.)' },
  assistanceHelpDesk: {
    riskCarrierLabel: 'Assistance, helpdesk, maintenance, tierce maintenance applicative, mises à jour',
  },
  conseilEtudesAMOA: {
    riskCarrierLabel: "Conseil, études, audit, AMOA (dont la gestion de projet valant moins d'un million d'euros)",
  },
  maitriseOeuvre: { riskCarrierLabel: "Maîtrise d'œuvre / gestion de projet informatique, Tech lead, Lead dev" },
  securiteSysInfo: { riskCarrierLabel: "Sécurité des systèmes d'information, dont tests d'intrusion" },
  formation: { riskCarrierLabel: 'Formation' },
  archivageNumerique: { riskCarrierLabel: 'Gestion électronique des documents / Archivage numérique' },
  bigData: { riskCarrierLabel: 'Big data, Data science' },
  productOwner: { riskCarrierLabel: 'Product owner, Business analyst en informatique' },
  infogerence: { riskCarrierLabel: 'Infogérence' },
  conseilCybersecurite: { riskCarrierLabel: 'Conseil en cybersécurité hors gestion de projet' },
  consultantRGPDDPO: {
    riskCarrierLabel:
      "DPO / Consultant en RGPD (ou Délégué à la Protection des Données / Consultant RGPD) à l'exclusion de tout exercice de cette activité par un avocat",
  },
  maintenanceMaterielInfo: { riskCarrierLabel: 'Maintenance de matériel informatique' },
  miseEnRelationPro: {
    riskCarrierLabel:
      "Mise en relation entre professionnels et/ou particuliers (via site internet et/ou application mobile) hors prestations réglementées, prestations dans le domaine touristique (y.c courtier en voyage), location ou vente de biens immobiliers, mise en relation pour des travaux de bâtiment et à l'exclusion de toute opération de financement participatif",
  },
  maintenanceMaterielTelecoms: { riskCarrierLabel: 'Maintenance de matériel télécom' },
  autresServices: {
    riskCarrierLabel:
      "Autres services (recrutement, veille, conseil en stratégie / organisation / marketing, etc.) dans la limite de 30% du chiffre d'affaires déclaré",
  },
  'e544c4eb-84a9-4d7b-8a2c-abb4406fb2fa': { riskCarrierLabel: 'Communication institutionnelle, financière' },
  'a0f0ce7b-1391-4647-86d1-eedb16c8face': { riskCarrierLabel: 'Communication interne/RH, marque employeur' },
  '475e1deb-04b2-44d6-a1d9-e63496b32d9a': { riskCarrierLabel: 'Media planning, web planning' },
  '7e13ae49-2f73-4455-949a-0408f82f399d': { riskCarrierLabel: 'Influenceur, lobbying' },
  'd38eb5c6-4064-4f1b-92ca-686bf665d0ad': { riskCarrierLabel: 'Social media, community management' },
  '29c37cc4-b75b-4330-ac71-b52cd4825803': {
    riskCarrierLabel: 'Relations publiques, Gestion réputation / e-réputation',
  },
  '68b32b5c-f64f-46d5-afbb-2fe84fe9b896': { riskCarrierLabel: 'Communication et gestion de crise' },
  'bb5d6cc3-414c-4c7a-baae-c0dc9b4d68ee': { riskCarrierLabel: 'Impression supports publicitaire ou corporate' },
  '34fdeca8-b167-4c7c-a583-1082dd863964': {
    riskCarrierLabel: 'Réalisation, production de films institutionnels et/ou publicitaires',
  },
  '669f98e5-801a-49a6-924c-2e4ee827ff66': { riskCarrierLabel: 'Publicité' },
  '0c19ced7-dddf-4adb-befc-b517c9046a9f': {
    riskCarrierLabel: 'Conception et création de contenu éditorial tous supports (dont content management)',
  },
  'de1b2b09-8322-4703-bc79-d8722e08bb77': {
    riskCarrierLabel: 'Conception, création de sites web et applications mobiles (design UX/UI, développement)',
  },
  '923a0507-ea59-47ed-aea3-909eb3d2f01c': {
    riskCarrierLabel: 'Direction artistique, conception et création graphique 360°',
  },
  'ec267cbc-209a-4f98-854c-c9b8da065d6b': {
    riskCarrierLabel: "Achat d'espaces publicitaires dans médias traditionnels et nouveaux médias (dont display)",
  },
  '69021375-2642-4076-895c-80a1a838856a': { riskCarrierLabel: 'Régie publicitaire' },
  '3db23ce7-6e32-40dd-b507-7615ff76d84e': { riskCarrierLabel: 'm-Marketing (dont campagnes SMS)' },
  'cc823640-e4c5-4c8f-a338-31d7e7475798': { riskCarrierLabel: 'Packaging - hors design industriel' },
  '521ae22f-93cd-49a6-8eda-fe97a3fb4483': {
    riskCarrierLabel:
      'Conception de goodies, enseignes ou produits dérivés (fabrication et installation sous-traitées)',
  },
  '78ca7009-3a97-44b8-bfd8-8e9b2439fe22': { riskCarrierLabel: 'Impression publipostage' },
  'f64754e4-353d-475f-8a27-2579d93eb655': { riskCarrierLabel: 'Promotion et animation des ventes' },
  'e065341e-13ac-4c29-b184-259e51e96a2c': { riskCarrierLabel: 'Merchandising, publicité sur lieu de vente' },
  '8e42e14a-0d04-479d-9afa-de02392e7f8b': {
    riskCarrierLabel: 'Marketing direct (phoning, (e)mailing, jeux concours, street marketing...)',
  },
  '24640bca-16ab-454b-9189-2a2a43fe6820': { riskCarrierLabel: 'Search marketing, référencement (SEO, SEA, SMO)' },
  'e7801ec2-6ff6-42c9-a30f-2afffcb9c51c': { riskCarrierLabel: 'Monétisation de contenu' },
  '4cd54013-52a5-489b-b4eb-bd45a3d8ceae': { riskCarrierLabel: 'Génération de leads' },
  'df69d9b1-51ef-467c-86d8-d50467b4f6ae': { riskCarrierLabel: 'Affiliation' },
  '37eda154-e09a-46c1-926f-5ac9bf569a0c': { riskCarrierLabel: 'Reciblage publicitaire (retargeting)' },
  '380843e6-63a4-4b5d-a922-38fb6f3f9f2b': { riskCarrierLabel: 'Analyse comportementale' },
  '14476c38-2be5-4fa6-a7f6-0e1a6a2b904d': { riskCarrierLabel: 'Veille, benchmark, social listening' },
  '32e5c09a-3c16-45f6-b0d1-9e8859bd052c': { riskCarrierLabel: 'Etude de marché, sondages' },
  'a65e1d73-aa9d-423c-831f-817f8013e653': { riskCarrierLabel: 'CRM analytics, base de données client' },
  'e0cea3f2-fe62-41d6-9a29-5bc95d499b86': { riskCarrierLabel: 'Datamining, datascience' },
  '5a2cee49-9831-44c8-8ce0-ef39392c3183': { riskCarrierLabel: 'Tracking, performance, metrics' },
  '3dcb49c0-f74f-4355-8c5b-daa19360450a': { riskCarrierLabel: 'Traffic management' },
  '3d2d2937-80ee-4be5-9df5-cec75b724fcf': { riskCarrierLabel: 'Conseil en communication, RP, social média' },
  '97df60cf-e3ab-4688-b999-ba44064bcf1c': { riskCarrierLabel: 'Conseil en marketing' },
  'bc52cd02-0647-4fbf-bf9b-5cd491959cbd': { riskCarrierLabel: 'Coaching, media training' },
  'f1f40fc1-5900-4abf-9fc4-f29ed438a758': { riskCarrierLabel: 'Conseil en branding (notoriété et image de marque)' },
  '43794ed6-c7e1-4d5e-86d5-05bf26630669': { riskCarrierLabel: 'Conseil en stratégie digitale' },
  '1e3dcf38-44a2-42e2-b3b0-57c43f2d18fb': { riskCarrierLabel: 'Planning stratégique' },
  '968f1c9c-f0d7-46b9-b740-210df56310c5': { riskCarrierLabel: "Conception et organisation d'événements" },
  '542d064a-6971-494b-b7a5-d69375546f3e': { riskCarrierLabel: 'Scénographie' },
  '00e10b2c-4058-4834-bfb0-de3c932b544d': {
    riskCarrierLabel:
      "Autres services : Informatique et Conseil (en stratégie, organisation, RSE...) dans la limite de 30% du chiffre d'affaires déclaré par l'assuré",
  },
  '4f505241-f734-46d6-ba7d-087cf8da4316': {
    riskCarrierLabel: 'Conseil en voyage / travel planner',
  },
  '562348a8-1d59-4e1d-9d1d-2f1c6d6ae918': {
    riskCarrierLabel: "Agence pour la création et/ou le développement d'entreprises",
  },
  'bfac3559-313f-4a7a-9816-e7a30c70d9d4': {
    riskCarrierLabel: "Conseil en stratégie, organisation, développement et management d'entreprises",
  },
  '08b1065e-2b1f-4b6f-9ff6-d07ec66ad889': { riskCarrierLabel: 'Conseil en logistique' },
  '41a971de-122f-4ffa-9e76-5d1291ec34f6': { riskCarrierLabel: 'Conseil en import' },
  '74a76d98-9994-4404-9f45-18372997f6ec': { riskCarrierLabel: 'Conseil en export' },
  'f3da4616-6b10-4b3b-8221-52054e161c12': { riskCarrierLabel: 'Conseil en gestion de risque' },
  '71dfb4a8-1147-473d-806e-1b9f79aca51d': { riskCarrierLabel: 'Conseil en télécommunication' },
  'e666caea-a4f5-4772-a411-5db260b02e18': { riskCarrierLabel: "Conseil en systèmes d'information" },
  'c3f7c42d-b037-475e-8a87-6e964259344f': { riskCarrierLabel: 'Conseil en systèmes de production' },
  '5e131a6f-85c2-40ca-8bca-3b94e72b90a4': {
    riskCarrierLabel:
      "Conseil en fusion et acquisition portant sur la stratégie, la gestion et le suivi du projet, la coordination des différents intervenants, la recherche de financement, la négociation, la valorisation d'entreprises - hors prestation relevant d'une profession réglementée, prospective financière et conseil en investissement financier",
  },
  '4344aafc-e424-485d-b90a-40e48471f945': {
    riskCarrierLabel:
      "Conseil et réalisation d'études stratégiques à destination des collectivités publiques ou des entreprises dans le domaine éducatif, social, de l'habitat, de l'urbanisme et du tourisme",
  },
  '17d4c130-807a-426c-a943-6a358e753683': {
    riskCarrierLabel: 'Conseil en recherche de financement et/ou de partenaires, assistance dans les négociations',
  },
  'a42d9dd3-06ee-46a2-bb0d-13771a19ee20': {
    riskCarrierLabel:
      "Conseil en optimisation des achats et maîtrise des frais généraux de l'entreprise - hors prestation de conseil en optimisation des charges fiscales et sociales",
  },
  '0523313c-757a-436b-92ab-fff1254b6807': {
    riskCarrierLabel: 'Conseil en gestion de trésorerie, de comptabilité et en contrôle de gestion',
  },
  '2c6fe08e-c158-47f7-8dbf-bcaa0dcd8315': {
    riskCarrierLabel: "Tenue des livres de comptes - hors activité d'expertise-comptable",
  },
  'f7cc46ce-b3a1-4857-833d-e26675c24946': {
    riskCarrierLabel: "Développement d'outils bureautiques de gestion (tableaux de bord excel)",
  },
  '5846429e-b6e7-48c7-a1e1-78306fd0cd37': {
    riskCarrierLabel:
      "Conseil et assistance à la constitution et au dépôt de dossiers administratifs / appels d'offres",
  },
  'd0a03f59-7854-4836-b1e0-2018fad6b276': { riskCarrierLabel: 'Conseil en externalisation' },
  'bee545fa-5661-4500-8cfb-56a146ebac68': {
    riskCarrierLabel:
      'Externalisation de la paie, des cotisations sociales et de la gestion administrative du personnel',
  },
  'd68b1575-4c7f-4133-a375-ac8164ae955e': { riskCarrierLabel: 'Externalisation de tâches administratives' },
  '17a02c02-85c8-4ea2-a206-932dba001c30': {
    riskCarrierLabel: "Externalisation du management des fonctions support de l'entreprise",
  },
  '89cccb7c-6dea-40cc-a96d-192e5e02561d': {
    riskCarrierLabel:
      "Externalisation du suivi et développement commercial en qualité d'agent commercial / apporteur d'affaires - hors prestations réglementées, courtier en voyage et mise en relation dans le bâtiment",
  },
  'e5185129-052e-430b-96e7-ce667b4ace28': {
    riskCarrierLabel: "Externalisation de la rédaction du document unique d'évaluation des risques professionnels",
  },
  '54dc223f-1b6f-4807-839c-99f1fe35493f': { riskCarrierLabel: 'Cabinet et/ou conseil en recrutement' },
  '346ffb33-7c8f-4f24-aca4-055c66541f21': { riskCarrierLabel: 'Chasseur de têtes' },
  '1267b1ea-0617-4bc2-8d14-b4292db980be': { riskCarrierLabel: 'Conseil en ressources humaines' },
  '0cafbeec-8ba0-41d2-a548-5305f8ff8b6a': { riskCarrierLabel: 'Conseil en développement personnel / Coaching' },
  'e1363838-241f-4332-9002-e9e04d153f37': {
    riskCarrierLabel:
      "Conseil et accompagnement à la mobilité professionnelle des salariés - hors activité d'agent immobilier, mise en relation avec des prestataires du bâtiment et vente de voyages ou séjours",
  },
  '0c0e05ce-6523-4787-b2c3-80790c4f285f': { riskCarrierLabel: 'Conseil en qualité, santé, sécurité, environnement' },
  '5aacb215-9e0c-4786-a51f-6fbef7090cc2': { riskCarrierLabel: 'Conseil en qualité et/ou en certification' },
  'aaf67f5b-d0fd-403c-9368-f4413d834d16': { riskCarrierLabel: 'Conseil en santé/sécurité' },
  'caf43c85-755f-495e-9186-7e60bf3df12f': { riskCarrierLabel: 'Conseil en environnement et développement durable' },
  '811cea61-83e6-456e-b2be-ab3b367142ef': { riskCarrierLabel: "Conseil en responsabilité sociale d'entreprise" },
  '7de1c3cc-f3c3-45bf-b2a4-7abc6aff3742': { riskCarrierLabel: "Conseil en économie d'énergie" },
  'd25242ce-f0a6-4efc-8b2f-e38d82edab52': {
    riskCarrierLabel:
      "Réalisation d'audits de certifications portant sur les méthodes d'organisation et de process des entreprises",
  },
  '7c369408-2618-46f3-9189-a971e864e86f': {
    riskCarrierLabel:
      "Conseil et formation à destination des entreprises en matière d'éthique et de conformité législative, réglementaire et/ou déontologique (compliance)",
  },
  'aff3c15b-68b3-4dd1-b677-d9cd85c9b654': { riskCarrierLabel: 'Conseil pour la prévention des risques psychosociaux' },
  'f419908e-0c2d-41a3-ab49-443dd8eaebde': {
    riskCarrierLabel: 'Conseil en sûreté - hors sites aéroportuaire et nucléaires',
  },
  '3ccbf4db-49c4-4848-b73d-71946797e68d': {
    riskCarrierLabel:
      "Conseil et assistance aux professionnels du bâtiment dans la gestion des Certificats d'Economie d'Energie (CEE)",
  },
  '0460df96-bd49-47db-a466-251caeae7d23': {
    riskCarrierLabel:
      "Assistance à la constitution et au dépôt des dossiers auprès du Pôle National des Certificats d'Economies d'Energie",
  },
  'a226c6ac-2756-4cdd-9b5f-711a8ea1c4e4': {
    riskCarrierLabel:
      "Recherche de la valorisation la plus élevée des CEE pour le compte des professionnels du bâtiment, ces derniers restant maître de la conclusion de l'opération",
  },
  '3793ab81-8200-4cb3-a440-174d6a8fac73': {
    riskCarrierLabel: 'Formation (hors formation aux activités privées de sécurité soumises à agrément du CNAPS)',
  },
  '9caf5b45-3f73-4d22-9d9d-5286e11ece0c': { riskCarrierLabel: 'Poste de direction par intérim' },
  '5f2ad356-9ce3-4ed7-9547-8af45ee69de1': { riskCarrierLabel: 'Turnaround management' },
  'f7548902-c2d6-4568-90f3-a17999cbd17d': { riskCarrierLabel: 'Management de transition' },
  'ca1c5645-64e5-417a-ac07-57c935309115': { riskCarrierLabel: 'Consultant en Ressources Humaines' },
  '090235f4-f86c-43b1-bfa6-08637ec45c3a': {
    riskCarrierLabel:
      'Activités annexes en matière de marketing et/ou communication - hors marketing direct / journalisme / impression / réseaux sociaux / design industriel / drones',
    isSecondaryActivity: true,
  },
  '21fd01c1-e4a8-490c-b673-3b05db34b25f': {
    riskCarrierLabel:
      'Activités informatiques annexes - hors secteurs automobile & aéroports / logiciels de paiement / contrôle de machines & SCADA / secteur médical / logiciels SAP / Oracle',
    isSecondaryActivity: true,
  },
  '5b400d56-2734-4d41-a6a4-e5818fa20ebe': {
    riskCarrierLabel: 'Entreprise de travail temporaire (hors BTP et industrie)',
  },
  '55f2c27a-165a-4196-9454-f398ca63bf94': {
    riskCarrierLabel: 'Coach en développement personnel/Conseil en gestion de stress',
  },
  '8c8013f8-c7f7-4bce-9d78-eb84621eae15': { riskCarrierLabel: 'Conseil en orientation / aide psychosociale' },
  'bf00f339-359d-4fe1-885a-8e539f7c73cf': { riskCarrierLabel: 'Conseiller conjugal' },
  '13941adb-7fca-4e6c-b474-45244a9fe5de': {
    riskCarrierLabel: 'Médiation familiale (hors médiation judiciaire / pénale)',
  },
  '7d3d273d-6f54-4de4-9a52-2409caf0dd6f': { riskCarrierLabel: 'Luminothérapeute / Aromathérapeute / Musicothérapeute' },
  '076e7d6f-0708-4bc1-8ebe-b6b295e62755': { riskCarrierLabel: 'Psychologue' },
  '53da2519-6dd9-4a31-b515-0eee52235669': { riskCarrierLabel: 'Psychothérapeute' },
  '81895dcc-d669-451c-a397-6aca97b829b4': { riskCarrierLabel: 'Thérapeute par la parole' },
  'e2d0f051-a467-42fe-b840-e79afaa25995': { riskCarrierLabel: 'Hypnothérapeute' },
  'e1ad40ec-e5fa-4bd3-8e22-0c0de233e1b2': { riskCarrierLabel: 'Access Bars' },
  'decbcb84-c606-45a2-958e-c01f51b5cc05': { riskCarrierLabel: 'Access Consciousness' },
  '8a90199f-f6db-4e90-a43e-7fd84fe974ed': { riskCarrierLabel: 'Access Energy Transformation' },
  'ec3077bd-00a2-4f71-a68f-19b25c51dc91': { riskCarrierLabel: 'Access Facelift' },
  '11af3e7b-dbe6-4664-8a03-289de55540da': { riskCarrierLabel: 'Access Processus Corporels' },
  '1cbf1816-9173-4533-8f03-24fea3584548': { riskCarrierLabel: 'Accompagnement Biographique' },
  '4620c245-0112-4a85-9071-728b09820af0': { riskCarrierLabel: 'Accompagnement psycho-émotionnel' },
  '9f3b018b-6352-4352-a229-e5cad72ebc4c': { riskCarrierLabel: 'Accompagnement sensoriel' },
  '3d72b180-eb15-41d9-a72b-b855a44ca53b': { riskCarrierLabel: 'Accupressure' },
  '681c5453-a29e-4183-9325-fdb6ccb41370': { riskCarrierLabel: 'Actualisation par les Mouvements Oculaires : EMA-AMO' },
  '5cb73a55-905e-4724-b629-ec0ec68f640a': {
    riskCarrierLabel: 'Aculifting, pratiqué uniquement par un docteur en médecine ou un kinésithérapeuthe',
  },
  'e83ca9b3-f4ed-48e2-996a-cd08d1e86599': {
    riskCarrierLabel: 'Acuponcture (Aculifting, luxoponcture) limité aux seuls médecins ou kinésithérapeutes',
  },
  'bfe4d29c-acbf-43ff-966c-baf045d7dbac': { riskCarrierLabel: 'Acupression' },
  '5201c629-20a0-46b8-811e-7b22c56b97ff': {
    riskCarrierLabel: 'Acupuncture, pratiqué uniquement par un docteur en médecine ou un kinésithérapeuthe',
  },
  'abab17d3-67b5-48a0-bdc3-1bbe56fdcfab': {
    riskCarrierLabel: 'Aide et/ou soutien psychologique (mémoire cellulaire, interprétation des rêves)',
  },
  'f2da756e-aa79-4954-b6b1-e1419408d17a': { riskCarrierLabel: 'Algothérapie' },
  '66d3de70-7d90-46be-89f7-4e8d09820d34': { riskCarrierLabel: 'Analyse de la posture (posturologie)' },
  'acada7d9-f8e8-4256-9eb1-8b32bdd23773': { riskCarrierLabel: 'Analyse du corps' },
  '8490613e-59e9-42f9-9eee-12275eab25d4': { riskCarrierLabel: 'Analyse Psycho-Organique' },
  'a34ed68b-fbd3-454e-b5bf-125d8cdeaa04': { riskCarrierLabel: 'Analyse transactionnelle' },
  '2520a944-65e7-4591-ba3b-2b5277c2d8d1': { riskCarrierLabel: 'Angel Therapy' },
  '9e49a432-3dd1-4a16-98f0-040356a89c49': {
    riskCarrierLabel: "Apithérapie (à l'exclusion de toute utilisation de venin)",
  },
  'c9fbf691-dda7-4b0a-bd07-6a197a6747db': { riskCarrierLabel: 'Approche Centrée sur la Personne' },
  'd7a2c4b9-b770-4d58-b7e8-9cfbe7e57728': { riskCarrierLabel: 'Archives Akashiques' },
  '162b096f-f24c-4303-9c86-bfbcf3b6d39b': { riskCarrierLabel: 'Argilothérapie' },
  'ecbd2fa4-2892-41c6-8c6e-4412d23dd37f': { riskCarrierLabel: 'Ariti Gymnastique' },
  'd34ef810-1292-4735-9a3c-3a7501aa7d02': { riskCarrierLabel: 'Aromachologie' },
  'a1599d45-06a1-425d-9912-a467de492a30': { riskCarrierLabel: 'Aromathérapie' },
  '84463db8-bdda-4939-bd2c-643dc6787aee': { riskCarrierLabel: 'Olphactothérapie' },
  '03301b12-07b0-4c4b-bb01-ef705c362dd9': { riskCarrierLabel: 'Aromatologie' },
  '5ee1bd74-ce3b-437f-a9a8-a64a852debae': { riskCarrierLabel: 'Arthérapie' },
  '2dac9656-ae96-481e-af62-5de9115be505': { riskCarrierLabel: 'Arts énergétiques' },
  '12a46308-09c7-4b6e-a95a-81cdbdb90fd0': {
    riskCarrierLabel: 'Art-thérapie (notamment musicothérapie, rigolothérapie, graphologie, peinture, danse…)',
  },
  '95ac603d-f841-4caa-bd29-ebdda6032a22': { riskCarrierLabel: 'Astrologie (y compris Astrologie chinoise)' },
  'fb7d9777-b4a7-4e3d-9751-0830072de78a': { riskCarrierLabel: 'Atrapuncture' },
  '89bea0e6-74bd-4e3c-a9d9-38e8a195e0a4': { riskCarrierLabel: 'Audio Psycho Phonologue' },
  'bf255cd0-b86b-4e0b-a5aa-26439ce6c2d7': { riskCarrierLabel: 'Aura-Soma© (Thérapie)' },
  'c063f52a-da4c-433f-a905-67555d9ec66d': {
    riskCarrierLabel:
      'Auriculothérapie, pratiqué uniquement par un docteur en médecine, une sage-femme ou un kinésithérapeuthe',
  },
  '501a691a-db2b-4363-b113-a4af79784410': {
    riskCarrierLabel:
      "Auxiliothérapie, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  '1e6b3cbf-02a2-46a8-88db-eee72680802c': { riskCarrierLabel: 'Ayurveda' },
  '2d54e923-c745-4941-afe1-b0bfb83aeb42': { riskCarrierLabel: 'Ayurveda massage' },
  'fe9bc333-e922-480b-bbdd-dd5c52a91d69': {
    riskCarrierLabel:
      "Bemer (Thérapie), à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  'edbdcf6b-5d00-4584-987f-c896f8ccace3': { riskCarrierLabel: 'Bio énergie' },
  '6c19ded4-0885-478a-a6f1-8260fee089ec': { riskCarrierLabel: 'Bio Psycho Synergie' },
  '1ee8f367-3bdc-4d01-945c-8bbd710058de': { riskCarrierLabel: 'Bioanalogie' },
  'd40ab9d2-0be5-4920-999d-cdb1374cf5e6': { riskCarrierLabel: 'Biodanza' },
  '3f0d2774-6270-4ae2-a513-959cfb645c74': { riskCarrierLabel: 'Bioénergie' },
  '3cc7ec76-9177-48e0-adf5-5a284f50b653': { riskCarrierLabel: 'Biokinergie' },
  '3ce5fa15-c22c-4107-a0da-22fadd5452d2': { riskCarrierLabel: 'Biokinésie' },
  '82735dac-f5b7-486f-83c8-574315aac6ae': { riskCarrierLabel: 'Biorésonance' },
  '6ffe5214-2fef-41d0-ba18-6b6fa9f58346': { riskCarrierLabel: 'Biorythme' },
  '749bfeeb-a1ad-4dc1-887b-7497a78d150a': {
    riskCarrierLabel: "Biosenetics, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  'fa60ee0c-3614-4360-ac1b-97ea69684d65': { riskCarrierLabel: 'Body conditioning' },
  'da6338d4-f30d-4d02-b8c6-b747d109f155': { riskCarrierLabel: 'Body Talk' },
  'e33ff357-8de2-4e29-a439-92b225fe27a6': { riskCarrierLabel: 'Body-Mind Centering®' },
  '07b09f59-0429-4e36-8d9b-105dab56f8f6': { riskCarrierLabel: 'Bowen (Méthode)' },
  '51c9ee43-e44c-474b-bfc0-5817aecd3b25': { riskCarrierLabel: 'Brain gym® (gymnastique cérébrale)' },
  'b94ec3c8-e6b3-4b1d-9e42-05f082843544': { riskCarrierLabel: 'Brennan (Méthode)' },
  '0adc0451-4dd0-4c1a-927c-e971095b2056': {
    riskCarrierLabel: "Bromatologie, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  '6602a5db-ff2c-4652-9cef-e80088068c25': { riskCarrierLabel: 'Buteyko (Méthode)' },
  'a37198c7-e272-4f72-96b3-869ca1af9d63': { riskCarrierLabel: 'Bye Bye Allergies - BBA' },
  '18389b9c-58ad-4b1d-9516-b03e0402cdd0': { riskCarrierLabel: 'Callanétic (Méthode)' },
  '4fbb1688-75e2-40ff-8508-17cb6ff767cf': { riskCarrierLabel: 'Camilli® (Massage sensitive de Claude)' },
  '756c4061-44ee-476a-9e40-8b0042284ce0': { riskCarrierLabel: 'Caractérologie' },
  'e8643016-dc1d-43e5-9b3c-ad353caf988d': { riskCarrierLabel: 'Cartomancie' },
  'c3f8b017-79ab-4515-a79f-1b9f181ad37d': { riskCarrierLabel: 'Chakra' },
  '2b30ce93-e00f-4678-a9b9-50b0683560bc': { riskCarrierLabel: 'Channeling' },
  '62c4a17c-bec0-4363-aa82-7aa7e95c1bd0': { riskCarrierLabel: 'Chant avec bébé' },
  'f023df56-baa9-4c8c-a4b1-7f06ce470773': { riskCarrierLabel: 'Chant Prénatal' },
  '324a4157-184a-4b7d-9ca5-e6cb41fa85f8': { riskCarrierLabel: 'Chat-thérapie / Ronron-thérapie' },
  '77125d81-098e-4ec0-915b-4d53c7c36103': { riskCarrierLabel: 'Chi Nei Tsang' },
  '6949180b-7af3-412f-8bed-b2944239bac4': { riskCarrierLabel: 'Chiromancie' },
  '835de749-6119-4ae7-9158-af506e297daa': { riskCarrierLabel: 'Chromothérapie' },
  '103610e4-036a-49fd-ba83-ffa355f2bb14': {
    riskCarrierLabel:
      "Chrononutrition, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  '57047bd9-f63c-439e-8668-c16a476b7efc': { riskCarrierLabel: 'Clair ressenti' },
  '18d6b397-e696-4de1-84d0-fb31caea9ced': { riskCarrierLabel: 'Clown relationnel' },
  '8cb5ea5a-d480-438d-b790-1dad5b43e995': { riskCarrierLabel: 'Clown-praticien' },
  '7b91514d-1f60-45ab-ae0c-9d6bcf405889': { riskCarrierLabel: 'Coach de vie' },
  '47355a83-926d-4ff7-8990-e808c87de30d': {
    riskCarrierLabel:
      "Coach en nutrition, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  '30217f6e-2740-4dd0-9c75-182fca35183a': { riskCarrierLabel: 'Coach Lover' },
  'b0551f7e-9a0c-4f6a-93a5-d075cab26d85': {
    riskCarrierLabel:
      "Coach sportif / fitness sans exploitation d'établissement/équipements sportifs - hors sports mécaniques, sports extrêmes, équitation",
  },
  'dbc17357-fe30-44b0-bd56-542bda70d4de': { riskCarrierLabel: 'Coaching' },
  '93d1323c-e0b3-458d-a378-10eb51fdfd92': { riskCarrierLabel: 'Coaching Développement personnel' },
  '914956a6-454a-4972-a14c-f0c1a85a6183': { riskCarrierLabel: 'Coaching sommeil' },
  'b7ad8e62-10d0-4732-b799-33dfaa3f18e7': { riskCarrierLabel: 'Cohérence cardiaque' },
  'abb4153d-c059-45a3-8429-efc68a1fa89d': { riskCarrierLabel: 'Coiffeur' },
  'aece7f94-255a-4efe-9ccc-7fcbf6225170': { riskCarrierLabel: 'Communication facilitée' },
  'f7c7574f-af60-4a5d-8229-7939495dc7a8': { riskCarrierLabel: 'Communication Non Violente (CNV)' },
  '10235cb0-5bbf-4c35-8f93-3f645b3a443d': {
    riskCarrierLabel:
      "Conférencier sur les métiers du Bien Etre (hors organisation d'évènement, d'atelier ou de visite)",
  },
  'fcebf6d0-fbb7-4d16-a6b3-f144e2bd5b37': { riskCarrierLabel: 'Conjugothérapie' },
  '724e3225-8dac-412f-b7fe-96f2beffcf8d': { riskCarrierLabel: 'Conscience corporelle dynamique' },
  'f8225c87-e1e0-44f1-bf35-ff019c346db3': {
    riskCarrierLabel: 'Conseil en Feng-Shui / Home staging - hors vente de mobilier / décoration',
  },
  'bed55e6a-51a4-41b0-ba92-0706dfe38c1b': { riskCarrierLabel: 'Conseil en image / relooking' },
  '6fb6e690-946e-4291-891a-a681d4f4b8d5': {
    riskCarrierLabel:
      'Conseil en nutrition et alimentation - hors Diététicien / prescription médicale et vente de produits',
  },
  '67813450-2665-4e41-b461-837a24227896': {
    riskCarrierLabel:
      'Conseil en nutrition et alimentation / Diététicien - hors prescription médicale et vente de produits',
  },
  '970958b4-d8bf-4906-bb41-1a2685019f3b': {
    riskCarrierLabel:
      "Conseil en plantes Médicinales et aromatiques, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  '0ec313ff-149d-4bce-978f-ed37f06dbf0b': { riskCarrierLabel: 'Conseil spirituel' },
  '562909b3-9b5d-4e5b-bb8e-24d17e6a347e': { riskCarrierLabel: 'Conseiller conjugal et familial' },
  '2acb5dbf-8d84-4c98-af53-6ea6a1c58890': { riskCarrierLabel: 'Conseiller en fleurs de Bach' },
  'cc22e377-18b8-4575-910c-5512e75c311e': { riskCarrierLabel: "Constellations d'abondance" },
  '4cafb148-6f5d-4c4c-9bfc-dd85223de075': { riskCarrierLabel: 'Consultant en éducation et relations humaines' },
  '84490990-0d7e-45f2-9edf-16114c345f39': {
    riskCarrierLabel:
      "Consultant en gestion de poids, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  'f1703bb2-653a-4fb5-99f3-3b739ababd65': { riskCarrierLabel: 'Cosméceutique' },
  '04efd970-1085-4d28-8832-4792ed9524c6': { riskCarrierLabel: 'Coué (Méthode)' },
  'dbbf4b87-fc8b-473f-a56d-eea717da9b70': { riskCarrierLabel: 'Coupeur de feu' },
  '714c9e0d-3ed4-43e4-8953-7b13aec65a40': { riskCarrierLabel: 'Cours de médiation tibétaine' },
  'e1822767-cfd7-4712-b8d3-d2fb1ae76806': { riskCarrierLabel: 'Crénothérapie, hors hydrothérapie du côlon' },
  '446fb845-2e07-433b-991d-ca6c62780aa4': { riskCarrierLabel: 'Cristallothérapie' },
  '9d1e3928-0736-4e62-8f4c-3481b3835872': { riskCarrierLabel: 'Danse biodynamique' },
  'df813540-f03e-4730-a8db-b1714b944417': { riskCarrierLabel: 'Danse Thérapie' },
  '30ed24e7-f506-4084-beee-d548028d50e6': { riskCarrierLabel: 'Danse Thérapie en Expression sensitive' },
  'aa38889b-ed71-41e9-b5ff-2025575856e3': { riskCarrierLabel: 'Dao Yin Fa' },
  '47c8319e-c6b4-4671-9e75-3874beb90f24': { riskCarrierLabel: 'Décodage Biologique' },
  '33a66c5d-8b1d-4dce-9fc5-801596086693': {
    riskCarrierLabel: "Détoxination, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  'b2266c16-ea35-405c-ac2d-39197d4bf94a': { riskCarrierLabel: 'Dharma guidance' },
  '24449095-b4b7-40f1-8a97-6d69a9428f5e': { riskCarrierLabel: 'Dialogueur intérieur' },
  '4952ba32-8343-4e34-b7df-04f36583005e': { riskCarrierLabel: 'Digiponcture' },
  '97e886af-9398-4b9d-bac9-c9854ae9cf1f': { riskCarrierLabel: 'Digitopression' },
  '4aa7ec3e-329c-420e-a010-0a76d198d259': { riskCarrierLabel: 'Discours et thérapie de langue' },
  'd656da3b-2e1a-430b-810d-aa05516185b9': {
    riskCarrierLabel: 'DMOKA® (Déprogrammation par les Mouvements Oculaires)',
  },
  '04c306cf-b1aa-4520-ab54-37eff2ea7cc6': { riskCarrierLabel: 'Do In' },
  '357fd8b5-fc54-46dc-8764-903209b256fc': { riskCarrierLabel: 'Dong Fa' },
  'a7bf96d8-8c72-4c87-987f-9f8127e7c011': { riskCarrierLabel: 'Drainage Lymphatique manuel (méthode du Dr Vodder)' },
  '130ad353-ecc1-4067-999c-10cebee4b134': { riskCarrierLabel: 'Drainolymphologie' },
  'f07f3e72-7f4e-47e9-b9fa-66a0686ca040': {
    riskCarrierLabel:
      'Dry Needling, pratiqué uniquement par un docteur en médecine, une sage-femme ou un kinésithérapeuthe',
  },
  '73694ce0-bb9d-41cf-bbe3-327c80096102': {
    riskCarrierLabel: "E.M.D.R. - reprogrammation et désensibilisation par le mouvement de l'oeil",
  },
  '31a8a604-4ed0-4864-843a-1f57043824b6': { riskCarrierLabel: 'Education kinesthésique' },
  'b3a2e351-b48b-47ee-9328-2dcd2692294d': { riskCarrierLabel: 'Ehrenfried (Méthode)' },
  'd4a4253c-1df8-4452-b327-ad86d05d5ba9': {
    riskCarrierLabel:
      "EIBE (Méthode) pratiqué uniquement par un docteur en médecine, une sage-femme ou un kinésithérapeuthe pour l'auriculothérapie",
  },
  '82d3a62c-9b74-4340-9bd0-eec2f16f04e9': { riskCarrierLabel: 'Electro-stimulation' },
  '5a518bda-60f8-46d7-bd4d-0c51508d64a0': { riskCarrierLabel: 'Electrothérapie' },
  '5eaaf0f3-3d06-4bb5-8681-0c1b446216fb': { riskCarrierLabel: 'Elixirs Floraux' },
  'f595c3c4-a878-4075-a0b1-b1d72f8ba92e': { riskCarrierLabel: 'Elixirs floraux, fleurs de Bach' },
  '7c7dd039-7c16-4568-bc85-0e337a54fc9b': { riskCarrierLabel: 'Emmett (Thérapie)' },
  '8d34eb5d-0fb6-4e75-b1e5-e08e84ecc3f5': { riskCarrierLabel: 'Emotional Freedom Technic (EFT)' },
  '22da7d37-747d-4aa7-b6e6-890025e3d63b': {
    riskCarrierLabel: 'Emotional Freedom Technic (Technique de Libération Emotionnelle)',
  },
  '59c139ca-925a-4a2b-a82f-90759d12f9aa': { riskCarrierLabel: 'Energéticien' },
  '614a1ac5-b709-4892-a459-629f36f21013': {
    riskCarrierLabel:
      'Energétique chinoise hors médecine traditionnel chinoise : prescription et vente de produit / si acuponcture limité aux seuls médecins ou kinésithérapeutes',
  },
  '290d6ebb-6bbd-4c3d-9b6e-7422d934b626': { riskCarrierLabel: 'Epigénétique par biorésonance' },
  '491470c3-0a2d-4615-907a-2a70c33da849': {
    riskCarrierLabel:
      'Equilibrage corporel, hors atlas profilax et hors pescription ou vente de produits, compléments alimentaires',
  },
  '8f3bc7b7-54fc-4d6f-b26b-e5c080f6d94f': {
    riskCarrierLabel: 'Esthéticien / Esthéticienne - hors épilation avec lumière pulsée et laser',
  },
  '6e7b56e6-a5b6-462f-818c-86ac6f183c55': { riskCarrierLabel: 'ETCN Entre Terre et Ciel Nous' },
  'f4ed6968-918c-418d-aa79-45e43a46b090': { riskCarrierLabel: 'Ethologie' },
  'cb5b0e7d-0877-40e4-9d3c-4b7d835bd350': { riskCarrierLabel: 'Ethologie Equine' },
  'f56ca469-7b9d-481e-890f-880128714f9e': { riskCarrierLabel: 'Etiopsychologie®' },
  '3bddf26e-536c-4a71-aa5b-b46b81b2ed8a': { riskCarrierLabel: "Etioscan générateurs d'organe" },
  '86e70d0f-41db-4cef-a9ab-836566f56e52': { riskCarrierLabel: 'Eurythmie' },
  '0371383c-0bc4-459c-8e0f-ffabd2ecb774': { riskCarrierLabel: 'Eutonie' },
  '0d0a2e53-34f8-4a7f-aabe-e999e8942e57': { riskCarrierLabel: 'Expression sensitive' },
  'fabfd03c-c709-47dd-a9a7-905b77e03b17': { riskCarrierLabel: 'Falun gong' },
  'd6c08d4b-345d-4653-8dce-c574a09baff0': { riskCarrierLabel: 'Fangologie' },
  '31778b51-c40e-469a-99f9-b30c38568655': { riskCarrierLabel: 'Fangothérapie' },
  'e2eeabf3-b587-4c0b-9a25-114196971975': { riskCarrierLabel: 'Fasciabioénergie' },
  'e633363e-aa49-4c9e-9ce0-462d0a95c519': { riskCarrierLabel: 'Fasciapulsologie' },
  '59470554-88dd-432d-b9b7-617eff825105': { riskCarrierLabel: 'Fasciathérapie' },
  '79f8fb24-25c1-49d3-b98e-906635889a61': { riskCarrierLabel: 'Fasciathérapie©' },
  '4fc2ef72-3524-4180-af3e-9172364f7538': { riskCarrierLabel: 'Feldenkrais (Méthode)' },
  '0f3c621e-ad34-40f0-9adc-65231abbb766': { riskCarrierLabel: 'Feng Shui' },
  '36413afd-3bf6-4895-aad8-86cd538a464c': { riskCarrierLabel: 'Filmothérapie' },
  '62bebc6b-e488-4be0-98a3-7d9d0690c584': { riskCarrierLabel: 'Florithérapie' },
  '5df61740-595a-49d0-8a9d-5caeb7bcf3b9': { riskCarrierLabel: 'Fluidie Therapie' },
  '071fe164-c257-4c44-a7e4-11a34d9358df': { riskCarrierLabel: 'Focusing ACP' },
  'cec142e0-3e38-4a34-bffd-cc4eb3b449fd': { riskCarrierLabel: 'Formation aux activités du bien-être' },
  '5a34efb5-d106-4ae6-9717-51be8233d657': { riskCarrierLabel: 'Fu jing tao' },
  'd90ca7b5-5ef9-4abf-a772-6ae6d36acdf4': { riskCarrierLabel: 'Gélothérapie' },
  '93245255-61ab-4756-a97b-c73d4492c661': { riskCarrierLabel: 'Gemmothérapie' },
  '41f54c63-a9cf-456d-91e8-04060575b05c': { riskCarrierLabel: 'Géobiologie' },
  'd0b7c01d-5ff0-4510-80d7-8dc074bdb80c': { riskCarrierLabel: 'Géobiophysique' },
  '78676ee7-28a9-4f8d-bd97-278322c6457d': { riskCarrierLabel: 'Gérontopraticien psychocorporel' },
  '4667344b-5cc5-485c-b036-70064b81c02b': { riskCarrierLabel: 'Gestalt-thérapie' },
  '1a327bc5-dcdb-4a51-823f-90edd521ee35': { riskCarrierLabel: 'Gommage du corps' },
  'e141e6aa-b00f-4e94-9693-deca83768db9': { riskCarrierLabel: 'Graphothérapeute' },
  '290bbfb1-bdfa-4f59-8d2f-67091c4e435f': { riskCarrierLabel: 'Guérisseur' },
  '7908c4ea-f0f9-4284-a505-1267a613d549': { riskCarrierLabel: 'Guidance Quantique' },
  '8fd134bc-8337-4298-86e5-9e7e98c41852': { riskCarrierLabel: 'Gundhra' },
  'b50ceeb5-0651-43f7-96a1-9371fed3d489': { riskCarrierLabel: 'Gym Bien-être' },
  'a6315ddd-4de5-45f1-8e10-9a2f9aacd516': { riskCarrierLabel: 'Gymnastique Holistique' },
  '8b5a0ccc-e4b8-4623-b7fc-9f83f5557057': { riskCarrierLabel: 'Halothérapie (Thérapie saline)' },
  '08984828-2f44-4d36-9341-7342e6028ea6': { riskCarrierLabel: 'Harmonisation globale' },
  '2c5c4622-f849-43f2-8576-bc7ab5714364': { riskCarrierLabel: 'Harmonisation lymphatique' },
  'f0a88acd-4634-4c47-8c2e-d42d2ef717e4': {
    riskCarrierLabel: 'Harmonisation vitale bioénergétique (hors ostéopathie)',
  },
  '30ae95c6-4c83-4155-83e0-68e157541eb2': { riskCarrierLabel: 'Herboriste' },
  'bf8aee40-0385-4f10-a648-7744e4eafce2': { riskCarrierLabel: 'Hijama (ventousothérapie) sec' },
  'b511c2f7-af0e-4d6c-a11a-1fd0046cc4f0': { riskCarrierLabel: "Ho'oponopono" },
  'ef4f7768-a9e5-47d7-9812-e79fb98a048a': { riskCarrierLabel: 'Holéoénergie' },
  '11865a0d-45a7-402e-a95d-79ec21f15465': { riskCarrierLabel: 'Hortithérapie' },
  'c91b1355-4f86-4574-89da-ef6c75add8e4': { riskCarrierLabel: 'Hydrothérapie (hors hydrothérapie du colon)' },
  '19306980-ca28-4215-a745-b4abaf202355': {
    riskCarrierLabel:
      "Hygiène neuropsychique, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  'b8f5bdef-78b0-4860-9f56-87c74d9bdcd5': { riskCarrierLabel: 'Hypnose' },
  'cd872b70-178e-4b34-a87a-42dbe7c80e87': { riskCarrierLabel: 'Hypnose Ericksonienne' },
  'fe505bba-110d-47ec-b207-8394dad801e3': { riskCarrierLabel: 'Hypnose Intégrative' },
  'e9bf5b18-3738-46c2-a618-d28989dd0970': { riskCarrierLabel: 'Hypnose Sajece' },
  '501a3256-e700-4228-8a8e-5f3cbae8b430': { riskCarrierLabel: 'Hypnothérapie' },
  'bd6406cc-41d4-43a8-b7fa-438b789e2fe3': { riskCarrierLabel: 'I.M.O Intégration des mouvements occulaires' },
  'cba424e0-de74-4975-9f4c-97260410c326': { riskCarrierLabel: 'Imago Thérapie' },
  '54a1c622-87d6-4df9-a718-f3081bc041fe': { riskCarrierLabel: 'Indologie' },
  'd4b80147-c96c-4300-ac47-5f12485d5cf1': {
    riskCarrierLabel:
      'Intégration Motrice Primordiale (sauf lorsque cette activité est exercée sur des enfants de moins de un an)',
  },
  '3fbdb151-23f3-4d73-a77c-bfa198b28b5a': { riskCarrierLabel: 'Intégration posturale' },
  'd4e7c1d3-92b7-4158-8a6b-66027bb95c1f': { riskCarrierLabel: 'Intégration structurale' },
  '15918b89-105c-40b1-b82a-2331bdc91d6c': { riskCarrierLabel: 'Internal Family Systems' },
  '58835014-f22a-48a2-aa8a-d09818f61d12': { riskCarrierLabel: 'Interprétation des rêves, travail sur le rêve' },
  'c4464dad-9f94-4681-89e4-872064c0097e': { riskCarrierLabel: 'Iridologie' },
  '204db239-a3a5-4186-8468-a0749a7e66ff': { riskCarrierLabel: 'Iyashi Dôme (Sauna japonais)' },
  '3d3b97f8-ff98-45c8-b9c4-84d2b58e57f6': { riskCarrierLabel: 'Janzu' },
  '93c7491e-7ee6-4cd9-9c1d-ec3a453bc449': { riskCarrierLabel: 'Jeûne thérapeutique' },
  '8b921cd7-5a88-4944-8a8a-7ed8caebcd5b': { riskCarrierLabel: 'Jin Shin Jyutsu' },
  '488cbf07-3ab9-4ad0-b8cf-7387e62530b9': { riskCarrierLabel: 'Katsugen Undo' },
  '4d4bfbc8-34ff-44f5-a7c1-2e7e82dbf335': {
    riskCarrierLabel: 'Kinésiologie (hors chiropractie, ostéopathie et prestations sur mineurs)',
  },
  '279ccb83-9909-4d53-aa2b-0968b9e38a02': { riskCarrierLabel: 'Kobido' },
  'ff58781c-9e0c-4791-a068-233a77f1a91f': { riskCarrierLabel: 'LaHoChi' },
  '9a3c2810-8d95-47c2-a38b-6523cd7c5cba': { riskCarrierLabel: 'Land Art Thérapie' },
  'fd982974-05db-4d7f-b9a2-4bec0b3c04ab': { riskCarrierLabel: 'Le processus Hoffman' },
  'c2bea2c0-de36-4bbc-8597-6985e5c30532': { riskCarrierLabel: 'Lecture de mémoires Akashiques' },
  '0e4c3199-a899-42b9-898f-3326daf55a0f': { riskCarrierLabel: 'Lectures de visage' },
  'c7a2042e-b378-499f-83e4-28adf8fa3827': { riskCarrierLabel: 'Libération des cuirasses' },
  '9150d6cd-7efb-469b-965f-967ec2d2c958': { riskCarrierLabel: 'Lithomancie' },
  'b6c27e22-47ca-4f9f-969f-9937b8885635': { riskCarrierLabel: 'Lithothérapie' },
  'e89d0c29-11bc-4f01-98be-cf07cac6ee92': { riskCarrierLabel: 'Logosynthèse' },
  '979d62b5-25fb-42fb-b8ad-cf5aa921efb8': { riskCarrierLabel: 'Lomilomi' },
  'dc3041e3-a4eb-4b0c-b60b-21f670a2d8a1': { riskCarrierLabel: 'Luminothérapie' },
  '4cc46ec9-18f1-4936-933d-9a3790d320a6': { riskCarrierLabel: 'Lunologie' },
  '96dbfdd0-9d31-47eb-8b2c-54eed6e603bb': { riskCarrierLabel: 'Luxoponcture' },
  'efeb50ac-206a-43d2-a522-6a2cf0d33f3b': { riskCarrierLabel: 'Luxothérapie' },
  'd54da2cc-5a9c-444a-9680-aed0a76831e3': { riskCarrierLabel: 'Mace Energy method' },
  '9493ee96-cfd7-421c-97bb-b0e49b02db88': { riskCarrierLabel: 'Magnétisme' },
  '5d9cf9b8-e597-4b5c-a4fb-efe4d0a0925b': { riskCarrierLabel: 'Magnétisme / Biomagnétisme' },
  'c7fa0d10-6717-45c5-a017-95506b4adbdc': { riskCarrierLabel: 'Magnétisme radionique' },
  '6a47e69d-0bc8-4012-8021-d459b8caa1ac': { riskCarrierLabel: 'Magnétothérapie' },
  '2c9825b5-4bcd-4232-8859-c640ccea8d33': { riskCarrierLabel: 'Maïeusthésie' },
  'd740c719-653d-44b6-b5be-bb3957e84484': { riskCarrierLabel: 'Makaton' },
  '36751abd-5c48-4105-ae38-7bef24fdd595': {
    riskCarrierLabel: 'Manupuncture pratiqué uniquement par un docteur en médecine ou un kinésithérapeuthe',
  },
  'c78439e7-efce-4975-b4e1-43f6dc3b2fb5': { riskCarrierLabel: 'Map thérapies' },
  'e954d454-07e5-42b1-b7ca-44d49b4b297d': { riskCarrierLabel: 'Marche Afghane' },
  '1ec9f52c-2f4c-4fb6-ac39-a9518cb6537e': { riskCarrierLabel: 'Marmathérapie' },
  '716f0e2d-2c49-46d1-b4a8-4e71d394632c': { riskCarrierLabel: 'Marma-Veda' },
  '8b55dc76-b711-400c-92a7-849f2fff44ab': { riskCarrierLabel: 'Masques corporels' },
  '4d4b2ef3-7518-4f9d-be64-087a613dfc12': { riskCarrierLabel: 'Massage Abhyanga' },
  '896d1383-38fc-43af-aeef-688246989f6e': {
    riskCarrierLabel:
      'Massage bien être / Modelage - hors prestation de soin à caractère médical/paramédical et exploitation de Spa',
  },
  'd2eae4f8-ed00-4bf0-933e-168a0a1f649d': { riskCarrierLabel: 'Massage Bols Tibétains' },
  '7225c6e0-3987-4411-9f21-25dfbd93a7b5': { riskCarrierLabel: 'Massage californien' },
  '4e4f0025-95af-4369-94f6-a75457360170': { riskCarrierLabel: 'Massage Edonis' },
  '4f605d03-14a8-43ee-a239-230852dea884': { riskCarrierLabel: 'Massage lemniscate' },
  '1dce2a73-65fc-484e-8e9b-55d99e0139d5': { riskCarrierLabel: 'Massage Lomi Lomi' },
  'c0f92a3b-64e5-467e-a378-74e6a888382e': { riskCarrierLabel: 'Massage lymphatique' },
  'f9b09c09-3ea5-4651-ab1b-3f8ebf3eee9b': { riskCarrierLabel: 'Massage sensoriel' },
  '87e14921-46c9-4a5a-98ed-24ad329b04ad': { riskCarrierLabel: 'Massage Taï' },
  '049ff802-abea-43fb-a85c-99b846317227': { riskCarrierLabel: 'Massage tampons de Siam (ou Herbal Siam)' },
  'f230328d-88af-485a-8af4-f23240d47c1c': { riskCarrierLabel: 'Massage Thaï traditionnel' },
  '2e80262c-d450-42b2-a8f6-43810537a156': { riskCarrierLabel: 'Massothérapie' },
  'fbf969d3-bc75-4cb2-9fec-0cf400839d73': { riskCarrierLabel: 'Médecine Symbolique®' },
  '8eeb4063-28a8-46de-a3d2-6a379b2f75f4': { riskCarrierLabel: 'Médiation Familiale' },
  '0b619d82-2738-4296-bbd5-55e00f52af0b': { riskCarrierLabel: "Médiation par l'animal" },
  'a15b13c3-ad0d-4544-97d6-dc1d39d67616': { riskCarrierLabel: 'Méditation' },
  'fdf864eb-bf7c-40f8-996c-bb86e819d57a': { riskCarrierLabel: 'Médium' },
  'e2cc0b54-7894-40e1-96e9-eea8f0dc3948': { riskCarrierLabel: 'Mémoire cellulaire' },
  '92be3bb5-875e-4a1b-98e9-839d2dcf6f07': { riskCarrierLabel: 'Mentaliste' },
  '489560d4-76b5-4723-bafd-fe59fd12ce91': { riskCarrierLabel: 'Meridian tapping therapy' },
  'dc2d3e1f-a1ca-4871-8463-48f8744fdd79': { riskCarrierLabel: 'Métapsychobiologie' },
  '0cf74fcb-fa74-4936-a95f-2e750e84c090': { riskCarrierLabel: 'Méthode 3C (concentration, calme, contrôle)' },
  '978d2fe2-bd41-430e-bb96-c99fbc45392f': { riskCarrierLabel: 'Méthode Alpé' },
  'b0ceaca4-8795-4e38-929c-2069422e522c': { riskCarrierLabel: 'Méthode AORA' },
  'efdbc9b4-453b-4aea-9e74-0e3fc4b8f8f9': { riskCarrierLabel: 'Méthode Bates' },
  '43e5dec0-26b1-4301-a9e0-02ed8b1b5a1e': { riskCarrierLabel: 'Méthode Bowen' },
  'bc9ecc8c-b5e2-46da-830f-d3a2115b2820': { riskCarrierLabel: 'Méthode des 2 points' },
  'b961726b-1d9c-4055-8247-9f615705063e': { riskCarrierLabel: 'Méthode des 4 dimensions' },
  '83c7a3ac-9521-4d5f-829d-079e01122ffe': { riskCarrierLabel: 'Méthode du journal créatif' },
  '662661d3-3cc6-4763-8079-6509b4250fec': { riskCarrierLabel: 'méthode eibed' },
  '036255f5-b7e8-4fad-820c-cb2635ddff8e': { riskCarrierLabel: 'Méthode Feldenkrais' },
  'd6d6f8b7-9589-403f-81d7-6e5ac4f267b9': {
    riskCarrierLabel: 'Méthode LEEA (libération des émotions et des allergies associées)',
  },
  '7a7eb059-f773-434c-a2ce-d734b166927f': { riskCarrierLabel: 'Méthode Linequartz®' },
  'deafb3a5-6d45-4687-8d3e-1a555cc49f43': { riskCarrierLabel: 'Méthode Meer®' },
  '3e86d865-781c-4372-9857-283963d8ef8f': { riskCarrierLabel: 'Méthode Naet' },
  '0032e991-b725-4f98-9575-a423e777323a': { riskCarrierLabel: 'Méthode Naët' },
  'ba5ef123-7db3-42f4-84ac-d9ce996427a4': { riskCarrierLabel: 'Méthode Quantique' },
  '78df69a0-7a19-42ac-be7c-8540ce3ca807': { riskCarrierLabel: 'Méthode Quertant ou Culture Psycho-sensorielle (CPS)' },
  'a4b9a1bf-3228-4359-bec3-f2f3f8341bc3': { riskCarrierLabel: 'Méthode Sinovi' },
  'ee3f0cdc-0e7f-44e0-b412-91eaa9541e15': {
    riskCarrierLabel: 'Méthode T.R.E. (Tension and Trauma Releasing Exercices)',
  },
  'c6b573ed-c711-453b-a26d-0a01457ea238': { riskCarrierLabel: 'Méthode Tomatis' },
  'b6fdef6b-8180-42a3-8ebe-fcd0cfe74c6a': { riskCarrierLabel: 'Méthode UPLEDGER' },
  'e93b9812-58a4-47e6-9330-e159090d5855': { riskCarrierLabel: 'Méthodes de Jacquier' },
  'a38e1fbd-7d3d-458b-9bfa-49b0a5fd38b3': { riskCarrierLabel: 'Mézières (méthode)' },
  '21ceddc1-d638-4f0d-802e-cfbe3ec7c4e5': { riskCarrierLabel: 'Micro-courant' },
  '4f30bd80-195e-4aaa-b012-f7a7ec675fa3': { riskCarrierLabel: 'Micro-ostéo digitale®' },
  'f4d51d69-bbec-4dac-8fb1-b6cf7510212d': { riskCarrierLabel: 'Mindfulness' },
  '2812d13c-c055-401b-b207-08a8f35b652a': { riskCarrierLabel: 'Modelage drainage lymphatique' },
  'd3393c68-f0b9-4d6d-9629-49d32a25da51': {
    riskCarrierLabel:
      'Mora (Thérapie), pratiqué uniquement par un docteur en médecine, une sage-femme ou un kinésithérapeute',
  },
  '8d7d67fd-4be6-4148-9b54-9ddc5cbff2a2': { riskCarrierLabel: 'Morphopsychlogie' },
  'f8f61308-c927-42f9-831a-a42659a60234': { riskCarrierLabel: 'Morphopsychologie' },
  'c82335e9-c64d-4f8a-8627-8e8abf02f354': { riskCarrierLabel: 'Moxibustion' },
  '887b9f72-3fd6-4396-8eac-b0c380d1d059': { riskCarrierLabel: 'Mudrâs' },
  '6d8b43b1-be09-433b-a366-cabba4c05422': { riskCarrierLabel: 'Multiréflexologie Dien Chan' },
  '5b1dc985-5f7d-40bd-a02c-16994de5c0a4': { riskCarrierLabel: 'Musicothérapie' },
  '7e1c6f49-6197-45ac-8938-718c92483d02': { riskCarrierLabel: 'Myers briggs' },
  '0275c519-d179-4f01-965c-05373a211229': { riskCarrierLabel: 'NAET® (Méthode)' },
  'c595ea7d-69e2-448e-aa3a-ee9f11a40594': {
    riskCarrierLabel:
      "Naturopathie (hygiène de vie, massothérapie, psychologie, aromathérapie), à l'exclusion de la prescription ou la vente de complément alimentaire",
  },
  'b84a0575-a5e6-4f9c-8850-237b453d40da': {
    riskCarrierLabel: "Naturopathie, à l'exclusion de toute pescription ou vente de produits, compléments alimentaires",
  },
  '9cf94458-5500-4189-a839-b7f43789790f': {
    riskCarrierLabel: 'NERTI (Nettoyage Emotionnel Rapide des Traumatismes inconscients)',
  },
  'b47db1f3-85db-4239-ab71-10b99f816998': { riskCarrierLabel: 'Neurofeedback' },
  '50a616b0-f125-4913-97a7-607b815579bc': { riskCarrierLabel: 'Neurothéapie' },
  'eedab18d-2fcd-4498-8a99-674861e67e82': { riskCarrierLabel: 'Neuro-training' },
  '49caae91-fca9-46ab-b788-a8e8303031a0': { riskCarrierLabel: 'Numérologie' },
  '3b748b9e-f101-4541-b99c-b392d3e4390a': { riskCarrierLabel: 'Olfactothérapie®' },
  '1cae5926-2a7b-4040-85d1-c40ed876dca1': {
    riskCarrierLabel:
      'Oligothérapie (hors prescription médicale et/ou de compléments alimentaires et/ou vente de produits)',
  },
  '3c7c0467-9a46-4bbe-888c-917b3b372988': { riskCarrierLabel: 'Onctions aromatiques' },
  'fd83ae8f-673c-47d6-a6cd-c5618c0df59b': { riskCarrierLabel: 'Ondobiologie' },
  '91b38cd4-753c-4509-bd06-56f9df4d466c': { riskCarrierLabel: 'Orthopédagogue' },
  'eca069d2-0c78-419e-961e-fadc69419724': { riskCarrierLabel: 'OstéoFluidique' },
  '6c2122b5-3f93-484a-9423-44669a5b1c2a': { riskCarrierLabel: "Oxygénation cellulaire (méthode bol d'air Jacquier)" },
  'db787f2e-fd15-4e85-a824-71d917eb6a05': { riskCarrierLabel: 'Oxygénothérapie non médicale, hors caisson hyperbare' },
  'a53ecf51-d485-49a8-8243-52fc373e1456': { riskCarrierLabel: 'Padovan (Méthode)' },
  '218479b8-4964-42ab-bcf3-2216679b7fed': { riskCarrierLabel: 'Parapsychologie' },
  '686cf311-be46-4d99-90fa-511a33428372': {
    riskCarrierLabel: "PEAT (activation de l'énergie primordiale de la transcendance)",
  },
  'b02b823a-9908-4bd1-8385-9494e36d9b44': { riskCarrierLabel: 'Pédagogie perceptive®' },
  '09399d53-ef34-4fbc-b7d1-6c7b600ef4e6': { riskCarrierLabel: 'Pélothérapie' },
  '118059b6-cb90-4296-91c4-58ca742edf2b': { riskCarrierLabel: 'Percussions et danses Africaines' },
  'ea542077-b778-47e6-8ed6-702ac526f45a': { riskCarrierLabel: 'Phénothérapie' },
  'a7e73b41-3390-43ac-97ea-6a8a1ce642c2': { riskCarrierLabel: 'Phytologie' },
  '01416346-ec94-477e-8b63-62773777499e': { riskCarrierLabel: 'Pig (Cochon) Thérapie' },
  '893c54de-2ee4-4f2a-a42b-722a00d792e4': { riskCarrierLabel: 'Pilates' },
  'fb57c753-bfa0-456c-aeb2-0e682d8e4507': { riskCarrierLabel: 'Plastithérapie' },
  '072a46d8-27a4-41d8-9109-af4cd097fbae': { riskCarrierLabel: 'PNL (Programmation neurolinguistique)' },
  '23fc98c5-e34d-4fa8-b2cd-352115661617': { riskCarrierLabel: 'Podoréflexologie' },
  '642633d0-fe8c-4324-b2bf-a8c9339f0f2d': { riskCarrierLabel: 'Posturologie' },
  'bff2bba1-ff57-4d3c-b131-37ffaf56c5ad': { riskCarrierLabel: 'Pranathérapie' },
  '46d7352e-4b4c-4ec8-93dd-43b68d2435fb': { riskCarrierLabel: 'Pratique du Mandala' },
  'e5c0bbb5-58b6-4a7a-a714-7f8ff92aedc7': { riskCarrierLabel: 'Préparation Mentale' },
  '8eb41f2b-e52e-4b16-a446-e8f35320804f': { riskCarrierLabel: 'Prévention du stress, gestion émotionnelle' },
  'dabdaab0-df9a-42af-bbe1-f9a53d114f16': { riskCarrierLabel: 'Programmation neuro linguistique (PNL)' },
  'f12ee667-b146-434b-8182-83eba8995f51': { riskCarrierLabel: 'Psycho-bio-acupressure' },
  '842268d1-0f57-4a40-ae81-136c43a321d4': { riskCarrierLabel: 'Psychobiologie' },
  'da17b561-5ae5-4fb4-b506-ab7c654096f0': { riskCarrierLabel: 'Psycho-énergéticien' },
  'd1a5051d-a689-4bbd-a72f-94d4cbabde26': { riskCarrierLabel: 'Psychogénéalogie' },
  '83d45d2e-7a58-4cee-901c-09061c493c51': { riskCarrierLabel: 'Psycho-généalogie' },
  'e84be755-9574-4767-9ac2-84ee0cc8d976': { riskCarrierLabel: 'Psychologie' },
  '75ae6369-5f06-441b-b221-405ba11e22d6': { riskCarrierLabel: 'Psychologie Biodynamique' },
  'e9edf171-387a-4e83-b3df-531fbe71df8a': { riskCarrierLabel: 'Psychomotricien' },
  'eefe490b-e5fd-469b-9cb4-70dcd2db4d53': { riskCarrierLabel: 'Psychopathologie' },
  'd3a863c2-85f6-49b4-be9a-9c207d14683d': { riskCarrierLabel: 'Psychophanie' },
  '455c8a12-a73b-4dc0-a447-1379cfee3c1c': { riskCarrierLabel: 'Psychophonie' },
  'd1eeb994-9cec-4ebd-a3f9-3bb3d6e59d20': { riskCarrierLabel: 'Psychopraticien' },
  '31beec4c-c79f-47ec-80be-2b9b3c38546d': { riskCarrierLabel: 'Psychosociologie' },
  'dae80c39-1bd2-4781-9a32-aa50dba43c55': { riskCarrierLabel: 'Psychosomaticien' },
  '6692d68f-ad6c-4523-9406-19f44790d827': { riskCarrierLabel: 'Psycho-somatothérapie' },
  '8b424acf-2dce-4e51-938d-da23b4f47a2e': { riskCarrierLabel: 'Psychosynthèse' },
  '0e3a0726-04e7-4b3d-a939-f7bac4c5e508': {
    riskCarrierLabel: 'Psychothérapie (Gestalt thérapie, approche centrée sur la personne, thérapie psycho corporelle)',
  },
  'e2828d63-3f96-4795-9954-cd58b69b57c2': { riskCarrierLabel: 'Psychothérapie (y compris téléconsultation)' },
  '2a50c3ee-9c89-4186-8c76-6378f9696b57': { riskCarrierLabel: 'Pychopracticien' },
  '3eb48064-c054-4fca-9f7d-12c74c9b2aa5': { riskCarrierLabel: 'Qi Gong' },
  '7db3cf40-59de-4d9b-aae6-1e31c8fd4080': { riskCarrierLabel: 'Quantum Touch®' },
  'f2823655-3ebe-4c41-8a21-b04ae31dfa5a': { riskCarrierLabel: 'Radiesthésie' },
  '04ff112b-9210-4061-a118-810482a188cb': { riskCarrierLabel: 'Radiésthésie' },
  '911b724e-85fb-4156-94d5-6452ff9db368': { riskCarrierLabel: 'Reboutement' },
  '2b83e2db-0a75-4c2b-a8f3-de7911b20733': { riskCarrierLabel: 'Rebouteux' },
  '192c2a4d-73ee-4e5b-b823-d6c07b0d2c3e': { riskCarrierLabel: 'Reboutologie' },
  '507fdeaa-0710-4602-b484-9a4a1f2a67cb': { riskCarrierLabel: 'Rebouto-réactivopathe' },
  '6db2985e-9cb8-4a29-b050-a19485c8bb6a': { riskCarrierLabel: 'Reconnexion' },
  '1e0f41e1-50c7-4286-88d4-cb6ee5b6f6b2': { riskCarrierLabel: 'Rééducation par résonance' },
  '65bd048b-7656-4bbb-a3bf-975cf3245696': { riskCarrierLabel: 'Rééquilibrage Energétique Biopolarité®' },
  'c2fe90cb-a49a-4860-b1a3-0aa87be6e1d1': { riskCarrierLabel: 'Réflexologue' },
  '7a1f762d-e9f4-4f83-bcd1-3b8ebcb73c27': { riskCarrierLabel: 'Reiki' },
  '640237cd-e170-4511-a8ea-44706784240d': { riskCarrierLabel: 'Réinformation Vibratoire' },
  '18c00250-f281-439a-b933-de0e1e3d1f6c': { riskCarrierLabel: "Relation d'aide par le toucher®" },
  '050e3ad8-3303-4cf5-b02d-1676f74d4965': { riskCarrierLabel: 'Relaxation coréenne' },
  '6aebb089-ef89-4697-b32e-8ee071b80f5c': { riskCarrierLabel: 'Relaxation musculaire profonde' },
  'd99f6b41-2304-4e66-8ae4-46cef32a6c8b': { riskCarrierLabel: 'Relaxologie' },
  '16838f22-1605-44a8-8785-6278ee8c2684': { riskCarrierLabel: 'REMAP®' },
  '46b86caa-bba7-481b-89e7-d7337ea459dc': { riskCarrierLabel: 'Résonnance Energétique par Stimulation Cutanée (RESC)' },
  '094e9131-26bf-4cd7-8428-9455ec7f2b08': { riskCarrierLabel: 'Respiration Holotropique' },
  'bf2db4bb-2bfc-448b-b402-12906488bbcb': { riskCarrierLabel: 'Rigologie' },
  '67d9e571-2b24-45c6-8dbc-37f254800f42': { riskCarrierLabel: 'RITMO' },
  '16793a2a-9917-4f4e-9351-e6cddda8db1b': { riskCarrierLabel: 'RMTI (Rhytmic Movement Training International)' },
  '41963cae-2e70-4c3d-bee9-e896235843d6': { riskCarrierLabel: 'Rolfing' },
  '3690d472-2ac7-4386-9b2e-07bae85eb0a3': { riskCarrierLabel: 'Rosen (Méthode)' },
  '0f7fcbf6-7c05-4e7c-80d4-3a9924eb0c4b': { riskCarrierLabel: 'Routinologue' },
  'f5b69b0f-c0c8-4522-a871-242239c1d9ea': { riskCarrierLabel: 'Runes (Méthode)' },
  '9bebe46c-febb-4d33-913d-7eb5b79baed6': {
    riskCarrierLabel:
      'Santé humaniste (hors prescription médicale et/ou de compléments alimentaires et/ou vente de produits)',
  },
  '99cd1ef5-9aef-431f-8adc-f64272167f2b': {
    riskCarrierLabel:
      'Santé humaniste,hors prescription médicale et/ou de compléments alimentaires et/ou vente de produits',
  },
  'a83f8c93-3c31-48dd-b65e-a96f7d02f50e': { riskCarrierLabel: 'Scenar (Thérapie)' },
  '1ea8164c-594a-4916-9db7-989c193d7097': { riskCarrierLabel: 'Sensymagologie' },
  '1374ae83-a5c0-49c9-b150-f5c84bed032d': { riskCarrierLabel: 'Sexo-Conjugothérapie' },
  '2009670e-6fd9-4c54-b29c-ebb71111dc8e': { riskCarrierLabel: 'Sexologie' },
  'af23d037-6cc0-4507-abfc-e7381a204754': { riskCarrierLabel: 'Sexothérapie' },
  '1287912d-7060-43f5-9c08-22d81fac2bfa': { riskCarrierLabel: 'Shiatsu' },
  'da4eef45-cd1a-41af-9ff1-e3a97d496caf': { riskCarrierLabel: 'Shiatsu (Watsu)' },
  '3b404187-e329-4060-9bc9-2f4b602df21f': { riskCarrierLabel: 'Shiatsu Fluidique' },
  'e9b5deb1-c102-4294-9d69-fc513e7f1e92': { riskCarrierLabel: 'Shiatsu Thérapeuthique' },
  '5dfa41aa-7a6f-4bfc-b74f-67c3a3d7d2cc': { riskCarrierLabel: 'Shinrin-yoku/Eco-thérapie' },
  '5a86f129-4cd6-44c8-be2f-24917d274685': { riskCarrierLabel: 'Shiroshampi' },
  '6a364d75-855d-434e-9199-11cf664dee35': { riskCarrierLabel: 'Shugendo' },
  '0abbcf4e-cab2-4152-83c9-d1c7b015cc1a': { riskCarrierLabel: 'Shutaïdo' },
  '6f29ab14-9ff1-40dc-9278-915394b7d019': { riskCarrierLabel: 'Simonton (Méthode) et la visualisation' },
  '7daf4ae8-f5e6-45ca-8dbc-fabfede4c859': { riskCarrierLabel: 'Soins Esséniens' },
  '851f5b08-3130-4f11-853e-a0abbdf82cbb': { riskCarrierLabel: 'Soins Ethérique' },
  'f2c85c1b-49ba-4533-b0d5-0ecfda4fce32': {
    riskCarrierLabel:
      'Soins Holistiques (hors prescription médicale et/ou de compléments alimentaires et/ou vente de produits)',
  },
  '291bf679-f0e1-4d33-afc2-7c85ac6e21f0': { riskCarrierLabel: 'Somato-analyse' },
  '9cd12023-dabb-4f48-b194-7e9f44c2ced1': { riskCarrierLabel: 'Somatopathie' },
  '13196445-3d07-4aea-83df-3a1133442b08': { riskCarrierLabel: 'Somato-psychopédagogie' },
  '5c951240-2dc9-43c0-8a6d-53c98bcdedf2': { riskCarrierLabel: 'Somato-Relaxologie' },
  '25fcefe9-779d-4075-96e9-6e5795f8d1d5': { riskCarrierLabel: 'Somatothérapie' },
  '0a8c1c98-6937-4ee4-a9a4-d608bc563a8e': { riskCarrierLabel: 'Sonothérpaie' },
  '065de1d8-95a2-442e-8023-44a0de7778f3': { riskCarrierLabel: 'Sophro en entreprise' },
  '18c97079-7c30-42ce-8db9-6ea0d2d30cec': { riskCarrierLabel: 'Sophro-analyse' },
  '8ae5b9af-a9d4-4dc2-be94-7d6d5ffb7ea9': { riskCarrierLabel: 'Sophrologue' },
  '84c97c04-30e4-45b4-816e-44ca2cc70353': { riskCarrierLabel: 'Sophro-relaxation et sophro-relaxation ludique' },
  'a027428e-2894-471a-9554-525e5b62413f': { riskCarrierLabel: 'Sophro-sexualité' },
  '89b87fa6-e100-48c5-9678-55ee559a9724': { riskCarrierLabel: 'Sophro-sport' },
  'ef064de1-c53e-4666-8d52-6450183f157c': { riskCarrierLabel: 'Sourcier' },
  '6392a6a3-ad3a-4573-ad61-ecf188077c9a': { riskCarrierLabel: 'Stretching' },
  '8004c138-a263-4f2c-a252-f42f87dfb1f4': { riskCarrierLabel: 'Stretching Postural®' },
  '1981e591-4b4b-4431-8aff-c2ba32bfcd53': { riskCarrierLabel: 'Sylvothérapie' },
  '9993c9a9-0db3-4f2e-9713-f2379b55e4ca': { riskCarrierLabel: 'Synergologie' },
  'e03281dd-2a3e-46e0-8203-19fd8a96a397': { riskCarrierLabel: 'T.C.C. - Thérapie comportementale et cognitive' },
  'e7ca2f38-9f72-4ce5-ad25-7c7e706f7f43': { riskCarrierLabel: 'Tabac Stop (Conseils uniquement)' },
  '1b634123-7efd-4680-8c22-5cf4c7d7ab27': { riskCarrierLabel: 'Tai Chi Chuan' },
  '2a4c42eb-b22e-4874-a976-00b95d0acbf1': { riskCarrierLabel: 'Tai Ji Quan' },
  '8bea37f9-613d-4cae-b716-712e53a90110': { riskCarrierLabel: 'Tantrisme' },
  '005251ed-f035-44cc-869c-43049e054a21': { riskCarrierLabel: 'Tao de la voix' },
  'a82377c1-8e9f-402b-800a-47ec57bb9f80': { riskCarrierLabel: 'Tarot' },
  'b9471a93-52ef-47fd-b751-604dede1d6ce': { riskCarrierLabel: 'TAT (Tapas Acupressure Technique)' },
  '96206451-c0e5-4eb0-85b2-6e6f09feb69e': { riskCarrierLabel: 'Technique AromaTouch™' },
  'b50ce310-d323-440e-9718-32688ca8a422': { riskCarrierLabel: 'Technique Autogénic' },
  'cb25de30-fc1e-451d-bb6c-415378f56616': { riskCarrierLabel: "Technique d'Alexander" },
  'feaeb5aa-fa6d-4d8d-830d-9fd7d2c74ccd': { riskCarrierLabel: "Technique d'Alexandre" },
  '169d80de-0f5e-4b63-aa8a-866c626ef10b': { riskCarrierLabel: "Technique d'alignement du corps" },
  '2c1bbf81-20c4-4cbc-aa3a-748b2d0cc5d6': {
    riskCarrierLabel: "Technique d'identification sensorielle des peurs inconscientes (TIPI)",
  },
  'b3d1aaa5-9ed7-440e-9561-1aad57c5b0bf': { riskCarrierLabel: 'Technique de Reiki' },
  '353ed754-678b-4b3a-96e2-f5c5f29bf3aa': { riskCarrierLabel: 'Technique de Rolfing' },
  '4165c611-4470-4bda-a1f9-7f33266d8148': { riskCarrierLabel: 'Technique des diapasons thérapeutiques' },
  '62acd268-74dd-42dd-a5f0-acb364779f8d': { riskCarrierLabel: 'Technique des points Knap' },
  '72c9a4ec-f251-4237-af87-50d9168b2a37': { riskCarrierLabel: 'Technique métamorphique' },
  '563b1902-a8d2-47d8-986e-2bf6d6ca98e6': { riskCarrierLabel: 'Technique Nia ou Nia' },
  '4f4299ff-4679-400e-8e6d-24d9c0eae8c7': { riskCarrierLabel: 'Techniques de Moxa' },
  'e40c4ebb-257a-42e6-9847-2c69b4d258fa': { riskCarrierLabel: 'Techniques respiratoires' },
  '97424253-a507-4e54-a627-0d9a2024085f': { riskCarrierLabel: 'The Journey (Thérapie)' },
  'e9488c65-d3e9-4441-9c1a-70296f6c2757': { riskCarrierLabel: 'Théatro thérapie' },
  '7d9d2271-ab46-492c-954e-67cfc5fc34d1': { riskCarrierLabel: 'Thème de Cristal' },
  '9c591e3c-0ca3-4301-8f3d-4ba6f524a7dc': { riskCarrierLabel: 'Thème Minéral' },
  '2ea9a2e0-4097-4852-bd2b-d500783bb315': { riskCarrierLabel: "Thérapeute de l'âme" },
  '8f10f66a-a44e-465d-8ad8-8875f2865477': { riskCarrierLabel: 'Thérapeute neurosciences EPRTH' },
  '3e1e9338-384a-4844-bc8d-375ce1ae7889': {
    riskCarrierLabel:
      'Thérapie cranio-sacrale, pratiqué uniquement par un docteur en médecine, ou un kinésithérapeuthe',
  },
  'bff61792-dfe3-46a8-a46f-2f472fbb832b': { riskCarrierLabel: 'Thérapie familiale' },
  '895141d3-12f4-4a10-b5a1-6dfe5af728b7': { riskCarrierLabel: 'Thérapie Intuitive' },
  '49067470-b56b-47d5-9af8-2ed2d9221f76': { riskCarrierLabel: 'Thérapie magnétique' },
  'a3625b50-bd88-4b6f-bd6b-39ee8c475d52': { riskCarrierLabel: 'Thérapie minérale Celluloid' },
  'c4476298-cc6c-4327-b745-54e99fdcf33a': { riskCarrierLabel: 'Thérapie par la polarité' },
  '38a323fd-333c-4d0f-8ce7-a3cfa8c26f7a': { riskCarrierLabel: 'Thérapie par les Cristaux' },
  'b31fdb1b-b320-407c-a227-530bf2dd9609': { riskCarrierLabel: 'Thérapie par un toucher léger' },
  'f1320624-0069-41a3-b882-bd2736157960': { riskCarrierLabel: 'Thérapie par un toucher léger (massage sensoriel)' },
  'efcd8e17-fc0f-4af7-80ab-766732e4a25f': { riskCarrierLabel: 'Thérapie sonore' },
  '32462603-3c97-4ec6-8854-3689bd7278d2': { riskCarrierLabel: 'Thérapie systémique & stratégique' },
  'adc0bc16-d9f2-4b7d-b366-de1aa07ef822': { riskCarrierLabel: 'Thérapies brèves' },
  '4f6497ff-43c5-4e76-85af-f4d5e5d78733': { riskCarrierLabel: 'Thérapies Chi' },
  '262bd7fe-0059-4a2c-9dac-a66096826fcd': { riskCarrierLabel: 'ThetaHealing®' },
  'dd843f7b-8897-4fad-8811-b161a4a89e7c': { riskCarrierLabel: 'Toucher de présence dynamique®' },
  '5973ee7f-abaa-4d92-a0e0-84a1dfc8ac6b': { riskCarrierLabel: 'Trager® (Technique)' },
  'b01c65f8-d7b0-43e2-bf16-316e0034b363': { riskCarrierLabel: 'Training autogène' },
  'adcbf294-f8f6-49e6-a505-7da7250a67f2': { riskCarrierLabel: 'Traitement énergétique des allergies' },
  '585581e6-4c29-4d2e-85cd-54baa8ee7789': { riskCarrierLabel: 'Trame' },
  'd5ba35cc-69b0-40b4-9687-88eed1e152df': {
    riskCarrierLabel:
      "Travail à pied en médiation équine à l'exclusion de toute autre technique d'équithérapie, notamment à l'exclusion du handisport, de l'équihandi, de l'équicie, de l'hippothérapie, de l'équithérapie et de l'équitation",
  },
  'bfc0f747-5952-4400-91f6-453374336133': { riskCarrierLabel: 'Tricothérapie' },
  '096fd8f8-949c-4185-9f3b-094a1a1e5f59': { riskCarrierLabel: 'Tui na' },
  'a88832a5-f0cf-47dd-9cf8-075608f4011c': { riskCarrierLabel: 'Tuina' },
  '5c396d4e-a2e5-45fa-916a-7bee72dc5fac': { riskCarrierLabel: 'Upbraining' },
  'ecd101f8-80d6-42a4-8cc8-48463ffc612f': { riskCarrierLabel: 'Vacuum' },
  'de7ee52c-acdc-4fa0-8839-4daea6f6ddd3': { riskCarrierLabel: 'Valaikappu' },
  '54ebdbf8-6145-4ca7-900c-34c782ff9c62': { riskCarrierLabel: 'Vibration Japonaise' },
  '7e9f4656-95df-4c9e-baad-f6c3752d0f78': { riskCarrierLabel: 'Vitaminothérapie' },
  '1bb96de9-ff32-44cd-a0ad-e033fff9e8bb': { riskCarrierLabel: 'Vittoz (Méthode)' },
  'ee98a385-b9b1-4fa9-ad82-3cd99ee038a6': { riskCarrierLabel: 'Vocale (Thérapie)' },
  '615322d0-2346-4eb3-aa12-2a5b1437d18f': { riskCarrierLabel: 'Voyance' },
  'c2fefafd-d5f3-43e2-91a6-d821155fd41a': { riskCarrierLabel: 'Wai Qi Liao Fa' },
  'a13990cc-cee8-4482-9fb9-bdd2131f6376': { riskCarrierLabel: 'Watsu' },
  'ac589fc5-4eeb-4c4c-b709-97ba59eb2bf5': { riskCarrierLabel: 'Wudang quan (art martial)' },
  '293d97f4-7b05-4b57-be89-94b1b02c091a': { riskCarrierLabel: 'Wutao' },
  'b217a484-5f2d-4334-9a4d-391e16489671': { riskCarrierLabel: 'Yang Sheng' },
  '49ad37e8-14ef-4a39-90ca-3bdfadfcc363': { riskCarrierLabel: 'Yang Shiatsu' },
  '0b7b5b7c-5d78-4a54-a771-bbfdbf2c74de': { riskCarrierLabel: 'Yi King' },
  'b49c6672-4ace-4ab8-bca3-97b8c3541042': { riskCarrierLabel: 'Yin Shiatsu' },
  'ceb9d85b-d1c0-499f-a0ba-9e33bde157d1': { riskCarrierLabel: 'Yin Yoga' },
  '9daa7a62-63b3-41e1-af8d-6f8ab57773eb': { riskCarrierLabel: 'Yoga' },
  'ad639ba7-8185-4a45-bc34-435266e4df11': { riskCarrierLabel: 'Yoga Aérien' },
  '69214c1a-730f-4caf-a701-396dc91ab4a1': { riskCarrierLabel: 'Yoga des yeux' },
  '3b216f3c-3e4c-4a2e-9dc3-a7ce9a1b8d29': { riskCarrierLabel: 'Yoga du rire' },
  'a829e95f-a810-4ad6-a968-35cf6c8546ff': { riskCarrierLabel: 'Yoga du Son' },
  '6d3d03c7-5a0b-4078-bac6-54790275ca25': { riskCarrierLabel: 'Zen Streching®' },
  'a64d3581-7e43-4c0b-9743-6f2a10599e67': { riskCarrierLabel: 'Zentangle' },
  '559a485d-69b8-49f0-9a77-3ea544b6602a': { riskCarrierLabel: 'Zoothérapie' },
  'c19ccfa6-7af4-46ca-972e-5c76820ea782': { riskCarrierLabel: 'Conférencier' },
  '2145a361-76ff-4e64-a266-b754de31daef': { riskCarrierLabel: 'Conseiller en éducation / Éducateur' },
  '83a64de0-d664-478d-aa33-e67abff43d8c': {
    riskCarrierLabel:
      'Formateur / Organisme de formation (hors formation aux activités privées de sécurité soumises à agrément du CNAPS et formation aux travaux en hauteur / sur corde)',
  },
  'e0dae10d-35f2-490d-af54-2c3590882374': { riskCarrierLabel: 'Correcteur de textes / examens' },
  '93a86f03-3ca6-43af-a0d7-b49f04848307': {
    riskCarrierLabel: "Comportementaliste chien et chat / Formation au dressage et à la communication avec l'animal",
  },
  '077cc4c9-c052-489c-90af-9ad5f7fd1588': { riskCarrierLabel: "Enseignant (hors enseignant de l'éducation nationale)" },
  '18b752fc-7df3-4071-bbdb-22af703de425': { riskCarrierLabel: 'Agence matrimoniale' },
  'b077aa3a-9c52-43fd-b9b4-9f19730f86fb': { riskCarrierLabel: 'Assistant social' },
  'a5cfbff0-8671-4555-b12f-acc4201dfe22': {
    riskCarrierLabel: 'Agent commercial immobilier (TRANSACTIONS SUR IMMEUBLES ET / OU FONDS DE COMMERCE)',
  },
  '85204fe3-3e4b-4ba8-868c-2a694b412985': {
    riskCarrierLabel:
      "Assistance à l'autonomie, aux personnes âgées, handicapées ou vulnérables - hors prestations réalisées dans le cadre de la loi Borloo et/ou hors prestation médicale/paramédicale",
  },
  '6307e24b-1a24-4372-9056-5b93c7aee02d': {
    riskCarrierLabel: 'Services aux particuliers hors règlementation Borloo : courses, travaux administratifs',
  },
  '278322e7-a0e8-45f0-89d9-cc02a2f524f6': { riskCarrierLabel: 'Guide touristique' },
  '22ae974a-398c-46e5-8c5f-8875bef75442': { riskCarrierLabel: 'Conseil en relocation' },
  '08274061-16a0-4101-afc0-b430a6393adf': { riskCarrierLabel: "Garde d'animaux et Pet sitting - hors chenils" },
  'acdb62c5-d83a-4331-bd9f-029a0902d003': { riskCarrierLabel: 'Gardien de maisons / Home sitting' },
  '39ff757c-68b9-4b47-a06e-da3467ea09c8': {
    riskCarrierLabel: 'Nettoyage courant de bureaux, commerces, habitations et établissements sportifs',
  },
  'fd83d729-2e1a-49f0-8abc-c182ffb1ddea': {
    riskCarrierLabel: "Jardinage hors élagage/abattage d'arbres et/ou travaux de terrassement",
  },
  '75bdf0d8-983b-4465-aaac-548f29c9c8af': {
    riskCarrierLabel:
      "Bricolage ne nécessitant pas de qualification professionnelle et pouvant être réalisées en 2 heures maximum - hors travaux de construction, réparation, intervention sur des équipements de gaz, d'eau, d'électricité",
  },
  '4552d5b7-4f78-4add-be47-45c8924cb71f': { riskCarrierLabel: 'Détective privé / Agent de recherches privé' },
  'a7c24603-0d4f-4647-a523-fa3a3ccb1053': {
    riskCarrierLabel:
      "Établissement d'enseignement supérieur / de formation continue - hors formation aux activités privées de sécurité soumises à agrément du CNAPS et formation aux travaux en hauteur / sur corde",
  },
  '524b1d8b-386e-487f-91d4-b155471ea77f': { riskCarrierLabel: 'Travaux de petit bricolage' },
  '6c8e77af-cf96-4ae4-bdb2-d988d528a4ad': { riskCarrierLabel: 'Petits travaux de jardinage' },
  'e5c2eb75-da28-4a29-8648-b3571207da25': { riskCarrierLabel: 'Préparation de repas à domicile' },
  'c55e59f9-d024-46a1-8050-dc0158ec92ea': { riskCarrierLabel: 'Livraison de courses à domicile' },
  '3ded3229-c8ea-4eff-839c-dbc46e82e504': { riskCarrierLabel: 'Livraison de repas à domicile' },
  '88c2dcc2-49b6-45ad-8b36-fd631c1eabfc': {
    riskCarrierLabel:
      'Maintenance, entretien et vigilance temporaires, à domicile, de résidences principales et secondaires',
  },
  '98c75acc-854b-4d86-8a56-67146127f142': { riskCarrierLabel: 'Entretien de la maison et travaux ménagers' },
  'e2d6f076-7e21-4991-8fb4-e078e4cd1b2b': {
    riskCarrierLabel:
      'Coordination, délivrance et suivi de services à la personnes (mise en relation avec un prestataire et suivi de la prestation)',
  },
  '257b2b08-d7b3-422d-8b17-14dbcc030390': {
    riskCarrierLabel: 'Accompagnement des personnes agées, dépendantes, handicapées en dehors du domicile',
  },
  'a4d8d64f-be9b-48ac-86e2-3d39bf21e596': {
    riskCarrierLabel: 'Accompagnement des enfants (plus de 3 ans) en dehors du domicile',
  },
  '4fd86a3c-f1ad-42a9-8dd8-dfe3a1cbaf72': {
    riskCarrierLabel: 'Accompagnement des enfants (moins de 3 ans) en dehors du domicile',
  },
  '6b3d93f0-db09-40e6-bd5e-18f32e84a4ae': { riskCarrierLabel: "Garde d'enfants de moins de 3 ans à domicile" },
  '20e75920-92c2-4312-9a49-30bc1b8af349': { riskCarrierLabel: "Garde d'enfants de plus de 3 ans à domicile" },
  '177525af-e88f-4232-ab8f-34d9216d4851': { riskCarrierLabel: 'Assistance administrative à domicile' },
  '619b8bd1-ee93-4fd8-bfef-5110c83c2932': { riskCarrierLabel: 'Assistance informatique à domicile' },
  '29572dc7-daa1-4361-aead-e6c8e6712fce': { riskCarrierLabel: 'Collecte et livraison de linge à domicile' },
  'e7bae985-24d2-497d-a105-c1747eca5d5b': {
    riskCarrierLabel: 'Conduite du véhicule personnel des seniors et personnes handicapées',
  },
  'bed12471-710e-4ff0-8a1f-ce942d7cabfa': {
    riskCarrierLabel: "Interprète langue des signes, technicien de l'écrit, codeur en langue parlé complété",
  },
  'e82b4fd0-e41a-45f7-93ef-cfc0eb877177': {
    riskCarrierLabel: 'Soins esthétiques à domicile pour les personnes dépendantes',
  },
  'ed7c2ef9-60b2-4086-9866-2d8b85a9d156': {
    riskCarrierLabel: "Soins et promenades d'animaux de compagnie pour les personnes dépendantes",
  },
  'f53e837c-41b8-48a0-9e11-fab4f91be2d9': { riskCarrierLabel: 'Soutien scolaire ou cours à domicile' },
  'b4623aca-3d0f-46b1-a56e-5e82936d3140': { riskCarrierLabel: 'Téléassistance et visio assistance' },
  '6202c99e-2b12-4fa1-af77-837921f73f79': {
    riskCarrierLabel:
      'Assistance dans les actes de la vie quotidienne des personnes agées, handicapées ou ayant des pathologies chroniques - hors soins médicaux',
  },
  '9287ff36-17ba-464a-885f-08b8cd499ea1': {
    riskCarrierLabel: "Aide à l'insertion sociale des personnes agées, handicapées ou ayant des pathologies chroniques",
  },
  '750cb518-c100-4147-882a-6c4cb72602d2': {
    riskCarrierLabel:
      "Aide personnelle à domicile pour les personnes en besoin d'assistance temporaire - hors soins médicaux",
  },
  '3fb76047-9df4-4d0d-b6d8-3adcc024ef3e': { riskCarrierLabel: 'Photographe' },
  'f781f184-611f-4453-b97f-f71e9ddadc67': { riskCarrierLabel: 'Conseil en immigration - hors conseils juridiques' },
  '1a37d30e-ecb4-47cb-a37d-c33274d0bca3': {
    riskCarrierLabel:
      'Archivage (photocopie, préparation de documents, scanning, saisie informatique, archivage de documents et gestion documentaire pour le compte de tiers)',
  },
  'a17c8191-1c09-4794-96f4-845b3add477e': {
    riskCarrierLabel:
      "Centre d'affaires (Secrétariat, saisie de données, domiciliation commerciale de sociétés ; gestion du courrier arrivant à l'adresse de domiciliation ; externalisation de tâches administratives, permanence téléphonique)",
  },
  '9af8a0a2-cb93-4384-9224-106271f055b8': { riskCarrierLabel: "Centre d'appel hors appels d'urgence" },
  '7c3871b1-6b46-41ec-9e6d-2115cf79e5ad': {
    riskCarrierLabel:
      'Concierge / services de conciergerie - hors réservation de séjours/voyages pour le compte de tiers',
  },
  '0661191a-95cb-459a-aa04-687a4aa34155': { riskCarrierLabel: 'Écrivain public' },
  'd93a7b59-4d05-413f-95ff-7d0e9062d4c7': {
    riskCarrierLabel:
      'Gestion de standard (Accueil, permanence téléphonique, gestion du courrier, démarches administratives, classements de documents etc...)',
  },
  '1c60a901-4f77-4f13-9ae7-a7698b90249e': {
    riskCarrierLabel: 'Gestion des achats (Recherche et gestion des fournisseurs)',
  },
  '3b38889b-7f31-4c6f-9975-67de5bee58b9': { riskCarrierLabel: 'Interprète' },
  '015bfe2f-a508-4887-9f51-52e2490cc929': {
    riskCarrierLabel: "Mise à disposition d'agents et hôtesses d'accueil - hors prestation de sécurité privée",
  },
  'a74a9321-4c44-441a-9237-d3f05410091a': {
    riskCarrierLabel: 'Mise à disposition de bureaux/espaces de travail courte et moyenne durée',
  },
  'bfbcf111-d16d-4d68-b559-729b28292f09': {
    riskCarrierLabel: 'Recouvrement amiable et judiciaire de créance pour le compte de tiers',
  },
  'a7a1300a-fcc5-4e2b-a8d6-24c78d4cb9d5': {
    riskCarrierLabel: "Secrétariat / Assistance administrative (gestion d'agenda, compte rendus etc..)",
  },
  '2414e34f-5b0f-44ce-a38c-bdcee26e8970': { riskCarrierLabel: 'Téléprospection' },
  '0e2858bf-9f6c-416a-8da9-07cb93bb5c60': {
    riskCarrierLabel: 'Traducteur (hors notices techniques et manuels utilisateurs)',
  },
  '7846547a-23dc-43c1-b2a8-5662b0d837fc': {
    riskCarrierLabel: 'Activités annexes en matière de marketing et/ou de communciation',
    isSecondaryActivity: true,
  },
  '48dd1a88-5929-45da-96b7-d08493c02c5e': { riskCarrierLabel: 'Agence de presse spécialisée' },
  '5d7ee426-4f41-4ce4-8d2f-08ddb0c741b5': { riskCarrierLabel: 'Attaché de presse' },
  '82c68ecd-c63f-4eec-a40d-0794a3d52df4': { riskCarrierLabel: 'Blogueur' },
  '9bd629fc-950a-42a1-8d9a-bf592f7c06f5': { riskCarrierLabel: "Journaliste (hors reporter d'image) / Pigiste" },
  '2a91f945-b791-4666-a1e0-0eb6917ffdf1': {
    riskCarrierLabel: "Edition et publication de journaux d'information locaux (y.c. en ligne)",
  },
  '226bb4a5-86e3-4aeb-9583-1d71c38318b4': {
    riskCarrierLabel: "Edition et publication de journaux d'information régionnaux (y.c. en ligne)",
  },
  'f69e0fff-1991-4e15-9e34-e45d62338894': {
    riskCarrierLabel: 'Edition et publication de magazines féminins (y.c. en ligne)',
  },
  '851f776b-54b4-495a-8b32-03fefb036864': {
    riskCarrierLabel:
      'Edition et publication de magazines / livres professionnels (y.c. en ligne) - hors domaine médical, financier et scientifique',
  },
  'a373eb1d-8228-4d25-bab0-ae32b8b87f50': {
    riskCarrierLabel:
      'Edition et publication de magazines / livres spécialisés loisirs type sports, culture, art, décoration, cuisine, voyages, etc... (y.c. en ligne)',
  },
  'd613c5f7-07ef-4a4e-a3ad-23c37c3f5a81': {
    riskCarrierLabel: 'Edition et publication de fictions littéraires (y.c. en ligne)',
  },
  '45dd7304-eca4-499e-a01a-83d0731c5b1e': {
    riskCarrierLabel: 'Edition et publication de magazines / revues dans le domaine médical (y.c. en ligne)',
  },
  'edfb0e4e-7002-40c2-a5cc-788c7ee37366': {
    riskCarrierLabel: 'Edition et publication de magazines / revues dans le domaine financier (y.c. en ligne)',
  },
  '2412a311-cec9-41a2-ae19-9873a4d88436': {
    riskCarrierLabel: 'Edition et publication de magazines / revues dans le domaine scientifique (y.c. en ligne)',
  },
  '33d5e3c7-bab5-4b00-ae49-4898fc7fa87d': {
    riskCarrierLabel: "Radio / Web radio d'informations et actualités locales",
  },
  '689f585a-d773-4d4d-95ef-e4b20903beef': {
    riskCarrierLabel: "Radio / Web radio d'informations et actualités régionales",
  },
  '6760238e-6313-4d36-8aa2-1f3853a08a55': { riskCarrierLabel: 'Radio / Web radio musicale' },
  'fda34252-6edb-42b8-a100-7e3b158692a7': { riskCarrierLabel: 'Radio / Web radio culturelle' },
  'a5e73a90-5443-419a-b91e-d062eb1eae9b': {
    riskCarrierLabel: 'Radio / Web radio de divertissement (jeux, humour, sport, etc...)',
  },
  'fb646b26-22d5-475d-8f4e-8d5d71307309': {
    riskCarrierLabel: "Rédaction d'articles de presse pour le compte de journaux d'information locale (y.c en ligne)",
  },
  'b9deca45-b4ca-4c7c-aed1-f5ecfd2c0d7b': {
    riskCarrierLabel:
      "Rédaction d'articles de presse pour le compte de journaux d'information régionale (y.c en ligne)",
  },
  'e0c4807b-b63a-43fc-a6a9-d39febd2eb45': {
    riskCarrierLabel:
      'Rédaction de contenus pour le compte de magazines féminins (y.c en ligne) - hors magazine et/ou site people',
  },
  '7cebc687-fea0-45be-9aa3-bfab30541a03': {
    riskCarrierLabel:
      'Rédaction de contenus pour le compte de magazines / livres professionnels (y.c. en ligne) - hors domaine médical, financier et scientifique',
  },
  'e866e735-85f7-49bb-adf7-f30e54232999': {
    riskCarrierLabel:
      'Rédaction de contenus pour le compte de magazines / livres spécialisés loisirs type sports, culture, art, décoration, cuisine, voyages, etc... (y.c. en ligne)',
  },
  'e5a073bc-b927-4cdb-9758-8f903a58ee16': {
    riskCarrierLabel:
      'Rédaction de contenus pour le compte de magazines / revues dans le domaine médical (y.c. en ligne)',
  },
  '0a34b1f9-a6ee-49ea-b32c-f28726922155': {
    riskCarrierLabel:
      'Rédaction de contenus pour le compte de magazines / revues dans le domaine financier (y.c. en ligne)',
  },
  'd3e3fba2-54e6-4491-ad1c-e0b0c17a9ae5': {
    riskCarrierLabel:
      'Rédaction de contenus pour le compte de magazines / revues dans le domaine scientifique (y.c. en ligne)',
  },
  '1d5eba86-72d8-4034-9936-3fe95da124ae': { riskCarrierLabel: "Télé / Web télé d'informations et actualités locales" },
  '50abb7f0-d2f7-4602-8b1a-626e41d985b4': {
    riskCarrierLabel: "Télé / Web télé d'informations et actualités régionales",
  },
  'a59730e9-77ee-4d39-a727-3a1467564daa': { riskCarrierLabel: 'Télé / Web télé musicale' },
  '5eded035-db55-4a42-946a-f06110a30272': { riskCarrierLabel: 'Télé / Web télé culturelle' },
  '308fa994-d282-4e70-951c-66d2519b2da2': {
    riskCarrierLabel: 'Télé / Web télé de divertissement (jeux, humour, sport, etc...)',
  },
  '956429fe-1108-4d5d-887d-8bc33483824b': { riskCarrierLabel: 'Acteur' },
  '8c3a765f-aa20-46eb-af2f-90d8f3dce775': { riskCarrierLabel: 'Agence de mannequins' },
  '6c51692c-1840-4326-be3d-5d848381b357': { riskCarrierLabel: 'Animateur du patrimoine' },
  '6276bf6d-f1c1-4a71-a682-15820ef80854': {
    riskCarrierLabel: 'Arts graphiques avec ou sans conception de logo/affiches/web',
  },
  '68cee9ac-1692-47e1-903f-24e572e592e6': { riskCarrierLabel: 'Auteur' },
  '416a6163-c3dc-4d05-9536-d1e5dcd9af3c': { riskCarrierLabel: "Chargé d'études documentaires" },
  '6c7bdb34-46f0-499c-bc2a-6e468535fcf0': { riskCarrierLabel: "Chef d'orchestre" },
  '0288d0c2-7823-4eba-9e21-b3bb945c8981': { riskCarrierLabel: 'Conseiller culturel et/ou en divertissement' },
  '11d1c823-dbe2-40f3-a6d5-2c97e9c74067': { riskCarrierLabel: 'Consultant en ingénierie touristique et culturelle' },
  '8c1f2afd-90f0-4743-b312-739fe0984323': {
    riskCarrierLabel: "Créateur d'image : papier / film / vidéo / numérique (hors caricatures et images provocatrices)",
  },
  '81634c0f-2bd6-4e52-be77-55fd806eb8e2': { riskCarrierLabel: 'Danseur' },
  '824e8824-468a-47f0-a8f6-2c8b913e365d': {
    riskCarrierLabel: 'Dessinateur / Illustrateur (hors caricatures et dessins provocateurs)',
  },
  'f552596e-0dee-4d4b-a511-49fb126e7e4c': {
    riskCarrierLabel: 'Dessinateur publicitaire (hors conception de produit et design industriel)',
  },
  '41adaeba-8e62-477f-803e-5da9073ab331': {
    riskCarrierLabel: "Design d'exposition / scenographie - hors fabrication et installation non sous-traitée",
  },
  'b1328a1c-cdf7-4417-b5d1-7042fd64f09f': { riskCarrierLabel: 'Disc-Jockey' },
  'eb1c4362-249b-478d-b363-043f77c30ae5': { riskCarrierLabel: 'Ecrivain' },
  '851e6413-716f-4678-8bb1-e9b2c26f718b': { riskCarrierLabel: 'Graphiste web' },
  '496e42fa-19fe-4c7c-952c-a283b361d3d4': { riskCarrierLabel: 'Guide-conférencier / Guide touristique' },
  '30185821-91e4-4898-8420-705a58d95f5a': { riskCarrierLabel: 'Lecteur-Correcteur' },
  '5413fb66-4216-4497-9a75-9146d95de87c': { riskCarrierLabel: 'Mannequin / Modèle' },
  '866fac30-6046-45c6-9cc8-7ec0c4f8fad0': { riskCarrierLabel: 'Médiateur culturel' },
  '80cb6f66-e340-4120-bf53-2372642ff396': { riskCarrierLabel: 'Muséographe' },
  '2296efd1-1d2c-49ea-bf19-4a56fb81f324': {
    riskCarrierLabel: 'Musicien solo ou en groupe constitué, public ou privé subventionné ou non',
  },
  '94e67f27-baf0-4bb4-aa8a-5a1e7927634c': { riskCarrierLabel: 'Professeur de danse' },
  '475eb06d-c131-47e8-b1b6-3f2598b939ab': { riskCarrierLabel: 'Professeur de musique' },
  '3f632f4b-4b6a-4f77-b532-4ea43487ec66': { riskCarrierLabel: 'Professeur de théâtre' },
  '6c81b75a-a8d4-4dcc-bc20-103d6097866e': { riskCarrierLabel: 'Secrétaire de documentation' },
  '71146750-193a-4c05-b00c-795a40aec421': { riskCarrierLabel: 'Adaptateur audiovisuel' },
  'e2372763-0ee0-4bbf-987d-2fb296ef0dcc': {
    riskCarrierLabel: "Animateur d'évènements ou d'actions promotionnelles / Speaker",
  },
  'b68f0825-06c4-4ac8-91b7-56eb88174d9a': {
    riskCarrierLabel: 'Assistant opérateur audiovisuel / cinéma / spectacles vivants',
  },
  'e830690c-3e10-4757-ab25-222f5b0cc4c3': { riskCarrierLabel: 'Bookeur de spectacles vivants' },
  '9218f5ba-7b9c-4bc1-b837-35e53f602980': { riskCarrierLabel: 'Bruiteur' },
  '36a8c9d7-b1c7-4e24-ae3e-628eaf5700fe': { riskCarrierLabel: 'Cadreur' },
  '17d96231-86c4-4c46-8da3-52a3c6cc786d': { riskCarrierLabel: 'Caméraman' },
  'a0705ef3-bd78-4dfe-ba66-400ffc34dd32': {
    riskCarrierLabel: 'Chef opérateur audiovisuel / cinéma / de spectacles vivants',
  },
  '395ca720-4838-4014-948a-81bfaf8af0d5': { riskCarrierLabel: 'Chorégraphe' },
  'df23c16c-0650-477c-9fbb-83ed64f1ca28': { riskCarrierLabel: 'Décorateur de film, accessoiriste' },
  '97c76f47-84a4-42ab-91e4-5eb8e32e1ac2': { riskCarrierLabel: "Décorateur d'espace scénique" },
  '3c121599-d36a-494f-abfb-f17da7f3e89b': { riskCarrierLabel: 'Diffuseur de spectacles vivants' },
  'b43bebec-1a88-41d8-8c6f-10cb72a04dde': {
    riskCarrierLabel:
      "Directeur artistique dans les domaines du spectacle vivant et/ou de l'audiovisuel et/ou de l'édition",
  },
  '46620837-649e-49d5-b3c4-16039d434427': {
    riskCarrierLabel:
      "Directeur de production audiovisuelle (hors production d'oeuvres destinées au cinéma et à la télévision)",
  },
  '47107238-8bcd-4d01-b606-3ad0230f4d5a': { riskCarrierLabel: 'Documentaliste audiovisuel' },
  '1d4acd5b-32d6-4cef-9d70-862e3aa56718': { riskCarrierLabel: 'Eclairagiste audiovisuel' },
  '9af79309-5fe4-4d97-adf1-5b129aaf67ec': { riskCarrierLabel: 'Ingénieur du son et/ou Mixeur' },
  'f07a9c21-5110-4a51-a0c5-aeaf32f8247b': {
    riskCarrierLabel:
      "Location de matériel son et image à destination des professionnels du spectacle, de l'audiovisuel et de la culture",
  },
  '108e1385-9a9e-440c-8b9e-009f9474969c': { riskCarrierLabel: 'Location de chapiteaux et/ou tribunes' },
  '10301a35-06b4-4677-af77-5c739a07f219': { riskCarrierLabel: 'Maquilleur artistes et comédiens' },
  '8539f3bb-7905-4449-8b8f-a33465ee0abf': { riskCarrierLabel: 'Metteur en scène / Réalisateur' },
  'f75063a0-9d01-4dd6-bbab-8e3d5e3a31df': { riskCarrierLabel: 'Monteur / Studio de montage video / audiovisuel' },
  '7630b1f1-f053-41b8-ac36-cfefeec3df9c': {
    riskCarrierLabel:
      "Organisation de mariages et d'événements familiaux / Wedding planner (hors activités de réservation de voyages ou séjours pour le compte de tiers)",
  },
  'feb68915-3615-4a8b-8d03-5853fecf5e07': { riskCarrierLabel: 'Organisateur de spectacles vivants' },
  'd985310e-6fb7-4803-a193-5e810fa868d0': { riskCarrierLabel: 'Photographe / Vidéaste évènementiel' },
  '5be67fd6-60bb-415b-aa42-adebe7ec140c': {
    riskCarrierLabel:
      'Producteur de documentaires / magazines / reportages dans le domaine de la culture et des loisirs',
  },
  '22e97ca1-5e52-4d09-bd82-b8b15d1b718e': { riskCarrierLabel: 'Producteur de films publicitaires et clips vidéo' },
  'c840399e-78ce-4126-974b-c0d89483c353': { riskCarrierLabel: 'Producteur de spectacles vivants' },
  '2e9a4c93-3950-478a-97c9-90b02930d6cb': { riskCarrierLabel: 'Scénariste' },
  'd0bf81ee-c04a-4816-925a-62141dbcd889': { riskCarrierLabel: 'Scripte' },
  '8e6ca2f1-3ed6-46ee-9840-88cbe215d6f0': { riskCarrierLabel: 'Sonorisateur' },
  '013543ed-a18f-430f-b27d-d7d718a5f4c8': {
    riskCarrierLabel:
      'Sous-titrage / Doublage de films et/ou Sur-titrage de spectacles vivants (hors films à caractère pornographique)',
  },
  'f71f6971-a490-4a08-a648-f6fecf9628d7': {
    riskCarrierLabel:
      'Régisseur pour le compte de productions audiovisuelles et évènementielles hors oeuvres destinées au cinéma / fictions et émissions télé en direct',
  },
  'efa1eb0a-9edd-4ce8-8447-c12bb7e9dc2b': { riskCarrierLabel: 'Régisseur son' },
  '7f428b9c-45e3-4e0e-89b8-37498c1e527a': { riskCarrierLabel: 'Technicien son et lumière' },
  'f2ae2d43-88e4-48d4-ae2c-cf64df21e15b': { riskCarrierLabel: 'Tourneur de spectacles vivants' },
  '30e21fb4-c1bd-4d8e-9ef1-d3b578b172f2': { riskCarrierLabel: 'Traducteur audiovisuel' },
  'dcd78c31-f6d3-450a-aeb8-8d76cb28d537': {
    riskCarrierLabel:
      "Assistant de post-production audiovisuelle - hors production d'œuvres destinées au cinéma et à la télévision",
  },
  '86a5dd36-031b-4377-ae8c-e8b9c8fb99d5': {
    riskCarrierLabel:
      "Chargé de post-production audiovisuelle - hors production d'œuvres destinées au cinéma et à la télévision",
  },
  'f22e0f2b-e5f7-4412-a3e7-c75bcc013ac4': { riskCarrierLabel: "Concepteur d'effets spéciaux" },
  '4807d864-159c-4609-8009-b9db247d9fcc': { riskCarrierLabel: 'Compositeur de musiques de films' },
  '4d1ee304-d3e3-4ba3-b761-b00216496583': {
    riskCarrierLabel:
      "Directeur de post-production audiovisuelle - hors production d'œuvres destinées au cinéma et à la télévision",
  },
  'f0a21172-c399-4856-8c3e-f9632b9fd997': { riskCarrierLabel: 'Etalonneur' },
  'e44f135b-1de6-4d40-ac54-f1f629bb6426': { riskCarrierLabel: 'Monteur son' },
  '1e386c72-b6c8-4173-bd05-fff52020c476': { riskCarrierLabel: 'Projectionniste' },
  '5e9e75a1-7de2-4135-ab10-f033a58b330f': { riskCarrierLabel: "Studio d'enregistrement" },
  '612391e3-a0d5-44a8-8e82-d190247383e8': { riskCarrierLabel: "Superviseur d'effets-spéciaux" },
  '5720d854-400a-4a66-bb3e-6c616545ca07': { riskCarrierLabel: 'Truquiste' },
  'f31d491d-90de-4db3-8928-74743e373bf1': {
    riskCarrierLabel: 'Agence de courtage en prêt / rachat en crédit (hors agence / établissement bancaire)',
  },
  '41117f01-2081-4717-b131-e6a4b0e476d5': { riskCarrierLabel: 'Agence immobilière' },
  'c12ac92b-972a-4ba7-a71f-d1f044fd96fb': { riskCarrierLabel: "Métiers de l'expertise comptable" },
  'e9adb18d-2f58-413e-845b-68f812db0c1f': {
    riskCarrierLabel: 'Métiers de la sécurité (bureaux administratifs exclusivement)',
  },
  '273ae9fd-20ab-40c4-971f-0f69663fd1ee': { riskCarrierLabel: 'Métiers du droit' },
  '430707b1-8bd8-471e-bbfc-b038f6e3d7a2': {
    riskCarrierLabel: "Entretien des bureaux et/ou des espaces verts (sans technique d'élagage de plus de 3 mètres)",
  },
  'bc430f52-9f5d-407e-a5f7-03dc9c8540ec': {
    riskCarrierLabel: "Installation d'alarmes et/ou de matériel de télé-sécurité et télésurveillance",
  },
  'c4f6f845-6067-49ad-acc2-154e454565ed': { riskCarrierLabel: "Réparation d'électroménager, HI-FI" },
  '6b28b437-6138-47dc-b1d4-06eeaee968ee': {
    riskCarrierLabel: "Services à la personne dans le cadre de l'agrément Borloo",
  },
  'd3806c13-d9a2-45d1-995e-da83efa6640b': { riskCarrierLabel: 'Toilettage pour animaux domestiques (chiens, chats)' },
  'cb32e459-4512-46f2-80f6-4f932c36e486': {
    riskCarrierLabel: 'Manucure, onglerie, esthétique (AVEC UV, Laser, Tatouage)',
  },
  '5662c530-8fcf-4c77-88b1-8113817db994': { riskCarrierLabel: 'Parfumerie' },
  '2e81c3f1-9a2c-489f-bed5-1c9ff415851b': { riskCarrierLabel: 'Diététicien / Nutritionniste' },
  'b4600bc4-0a11-4f27-ab2d-e8689d39a517': {
    riskCarrierLabel: "Organisation de mariages et d'événements familiaux / Wedding planner",
  },
  'be6b5950-1434-4275-abd0-9f4fdd71854e': { riskCarrierLabel: 'Artisan bottier' },
  'aec14e8e-5991-4a46-b9a0-cb67c5f97cc3': { riskCarrierLabel: 'Artisan boutonnier' },
  'a63dd9d9-8e9d-44dd-8d20-0d1b22c4f072': { riskCarrierLabel: 'Artisan brodeur' },
  '6222c08b-d7a9-455a-b2d8-e8cb782b0d47': { riskCarrierLabel: 'Artisan chapelier' },
  '4d14e551-2b14-41ac-b0e7-edc3db853e59': { riskCarrierLabel: 'Artisan corsetier' },
  'f5aeb700-c856-471d-a0b3-e6cb595ca7cd': { riskCarrierLabel: 'Artisan costumier' },
  'a7685f7c-5fa7-4caf-9525-a63eb1aabc80': { riskCarrierLabel: 'Artisan couturier et / ou couturier flou' },
  '32402078-ec48-45dd-8992-4f82bf0123ef': { riskCarrierLabel: 'Artisan dentellier' },
  'd00acc60-3c36-4ff1-b657-263596c914a6': { riskCarrierLabel: 'Artisan ennoblisseur textile' },
  '61df8e2b-facd-4f13-b5fc-8f014ebc2256': { riskCarrierLabel: 'Artisan eventailliste' },
  '8ed5044c-eaf8-4be2-b759-df82043d05fa': { riskCarrierLabel: 'Artisan feutrier' },
  'a8bd1f12-ec35-4043-9544-14a11d87392c': { riskCarrierLabel: 'Artisan formier' },
  '9bff8973-c3f0-4451-8a06-22f83237a784': { riskCarrierLabel: 'Artisan fourreur' },
  '46b40944-81f2-454b-aa77-1072ee6f489b': { riskCarrierLabel: 'Artisan gainier' },
  '000087d9-ffa7-4b70-9481-2a244ca462f1': { riskCarrierLabel: 'Artisan gantier' },
  '52d7c668-b756-4518-b660-cceb8bf7269d': { riskCarrierLabel: 'Artisan lunetier' },
  '3af7c07f-52f2-4dbc-97c1-d702cf41fda2': { riskCarrierLabel: 'Artisan malletier' },
  'c5ce101d-7639-48b4-bbac-542233030607': { riskCarrierLabel: 'Artisan maroquinier' },
  '0518d4ed-1ac1-4e8d-a1be-d96b1836e6f4': { riskCarrierLabel: 'Artisan mégissier' },
  '98a59d27-ca21-488a-a10e-ab288812c10b': { riskCarrierLabel: 'Artisan modéliste' },
  '60245214-371f-4089-907e-143ef618c2ef': { riskCarrierLabel: 'Artisan modiste' },
  '6e276d54-fb10-4e46-be47-6f1a8bf2d68d': { riskCarrierLabel: 'Artisan pareur' },
  'cebbcff9-3d40-443d-933e-6173f9997cb4': { riskCarrierLabel: 'Artisan parurier floral' },
  '46212c02-f06e-44c0-a956-6ba190ccc3cb': { riskCarrierLabel: 'Artisan péruquier' },
  '56d01149-2e94-466e-a83f-bbb915808d1c': { riskCarrierLabel: 'Artisan plumassier' },
  '8bde860d-3a24-4ce7-b80e-cbd170ed8b76': { riskCarrierLabel: 'Artisan restaurateur de textiles' },
  'a42da967-7852-48ab-9da5-0f72c7903c10': { riskCarrierLabel: 'Artisan sellier-maroquinier' },
  'c1205c39-190e-447c-9272-762757e33545': { riskCarrierLabel: 'Artisan sérigraphe' },
  '5192abc6-4a70-4a2f-9c23-a447884f6aed': { riskCarrierLabel: 'Artisan tailleur' },
  'd8f81550-24ed-401c-8533-1c0c61bc8bad': { riskCarrierLabel: 'Artisan tanneur' },
  'bec409b5-f41a-4cce-8cab-b3cf3ea50200': { riskCarrierLabel: 'Artisan tisserand' },
  'ff1c5182-97a2-4a41-8df3-e5148f533fd9': { riskCarrierLabel: 'Artisan tresseur' },
  '06a6bc6d-7a74-4930-9c20-3c5bd640497c': { riskCarrierLabel: 'Artisan archetier' },
  '89c8ffa4-9c0c-4075-a9ad-d2bd5253407b': { riskCarrierLabel: "Artisan fabricant d'anches" },
  '150d2e5e-80bf-47e1-83b8-67ac8483878d': { riskCarrierLabel: "Artisan facteur et / ou restaurateur d'accordéons" },
  '743c50c7-73d2-40c1-8aff-e5fc596c3f75': { riskCarrierLabel: 'Artisan facteur et / ou restaurateur de harpes' },
  '4357b3d3-69de-46eb-8f85-f0554b1d693a': { riskCarrierLabel: "Artisan facteur et / ou restaurateur d'harmoniums" },
  '8f333706-e9b1-4dc0-8848-0a62517c9c99': {
    riskCarrierLabel: "Artisan facteur et / ou restaurateur d'instruments à claviers",
  },
  'f33354e3-b456-4807-aab7-285ce45cc06a': {
    riskCarrierLabel: "Artisan facteur et / ou restaurateur d'instruments à vent",
  },
  '97b39a5a-3f40-40de-9513-bce6affee6c7': {
    riskCarrierLabel: "Artisan facteur et / ou restaurateur d'instruments de musique mécanique",
  },
  '0ff3ba9d-941b-4a0d-ba69-dee7bf9566cf': {
    riskCarrierLabel: "Artisan facteur et / ou restaurateur d'instruments traditionnels",
  },
  '5e36f057-7619-4e97-bf4c-58d20f2f961e': { riskCarrierLabel: "Artisan facteur et / ou restaurateur d'orgues" },
  '6705f3c1-c3e3-4d33-ac7b-fca5e7ee0adf': { riskCarrierLabel: 'Artisan facteur et / ou restaurateur de percussions' },
  'ea32c963-3af1-4fa4-af61-f5f86bd30f38': {
    riskCarrierLabel: 'Artisan luthier en guitare et / ou restaurateur de guitares',
  },
  '8e6830f7-b112-4202-91c5-4808d12995e3': {
    riskCarrierLabel: "Artisan luthier et / ou restaurateur d'instruments à cordes frottée",
  },
  '4d88b5bf-e352-47cc-8e33-99eba68c5de0': { riskCarrierLabel: 'Artisan céramiste' },
  '74e5a0ea-17f9-435c-a939-2eb94f7041e2': { riskCarrierLabel: 'Artisan décorateur sur céramique' },
  'd1fcda0c-8a8b-44f1-b228-dfa934adfb71': { riskCarrierLabel: 'Artisan doreur' },
  '3ef8fcc5-c446-47a3-be6e-4f5d71df48a9': { riskCarrierLabel: 'Artisan encadreur' },
  '08bde499-d210-4b54-9274-b0c4540d9ecd': { riskCarrierLabel: 'Artisan enlumineur' },
  'c9e45a5d-2383-43b1-8b17-3151c7019be5': {
    riskCarrierLabel: 'Artisan fabricant de compositions végétales stables et durables',
  },
  '8dd73f0b-cad9-4129-b4f0-ba027e0ae1bf': { riskCarrierLabel: 'Artisan fresquiste' },
  '1d003d0a-a6bd-408a-9c08-a33585be5c62': { riskCarrierLabel: 'Artisan laqueur' },
  '0cc8fd06-d615-4c5b-af89-8da95bdcef40': { riskCarrierLabel: 'Artisan mosaïste' },
  'a8e43626-8ed1-4c3f-8246-e70a0fd572de': { riskCarrierLabel: 'Artisan mouleur' },
  'c60500e1-3f47-434f-a3e0-945600e2ca8f': { riskCarrierLabel: 'Artisan passementier' },
  '65ee7bc8-2c8a-4924-909e-55e54933f683': { riskCarrierLabel: 'Artisan peintre en décor' },
  '311fa160-22c8-4999-a24d-08622474ae18': { riskCarrierLabel: 'Artisan peintre sur mobilier' },
  '988db567-54e1-49c6-a13a-16e0998e695e': { riskCarrierLabel: 'Artisan relieur' },
  '3193f31a-8053-4c87-9bf5-14c166c356a2': { riskCarrierLabel: 'Artisan restaurateur de céramique' },
  'e8042cfa-e5e2-4374-8fbc-b2db6277a1c7': {
    riskCarrierLabel: 'Artisan restaurateur de documents graphiques et imprimés',
  },
  '14757a26-7b91-43a4-88b6-d5acd4f8fa02': {
    riskCarrierLabel: 'Artisan restaurateur de peintures et / ou photographies',
  },
  '9f8ddbc3-e522-481a-9a68-6e13de31bd31': { riskCarrierLabel: 'Artisan santonnier' },
  '7e17f40d-f5c0-4a97-8afb-855dc8a7c729': {
    riskCarrierLabel: "Artisan tapissier d'ameublement et / ou tapissier décorateur",
  },
  '00ad391d-5d44-4942-b5fb-d3d217b6fd5f': { riskCarrierLabel: 'Calligraphie' },
  '5e706cd9-3b01-4ccd-81f9-f2833628eed6': { riskCarrierLabel: "Galerie d'art" },
  '07a4aadc-9d2f-4308-8b6a-484f4730e5f5': { riskCarrierLabel: 'Vente de chaussures (petit commerce)' },
  'e351f5f5-4b1c-4a74-95ae-847568686b77': {
    riskCarrierLabel:
      "Vente d'objet de décoration intérieure, luminaires et petits mobiliers (hors magasin de meuble et literie)",
  },
  '85b06227-a5e9-499b-95a4-d130215700be': {
    riskCarrierLabel: "Vente d'objets publicitaires sans fabrication (petit commerce)",
  },
  'b22d7b3a-117c-4e06-bcd5-db008e787a13': { riskCarrierLabel: 'Vente de vêtements et accessoires (petit commerce)' },
  '6b894706-8f29-4188-891b-f682171ab39e': { riskCarrierLabel: 'Fleuriste' },
  '509334fe-6786-468a-92d8-052550f5d6fc': { riskCarrierLabel: 'Agence de voyage / Tour opérateur' },
  'bf3654aa-96ae-4dbe-8d37-8660ad600898': {
    riskCarrierLabel: 'Décoration intérieure et extérieure non soumis à Responsabilité Civile Décennale',
  },
  'ae9041c7-69e8-44c4-875a-695bdf22d6ad': {
    riskCarrierLabel: 'Manucure, onglerie, esthétique (SANS UV, Laser, Tatouage)',
  },
  'e47f0aac-127a-4bae-a277-dc7a2c84a7f0': { riskCarrierLabel: 'Cabinet médical et paramédical' },
  'd6f4b78b-47f5-40ab-b573-6dbab39b6b09': { riskCarrierLabel: 'Billetterie' },
  '16e6d057-dfd5-4712-bf82-377c9eb4df83': {
    riskCarrierLabel: 'Vente de séjours ou voyages organisés par un Tour-operator',
  },
  'a0039ccf-d5ea-4c31-b0d9-7299156aeadd': { riskCarrierLabel: "Tourisme d'accueil" },
  '35ce0385-bff9-4a5c-b95d-a2730766e636': { riskCarrierLabel: 'Vente de forfaits dynamiques' },
  '376ed418-b3cb-492f-b29c-256b9d2ddeff': {
    riskCarrierLabel: 'Gardiennage, surveillance, filtrage (y compris agent cynophile) – hors télésurveillance',
  },
  '860a9ac3-2eb5-4b5e-a9ad-7b29f11278ad': {
    riskCarrierLabel:
      'Vente et installation de détecteurs d’incendie, fumée, gaz et de systèmes légers de lutte contre l’incendie ou intoxication de type Extincteurs et RIA (Robinets d’Incendie Armé)',
  },
  '969f9e61-fb5c-43ca-8b1a-cf0717cdba52': {
    riskCarrierLabel: 'Intervention sur site en cas de déclenchement d’alarme intrusion',
  },
  '6ab7cdfe-c32b-46d8-8a2c-797d000cf2f0': { riskCarrierLabel: 'Télésurveillance' },
  '9aae5cec-9e0d-4bf2-9e96-b87c039a6924': {
    riskCarrierLabel: 'Installation d’alarmes et/ou de matériel de vidéosurveillance',
  },
  '7ba29cc0-1c1c-40f2-b533-772433125de1': { riskCarrierLabel: 'Installation et gestion d’alarmes sociales' },
  '2f4ff281-2261-4231-986d-44e4705bcdd6': {
    riskCarrierLabel: 'Sécurité incendie : Agent de sécurité qualifié / confirmé / chef de poste',
  },
  '911edd06-ff2b-4ba0-a623-c4f4861a5600': { riskCarrierLabel: 'Formation aux activités privées de sécurité' },
  '823b00eb-3192-45d8-bf08-4f313a293373': {
    riskCarrierLabel:
      "Activités annexes de conseil et formation en matière de sécurité - hors prescription technique assimilée à de l'ingénierie/BET/maîtrise d'œuvre & AMOA sur travaux de bâtiment & génie civil",
    isSecondaryActivity: true,
  },
  '1e96e820-b6e3-4557-b061-0430450b0e57': { riskCarrierLabel: 'Conseil en décoration' },
  '9f8276bf-a96b-4520-bb76-8bc70da37b20': { riskCarrierLabel: 'Courtier en travaux' },
  '0188c3a4-11e2-45dc-9116-05222738cefb': {
    riskCarrierLabel: 'Exploitant de voitures de tourisme avec chauffeur en France métropolitaine',
  },
  '8b3e3d55-0a16-44c3-ade5-0a44fbfcf37e': { riskCarrierLabel: 'Exploitant de taxi' },
  'e8e40940-f4bb-4408-a5f8-d82fbd14942a': { riskCarrierLabel: 'Convoyeur de véhicule de moins de 3,5 tonnes' },
  '709deab4-625a-4e26-9d4b-29d16c9d1eb6': { riskCarrierLabel: 'Compositeur' },
  'a05da5b8-0f40-4eea-93e8-b9fc21ea4f01': { riskCarrierLabel: 'Assistante maternelle' },
  '62c5802a-dcad-4ac5-9b5a-48fdaa04368d': {
    riskCarrierLabel: 'Transport de marchandises en véhicules terrestres à moteur de moins de 3,5 tonnes',
  },
  //#region MRP Wakam retailer
  '8a57291b-5faf-43f3-8953-981af539f8aa': {
    riskCarrierLabel: 'Alimentation générale, Epicerie, Surface de vente inférieure à 120 m²',
  },
  '3f27aba9-187b-4975-b35b-c0925e09ab9f': {
    riskCarrierLabel:
      'Boucherie charcuterie (artisan) avec ou sans organisation de réceptions, avec ou sans plats cuisinés',
  },
  '854da267-af97-46f4-a2ed-cb829a7def4d': { riskCarrierLabel: 'Boulangerie, Pâtisserie (artisan)' },
  'd15dd219-f0ac-4619-b322-bb872d49ae99': {
    riskCarrierLabel:
      'Vente de pain ou de viennoiseries sans fabrication, avec ou sans terminal de cuisson (détaillant)',
  },
  '71db9e3f-35ec-490d-a134-f56aa0cca710': {
    riskCarrierLabel: 'Vente de cafés et thés, avec ou sans torréfaction (détaillant)',
  },
  '7ac250c9-2c04-4df5-94e0-a17086be02a1': {
    riskCarrierLabel: 'Vente de confiserie, chocolats (détaillant) avec ou sans fabrication',
  },
  '93fa60cf-f6c4-499a-b855-bc58b036b573': { riskCarrierLabel: 'Crémerie, fromagerie (détaillant)' },
  'ced37235-bc0d-4a81-bdfd-4bc99f2576b8': {
    riskCarrierLabel:
      'Vente au détail de produits de diététique fabriqués en Union Européenne et conformes aux normes en vigueur en France',
  },
  '1bf42674-0977-4d82-8218-feac24bc9862': { riskCarrierLabel: 'Vente de fruits, légumes, primeurs (détaillant)' },
  '1dac3538-9827-4303-bde4-b1d895a83c35': {
    riskCarrierLabel: 'Poissonnerie, vente de coquillages et crustacés (détaillant)',
  },
  '93dcbb77-bd68-4605-8413-dc2990a4c8a8': { riskCarrierLabel: 'Vente au détail de produits surgelés' },
  'c5d89007-1585-4bff-8e82-cbc0e713f19b': { riskCarrierLabel: 'Vente au détail de vins et spiritueux' },
  '07a5e870-78ad-45e2-b65e-fad0de2a7c40': {
    riskCarrierLabel: 'Vente de conserves, huiles et autres produits régionaux (détaillant)',
  },
  'a0bfd71e-083c-42f1-8b6f-519fc770e0c5': {
    riskCarrierLabel: "Vente, location d'articles de bricolage, outillage (détaillant)",
  },
  'bdd5c5ab-1a2a-421f-af9a-a97430b14b95': {
    riskCarrierLabel:
      "Cadeaux et objets de décoration sans vente de meubles d'une valeur unitaire d'achat > 300 € (détaillant)",
  },
  'ae7076f5-60a9-46dd-ab2c-9d624ca98fb5': {
    riskCarrierLabel:
      "Vente au détail, location d'appareils électroménagers sans produits audiovisuels, multimédias ni de téléphonie:",
  },
  '83ca94fd-568e-4a75-90dc-7aede3bfe7c7': { riskCarrierLabel: 'Vente au détail de linge de maison' },
  'b5aa18b7-93e4-4fbf-820c-001463e161a4': {
    riskCarrierLabel:
      "Vente au détail de luminaires, d'appareils d'éclairage domestiques sans produits audiovisuels, multimédias ni de téléphonie",
  },
  'a74c6e08-8124-43de-a7e6-762707a97825': { riskCarrierLabel: 'Vente au détail de machines à coudre ou à tricoter' },
  '8f6cd5da-bc9a-4240-b4db-519aaaba4f2a': {
    riskCarrierLabel: 'Quincaillier, droguiste, sans laboratoire ni préparation de produits chimiques (détaillant)',
  },
  'cac9e6f4-aeb1-4005-956e-c6c66b4f9c0e': {
    riskCarrierLabel: 'Services minute (Clés, Imprimerie, Talons minute, ...)',
  },
  '99d56449-c6c0-42fe-9cc4-937d41e57525': {
    riskCarrierLabel:
      'Vente au détail de vaisselle et arts de la table (cristaux, faïence, porcelaine, verrerie, coutellerie)',
  },
  '30c44302-6362-475a-baae-9f4abe528ebd': {
    riskCarrierLabel: "Dépanneur, réparateur d'électroménager, de produits audiovisuels et multimédias",
  },
  'a1f46975-2c24-4123-8a15-a470ec54d84d': {
    riskCarrierLabel:
      "Vente au détail d'accessoires de l'habillement (gants, cravates, ceintures, chapeaux,...) sans bijoux",
  },
  '6a58fe26-a769-4d9c-ade9-c4dfb9de37f5': {
    riskCarrierLabel:
      "Vente au détail de bijoux fantaisie sans objets d'une valeur unitaire d'achat > 300 € sans perçage corporel autre que le pavillon de l'oreille et l'aile du nez par pistolet perce-oreille",
  },
  'a05eaa4e-9c78-474b-878b-6c0c64dd6a80': {
    riskCarrierLabel:
      "Vente au détail de chaussures avec chaussures de sport n'excédant pas 20% de la valeur totale des marchandises",
  },
  '25600391-c705-4034-a79e-b58b5f9a9ce7': {
    riskCarrierLabel: 'Cordonnier sans vente de chaussures, ni de maroquinerie',
  },
  '3cb47245-519c-4b77-8843-35aae8c18a7b': { riskCarrierLabel: 'Vente au détail de lingerie' },
  'd391d169-8868-45ad-a451-8cfb9a4a5967': { riskCarrierLabel: 'Vente au détail de maroquinerie et articles de voyage' },
  '54c62891-a276-4051-8723-1f254624a856': {
    riskCarrierLabel: 'Vente au détail de mercerie et/ou laines à tricoter sans vente de vêtements et de lingerie',
  },
  'b6545179-344f-428f-897c-e9ad2f476eec': {
    riskCarrierLabel: 'Vente au détail de mercerie et/ou laines à tricoter avec vente de vêtements et de lingerie',
  },
  '0c9a9824-1def-49e4-81b0-f1c0100fafd7': {
    riskCarrierLabel:
      'Vente au détail de vêtements prêt-à-porter sans cuirs et peaux excédant 20% de la valeur totale du stock',
  },
  '336c569a-ef6e-4a50-b25a-71d76f1a41db': { riskCarrierLabel: 'Vente au détail de vêtements enfants, layette' },
  '4f7319d3-1d7f-4f82-86ef-c424c7d386fe': { riskCarrierLabel: 'Coiffeur (exercice en salon)' },
  '998f6145-741b-464b-9a7a-f3986f5c3402': { riskCarrierLabel: "Institut de beauté, prothésiste et styliste d'ongles" },
  'afc4279f-536e-466c-97d2-4f376485be10': { riskCarrierLabel: "Vente au détail d'articles de puériculture" },
  'ec08ca50-e20a-4c57-8717-70753baa414b': { riskCarrierLabel: "Prothésiste et styliste d'ongles" },
  '628aafd6-e74f-41fe-8047-083caa9c2f59': {
    riskCarrierLabel: 'Fleuriste (vente au détail, location de fleurs et plantes)',
  },
  'a53a68c4-0788-4ae0-9ecc-c06994f38bd0': {
    riskCarrierLabel: "Vente au détail et réparation d'instruments de musique",
  },
  '5b181507-f061-4edb-a87d-9f0516ecf2be': { riskCarrierLabel: 'Vente au détail de jeux, jouets et modèles réduits' },
  '8d37baf8-70fb-49c5-aa8b-a7271723129c': { riskCarrierLabel: 'Laboratoire de développement et de tirage photo' },
  '77dcf20b-5213-4da0-892d-c35d6cf44bea': { riskCarrierLabel: 'Librairie, papeterie (détaillant)' },
  'a4c7baa6-332c-4647-8e71-3a1270c238c2': { riskCarrierLabel: "Toilettage d'animaux" },
  '9567632f-dc48-4edd-a78e-2897495ccaf0': {
    riskCarrierLabel: 'Vente au détail de matériels pour loisirs créatifs (peinture, chevalets,...)',
  },
  'f68a3981-9ff6-4973-a89b-a5cd0c2cebd0': {
    riskCarrierLabel: "Photographe, vidéaste sans vente d'appareils ni fournitures",
  },
  '2eb95c60-a5bc-4dbe-a875-fb973345ae8a': {
    riskCarrierLabel: "Photographe, vidéaste avec vente d'appareils et de fournitures",
  },
  '6687c8b4-df16-428f-bee5-a4a38011a3a5': { riskCarrierLabel: 'Carterie, vente de gadgets (détaillant)' },
  '2d87a555-c12f-4727-8055-c8907d070257': { riskCarrierLabel: 'Vente de souvenirs' },
  'f6aa7928-8f3a-441a-96c2-f7882b937382': {
    riskCarrierLabel: "Vente au détail d'articles funéraires sans activité de pompes funèbres",
  },
  '26b8b554-6caa-4d72-96a3-d143055dcaad': {
    riskCarrierLabel: "Vente au détail d'articles de bureau sans vente de matériel bureautique / informatique",
  },
  '783fe15a-e3be-41ff-a3b0-fc2bc3b9b5b3': {
    riskCarrierLabel:
      'Vente de matériel bureautique / informatique (détaillant) sans conception de progiciels et/ou de logiciels',
  },
  'bcbc1001-667a-46b5-8784-bdaf420b817c': { riskCarrierLabel: 'Meuble et Literie' },
  '5693295e-9178-4888-a45b-85f28463a513': { riskCarrierLabel: 'Artisans lunetiers - Opticiens' },
  '12d5859c-4500-4040-b752-0f1a60838acc': { riskCarrierLabel: "Vente de livres et de disques d'occasions" },
  '4e9f39c2-c9d7-46c4-a41f-6bec186c75aa': { riskCarrierLabel: 'Pharmacie' },
  'f8118c58-465c-4251-81c7-6ed101d2cbfd': { riskCarrierLabel: 'Laverie Automatique' },
  'c9736af7-ebd1-4bd9-98cf-692866b3864f': { riskCarrierLabel: 'Accessoires et alimentation pour animaux' },
  '106273a9-d868-4dfc-b426-7474fde515fe': { riskCarrierLabel: 'Supérette' },
  'a8f6e33a-5316-4903-a6f2-d7bb17028bee': { riskCarrierLabel: "Vêtements et chaussures d'occasions (Friperie)" },
  '8181eea6-6f0b-4825-b675-f0ec9c2121c0': { riskCarrierLabel: 'Retoucherie' },
  '8d2d4320-d61e-48eb-a65f-027543f916cc': { riskCarrierLabel: "Vente d'ustensiles pour cuisines (détaillant)" },
  'c109080d-80b6-4f2f-9120-ce7d620f8944': { riskCarrierLabel: 'Copie-minute en libre service' },
  //#endregion
  '3de20359-9a94-4302-912b-99cd28179210': { riskCarrierLabel: 'Tatoueur / perceur' },
  //#region RC Pro Hiscox retailers
  '00006e74-d804-4f8d-bec8-49cc20be03e5': { riskCarrierLabel: 'E-commerce - Mode' },
  'ca601df7-8377-44fd-8321-8ab01e9cff81': { riskCarrierLabel: 'E-commerce - Produits de loisirs' },
  'a92f3425-c4d2-42fb-9c62-73f5e99868a2': { riskCarrierLabel: 'E-commerce - Oeuvres d’art' },
  'a610e5f9-a99d-45ce-ab6a-65ed69ca4682': { riskCarrierLabel: 'E-commerce - Mobilier et objets de décoration' },
  '37a31631-1ca6-433d-b84f-39ea6ca2afe8': {
    riskCarrierLabel: 'E-commerce - Matériel informatique, électronique et électroménager grand public',
  },
  '99237c7e-869d-4711-bc40-a9d484dceafd': { riskCarrierLabel: 'E-commerce - Produits alimentaires et boissons' },
  '7a7421c0-0bd9-47e5-bcbc-c18e0e00ec93': { riskCarrierLabel: 'E-commerce - Hygiène et beauté, parfums' },
  //#endregion
  //#region RCDA
  '03ec14e2-7db4-40f3-9ec8-f18c4e481152': { riskCarrierLabel: 'Démolition' },
  '8125d785-ec58-4879-9bef-059fc9b2944e': { riskCarrierLabel: 'Terrassement' },
  '2deebba5-7c95-4957-a2c0-4a9a3843e4b1': { riskCarrierLabel: 'Amélioration des sols' },
  'a5632cce-0f44-474d-826a-3962f0f6f347': {
    riskCarrierLabel:
      'V.R.D. : Canalisations – Assainissement – Chaussées – Trottoirs – Pavage – Arrosage – Espaces verts',
  },
  '74e1ce38-4045-4efa-8920-02c2a5b04b4a': { riskCarrierLabel: 'Montage d’échafaudage – Etaiement' },
  '942edd2d-dc0a-4992-b92c-26a1f099f5af': { riskCarrierLabel: 'Traitement amiante' },
  '7cddf5b3-5e32-4c3a-9170-f3b9d748f9bb': {
    riskCarrierLabel: 'Traitement curatif (insectes xylophages – champignons)',
  },
  'a4e3ec59-7114-4219-a752-508abe8caad7': { riskCarrierLabel: 'Assèchement des murs' },
  '333ede95-489f-4431-8525-b3dacd082152': { riskCarrierLabel: 'Fondations spéciales' },
  'b26dbb8b-5614-4ed0-aac0-c02405bf82e6': { riskCarrierLabel: 'Maçonnerie et béton armé sauf précontraint in situ' },
  '8134f69c-37f8-4c76-8f9c-34e57d807916': { riskCarrierLabel: 'Béton précontraint in situ' },
  '352e23cd-eda7-4767-9553-5965548c54d2': { riskCarrierLabel: 'Charpente et structure en bois' },
  '5ddc9f0c-15b2-43f4-9429-abc7278a003b': { riskCarrierLabel: 'Charpente et structure métallique' },
  '270819de-d629-444a-b52e-3e73c27d6e45': { riskCarrierLabel: 'Couverture' },
  '22879805-e322-4d23-b529-65380c0828a4': { riskCarrierLabel: 'Etanchéité de toiture, terrasse et plancher intérieur' },
  'ca817d3e-9fa3-4601-817c-c1e660a8b497': {
    riskCarrierLabel: 'Etanchéité et imperméabilisation de cuvelage, réservoirs et piscines',
  },
  'b2a9bd86-f5fd-49de-b719-2b386b3ca577': {
    riskCarrierLabel: 'Calfeutrement protection, imperméabilité et étanchéité des façades',
  },
  '57a9bc56-362d-4012-afee-b4fb412cf2c6': { riskCarrierLabel: 'Menuiseries extérieures' },
  'f259e466-d661-4cfd-8804-d17535ba255f': { riskCarrierLabel: 'Bardages de façade' },
  '9057bf2a-190d-41c4-8efa-334bce1f2b19': { riskCarrierLabel: 'Façades-Rideaux' },
  '78fa3f47-0095-49da-b5ef-30982341986f': { riskCarrierLabel: 'Structures et couvertures textiles' },
  '5f239cd8-1bb1-4466-b2b9-bc63584162c7': { riskCarrierLabel: 'Menuiseries intérieures' },
  '5e4b42cb-c817-464c-b68b-67f629e1f462': { riskCarrierLabel: 'Plâtrerie – Staff – Stuc – Gypserie' },
  '7cfb9bd7-c5a9-4a64-8e71-290edc4c048e': { riskCarrierLabel: 'Serrurerie – Métallerie' },
  'cbf5ebc1-2c56-4e6e-9c0b-470be9f5d9ae': { riskCarrierLabel: 'Vitrerie – Miroiterie' },
  'a782046f-0412-4f5f-90ad-bbbb514e9ccd': { riskCarrierLabel: 'Peinture' },
  'f925bcff-29df-4f82-8c8a-2beee439d53f': {
    riskCarrierLabel: 'Revêtement de surfaces en matériaux souples et parquets flottants',
  },
  'f8acf0bc-d0c8-4017-82a4-d22fb14763b0': {
    riskCarrierLabel: 'Revêtement de surfaces en matériaux durs – Chapes et sols coulés',
  },
  '7dc9b3e8-94ba-48e0-b4e4-0a0f1e948592': { riskCarrierLabel: 'Isolation thermique – Acoustique – Frigorifique' },
  '56e727b6-b0b4-485d-9f13-c21036eb9249': { riskCarrierLabel: 'Plomberie – Installations sanitaires' },
  '5335bd82-6d9a-47c0-8cb3-7a759dffd1bc': { riskCarrierLabel: 'Installations thermiques de génie climatique' },
  '74785579-9e7b-4635-9492-917d57ef0838': { riskCarrierLabel: 'Fumisterie' },
  '26e00401-a082-45ad-9070-cf31173d91c7': {
    riskCarrierLabel: 'Installations d’aéraulique et de conditionnement d’air',
  },
  '3d74bb69-00ca-4d14-b487-4827a379b852': { riskCarrierLabel: 'Electricité' },
  '88ce4ba5-899f-4f0a-8b3d-681f71d40bdf': { riskCarrierLabel: 'Four et cheminée industriels' },
  'f93fc57e-ea53-4ff8-8121-ecd251106c54': { riskCarrierLabel: 'Ascenseurs' },
  '2581121a-3281-4980-ac2c-6cef8fd149eb': { riskCarrierLabel: 'Piscines' },
  'c7308119-ec1b-4bde-a7a4-98938d0471f7': { riskCarrierLabel: 'Maison à ossature bois' },
  '1a25912a-531d-48dd-a239-9019c88d49c8': { riskCarrierLabel: 'Géothermie' },
  '43221a8d-e15b-4e7c-ad70-237b2d7ac3b5': { riskCarrierLabel: 'Aménagement de cuisines et/ou salles de bains' },
  '1139a9b7-20b0-4c17-b73a-135cbf6e2dc6': {
    riskCarrierLabel:
      "Réalisation de jardins, d'espaces verts et d'aménagements paysagers y compris les travaux complémentaires de maçonnerie",
  },
  //#endregion
  //#reqion ES-RCPH
  'es-rcph-101': { riskCarrierLabel: 'Consultores calidad de agua' },
  'es-rcph-102': { riskCarrierLabel: 'Consultores de calidad' },
  'es-rcph-103': { riskCarrierLabel: 'Consultores de catering' },
  'es-rcph-104': {
    riskCarrierLabel: 'Consultores de negocio y/o estrategia, y/o financiero (no asesoramiento en inversiones)',
  },
  'es-rcph-105': { riskCarrierLabel: 'Consultores en agricultura (excluyendo los pesticidas)y /o ecología' },
  'es-rcph-106': { riskCarrierLabel: 'Consultores en comercio exterior y/o consultor en  importación y exportación.' },
  'es-rcph-107': {
    riskCarrierLabel: 'Consultores en derechos de la propiedad intelectual y marcas (excluyendo patentes)',
  },
  'es-rcph-108': { riskCarrierLabel: 'Consultores en externalización' },
  'es-rcph-110': { riskCarrierLabel: 'Consultores en fusiones y adquisiciones' },
  'es-rcph-111': {
    riskCarrierLabel: 'Consultores en gerencia de riesgo (salvo corredores, mediadores o intermediación financiera)',
  },
  'es-rcph-112': { riskCarrierLabel: 'Consultores en higiene y seguridad alimentaria' },
  'es-rcph-113': { riskCarrierLabel: 'Consultores en inmigración' },
  'es-rcph-114': { riskCarrierLabel: 'Consultores en logística y transporte' },
  'es-rcph-115': { riskCarrierLabel: 'Consultor en transporte de mercancías peligrosas por carretera' },
  'es-rcph-116': { riskCarrierLabel: 'Consultores en materias de protección de datos' },
  'es-rcph-117': { riskCarrierLabel: 'Consultores en medidas de protección contra incendios' },
  'es-rcph-118': { riskCarrierLabel: 'Consultores en responsabilidad social de las empresas y compliance' },
  'es-rcph-119': { riskCarrierLabel: 'Consultores en ruido y acústica' },
  'es-rcph-120': { riskCarrierLabel: 'Consultores en selección y recursos humanos' },
  'es-rcph-121': { riskCarrierLabel: 'Gestión de bienes y servicios: facility management (excluyendo tasación)' },
  'es-rcph-122': { riskCarrierLabel: 'Asesores y consultores en energia y aprovechamiento energético' },
  'es-rcph-158': { riskCarrierLabel: 'Consultoría - Otra no detallada' },
  'es-rcph-1892': { riskCarrierLabel: 'Asesoría en prevención de blanqueo de capitais' },
  'es-rcph-220': { riskCarrierLabel: 'Entrenador físico deportivo  (personal, gimnasio)' },
  'es-rcph-221': { riskCarrierLabel: 'Profesor / formador' },
  'es-rcph-222': {
    riskCarrierLabel:
      'Servicios de entretenimiento: Animadores,  monitores de tiempo libre (excluidas actividades de aventura)',
  },
  'es-rcph-223': { riskCarrierLabel: 'Centros de enseñanza: colegios, universidades, Guarderías' },
  'es-rcph-224': { riskCarrierLabel: 'Escuelas de formación-academias' },
  'es-rcph-225': { riskCarrierLabel: 'Educadores sociales y trabajadores sociales' },
  'es-rcph-226': { riskCarrierLabel: 'Gimnasios y centros deportivos' },
  'es-rcph-227': { riskCarrierLabel: 'Educación/formación - Otra no detallada' },
  'es-rcph-300': { riskCarrierLabel: 'Asesoria/Consultoría de marca e identidad corporativa' },
  'es-rcph-301': { riskCarrierLabel: 'Creación y diseño multimedia para publicidad' },
  'es-rcph-302': { riskCarrierLabel: 'Diseño Gráfico' },
  'es-rcph-303': { riskCarrierLabel: 'Investigación de Mercados' },
  'es-rcph-304': { riskCarrierLabel: 'Diseño y creación de piezas publicitarias: gráfica, audiovisual  y online' },
  'es-rcph-305': { riskCarrierLabel: 'Agencia de relaciones públicas /Consultoría de comunicación' },
  'es-rcph-306': { riskCarrierLabel: 'Planificación de medios y compra de espacios publicitarios' },
  'es-rcph-308': { riskCarrierLabel: 'Marketing promocional - Promoción de ventas' },
  'es-rcph-309': { riskCarrierLabel: 'Marketing/publicidad - Otra no detallada' },
  'es-rcph-1893': { riskCarrierLabel: 'Marketing Directo' },
  'es-rcph-1894': { riskCarrierLabel: 'Marketing Digital' },
  'es-rcph-400': { riskCarrierLabel: 'Edición de libros' },
  'es-rcph-401': { riskCarrierLabel: 'Edición de revistas (información actual/general /especializada/suplementos)' },
  'es-rcph-402': { riskCarrierLabel: 'Edición de diarios' },
  'es-rcph-403': { riskCarrierLabel: 'Emisora de Radio' },
  'es-rcph-404': { riskCarrierLabel: 'Cadena de TV' },
  'es-rcph-405': {
    riskCarrierLabel: 'Medios online - Actividades informativas en Internet (excluido blogs y social media)',
  },
  'es-rcph-406': { riskCarrierLabel: 'Medios comunicación-editoriales - Otra no detallada' },
  'es-rcph-1916': { riskCarrierLabel: 'Producción audiovisual' },
  'es-rcph-519': { riskCarrierLabel: 'Tecnología - Otra no detallada' },
  'es-rcph-1869': { riskCarrierLabel: 'Servicios Gestionados' },
  'es-rcph-1870': { riskCarrierLabel: 'Servicios de Ciberseguridad' },
  'es-rcph-1871': { riskCarrierLabel: 'Consultoria IT (incluida Transformación Digital)' },
  'es-rcph-1872': { riskCarrierLabel: 'Formación IT en Protección de Datos y Ciberseguridad' },
  'es-rcph-1873': {
    riskCarrierLabel:
      'Desarrollo de software o apps (excluyendo salud, videojuegos, financiero, aplicaciones móvil, industrial, pago o cadena de suministro)',
  },
  'es-rcph-1874': {
    riskCarrierLabel:
      'Desarrollo de software o apps de salud, videojuegos, financiero, aplicaciones móvil, industrial, pago o cadena de suministro',
  },
  'es-rcph-1875': { riskCarrierLabel: 'Servicios a terceros en la nube (IaaS, SaaS, PaaS)' },
  'es-rcph-1876': { riskCarrierLabel: 'Servicios de Hosting o Data Center' },
  'es-rcph-1877': { riskCarrierLabel: 'Servicios de telecomunicaciones - Proveedor Internet' },
  'es-rcph-1878': { riskCarrierLabel: 'Desarrollo de herramientas de Marketing Digital (SEO y similares)' },
  'es-rcph-1879': { riskCarrierLabel: 'Fabricación y/o venta de hardware' },
  'es-rcph-1880': { riskCarrierLabel: 'Servicios a terceros de personal de IT' },
  'es-rcph-1881': { riskCarrierLabel: 'Integrador de tecnología IT' },
  'es-rcph-1882': { riskCarrierLabel: 'Integrador de tecnología industrial' },
  'es-rcph-1883': { riskCarrierLabel: 'Venta de software (mayorista o distribuidor)' },
  'es-rcph-1884': { riskCarrierLabel: 'Venta de software (minorista)' },
  'es-rcph-1885': { riskCarrierLabel: 'Soporte y Mantenimiento IT' },
  'es-rcph-1886': { riskCarrierLabel: 'Soporte y Mantenimiento sistemas Industriales' },
  'es-rcph-516': { riskCarrierLabel: 'Telecomunicaciones' },
  'es-rcph-1938': { riskCarrierLabel: 'Outsourcing Tecnológico' },
  'es-rcph-601': {
    riskCarrierLabel: 'Acupuntores - profesionales de la acupuntura y/o Terapias con cristales curativos',
  },
  'es-rcph-603': { riskCarrierLabel: 'Asesores en nutrición – Nutricionista / Dietistas' },
  'es-rcph-604': { riskCarrierLabel: 'Balnearios' },
  'es-rcph-606': { riskCarrierLabel: 'Asesores/Consultores en gestión de estrés' },
  'es-rcph-607': { riskCarrierLabel: 'Peluquería/ Esteticista / Depilación / Manicura / Pedicura' },
  'es-rcph-608': { riskCarrierLabel: 'Fisioterapeutas / Ergoterapeutas.' },
  'es-rcph-609': { riskCarrierLabel: 'Terapias alternativas' },
  'es-rcph-610': { riskCarrierLabel: 'Masajistas y quiromasajistas' },
  'es-rcph-611': { riskCarrierLabel: 'Orientadores psicopedagógicos / consejero matrimonial.' },
  'es-rcph-612': { riskCarrierLabel: 'Psicólogo' },
  'es-rcph-613': { riskCarrierLabel: 'Yoga' },
  'es-rcph-616': { riskCarrierLabel: 'Tatuadores' },
  'es-rcph-617': { riskCarrierLabel: 'Micropigmentacion' },
  'es-rcph-619': { riskCarrierLabel: 'Terapias alternativas – bienestar - Otra no detallada' },
  'es-rcph-1918': { riskCarrierLabel: 'Coach' },
  'es-rcph-1919': { riskCarrierLabel: 'Psicoanalistas / Psicoterapeuta' },
  'es-rcph-700': { riskCarrierLabel: 'Teatros' },
  'es-rcph-701': { riskCarrierLabel: 'Museos y Galerías de arte (excluido tasadores)' },
  'es-rcph-703': { riskCarrierLabel: 'Coros y músicos' },
  'es-rcph-704': { riskCarrierLabel: 'Escritores (excluyendo los periodistas)' },
  'es-rcph-705': { riskCarrierLabel: 'Ocio y Entretenimiento - Otra no detallada' },
  'es-rcph-900': { riskCarrierLabel: 'Organizador de eventos' },
  'es-rcph-702': { riskCarrierLabel: 'Casas de subastas y tasadores (sin Obras de Arte)' },
  'es-rcph-1889': { riskCarrierLabel: 'Gestoría y asesoría fiscal, laboral y/o contable' },
  'es-rcph-1890': { riskCarrierLabel: 'Asesor financiero' },
  'es-rcph-909': { riskCarrierLabel: 'Abogados / Despacho de abogados / Administrador concursal' },
  'es-rcph-901': { riskCarrierLabel: 'Agencias de colocación y/o trabajo temporal ETTs' },
  'es-rcph-902': { riskCarrierLabel: 'Agencias de modelos y/o azafatas' },
  'es-rcph-903': { riskCarrierLabel: 'Contact center – Call center' },
  'es-rcph-904': { riskCarrierLabel: 'Empresas de recobro' },
  'es-rcph-905': { riskCarrierLabel: 'Intérpretes y traductores' },
  'es-rcph-906': { riskCarrierLabel: 'Servicio de conserjería y/o recepción' },
  'es-rcph-907': { riskCarrierLabel: 'Archivo, digitalización y almacenamiento de documentos.' },
  'es-rcph-908': { riskCarrierLabel: 'Peritos de seguros' },
  'es-rcph-913': { riskCarrierLabel: 'Agencias de acogida / adopción' },
  'es-rcph-914': { riskCarrierLabel: 'Agentes de aduanas (excluyendo  todos tipos de impuestos )' },
  'es-rcph-915': { riskCarrierLabel: 'Arqueólogos' },
  'es-rcph-917': { riskCarrierLabel: 'Detectives privados o investigadores' },
  'es-rcph-918': { riskCarrierLabel: 'Diseño/Decoración de interiores' },
  'es-rcph-919': { riskCarrierLabel: 'Enólogos' },
  'es-rcph-920': { riskCarrierLabel: 'Fotógrafos' },
  'es-rcph-921': { riskCarrierLabel: 'Funerarias' },
  'es-rcph-922': { riskCarrierLabel: 'Genealogistas' },
  'es-rcph-923': { riskCarrierLabel: 'Gestión de flotas (Rent a car)' },
  'es-rcph-924': { riskCarrierLabel: 'Guías turísticos (excluyendo senderismo)' },
  'es-rcph-925': { riskCarrierLabel: 'Inspección técnica de automóviles (ITV)' },
  'es-rcph-926': {
    riskCarrierLabel:
      'Laboratorios de análisis ,excluyendo temas relacionados con medicina, productos farmecéuticos, sangre y tejidos humanos, suelos y construcción',
  },
  'es-rcph-927': { riskCarrierLabel: 'Niñeras' },
  'es-rcph-929': { riskCarrierLabel: 'Paisajismo, jardinería y diseño de exteriores (excluido campos de golf)' },
  'es-rcph-930': { riskCarrierLabel: 'Residencias animales (caninas / otros)' },
  'es-rcph-931': { riskCarrierLabel: 'Servicios de mediación y arbitraje (excluido seguros)' },
  'es-rcph-932': { riskCarrierLabel: 'Club deportivo (no profesional) y Club de ocio' },
  'es-rcph-934': { riskCarrierLabel: 'Mantemiento de piscinas/Socorristas' },
  'es-rcph-935': { riskCarrierLabel: 'Floristas' },
  'es-rcph-936': { riskCarrierLabel: 'Investigador de fraude' },
  'es-rcph-938': { riskCarrierLabel: 'Ingeniería' },
  'es-rcph-3037': {
    riskCarrierLabel: 'Vigilancia y protección de bienes, establecimientos, espectáculos, certámenes o convenciones',
  },
  'es-rcph-3038': { riskCarrierLabel: 'Protección de personas determinadas, previa la autorización correspondiente' },
  'es-rcph-3039': {
    riskCarrierLabel: 'Instalación y mantenimiento de otros aparatos, dispositivos y sistemas de seguridad',
  },
  'es-rcph-3040': {
    riskCarrierLabel:
      'Explotación de centrales para la recepción, verificación y transmisión de las señales de alarmas y su comunicación a las Fuerzas y Cuerpos de Seguridad',
  },
  'es-rcph-3041': { riskCarrierLabel: 'Planificación y asesoramiento de las actividades de seguridad' },
  'es-rcph-941': { riskCarrierLabel: 'Somellier' },
  'es-rcph-943': { riskCarrierLabel: 'Agencia de viaje' },
  'es-rcph-944': { riskCarrierLabel: 'Prevención de riesgos laborales' },
  'es-rcph-945': { riskCarrierLabel: 'Restauradores de arte' },
  'es-rcph-946': { riskCarrierLabel: 'personal shopper' },
  'es-rcph-947': { riskCarrierLabel: 'paseador / adiestrador de perros' },
  'es-rcph-949': { riskCarrierLabel: 'Otra no detallada' },
  'es-rcph-939': { riskCarrierLabel: 'Arquitectos' },
  'es-rcph-1925': { riskCarrierLabel: 'Instituciones religiosas' },
  'es-rcph-1930': { riskCarrierLabel: 'Servicios no profesionales - Otra no detallada' },
  'es-rcph-1931': { riskCarrierLabel: 'Estaciones de servicios' },
  'es-rcph-1932': { riskCarrierLabel: 'Gestion de empresas' },
  'es-rcph-1933': { riskCarrierLabel: 'Partidos politicos, sindicatos' },
  'es-rcph-1939': { riskCarrierLabel: 'Reciclaje, tratamiento de residuos' },
  'es-rcph-1001': { riskCarrierLabel: 'Farmaceuticas' },
  'es-rcph-1101': { riskCarrierLabel: 'Biotecnologia' },
  'es-rcph-1401': { riskCarrierLabel: 'Energías Renovables (excepto hidráulica)' },
  'es-rcph-1501': { riskCarrierLabel: 'Juegos y apuestas' },
  'es-rcph-1801': { riskCarrierLabel: 'Aeropuertos' },
  'es-rcph-1809': {
    riskCarrierLabel:
      'Trabajos de albañilería y reformas de interior (sin afectar a elementos estructurales), Enyesado, Alicatado, Cerramientos, Revestimientos, Revoques',
  },
  'es-rcph-1815': { riskCarrierLabel: 'Trabajos de pintura de exteriores (máximo dos alturas) e interiores' },
  'es-rcph-1817': { riskCarrierLabel: 'Instalación y mantenimiento de cercados y vallados de fincas y  terrenos' },
  'es-rcph-1818': {
    riskCarrierLabel: 'Instalación, mantenimiento y/o reparación de sistemas de protección contra incendios',
  },
  'es-rcph-1819': {
    riskCarrierLabel:
      'Instalación, manipulación y montaje de cristal y/o vidrio. (Excluído fabricación y trabajos superiores a dos alturas)',
  },
  'es-rcph-1821': { riskCarrierLabel: 'Instalación de Paneles de yeso: pladur' },
  'es-rcph-1822': { riskCarrierLabel: 'Instalación y reparación de carpintería de madera' },
  'es-rcph-1823': { riskCarrierLabel: 'Reparación de muebles y artículos de menaje' },
  'es-rcph-1824': { riskCarrierLabel: 'Reparación de carpintería metálica (excluidos trabajos estructurales)' },
  'es-rcph-1825': { riskCarrierLabel: 'Instalación, mantenimiento y reparación de Electricidad Baja Tensión' },
  'es-rcph-1826': { riskCarrierLabel: 'Instalación, mantenimiento y reparación de Electricidad Alta Tensión' },
  'es-rcph-1827': { riskCarrierLabel: 'Instalación, Mantenimiento y Montaje de Energia Solar, Termica y fotovoltaica' },
  'es-rcph-1829': {
    riskCarrierLabel: 'Instalador de Telecomunicaciones (incluído daños al dominio público radioeléctrico)',
  },
  'es-rcph-1830': { riskCarrierLabel: 'Instalaciones de sistemas de calefacción y aire acondicionado' },
  'es-rcph-1831': { riskCarrierLabel: 'Trabajos de fontanería' },
  'es-rcph-1832': { riskCarrierLabel: 'Trabajos de soldadura' },
  'es-rcph-1833': { riskCarrierLabel: 'Instalaciones térmicas en edificios (calefaccion, ACS, climatizacion)' },
  'es-rcph-1834': {
    riskCarrierLabel: 'Instalación, mantenimiento y/o reparación de conducciones y aparatos de gas (Tipos A,B y C)',
  },
  'es-rcph-1835': { riskCarrierLabel: 'Instalación de conducciones para Productos Petrolíferos Líquidos' },
  'es-rcph-1836': { riskCarrierLabel: 'Instalación, mantenimiento y limpieza de chimeneas o estufas: fumistería' },
  'es-rcph-1838': { riskCarrierLabel: 'Conservación y mantenimiento y/o asfaltado de carreteras sin excavación' },
  'es-rcph-1922': { riskCarrierLabel: 'Construcción - Otras actividades' },
  'es-rcph-910': { riskCarrierLabel: 'Limpieza general de edificios' },
  'es-rcph-1839': { riskCarrierLabel: 'Instalación de mobiliario en viviendas, oficinas y locales comerciales' },
  'es-rcph-1840': {
    riskCarrierLabel: 'Instalación, mantenimiento y reparación de máquinas (excluido maquinaria industrial)',
  },
  'es-rcph-1841': { riskCarrierLabel: 'Reparación de Electrodomésticos' },
  'es-rcph-1842': { riskCarrierLabel: 'Reparación de Equipos Electrónicos' },
  'es-rcph-1843': { riskCarrierLabel: 'Instalación, mantenimiento y reparación de maquinaria de oficina' },
  'es-rcph-1844': { riskCarrierLabel: 'Instalación de antenas y pararrayos' },
  'es-rcph-1845': { riskCarrierLabel: 'Instalación y reparación de servicios domóticos en viviendas y oficinas' },
  'es-rcph-1846': { riskCarrierLabel: 'Instalaciones Frigorificas' },
  'es-rcph-1847': {
    riskCarrierLabel:
      'Instalación, mantenimiento y reparación de maquinaria y equipos industriales (excluidos trabajos en sectores automoción, aviación y marítimo)',
  },
  'es-rcph-1848': { riskCarrierLabel: 'Reparacion y/o mantenimiento de aparatos de telefonía' },
  'es-rcph-1849': {
    riskCarrierLabel:
      'Mantenimiento de Energia Solar, Termica, Fotovoltaica y Biomasa sobre suelo y edificios de viviendas',
  },
  'es-rcph-1850': {
    riskCarrierLabel: 'Trabajo y manipulación de la piedra: mármol, granito, roca.(Excluído canteras y extracción)',
  },
  'es-rcph-1851': { riskCarrierLabel: 'Reparación de calzado y artículos de cuero: zapatero y curtidores' },
  'es-rcph-1852': { riskCarrierLabel: 'Conservacion y Mantenimiento de Ascensores (excluido instalación)' },
  'es-rcph-1853': { riskCarrierLabel: 'Mantenimiento y reparación de Calderas de Vapor (excluido instalación)' },
  'es-rcph-1854': { riskCarrierLabel: 'Cerrajeros' },
  'es-rcph-1855': { riskCarrierLabel: 'Mantenimiento, instalación y/o reposición de máquinas vending' },
  'es-rcph-1856': { riskCarrierLabel: 'Instalador de cortinas' },
  'es-rcph-1857': { riskCarrierLabel: 'Lavado a domicilio de vehículos excl. tunel de lavado' },
  'es-rcph-1858': { riskCarrierLabel: 'Instalador de suelos de parque, madera, acuchillador.' },
  'es-rcph-1859': { riskCarrierLabel: 'Pulido de suelos, microcemento' },
  'es-rcph-1860': { riskCarrierLabel: 'Buzoneo' },
  'es-rcph-1861': { riskCarrierLabel: 'Taller de costura' },
  'es-rcph-1928': { riskCarrierLabel: 'Vehículos destinados al transporte de mercancias - Otras no detalladas' },
  'es-rcph-1929': { riskCarrierLabel: 'Blue Collar - Otras actividades' },
  'es-rcph-1862': { riskCarrierLabel: 'Vehículos destinados al transporte de mercancias no peligrosas por carretera' },
  'es-rcph-1863': {
    riskCarrierLabel: 'Vehículos destinados al transporte especiales (mercancías de grandes dimensiones)',
  },
  'es-rcph-1864': { riskCarrierLabel: 'Mensajería: carteros y recaderos' },
  'es-rcph-1865': {
    riskCarrierLabel:
      'Recogida y Transporte de residuos no peligrosos (excluida  la Responsabilidad Civil por Contaminación)',
  },
  'es-rcph-1926': { riskCarrierLabel: 'Almacenamiento' },
  'es-rcph-1927': { riskCarrierLabel: 'Transporte pasajeros' },
  'es-rcph-933': { riskCarrierLabel: 'Servicios sociales para personas dependientes (discapacitados,ancianos)' },
  'es-rcph-1896': { riskCarrierLabel: 'Hospitales' },
  'es-rcph-1897': { riskCarrierLabel: 'Clinicas Dentales' },
  'es-rcph-1898': { riskCarrierLabel: 'Centros especializados' },
  'es-rcph-1899': { riskCarrierLabel: 'Centros de fertilidad' },
  'es-rcph-1900': { riskCarrierLabel: 'Farmacia' },
  'es-rcph-1901': { riskCarrierLabel: 'Residencias de mayores - Geriatricos' },
  'es-rcph-1902': { riskCarrierLabel: 'Consultorio médico' },
  'es-rcph-1903': { riskCarrierLabel: 'Centro Sanitario' },
  'es-rcph-1701': { riskCarrierLabel: 'Gas, mineria, petroleo' },
  'es-rcph-1909': {
    riskCarrierLabel: 'Suministro de electricidad, gas o agua (generación, distribución y comercialización)',
  },
  'es-rcph-1910': { riskCarrierLabel: 'Armamentistico' },
  'es-rcph-1924': { riskCarrierLabel: 'Energía hidráulica' },
  'es-rcph-1912': { riskCarrierLabel: 'Bancos, Fondos de Inversiones, Asset Manager, Family Office, Capital Riesgo' },
  'es-rcph-1913': { riskCarrierLabel: 'Otras entidades supervisadas por la CNMV o el Banco de España' },
  'es-rcph-1937': { riskCarrierLabel: 'Servicios financieros y seguros' },
  'es-rcph-1915': { riskCarrierLabel: 'Ayuntamientos' },
  'es-rcph-1914': { riskCarrierLabel: 'Otras entidades gubernamentales o públicas' },
  'es-rcph-1917': { riskCarrierLabel: 'Agricultura, silvicultura, pesca y caza' },
  'es-rcph-1920': { riskCarrierLabel: 'Comercio al por mayor' },
  'es-rcph-1921': { riskCarrierLabel: 'Venta minorista (cualquier otra)' },
  'es-rcph-1904': { riskCarrierLabel: 'Fabricación de alimentos' },
  'es-rcph-1905': { riskCarrierLabel: 'Fabricación textil' },
  'es-rcph-1906': { riskCarrierLabel: 'Fabricación de automóviles' },
  'es-rcph-1907': { riskCarrierLabel: 'Fabricación de maquinaria' },
  'es-rcph-1908': { riskCarrierLabel: 'Fabricación de robots' },
  'es-rcph-1911': { riskCarrierLabel: 'Fabricación - Otras actividades' },
  'es-rcph-1923': { riskCarrierLabel: 'Fabricación de paneles solares' },
  'es-rcph-1935': { riskCarrierLabel: 'Servicios de alimentación (Restaurantes)' },
  'es-rcph-1936': { riskCarrierLabel: 'Servicios de alojamiento (Hoteles)' },
  'es-rcph-916': { riskCarrierLabel: 'Asociación profesional y de comercio' },
  'es-rcph-928': { riskCarrierLabel: 'Entidades benéficas y organizaciones sin ánimo de lucro' },
  'es-rcph-912': {
    riskCarrierLabel:
      'Administradores de fincas  / Consultor Inmobiliario / Agencias de la propiedad inmobiliaria - API',
  },

  //#endregion
} as const
