import stripIndent from 'strip-indent'

/**
 * The standard non-breaking space character.
 */
export const nbsp = '\xa0'

/**
 * The html entity for non-breaking space.
 */
export const nbspEntity = '&nbsp;'

/**
 * Capitalize the first letter of a string
 */
export function capitalize(text: string): string {
  return text.replace(/(^\w)/g, (m) => m.toUpperCase())
}

export function isEmptyOrBlankSpaces(text: string): boolean {
  return text === '' || text.match(/^\s*$/) !== null
}

/**
 * Removes all the accents and special characters from a string (e.g. : œ -> oe, é -> e, ñ -> n, etc.)
 * The map comes from https://stackoverflow.com/a/71408065
 */

const diacriticsRemovalMap: Record<string, string> = {
  ä: 'ae',
  æ: 'ae',
  ǽ: 'ae',
  đ: 'd',
  ð: 'd',
  ƒ: 'f',
  ħ: 'h',
  ı: 'i',
  ł: 'l',
  ø: 'o',
  ǿ: 'o',
  ö: 'oe',
  œ: 'oe',
  ß: 'ss',
  ŧ: 't',
  ü: 'ue',
  Ä: 'AE',
  Æ: 'AE',
  Ǽ: 'AE',
  Đ: 'D',
  Ð: 'D',
  Ƒ: 'F',
  Ħ: 'H',
  I: 'I',
  Ł: 'L',
  Ø: 'O',
  Ǿ: 'O',
  Ö: 'OE',
  Œ: 'OE',
  ẞ: 'SS',
  Ŧ: 'T',
  Ü: 'UE',
}

export function removeAccentsAndSpecialCharacters(text: string): string {
  return (
    text
      .normalize('NFKD')
      // eslint-disable-next-line no-control-regex
      .replace(/./g, function (a) {
        return diacriticsRemovalMap[a] || a
      })
      .replace(/[^a-zA-Z0-9]/g, '')
  )
}

/**
 * Detects a common minimal indent of all the input lines, removes it from every line and trim the result
 *
 * @param text
 * @returns
 */
export function trimIndent(text: string): string {
  return stripIndent(text).trim()
}
