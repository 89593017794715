import { SnackbarProvider } from 'notistack'

import { memo, useMemo, type PropsWithChildren } from 'react'
import { Toast } from '../../toast.js'
import { TemporaryNotificationAlert } from '../temporary-notification/temporary-notification.js'

export const TemporaryNotificationAlertProvider = memo<PropsWithChildren>(function TemporaryNotificationAlertProvider({
  children,
}) {
  const components = useMemo(() => ({ default: TemporaryNotificationAlert }), [])

  return (
    <SnackbarProvider
      Components={components}
      maxSnack={3}
      hideIconVariant
      autoHideDuration={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </SnackbarProvider>
  )
})

export const ToastProvider = memo<PropsWithChildren>(function ToastProvider({ children }) {
  const components = useMemo(() => ({ default: Toast }), [])

  return (
    <SnackbarProvider
      Components={components}
      maxSnack={3}
      hideIconVariant
      autoHideDuration={3500}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {children}
    </SnackbarProvider>
  )
})
