import type { Amount } from '@orus.eu/amount'
import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import { BaseDimension, dimensionValidationFailure, type DimensionValidationProblem } from '../abstract-dimension.js'

export const mutaFormulaGroup = ['ESSENTIEL', 'PRIVILEGE'] as const

export const mutaFormulaGroupSchema = z.enum(mutaFormulaGroup)
export type MutaFormulaGroup = z.infer<typeof mutaFormulaGroupSchema>

export const mutaFormulaTypes = [
  'ESSE-1',
  'ESSE-2',
  'ESSE-3',
  'ESSE-4',
  'PRIV-1',
  'PRIV-2',
  'PRIV-3',
  'PRIV-4',
  'PRIV-5',
  'PRIV-6',
  'PRIV-7',
  'PRIV-8',
] as const

export const mutaFormulaTypeSchema = z.enum(mutaFormulaTypes)
export type MutaFormulaType = z.infer<typeof mutaFormulaTypeSchema>

export const mutaFormulaLabels: Record<MutaFormulaType, string> = {
  'ESSE-1': 'Essentiel - Niveau 1',
  'ESSE-2': 'Essentiel - Niveau 2',
  'ESSE-3': 'Essentiel - Niveau 3',
  'ESSE-4': 'Essentiel - Niveau 4',
  'PRIV-1': 'Privilège - Niveau 1',
  'PRIV-2': 'Privilège - Niveau 2',
  'PRIV-3': 'Privilège - Niveau 3',
  'PRIV-4': 'Privilège - Niveau 4',
  'PRIV-5': 'Privilège - Niveau 5',
  'PRIV-6': 'Privilège - Niveau 6',
  'PRIV-7': 'Privilège - Niveau 7',
  'PRIV-8': 'Privilège - Niveau 8',
}

export const mutaFormulaTypeGroup: Record<MutaFormulaType, MutaFormulaGroup> = {
  'ESSE-1': 'ESSENTIEL',
  'ESSE-2': 'ESSENTIEL',
  'ESSE-3': 'ESSENTIEL',
  'ESSE-4': 'ESSENTIEL',
  'PRIV-1': 'PRIVILEGE',
  'PRIV-2': 'PRIVILEGE',
  'PRIV-3': 'PRIVILEGE',
  'PRIV-4': 'PRIVILEGE',
  'PRIV-5': 'PRIVILEGE',
  'PRIV-6': 'PRIVILEGE',
  'PRIV-7': 'PRIVILEGE',
  'PRIV-8': 'PRIVILEGE',
}

export class MutaFormulasDimension<NAME extends string> extends BaseDimension<NAME, MutaFormulaType[]> {}

export class MutaFormulaDimension<NAME extends string> extends BaseDimension<NAME, MutaFormulaType> {
  override validateData(value: unknown): Result<MutaFormulaType, DimensionValidationProblem> {
    const parsedFormula = mutaFormulaTypeSchema.safeParse(value)
    if (parsedFormula.success) {
      return success(parsedFormula.data)
    }
    return dimensionValidationFailure(`Field ${this.name} is not a valid MUTA formula with value ${value}`)
  }
}

export class MutaFormulaGroupDimension<NAME extends string> extends BaseDimension<NAME, MutaFormulaGroup> {
  override validateData(value: unknown): Result<MutaFormulaGroup, DimensionValidationProblem> {
    const parsedFormula = mutaFormulaGroupSchema.safeParse(value)
    if (parsedFormula.success) {
      return success(parsedFormula.data)
    }
    return dimensionValidationFailure(`Field ${this.name} is not a valid MUTA formula group with value ${value}`)
  }
}

export type MutaFormulasWithPrice = Record<MutaFormulaType, { yearlyPrice: Amount; monthlyPrice: Amount }>

export const mutaClientRecommendedFormulaDimension = new MutaFormulaDimension({
  name: 'mutaClientRecommendedFormula',
  displayValues: { name: 'Formule recommandé pour le client dans le BO' },
  tags: ['MUTA'],
} as const)

export const mutaFormulaDimension = new MutaFormulaDimension({
  name: 'mutaFormula',
  displayValues: { name: 'Formule' },
  tags: ['MUTA'],
} as const)

export const mutaFormulaGroupDimension = new MutaFormulaGroupDimension({
  name: 'mutaFormulaGroup',
  displayValues: {
    name: 'Groupe de formule',
    hint: 'Essentiel ou Privilège',
  },
  tags: ['MUTA'],
})

export const mutaPossibleClientFormulasDimension = new MutaFormulasDimension({
  name: 'mutaPossibleClientFormulas',
  displayValues: { name: 'Formules possibles pour le client' },
} as const)

export const mutaPossibleFormulasDimension = new MutaFormulasDimension({
  name: 'mutaPossibleFormulas',
  displayValues: { name: 'Formules possibles' },
} as const)

export class MutaFormulasWithPriceDimension<NAME extends string> extends BaseDimension<NAME, MutaFormulasWithPrice> {}

export const mutaFormulasWithPriceDimension = new MutaFormulasWithPriceDimension({
  name: 'mutaFormulasWithPrice',
  displayValues: { name: 'Formules et leurs prix' },
})
