import { amountToNumber, amountToString, type Amount } from '@orus.eu/amount'
import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class AmountDimension<NAME extends string> extends AbstractDimension<NAME, Amount> {
  override validateData(value: LooselyTypedValue): Result<Amount, DimensionValidationProblem> {
    if (typeof value !== 'object' || value === null)
      return dimensionValidationFailure(`Field ${this.name} is not an Amount`)
    if (!('__encodedAmount' in value))
      return dimensionValidationFailure(
        `Field ${this.name} is not an Amount because property "__encodedAmount" is missing`,
      )
    const { __encodedAmount } = value
    if (typeof __encodedAmount !== 'number') {
      return dimensionValidationFailure(
        `Field ${this.name} is not an Amount because property "__encodedAmount" is not a number`,
      )
    }
    if (!Number.isSafeInteger(__encodedAmount)) {
      return dimensionValidationFailure(
        `Field ${this.name} is not an Amount because property "__encodedAmount" is not a safe integer`,
      )
    }
    return success({ __encodedAmount })
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<number> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.number(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: Amount | null | undefined): number | null => {
      if (!stateValue) return null
      return amountToNumber(stateValue)
    },
  }
}

export function formatAmountDimensionValue(value: Amount): string {
  return amountToString(value, { displayDecimals: 'when-amount-has-cents', addCurrency: true })
}

export function formatRoundedAmountDimensionValue(value: Amount): string {
  return amountToString(value, { displayDecimals: false, addCurrency: true })
}
