import { amountToString, newAmount } from '@orus.eu/amount'
import { failure } from '@orus.eu/result'

/**
 * Temporary failures of the wakam API that can be solved by retrying later
 */
export type TemporaryWakamProblem = { type: 'temporary-wakam-issue' }

export type WakamUnexpectedResultProblem = { type: 'unexpected-wakam-issue' }

export type WakamApiProblem = TemporaryWakamProblem | WakamUnexpectedResultProblem

export const temporaryWakamProblem: TemporaryWakamProblem = { type: 'temporary-wakam-issue' }
export const temporaryWakamFailure = failure(temporaryWakamProblem)
export const wakamUnexpectedResultProblem: WakamUnexpectedResultProblem = { type: 'unexpected-wakam-issue' }
export const wakamUnexpectedResultFailure = failure(wakamUnexpectedResultProblem)

export function isTemporaryWakamProblem(problem: unknown): problem is TemporaryWakamProblem {
  return (
    typeof problem === 'object' &&
    problem !== null &&
    'type' in problem &&
    (problem as { type: unknown }).type === temporaryWakamFailure.type
  )
}

export const firstMonthDeductiblePolicyDescription = {
  deductible: amountToString(newAmount('2000'), { addCurrency: true, displayDecimals: false }),
  description: "pendant le premier mois du contrat. Cette franchise s'applique à tous les sinistres.",
}

/**
 * This problems report that the pricing call failed because of a flood risk. This is a
 * problem because the flood risk should be eliminated at the address validation step, so
 * that people don't have to fill in all the pricing parameters do discover later that they
 * have a flood risk.
 */
export const floodRiskProblem = { type: 'flood-risk-problem' } as const
export type FloodRiskProblem = typeof floodRiskProblem
export const floodRiskFailure = failure(floodRiskProblem)
