import type { SerializedStyles } from '@emotion/react'
import { memo } from 'react'
import { TextInputLabelWrapper, type TextInputLabelWrapperProps } from '../../input-label-wrapper/index.js'
import {
  ToggleButtons,
  YesNoToggleButtons,
  type ToggleButtonsProps,
  type YesNoToggleButtonsProps,
} from '../../toggle-buttons.js'

export type ToggleButtonsFormFieldProps = ToggleButtonsProps &
  Omit<TextInputLabelWrapperProps, 'children'> & { fieldClassName?: SerializedStyles }

export const ToggleButtonsFormField = memo<ToggleButtonsFormFieldProps>(function ToggleButtonsFormField(props) {
  return (
    <TextInputLabelWrapper
      variant={props.variant}
      label={props.label}
      caption={props.caption}
      infoTooltip={props.infoTooltip}
      required={props.required}
    >
      <ToggleButtons {...props} css={props.fieldClassName} />
    </TextInputLabelWrapper>
  )
})

export type YesNoToggleButtonsFormFieldProps = YesNoToggleButtonsProps &
  Omit<TextInputLabelWrapperProps, 'children'> & { fieldClassName?: SerializedStyles }

export const YesNoToggleButtonsFormField = memo<YesNoToggleButtonsFormFieldProps>(
  function YesNoToggleButtonsFormField(props) {
    return (
      <TextInputLabelWrapper
        variant={props.variant}
        label={props.label}
        caption={props.caption}
        infoTooltip={props.infoTooltip}
        required={props.required}
      >
        <YesNoToggleButtons {...props} css={props.fieldClassName} />
      </TextInputLabelWrapper>
    )
  },
)
