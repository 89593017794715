import { typedMemo } from '../../../../util.js'
import { ValidatedTextField, type ValidatedTextFieldProps } from '../../../atoms/index.js'
import { TextInputLabelWrapper, type TextInputLabelWrapperProps } from '../../input-label-wrapper/index.js'

export type ValidatedTextFieldFormFieldProps<TYPE> = ValidatedTextFieldProps<TYPE> &
  Omit<TextInputLabelWrapperProps, 'children'>

export const ValidatedTextFieldFormField = typedMemo(function ValidatedTextFieldFormField<TYPE>(
  props: ValidatedTextFieldFormFieldProps<TYPE>,
) {
  return (
    <TextInputLabelWrapper
      variant={props.variant}
      label={props.label}
      caption={props.caption}
      infoTooltip={props.infoTooltip}
      badge={props.badge}
      required={props.required}
    >
      <ValidatedTextField {...props} />
    </TextInputLabelWrapper>
  )
})
