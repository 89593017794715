import { css } from '@emotion/react'
import { Checkbox } from '@mui/material'
import { memo } from 'react'
import { spacing } from '../../../../foundation/spacing-tokens.js'
import { Text } from '../../../atoms/index.js'
import { ButtonInputLabelWrapper, type ButtonInputLabelWrapperProps } from '../../input-label-wrapper/index.js'

export type CheckboxFieldProps = {
  checked: boolean
  onChange: (newValue: boolean) => void
  disabled?: boolean
  optionalText?: string
} & Omit<ButtonInputLabelWrapperProps, 'leftComponent' | 'rightComponent' | 'rowGap'>

export const CheckboxFormField = memo<CheckboxFieldProps>(function CheckboxFormField(props) {
  const { onChange, checked, disabled, optionalText, variant, label, value, infoTooltip } = props
  const rightComponent = optionalText ? (
    <Text variant="body2" screenVariant={variant}>
      {optionalText}
    </Text>
  ) : undefined
  const leftComponent = (
    <div
      css={css`
        margin-right: ${spacing[50]};
      `}
    >
      <Checkbox
        css={css`
          padding: 0;
        `}
        disabled={disabled}
        checked={checked}
        onChange={() => onChange(!checked)}
      />
    </div>
  )
  return (
    <ButtonInputLabelWrapper
      variant={variant}
      label={label}
      value={value}
      infoTooltip={infoTooltip}
      leftComponent={leftComponent}
      rightComponent={rightComponent}
    />
  )
})
