import type { Result } from '@orus.eu/result'
import { AbstractDimension } from '../abstract-dimension.js'
import type { WakamApiProblem } from './wakam-util.js'

export const wakamAddressValidationOutput = [
  'has-risk',
  'has-no-risk',
  /**
   * A flood risk cannot be computed because we don't have enough data in the address
   * (example invalid address)
   */
  'not-applicable',
] as const

/**
 * The result of a flood risk test from the Wakam API
 */
export type WakamAddressValidationOutput = (typeof wakamAddressValidationOutput)[number]

export type WakamAddressValidationResult = Result<WakamAddressValidationOutput, WakamApiProblem>

export class MrpwAddressValidationResultDimension<NAME extends string> extends AbstractDimension<
  NAME,
  WakamAddressValidationResult
> {}
