import { success, type Result } from '@orus.eu/result'
import type { JsonableSiretData } from '@orus.eu/siret'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class SiretDimension<NAME extends string> extends AbstractDimension<NAME, JsonableSiretData> {
  override validateData(value: LooselyTypedValue): Result<JsonableSiretData, DimensionValidationProblem> {
    if (typeof value !== 'object' || value === null)
      return dimensionValidationFailure(`Field ${this.name} is not a JsonableSiretData`)

    if (!('type' in value))
      return dimensionValidationFailure(
        `Field ${this.name} is not a JsonableSiretData because property "type" is missing`,
      )
    const type = value.type

    switch (type) {
      case 'provided': {
        if (!('siret' in value)) {
          return dimensionValidationFailure(
            `Field ${this.name} is not a JsonableSiretData because property "siret" is missing and property type was provided`,
          )
        }
        const siret = value.siret
        if (typeof siret !== 'string') {
          return dimensionValidationFailure(
            `Field ${this.name} is not a JsonableSiretData because property "siret" is not a string`,
          )
        }
        return success({ type, siret })
      }
      case 'later':
        return success({ type })
      default:
        return dimensionValidationFailure(
          `Field ${this.name} is not a JsonableSiretData because property "type" is invalid`,
        )
    }
  }
}

export function formatSiretDataForDocuments(siretData: JsonableSiretData): string {
  return siretData.type === 'later' ? "SIRET en cours d'obtention" : siretData.siret
}
