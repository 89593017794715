import { css } from '@emotion/react'
import { memo } from 'react'
import { useScreenVariant } from '../../../../hooks/index.js'
import { Datepicker, type DatepickerProps } from '../../../atoms/index.js'
import { TextInputLabelWrapper, type TextInputLabelWrapperProps } from '../../input-label-wrapper/index.js'

export type DatepickerFormFieldProps = DatepickerProps & Omit<TextInputLabelWrapperProps, 'children'>

export const DatepickerFormField = memo<DatepickerFormFieldProps>(function DatepickerFormField(props) {
  const screenVariant = useScreenVariant()
  return (
    <TextInputLabelWrapper
      variant={props.variant}
      label={props.label}
      caption={props.caption}
      infoTooltip={props.infoTooltip}
    >
      <Datepicker
        {...props}
        css={css`
          ${screenVariant !== 'mobile' ? 'max-width: 400px;' : ''}
        `}
      />
    </TextInputLabelWrapper>
  )
})
