import { z } from 'zod'

const nifRegex = /(X(-|\.)?0?\d{7}(-|\.)?[A-Z]|[A-Z](-|\.)?\d{7}(-|\.)?[0-9A-Z]|\d{8}(-|\.)?[A-Z])$/
export function parseNifValue(input: string): string | null {
  const trimmedInput = input.replace(/\s/g, '').trim()
  if (nifRegex.test(trimmedInput)) {
    return trimmedInput
  }
  return null
}

export const nifValueZodSchema = z.string().refine((s) => parseNifValue(s) !== null, {
  message: 'Invalid nif',
})
