import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import type { Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export abstract class AbstractNumberDimension<NAME extends string, VALUE = number> extends AbstractDimension<
  NAME,
  VALUE
> {
  override validateData(value: LooselyTypedValue): Result<VALUE, DimensionValidationProblem> {
    return typeof value === 'number'
      ? this.validateNumber(value)
      : dimensionValidationFailure(`Field ${this.name} is not a number`)
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<number> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.number(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: number | null | undefined): number | null => {
      if (stateValue == undefined) return null
      return stateValue
    },
  }

  abstract validateNumber(value: number): Result<VALUE, DimensionValidationProblem>
}
