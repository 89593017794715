import { css } from '@emotion/react'
import { memo } from 'react'
import ReactMarkdown, { type Options } from 'react-markdown'
import { Text, TextContainer } from './text/text.js'
import { type TextVariant } from './text/variant.js'

export type MarkdownProps = {
  markdown: string

  className?: string
}

export const Markdown = memo<MarkdownProps>(function Markdown(props: MarkdownProps) {
  const { markdown, className } = props

  return (
    <TextContainer variant={textVariant} className={className}>
      <ReactMarkdown components={componentsMap}>{markdown}</ReactMarkdown>
    </TextContainer>
  )
})

const textVariant: TextVariant = 'body2'

const componentsMap: Options['components'] = {
  h1: ({ node, children, ...props }) => (
    <Text variant="h1" {...props}>
      {children}
    </Text>
  ),
  h2: ({ node, children, ...props }) => (
    <Text variant="h2" {...props}>
      {children}
    </Text>
  ),
  h3: ({ node, children, ...props }) => (
    <Text variant="h3" {...props}>
      {children}
    </Text>
  ),
  h4: ({ node, children, ...props }) => (
    <Text variant="h4" {...props}>
      {children}
    </Text>
  ),
  h5: ({ node, children, ...props }) => (
    <Text variant="h5" {...props}>
      {children}
    </Text>
  ),
  h6: ({ node, children, ...props }) => (
    <Text variant="h6" {...props}>
      {children}
    </Text>
  ),

  p: ({ node, children, ...props }) => (
    <Text variant={textVariant} {...props}>
      {children}
    </Text>
  ),
  li: ({ node, children, ...props }) => (
    <li>
      <Text variant={textVariant} {...props}>
        {children}
      </Text>
    </li>
  ),
  strong: ({ node, children, ...props }) => (
    <Text
      variant="body2Medium"
      css={css`
        display: inline;
      `}
      {...props}
    >
      {children}
    </Text>
  ),
}
