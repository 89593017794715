import styled from '@emotion/styled'
import { MenuItem } from '@mui/material'
import { colorTokens, spacing } from '../../../foundation'
import type { TextCssProps } from '../../atoms/text'

type SelectMenuItemSize = 'small' | 'medium' | 'large'

const padding: Record<SelectMenuItemSize, string> = {
  small: `${spacing['30']} ${spacing['50']}`,
  medium: `${spacing['40']} ${spacing['50']}`,
  large: `${spacing['50']}`,
}

export const SelectMenuItem = styled(MenuItem)<{
  typography: TextCssProps
  size: SelectMenuItemSize
  destructive?: boolean
  disabled?: boolean
}>`
  display: flex;
  padding: ${(props) => padding[props.size]};
  align-items: center;
  align-content: center;
  gap: 16px ${spacing['50']};
  align-self: stretch;
  flex-wrap: wrap;

  ${({ typography }) => typography}

  color: ${colorTokens['color-text-base-main']};
  background-color: ${colorTokens['color-bg-base-normal']};

  :focus {
    background-color: ${colorTokens['color-bg-base-normal']} !important;
  }

  &.Mui-selected {
    color: ${colorTokens['color-text-base-link']} !important;
    background-color: ${colorTokens['color-bg-base-normal']} !important;
  }

  :hover {
    background-color: ${colorTokens['color-bg-base-hover']} !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
      color: ${colorTokens['color-text-base-disable']};
      opacity: 1 !important;
    `}

  ${({ destructive }) => destructive && `color: ${colorTokens['color-text-danger-secondary']}`}
`
