import { memo, type ReactNode } from 'react'
import { Avatar } from '../../../atoms/index.js'
import { CalloutNotificationTemplate, type ActionButton } from '../../common/callout-notification-template.js'
import { colorsPerVariant, type NotificationVariant } from '../util.js'

export type PersistentNotificationProps = {
  variant: NotificationVariant
  dark?: boolean
  title?: string
  actionButton?: ActionButton
  showAvatar?: boolean
  children?: ReactNode
  className?: string
}

export const PersistentNotification = memo<PersistentNotificationProps>(function PersistentNotification(props) {
  const { variant, title, dark, actionButton, showAvatar = true, children, className } = props

  const colors = colorsPerVariant[variant][dark ? 'dark' : 'light']

  return (
    <CalloutNotificationTemplate
      {...colors}
      leftAvatar={showAvatar ? <Avatar icon="circle-info-solid" /> : undefined}
      leftAvatarSize="30"
      actionButton={actionButton}
      title={title}
      className={className}
      indented={true}
    >
      {children}
    </CalloutNotificationTemplate>
  )
})
