import styled from '@emotion/styled'
import { Fragment, memo, type ReactNode } from 'react'
import { colors } from '../../../colors.js'
import { spacing } from '../../../foundation/spacing-tokens.js'

export const DividedList = memo<{ children: ReactNode[]; margin?: string }>(function DividedList(props) {
  const { children, margin = spacing[30] } = props

  return (
    <>
      {children.map((child, index) => (
        <Fragment key={index}>
          {child}
          {index < children.length - 1 ? <Divider margin={margin} /> : <></>}
        </Fragment>
      ))}
    </>
  )
})

const Divider = styled.div<{ margin: string }>`
  width: 100%;
  height: 1px;

  margin: ${(props) => `${props.margin} 0 ${props.margin} 0`};

  background-color: ${colors.blue[200]};
`
