import { countriesSchema, type Countries } from '@orus.eu/countries'
import { success, type Result } from '@orus.eu/result'
import { AbstractDimension, dimensionValidationFailure, type DimensionValidationProblem } from './abstract-dimension.js'

export class CountryDimension<NAME extends string> extends AbstractDimension<NAME, Countries> {
  override validateData(value: unknown): Result<Countries, DimensionValidationProblem> {
    const parsedValue = countriesSchema.safeParse(value)
    if (parsedValue.success) {
      return success(parsedValue.data)
    }
    return dimensionValidationFailure(`Field ${this.name} is not a valid ISO-3166 country`)
  }
}
