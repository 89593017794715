import { AmountDimension } from '../amount-dimension.js'
import { CalendarDateDimension } from '../calendar-date-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'

export const rcdaOptionHistoryTakeoverYearsDimension = new StringsWithDataEnumDimension({
  name: 'rcdaOptionHistoryTakeoverYears',
  displayValues: { name: 'Années à reprendre' },
  entries: [
    ['6months', { label: '6 mois' }],
    ['1year', { label: '1 an' }],
    ['2years', { label: '2 ans' }],
    ['3years', { label: '3 ans' }],
    ['4years', { label: '4 ans' }],
    ['5years', { label: '5 ans' }],
    ['6years', { label: '6 ans' }],
    ['7years', { label: '7 ans' }],
    ['8years', { label: '8 ans' }],
    ['9years', { label: '9 ans' }],
    ['10years', { label: '10 ans' }],
  ],
  tags: ['RCDA'],
} as const)

export type RcdaOptionHistoryTakeoverYears = (typeof rcdaOptionHistoryTakeoverYearsDimension.values)[number]

export const rcdaOptionHistoryTakeoverYearsValues: string[] = rcdaOptionHistoryTakeoverYearsDimension.values as string[]

export const rcdaOptionHistoryTakeoverYearsLabels: Record<RcdaOptionHistoryTakeoverYears, string> = Object.fromEntries(
  rcdaOptionHistoryTakeoverYearsDimension.entries.map(([key, def]) => [key, def.label]),
) as Record<RcdaOptionHistoryTakeoverYears, string>

export const rcdaOptionHistoryTakeoverStartDateDimension = new CalendarDateDimension({
  name: 'rcdaOptionHistoryTakeoverStartDate',
  displayValues: { name: 'Date de début de la reprise du passé' },
  tags: ['RCDA'],
} as const)

export const rcdaOptionHistoryTakeoverPremiumDimension = new AmountDimension({
  name: 'rcdaOptionHistoryTakeoverPremium',
  displayValues: { name: 'Prime de reprise du passé' },
  tags: ['RCDA'],
} as const)
