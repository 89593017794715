import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { globalStateSlice } from './global-state-slice'
import { reset } from './reset-action'

export const globalStore = configureStore({
  reducer: createRootReducer(),
})

/**
 * Create the root reducer by combining all the loaded reducers.
 *
 * Also, this reducer handles the `reset` action, which resets the store to its initial state.
 *
 * @returns The combined reducers
 */
function createRootReducer() {
  // Our `loadedReducers` object is not fully typed, because we don't know which reducers are loaded.
  // This is a hack to prevent the compiler to realize that some slices are actually undefined.
  // It won't create any problem as long as we use the `ensureSliceLoaded` function in the files
  // where the slices are needed, and this problem would be very easy to detect.
  const rootReducer = combineReducers({
    globalState: globalStateSlice.reducer,
  })

  const resettableRootReducer: typeof rootReducer = (state, action) => {
    if (action.type === reset.toString()) {
      // In Redux, passing `undefined` as state returns the initial state
      return rootReducer(undefined, action)
    }

    return rootReducer(state, action)
  }

  return resettableRootReducer
}

export type GetState = typeof globalStore.getState
export type RootState = ReturnType<GetState>
export type AppDispatch = typeof globalStore.dispatch
