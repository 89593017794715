import { m } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type DimensionnedState,
  type LooselyTypedValue,
} from './abstract-dimension'
import type { rcdaSelectedDimension } from './rcda'

/**
 * Files names are sometimes in french given the context of the product and those documents are specific to french regulation
 * WARNING - Only add values to this list
 */
export const subscriptionDocumentTypes = [
  'id',
  'immatriculation',
  'infoReport',
  'proformaInvoice',
  'invoices',
  'RIB',
  'experience',
  'experiencePizzeria',
  'yearlyAccount',
  'decennaleCertificate',
  'other',
] as const

export const subscriptionDocumentValidationStatus = [
  'none',
  'pending',
  'received',
  'insuranceReview',
  'incomplete',
  'rejected',
  'validated',
] as const
export const subscriptionDocumentValidationStatusSchema = z.enum(subscriptionDocumentValidationStatus)
export type SubscriptionDocumentValidationStatus = z.infer<typeof subscriptionDocumentValidationStatusSchema>

export function isSubscriptionValidationStatus(value: string): value is SubscriptionDocumentValidationStatus {
  return subscriptionDocumentValidationStatusSchema.safeParse(value).success
}

export const subscriptionDocumentTypeSchema = z.enum(subscriptionDocumentTypes)
export type SubscriptionDocumentType = z.infer<typeof subscriptionDocumentTypeSchema>

export function isSubscriptionDocumentTypes(value: string): value is SubscriptionDocumentType {
  return subscriptionDocumentTypeSchema.safeParse(value).success
}

export const subscriptionDocumentFileSchema = z.object({
  name: z.string(),
  id: z.string(),
  mimeType: z.string(),
  creationTimestamp: z.number(),
  description: z.string().optional().nullable(),
})

export const subscriptionDocumentNoteSchema = z.object({
  notes: z.string(),
  noteTimestamp: z.number(),
})

export const subscriptionDocumentSchema = z.object({
  files: z.array(subscriptionDocumentFileSchema),
  status: subscriptionDocumentValidationStatusSchema,
  notes: subscriptionDocumentNoteSchema.optional(),
})

export type SubscriptionDocumentFile = z.infer<typeof subscriptionDocumentFileSchema>

export type SubscriptionDocumentNote = z.infer<typeof subscriptionDocumentNoteSchema>

export type SubscriptionDocument = z.infer<typeof subscriptionDocumentSchema>

export class SubscriptionDocumentDimension<NAME extends string> extends AbstractDimension<NAME, SubscriptionDocument> {
  override validateData(value: LooselyTypedValue): Result<SubscriptionDocument, DimensionValidationProblem> {
    const parseResult = subscriptionDocumentSchema.safeParse(value)

    if (!parseResult.success)
      return dimensionValidationFailure(
        `Field ${this.name} is not an SubscriptionDocument : ${parseResult.error.message}`,
      )

    return success(parseResult.data)
  }
}

export const idDocumentDimension = new SubscriptionDocumentDimension({
  name: 'idDocument',
  displayValues: { name: "Pièce d'identité" },
} as const)

export const immatriculationDocumentDimension = new SubscriptionDocumentDimension({
  name: 'immatriculationDocument',
  displayValues: { name: 'Immatriculation de votre entreprise' },
} as const)

export const infoReportDocumentDimension = new SubscriptionDocumentDimension({
  name: 'infoReportDocument',
  displayValues: { name: "Relevé d'information" },
} as const)

export const proformaInvoiceDocumentDimension = new SubscriptionDocumentDimension({
  name: 'proformaInvoiceDocument',
  displayValues: { name: 'Facture vierge' },
} as const)

export const invoicesDocumentDimension = new SubscriptionDocumentDimension({
  name: 'invoicesDocument',
  displayValues: { name: 'Factures justificatives' },
} as const)

export const yearlyAccountsDocumentDimension = new SubscriptionDocumentDimension({
  name: 'yearlyAccountsDocument',
  displayValues: { name: 'Comptes annuels' },
} as const)

export const experienceDocumentDimension = new SubscriptionDocumentDimension({
  name: 'experienceDocument',
  displayValues: { name: "Justificatif d'expérience" },
} as const)

export const experiencePizzeriaDocumentDimension = new SubscriptionDocumentDimension({
  name: 'experiencePizzeriaDocument',
  displayValues: { name: "Justificatif d'expérience (pizzeria)" },
} as const)

export const ribDocumentDimension = new SubscriptionDocumentDimension({
  name: 'ribDocument',
  displayValues: { name: 'RIB' },
} as const)

export const decennaleCertificateDocumentDimension = new SubscriptionDocumentDimension({
  name: 'decennaleCertificateDocument',
  displayValues: { name: 'Attestation décennale' },
} as const)

export const otherDocumentDimension = new SubscriptionDocumentDimension({
  name: 'otherDocument',
  displayValues: { name: 'Autre' },
} as const)

export const documentsDimensions = [
  idDocumentDimension,
  immatriculationDocumentDimension,
  infoReportDocumentDimension,
  proformaInvoiceDocumentDimension,
  invoicesDocumentDimension,
  yearlyAccountsDocumentDimension,
  experienceDocumentDimension,
  decennaleCertificateDocumentDimension,
  ribDocumentDimension,
  otherDocumentDimension,
  experiencePizzeriaDocumentDimension,
] as const
export type DocumentsState = DimensionnedState<typeof documentsDimensions>

export type SubscriptionDocumentDimensions = SubscriptionDocumentDimension<keyof DocumentsState>

export const subscriptionDocumentDimensionByType: Record<SubscriptionDocumentType, SubscriptionDocumentDimensions> = {
  id: idDocumentDimension,
  immatriculation: immatriculationDocumentDimension,
  infoReport: infoReportDocumentDimension,
  proformaInvoice: proformaInvoiceDocumentDimension,
  invoices: invoicesDocumentDimension,
  yearlyAccount: yearlyAccountsDocumentDimension,
  experience: experienceDocumentDimension,
  experiencePizzeria: experiencePizzeriaDocumentDimension,
  decennaleCertificate: decennaleCertificateDocumentDimension,
  RIB: ribDocumentDimension,
  other: otherDocumentDimension,
}

export const neededSubscriptionDocumentsSchema = z.array(subscriptionDocumentTypeSchema)

export type NeededSubscriptionDocuments = z.infer<typeof neededSubscriptionDocumentsSchema>

export class NeededSubscriptionDocumentsDimension<NAME extends string> extends AbstractDimension<
  NAME,
  NeededSubscriptionDocuments
> {
  override validateData(
    unvalidatedValue: LooselyTypedValue,
  ): Result<NeededSubscriptionDocuments, DimensionValidationProblem> {
    const parseResult = neededSubscriptionDocumentsSchema.safeParse(unvalidatedValue)
    if (parseResult.success) {
      return success(parseResult.data)
    }
    return dimensionValidationFailure(parseResult.error.message)
  }
}

export const neededSubscriptionDocumentsDimension = new NeededSubscriptionDocumentsDimension({
  name: 'neededSubscriptionDocuments',
  displayValues: { name: 'Pièces justificatives' },
} as const)

export const subscriptionReviewFinishedDocumentsStatusEnumValueSpecification = m.nullable(
  m.enum<SubscriptionDocumentValidationStatus>(
    {
      title: 'Document reviewed status',
    },
    {
      none: 'Default status',
      pending: 'En attente de reception de documents',
      received: 'De nouveaux documents sont à vérifier',
      insuranceReview: 'À faire vérifier par les souscripteurs décennale',
      incomplete: 'Des documents sont manquants',
      rejected: 'L’ensemble des pièces a été refusé',
      validated: 'Tous les documents sont conformes',
    },
  ),
)

export type SubscriptionReviewFinishedDocumentsStatusSpec = Record<
  SubscriptionDocumentType,
  typeof subscriptionReviewFinishedDocumentsStatusEnumValueSpecification
>

export const subscriptionReviewFinishedDocumentsNoteSpecification = m.nullable(
  m.string({ title: 'Note relative au document' }),
)

export type SubscriptionDocumentTypeNoteKey = SubscriptionDocumentType extends SubscriptionDocumentType
  ? `note_${SubscriptionDocumentType}`
  : never

export type SubscriptionReviewFinishedDocumentsNoteSpec = Record<
  SubscriptionDocumentTypeNoteKey,
  typeof subscriptionReviewFinishedDocumentsNoteSpecification
>

export const subscriptionAcceptedDocumentsDescription: Record<SubscriptionDocumentType, string[]> = {
  id: ['Carte d’identité, passeport, permis de conduire, carte de séjour.'],
  immatriculation: ['Extrait Kbis, Fiche INSEE, Fiche INPI.'],
  infoReport: ['Relevé d’informations, relevé de sinistralité (délivré par votre précédente assurance).'],
  proformaInvoice: ['Facture vierge de votre société.'],
  invoices: [
    'Factures clients.',
    'Pour les entreprises de moins de 6 mois les factures, devis vierge ou papier en-tête suffisent.',
  ],
  RIB: ['RIB.'],
  experience: [
    "Certificat de travail, bulletin de paie, attestation employeur : minimum 1 an d'expérience cumulé sur les 3 dernières années.",
  ],
  experiencePizzeria: ['Certificat de travail, bulletin de paie, attestation employeur'],
  yearlyAccount: ['Bilan comptable ou déclaration URSSAF.'],
  decennaleCertificate: ['Précédente attestation d’assurance décennale.'],
  other: ['Tout autre type de documents (exemple : Diplôme). '],
}

export const subscriptionEmailAcceptedDocumentsDescription: Record<SubscriptionDocumentType, string[]> = {
  id: ['Carte d’identité, passeport, permis de conduire, carte de séjour.'],
  immatriculation: ['Extrait Kbis, Fiche INSEE, Fiche INPI (téléchargeable sur https://data.inpi.fr/).'],
  infoReport: [
    "Le relevé d'information de votre assurance précédente (vous pouvez leur demander directement) indiquant votre historique de sinistralité.",
  ],
  proformaInvoice: ['Facture vierge de votre société.'],
  invoices: [
    'Factures clients.',
    'Pour les entreprises de moins de 6 mois les factures, devis vierge ou papier en-tête suffisent.',
  ],
  RIB: ['Pour les virements et/ou régler des factures par avis de prélèvement.'],
  experience: ['Certificat de travail, bulletin de paie, attestation employeur.'],
  experiencePizzeria: ['Certificat de travail, bulletin de paie, attestation employeur'],
  yearlyAccount: ['Bilan comptable ou déclaration URSSAF.'],
  decennaleCertificate: ['Précédente attestation d’assurance décennale.'],
  other: ['Tout autre type de documents (exemple : Diplôme). '],
}

export const subscriptionEmailAlternativeAcceptedDocumentsDescription: Partial<
  Record<SubscriptionDocumentType, string[]>
> = {
  experience: [
    "Au moins 5 factures réparties sur les 4 derniers trimestres faisant mention de l'activité que vous souhaitez souscrire.",
  ],
}

export const subscriptionDocumentsEmoji: Record<SubscriptionDocumentType, string> = {
  id: '🙋',
  immatriculation: '👔',
  infoReport: '📝',
  proformaInvoice: '🧾',
  invoices: '🧾',
  RIB: '💳',
  experience: '🎖️',
  experiencePizzeria: '🎖️',
  yearlyAccount: '📒',
  decennaleCertificate: '📄',
  other: '📁',
}

/**
 * Defines the email address communicated to clients for subscription document related questions
 */
export function getSubscriptionDocumentEmail(state: DimensionnedState<[typeof rcdaSelectedDimension]>): string {
  return state.rcdaSelected
    ? // rcda is a special case which has it's own email address
      'construction@orus.eu'
    : // all other products are handled through the default support email address
      'hello@orus.eu'
}
