import { ensureError } from '@orus.eu/error'
import { type ErrorComponentProps } from '@tanstack/react-router'
import { logger } from '../../lib/logger'
import { handleTrpcError } from '../../lib/trpc-handler'
import { CrashPage } from '../organisms/crash-page'

export function RootErrorBoundary(props: ErrorComponentProps): JSX.Element {
  const error = ensureError(props.error)

  const trpcErrorHandler = handleTrpcError(error)
  if (trpcErrorHandler) {
    return trpcErrorHandler
  }

  logger.error(error)
  console.log('setting logger error', error)
  return <CrashPage />
}
