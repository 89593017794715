import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { OTPInput, REGEXP_ONLY_DIGITS_AND_CHARS, type SlotProps } from 'input-otp'
import { memo, useCallback, useRef, useState } from 'react'
import { colorTokens, spacing } from '../../../foundation'
import { mobileMediaQuery } from '../../../hooks'

type State = 'neutral' | 'error' | 'loading'

export type OtpInputProps = {
  state: State
  /**
   * Called when the input value changes. At this point, the OTP can be incomplete.
   * You can use it to discard the `error` state for example.
   */
  onChange?: (otp: string) => void
  /**
   * Called when the OTP is complete.
   */
  onComplete: (otp: string) => void

  autoFocus?: boolean
  className?: string
}

export const OtpInput = memo<OtpInputProps>(function OtpInput({ state, onComplete, onChange, autoFocus, className }) {
  const [otp, setOtp] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleChange = useCallback(
    (value: string) => {
      setOtp(value.toUpperCase())
      onChange?.(value)
    },
    [onChange],
  )

  const handleComplete = useCallback(
    (otp: string) => {
      // We blur the input on complete because otherwise we might see the iOS cursor on the underlying
      // input even if the field and its caret are set to a transparent color.
      inputRef.current?.blur()
      onComplete(otp)
    },
    [onComplete, inputRef],
  )

  const reset = useCallback(() => {
    setOtp('')
    onChange?.('')
  }, [onChange])

  return (
    <OTPInput
      minLength={6}
      maxLength={6}
      ref={inputRef}
      autoComplete="one-time-code"
      onComplete={handleComplete}
      onChange={handleChange}
      onClick={reset}
      value={otp}
      disabled={state === 'loading'}
      inputMode="text"
      aria-label="Code de connexion"
      containerClassName={className}
      pushPasswordManagerStrategy="none"
      render={({ slots }) => {
        return (
          <Container>
            {slots.slice(0, 3).map((slot, index) => (
              <Slot key={index} slot={slot} state={state} />
            ))}

            <Dash />

            {slots.slice(3).map((slot, index) => (
              <Slot key={index} slot={slot} state={state} />
            ))}
          </Container>
        )
      }}
      pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
      autoFocus={autoFocus}
    />
  )
})

type SlotState = 'neutral' | 'active' | 'error' | 'loading'

const STYLE_PER_STATE: Record<
  SlotState,
  {
    border: string
    backgroundColor: string
    boxShadow: string
    textColor: string
  }
> = {
  neutral: {
    border: `1px solid ${colorTokens['color-stroke-base']}`,
    backgroundColor: colorTokens['color-bg-base-normal'],
    boxShadow: 'none',
    textColor: colorTokens['color-text-base-main'],
  },
  active: {
    border: `2px solid ${colorTokens['color-stroke-base-selected']}`,
    backgroundColor: colorTokens['color-bg-base-normal'],
    boxShadow: '0px 0px 0px 2px #dbdcfe',
    textColor: colorTokens['color-text-base-main'],
  },
  error: {
    border: `2px solid ${colorTokens['color-stroke-danger']}`,
    backgroundColor: colorTokens['color-bg-base-normal'],
    boxShadow: 'none',
    textColor: colorTokens['color-text-base-main'],
  },
  loading: {
    border: `1px solid ${colorTokens['color-stroke-base-disable']}`,
    backgroundColor: colorTokens['color-bg-base-disable'],
    boxShadow: 'none',
    textColor: colorTokens['color-text-base-disable'],
  },
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[30]};

  ${mobileMediaQuery} {
    gap: ${spacing[20]};
  }
`

const SlotContainer = styled.div<{ $state: SlotState }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 56px;
  height: 80px;

  font-size: 24px;
  font-weight: 500;
  text-align: center;

  ${({ $state }) => css`
    color: ${STYLE_PER_STATE[$state].textColor};
    border-radius: 8px;
    background-color: ${STYLE_PER_STATE[$state].backgroundColor};
    border: ${STYLE_PER_STATE[$state].border};
    box-shadow: ${STYLE_PER_STATE[$state].boxShadow};
  `}

  transition: all 0.2s ease;

  ${mobileMediaQuery} {
    width: 48px;
    height: 72px;

    font-size: 18px;
  }
`

const Slot = memo<{ slot: SlotProps; state: State }>(function Slot({ slot, state }) {
  const stateOverride = state !== 'neutral' ? state : undefined

  return (
    <SlotContainer $state={stateOverride ?? (slot.isActive ? 'active' : 'neutral')}>
      {slot.hasFakeCaret ? <Caret /> : slot.char}
    </SlotContainer>
  )
})

const Dash = styled.div`
  width: 16px;
  height: 1px;

  background-color: ${colorTokens['color-stroke-base']};
`

const Caret = styled.div`
  width: 2px;
  height: 24px;

  background-color: ${colorTokens['color-stroke-base-selected']};
  animation: blink 1.2s ease-out infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }

    20% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }
`
