import { nbsp } from '@orus.eu/char'
import { z } from 'zod'

export type Amount = {
  readonly __encodedAmount: number
}

export const amountZodSchema = z.object({
  __encodedAmount: z.number().int(),
})

export function isAmount(candidate: unknown): candidate is Amount {
  return (
    typeof candidate === 'object' &&
    candidate !== null &&
    '__encodedAmount' in candidate &&
    typeof candidate.__encodedAmount === 'number'
  )
}

export const defaultFinancialRateConfig: FinancialRateConfiguration = {
  max: 1,
  min: 0,
  step: 0.01,
}

/**
 * Configuration for a financial rate. Number are decimals 100% = 1, 0% = 0, 1% = 0.01.
 */
export type FinancialRateConfiguration = {
  readonly min: number
  readonly max: number
  readonly step: number
}

export type FinancialRate = {
  readonly __encodedRate: number
}

export const financialRateZodSchema = z.object({
  __encodedRate: z.number(),
})

export function isFinancialRateInRange({ __encodedRate }: FinancialRate, config: FinancialRateConfiguration): boolean {
  const min = Math.round(config.min * RATE_MULTIPLIER)
  const max = Math.round(config.max * RATE_MULTIPLIER)
  const step = Math.round(config.step * RATE_MULTIPLIER)
  return min <= __encodedRate && __encodedRate <= max && __encodedRate % step === 0
}

export function isFinancialRate(candidate: unknown): candidate is FinancialRate {
  return (
    typeof candidate === 'object' &&
    candidate !== null &&
    '__encodedRate' in candidate &&
    typeof candidate.__encodedRate === 'number'
  )
}

export class InvalidAmount extends Error {
  constructor(msg: string) {
    super(msg)
  }
}

export class NegativeAmount extends Error {
  constructor(msg: string) {
    super(msg)
  }
}

export class InvalidRate extends Error {
  constructor(msg: string) {
    super(msg)
  }
}

/**
 * Parse the provided value, assuming it's correct and returns the Amount. Throws InvalidAmount if the
 * string is incorrect.
 */
export function newAmount(rawAmountValue: string | number): Amount {
  const amount = parseAmount(rawAmountValue)
  if (amount === null) {
    throw new InvalidAmount(`Invalid amount string '${rawAmountValue}'`)
  }
  return amount
}

/**
 * Parse the provided value and returns an amount, or null if the value is incorrect.
 */
export function parseAmount(rawAmountValue: string | number): Amount | null {
  // If changed, must stay compatible with amountToString
  const amountValue =
    typeof rawAmountValue === 'number'
      ? rawAmountValue.toFixed(2)
      : rawAmountValue.replaceAll('€', '').replaceAll(',', '.').replaceAll(/\s/g, '') // remove all whitespaces

  if (!/^(\d+(\.\d{1,2})?)$/.test(amountValue)) {
    return null
  }
  const amount = parseFloat(amountValue)

  const centsValue = Math.round(amount * 100)
  if (!Number.isSafeInteger(centsValue)) return null
  return {
    __encodedAmount: centsValue,
  }
}

function separateThousands(amountString: string, separator: string) {
  return amountString.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&' + separator).replace('.', ',')
}

export type AmountToStringOptions = {
  displayDecimals?: boolean | 'when-amount-has-cents'
  addCurrency?: boolean
  separator?: string
}

export function amountToString(amount: Amount, options?: AmountToStringOptions): string {
  const {
    displayDecimals = true,
    addCurrency = false,
    separator = nbsp,
  } = options ?? { displayDecimals: true, addCurrency: false } // default values
  const { __encodedAmount } = amount
  const shouldDisplayDecimals =
    displayDecimals === 'when-amount-has-cents' ? amountToNumber(amount) % 1 !== 0 : displayDecimals
  // If changed, must stay compatible with newAmount
  const fractionDigits = shouldDisplayDecimals ? 2 : 0
  const withoutCurrency = separateThousands((__encodedAmount / 100).toFixed(fractionDigits), separator)
  return addCurrency ? withoutCurrency + nbsp + '€' : withoutCurrency
}

export function amountToNumber(amount: Amount): number {
  const { __encodedAmount } = amount

  return __encodedAmount / 100
}

/**
 * Use this method to convert amounts to number of cents to communicate with external APIs
 * @returns an integer number of cents.
 */
export function amountToCents(amount: Amount): number {
  const { __encodedAmount } = amount

  return __encodedAmount
}

export function financialRateToNumber(financialRate: FinancialRate): number {
  const { __encodedRate } = financialRate
  return __encodedRate / RATE_MULTIPLIER
}

export function subtractAmounts(amount1: Amount, amount2: Amount): Amount {
  return { __encodedAmount: amount1.__encodedAmount - amount2.__encodedAmount }
}

export function getAmountAbsoluteValue(amount: Amount): Amount {
  return { __encodedAmount: Math.abs(amount.__encodedAmount) }
}

export function addAmounts(...amounts: Amount[]): Amount {
  let sum = 0
  for (const amount of amounts) {
    sum += amount.__encodedAmount
  }
  return { __encodedAmount: sum }
}

/**
 * amount1 - amount2
 */
export function substractAmounts(amount1: Amount, amount2: Amount): Amount {
  return { __encodedAmount: amount1.__encodedAmount - amount2.__encodedAmount }
}

/**
 * amount1 - ordered amounts (amount[0] - amount[1] - amount[2]...)
 */
export function substractMultipleAmounts(amount1: Amount, ...amounts: Amount[]): Amount {
  if (amounts.length === 0) return amount1
  return amounts.reduce((acc, amount) => substractAmounts(acc, amount), amount1)
}

// Comparison functions

/**
 * amount1 === amount2
 */
export function areAmountsEqual(amount1: Amount, amount2: Amount): boolean {
  return amount1.__encodedAmount === amount2.__encodedAmount
}

/**
 * amount1 < amount2
 */
export function lt(amount1: Amount, amount2: Amount): boolean {
  return amount1.__encodedAmount < amount2.__encodedAmount
}

/**
 * amount1 <= amount2
 */
export function lte(amount1: Amount, amount2: Amount): boolean {
  return amount1.__encodedAmount <= amount2.__encodedAmount
}

/**
 * amount1 > amount2
 */
export function gt(amount1: Amount, amount2: Amount): boolean {
  return amount1.__encodedAmount > amount2.__encodedAmount
}

export function rateLt(amount1: FinancialRate, amount2: FinancialRate): boolean {
  return amount1.__encodedRate < amount2.__encodedRate
}

export function rateLte(amount1: FinancialRate, amount2: FinancialRate): boolean {
  return amount1.__encodedRate <= amount2.__encodedRate
}

export function rateGt(amount1: FinancialRate, amount2: FinancialRate): boolean {
  return amount1.__encodedRate > amount2.__encodedRate
}

export function rateGte(amount1: FinancialRate, amount2: FinancialRate): boolean {
  return amount1.__encodedRate >= amount2.__encodedRate
}
export function ratesEqual(amount1: FinancialRate, amount2: FinancialRate): boolean {
  return amount1.__encodedRate === amount2.__encodedRate
}
/**
 * amount1 >= amount2
 */
export function gte(amount1: Amount, amount2: Amount): boolean {
  return amount1.__encodedAmount >= amount2.__encodedAmount
}

export function newFinancialRate(rawRateValue: string | number): FinancialRate {
  const rate = parseFinancialRate(rawRateValue)
  if (rate == undefined) {
    throw new InvalidRate(`Invalid rate string '${rawRateValue}'`)
  }

  return rate
}

export function parseFinancialRate(rawRateValue: string | number): FinancialRate | undefined {
  const rateValue =
    typeof rawRateValue === 'number' ? rawRateValue.toFixed(6) : rawRateValue.replaceAll(',', '.').replaceAll(/\s/g, '') // remove all whitespaces

  if (!/^-?(\d+(\.\d{1,6})?)$/.test(rateValue)) {
    return undefined
  }
  const rate = parseFloat(rateValue)

  return { __encodedRate: Math.round(rate * RATE_MULTIPLIER) }
}

export function parseFinancialRateFromPercent(rawRateValue: string | number): FinancialRate | undefined {
  const percentValueAsRate = parseFinancialRate(rawRateValue)
  return percentValueAsRate == undefined ? undefined : multiplyRates(percentValueAsRate, oneHundredth)
}

export function rateToString(rate: FinancialRate, decimals: number): string {
  return (rate.__encodedRate / RATE_MULTIPLIER).toFixed(decimals).replace('.', ',')
}

export function rateToPercentString(rate: FinancialRate, decimals: number, includePercentSign = true): string {
  return ((100 * rate.__encodedRate) / RATE_MULTIPLIER).toFixed(decimals) + (includePercentSign ? nbsp + '%' : '')
}

export function rateToPercentNumber(rate: FinancialRate): number {
  return (100 * rate.__encodedRate) / RATE_MULTIPLIER
}

export function rateToNumber(rate: FinancialRate): number {
  return rate.__encodedRate / RATE_MULTIPLIER
}

export function multiplyRateByNumber(rate: FinancialRate, multiplier: number): FinancialRate {
  return { __encodedRate: rate.__encodedRate * multiplier }
}

export function multiplyRates(...rates: FinancialRate[]): FinancialRate {
  let result = RATE_MULTIPLIER
  for (const rate of rates) {
    if (typeof rate === 'undefined') {
      throw new Error('Undefined rate')
    }
    result = Math.round((result * rate.__encodedRate) / RATE_MULTIPLIER)
  }
  return { __encodedRate: result }
}

export function applyRateToAmount(amount: Amount, rate: FinancialRate): Amount {
  return {
    __encodedAmount: Math.round(amount.__encodedAmount * (1 + rate.__encodedRate / RATE_MULTIPLIER)),
  }
}

export function unapplyRateToAmount(amount: Amount, rate: FinancialRate): Amount {
  return {
    __encodedAmount: Math.round(amount.__encodedAmount / (1 + rate.__encodedRate / RATE_MULTIPLIER)),
  }
}

export function addRates(...rates: FinancialRate[]): FinancialRate {
  return {
    __encodedRate: rates.map((rate) => rate.__encodedRate).reduce((prev, curr) => prev + curr, 0),
  }
}

export function multiplyByRate(amount: Amount, rate: FinancialRate): Amount {
  return {
    __encodedAmount: Math.round((amount.__encodedAmount * rate.__encodedRate) / RATE_MULTIPLIER),
  }
}

export function multiplyByNumber(amount: Amount, factor: number): Amount {
  return { __encodedAmount: Math.round(amount.__encodedAmount * factor) }
}

export function makeAmountDivisibleByRate(amount: Amount, rate: FinancialRate): Amount {
  const remainder = remainderByRate(amount, rate)
  if (remainder.__encodedAmount === 0) return amount

  return {
    __encodedAmount: amount.__encodedAmount + rate.__encodedRate / RATE_MULTIPLIER - remainder.__encodedAmount,
  }
}

export function divideByRate(amount: Amount, rate: FinancialRate): Amount {
  return {
    __encodedAmount: Math.round((RATE_MULTIPLIER * amount.__encodedAmount) / rate.__encodedRate),
  }
}

export function divideByNumber(amount: Amount, denominator: number): Amount {
  return {
    __encodedAmount: Math.round(amount.__encodedAmount / denominator),
  }
}

export function remainderByRate(amount: Amount, rate: FinancialRate): Amount {
  return {
    __encodedAmount: ((RATE_MULTIPLIER * amount.__encodedAmount) % rate.__encodedRate) / RATE_MULTIPLIER,
  }
}

export function minAmount(firstAmount: Amount, ...amounts: Amount[]): Amount {
  return amounts.reduce((a, b) => (lt(a, b) ? a : b), firstAmount)
}

export function maxAmount(firstAmount: Amount, ...amounts: Amount[]): Amount {
  return amounts.reduce((a, b) => (gt(a, b) ? a : b), firstAmount)
}

export function computePercentageChange(finalValue: Amount, initialValue: Amount): FinancialRate {
  return {
    __encodedRate: Math.round((finalValue.__encodedAmount / initialValue.__encodedAmount - 1) * RATE_MULTIPLIER),
  }
}

/**
 * Limits an amout to be within a range
 * @param amount
 * @param [min, max] the range
 * @returns amount if the amount was in the range, or the closest bound otherwise
 */
export function clampAmount(amount: Amount, [min, max]: [Amount, Amount]): Amount {
  if (!lte(min, max)) {
    throw new RangeError('Invalid clamp attempt : min is not lte max')
  }
  if (lte(amount, min)) return min
  if (gte(amount, max)) return max
  return amount
}

export function isAmountMultipleOf(amount: Amount, divider: Amount): boolean {
  return amount.__encodedAmount % divider.__encodedAmount === 0
}

/**
 * Positive integer intended to be collected by Stripe
 */
export function getStripeAmount(amount: Amount): number {
  // Check is positive
  if (amount.__encodedAmount < 0) {
    throw new NegativeAmount(`the amount '${amount.__encodedAmount}' is negative`)
  }

  return amount.__encodedAmount
}

const RATE_MULTIPLIER = 1000000

// common amounts
export const zeroAmount = newAmount('0')

// common rates
export const oneHundredth = newFinancialRate('0.01')
export const X0 = newFinancialRate('0')
export const X1 = newFinancialRate('1')
export const XMinus1 = newFinancialRate('-1')
export const X12 = newFinancialRate('12')
export const fivePercent = newFinancialRate('0.05')
export const fifteenPercent = newFinancialRate('0.15')
export const twentyPercent = newFinancialRate('0.2')
export const thirtyPercent = newFinancialRate('0.3')
export const fiftyPercent = newFinancialRate('0.5')
export const eightyPercent = newFinancialRate('0.8')
