import { amountToString, gt, newAmount, zeroAmount } from '@orus.eu/amount'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { PartialDimensionnedState } from '../abstract-dimension.js'
import type { MrpwGuaranteesType, PolicyGuarantee } from '../quote-offer-information-dimension.js'
import { offersDescriptions } from '../shared-quote-offer-information.js'
import {
  mrpwAssetsValueDimension,
  mrpwAssetsValueFiftyPctDimension,
  mrpwAssetsValueTwentyPctDimension,
  mrpwMarchandiseConserveeDimension,
  mrpwOptionBoostDimension,
  mrpwRcpDimension,
  mrpwRevenueFifteenPctDimension,
} from './mrpw-dimensions.js'
import { firstMonthDeductiblePolicyDescription } from './wakam-util.js'

const twoHundredFifty = newAmount(250)
const twoHundredFiftyText = amountToString(twoHundredFifty, { addCurrency: true, displayDecimals: false })

const fiveMillion = newAmount('5000000')
const fiveMillionText = amountToString(fiveMillion, { addCurrency: true, displayDecimals: false })

export const mrpwGuaranteeDependencies = [
  mrpwAssetsValueDimension,
  mrpwAssetsValueTwentyPctDimension,
  mrpwOptionBoostDimension,
  mrpwAssetsValueFiftyPctDimension,
  mrpwMarchandiseConserveeDimension,
  mrpwRevenueFifteenPctDimension,
  mrpwRcpDimension,
] as const

export const mrpwGuaranteeDetails = {
  Incendie: {
    type: 'Incendie',
    name: 'Incendie',
    example: `Un incendie se déclare dans votre commerce. Vous et deux de vos employés tentez de contenir l’incendie avec vos extincteurs. Les pompiers arrivent quelques minutes après et parviennent à contrôler l’incendie.
      Les dégâts causés par l’incendie, par les fumées, par l’utilisation des extincteurs et par l’intervention des pompiers sont couverts, de même que la recharge des extincteurs.`,
    fullText: `Dommages causés à vos locaux et à votre mobilier et matériel par les incendies et leurs fumées, les explosions et les implosions, ou occasionnés par les secours.

- Cette garantie couvre également les chocs de véhicules à moteur.
- Cette garantie ne couvre pas les brûlures provoquées par certains appareils, comme les fers à repasser.`,
    priority: 'P1',
    shortText: `Nous couvrons les conséquences matérielles causées aux biens de l’assurés par l’incendie, la combustion, la chute de la foudre, les explosions, implosions, etc.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  DommagesElec: {
    type: 'DommagesElec',
    name: 'Dommages électriques',
    example: `La foudre touche votre local et la surtension en résultant abîme votre caisse enregistreuse.`,
    fullText: `Dommages causés par des courts-circuits, surtensions ou sous-tensions à vos installations et appareils électriques.

Exclusions principales : les dommages causés par l'usure ou un mauvais entretien.`,
    priority: 'P1',
    shortText: `Dommages causés par des courts-circuits, surtensions ou sous-tensions à vos installations et appareils électriques.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  DegatsEaux: {
    type: 'DegatsEaux',
    name: 'Dégâts des eaux',
    example: `Une canalisation d’eau s’est rompue dans votre magasin, occasionnant un débordement qui endommage votre parquet.`,
    fullText: `Dommages causés par des fuites et infiltrations d’eau à vos locaux et à votre mobilier et matériel.

Pour être couvert, pensez à interrompre la distribution d'eau en cas d'absence, à vidanger vos installations non chauffées pendant l'hiver, et à stocker vos marchandises à plus de 10 cm du sol.`,
    priority: 'P1',
    shortText: `Risque qui touche de nombreux commerces, nous remboursons jusqu’à 100 % du contenu de vos locaux en cas de fuites et d’infiltrations d’eau dans votre local.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  Vol: {
    type: 'Vol',
    name: 'Vol et tentative de vol',
    example: `La serrure de votre local est fracturée pendant la nuit et le cambrioleur s'empare de la tablette qui vous sert à gérer votre caisse.`,
    fullText: `Vol de vos biens mobiliers et détériorations de vos biens et de vos locaux lors d’une tentative de vol. Nous couvrons également le remplacement de vos clés et serrures ainsi que le vol de vos espèces, titres et valeurs.

- Pour être couvert, vous devez effectuer une déclaration de vol auprès de la police. Si vos biens sont retrouvés avant 30 jours, vous vous engagez à en reprendre possession et à rembourser vos indemnités perçues.
- Trois sinistres maximum par année d’assurance.`,
    priority: 'P1',
    shortText: `Vous êtes couvert en cas de vol de vos biens et détérioration de vos locaux lors d’un vol. Si nécessaire, nous remplaçons vos clés et serrures.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  Vandalisme: {
    type: 'Vandalisme',
    name: 'Vandalisme',
    example: `Du matériel et de l’outillage dans votre remise ont été endommagés suite à acte malveillant pendant la nuit.`,
    fullText: `Dommages et dégradations causées volontairement par un tiers au contenu de votre local, hors rayures, ébréchures, écaillures, tags et graffitis.

- Trois sinistres maximum par année d’assurance.`,
    priority: 'P1',
    shortText: `Nous couvrons les actes de vandalisme causés volontairement par un tiers à l’extérieur de vos locaux, sur votre bâtiment ou vos aménagements immobiliers extérieurs`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  EvenementsClim: {
    type: 'EvenementsClim',
    name: 'Événements climatiques',
    example: `Une tempête arrache une partie de la toiture de votre commerce et la pluie abîme votre mobilier et votre parquet.`,
    fullText: `Dommages causées par la tempête, grêle, neige, glace et les inondations.

Pour être couvert, pensez à vidanger vos installations non chauffées pendant l'hiver.`,
    priority: 'P1',
    shortText: `Nous vous protégeons en cas de dommages causés à votre local par un évènement climatique, comme par exemple par une tempête, la grêle, la neige, la glace et les inondations.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  CatastropheNat: {
    type: 'CatastropheNat',
    name: 'Catastrophes naturelles',
    example: `Un raz-de-marée submerge votre commerce situé sur la côte Atlantique. Le raz-de-marée déclenche un arrêté de catastrophe naturelle sur votre commune.`,
    fullText: `Vos biens immobiliers ou mobiliers sont endommagés par une catastrophe naturelle : tremblement de terre, raz-de marée, éruption volcanique…`,
    priority: 'P1',
    shortText: `Nous vous couvrons en cas de catastrophes naturelles (officiellement déclarées comme telles par un arrêté interministériel), comme par exemple lors de tremblements de terre ou encore de tempêtes qui endommagent vos locaux ou votre matériel.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  Attentat: {
    type: 'Attentat',
    name: 'Attentats',
    example: `La façade de votre atelier ainsi que son mobilier ont été endommagé par l’explosion d’une bombe artisanale.`,
    fullText: `Vos biens sont endommagés par un attentat ou acte de terrorisme.`,
    priority: 'P1',
    shortText: `Nous protégeons votre local et son contenu en cas de dommages causés par un attentat, des actes de terrorisme ainsi qu’en cas d’émeutes.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  MarchConservee: {
    type: 'MarchConservee',
    name: 'Marchandises conservées',
    example: `Votre chambre froide qui contient l’ensemble de votre marchandise périssable, s’est arrêtée accidentellement durant la nuit dernière, vous avez perdu tout le contenu stocké.`,
    fullText: `Dommages causées sur votre marchandises périssables, entreposées dans des installations sous température régulée comme les chambres froides, les réfrigérateurs, les congélateurs les vitrines réfrigérés.`,
    priority: 'P1',
    shortText: `Nous couvrons les dommages causés aux marchandises que vous conservez dans votre local en cas de sinistre.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  BrisMateriel: {
    type: 'BrisMateriel',
    name: 'Bris de matériel',
    example: `Une étagère murale contenant des éléments lourds (outillages...) se décroche et endommage votre matériel professionnel récemment installé juste en dessous.`,
    fullText: `Détériorations accidentelles de vos machines professionnelles, que vous en soyez propriétaire ou locataire.

- Cette garantie ne couvre pas les dommages esthétiques
- Cette garantie ne couvre pas les dommages occasionés par une utilisation ou maintenance non conforme du matériel`,
    priority: 'P1',
    shortText: `Nous vous dédommageons en cas de détériorations accidentelles de vos machines professionnelles, que vous en soyez propriétaire ou locataire.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  RespCivOccupant: {
    type: 'RespCivOccupant',
    name: 'Responsabilité locative',
    example: `En tant que locataire, un incendie se déclare dans votre local, gagne l’habitation de votre voisin et y cause des dommages. Votre propriétaire et votre voisin exercent alors un recours contre vous.`,
    fullText: `Dommages causés :

- Au propriétaire des locaux professionnels si vous êtes locataire,
- Aux voisins et aux tiers, y compris vos colocataires ou vos copropriétaires

Lorsque ces dommages et préjudices résultent d’un sinistre comme l’incendie, l’explosion ou le dégâts des eaux, survenu dans vos locaux.`,
    priority: 'P1',
    shortText: `Nous couvrons les réparations du local exigées par votre propriétaire en cas de sinistre.`,
    offer: offersDescriptions['assuranceDommagesBiens'],
  },
  publicLiability: {
    type: 'publicLiability',
    name: 'Responsabilité civile professionnelle',
    example: `Suite à une commande effectuée par l’un de vos client auprès de votre magasin, vous endommagez le bien confié lors d’une simple manipulation.`,
    fullText: `Les garanties Responsabilité Civile vous protègent lorsque vous causez un dommage à un tiers. Dommages causés directement par les prestations de service de votre entreprise.`,
    priority: 'P1',
    shortText: `Dommages causés à un tiers au cours de votre activité professionnelle (en mission, lors de commande, etc.). Comme, par exemple, si vous renversez par accident du café brûlant sur un client. Cela couvre donc aussi les intoxications alimentaires, pour les restaurateurs.`,
    offer: offersDescriptions['rcp'],
  },
} satisfies Record<MrpwGuaranteesType, Omit<PolicyGuarantee, 'guaranteeLimits'>>

export const mrpwGuaranteeFactory: {
  [key in MrpwGuaranteesType]: (
    state: PartialDimensionnedState<typeof mrpwGuaranteeDependencies>,
  ) => PolicyGuarantee | undefined
} = {
  Incendie: (state) => {
    return {
      ...mrpwGuaranteeDetails['Incendie'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: twoHundredFiftyText,
            description: 'cas général',
          },
          {
            deductible: amountToString(newAmount(500), { addCurrency: true, displayDecimals: false }),
            description: 'mobilier extérieur',
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond d’indemnisation du contenu de vos locaux',
          limit: amountToString(checkDefinedAndNotNull(state.mrpwAssetsValue), {
            addCurrency: true,
            displayDecimals: false,
          }),
          isGuaranteeFixed: false,
        },
        {
          name: 'Plafond d’indemnisation de vos locaux',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  DommagesElec: (state) => {
    const assetsRelative = checkDefinedAndNotNull(state.mrpwAssetsValueTwentyPct)
    const minimum = state.mrpwOptionBoost ? newAmount(20000) : zeroAmount
    const limit = gt(assetsRelative, minimum) ? assetsRelative : minimum

    return {
      ...mrpwGuaranteeDetails['DommagesElec'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: twoHundredFiftyText,
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond d’indemnisation du contenu de vos locaux',
          limit: amountToString(limit, { addCurrency: true, displayDecimals: false }),
          isGuaranteeFixed: false,
        },
        {
          name: 'Plafond d’indemnisation des aménagements intérieurs et extérieurs',
          description: '',
          limit: amountToString(newAmount('20000'), { addCurrency: true, displayDecimals: false }),
          isGuaranteeFixed: true,
        },
        {
          name: 'Plafond d’indemnisation de vos locaux',
          description: '',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  DegatsEaux: (state) => {
    return {
      ...mrpwGuaranteeDetails['DegatsEaux'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: twoHundredFiftyText,
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond d’indemnisation du contenu de vos locaux',
          limit: amountToString(checkDefinedAndNotNull(state.mrpwAssetsValue), {
            addCurrency: true,
            displayDecimals: false,
          }),
          isGuaranteeFixed: false,
        },
        {
          name: 'Plafond d’indemnisation de vos locaux',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  Vol: (state) => {
    return {
      ...mrpwGuaranteeDetails['Vol'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: twoHundredFiftyText,
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond d’indemnisation du contenu de vos locaux',
          limit: amountToString(checkDefinedAndNotNull(state.mrpwAssetsValueFiftyPct), {
            addCurrency: true,
            displayDecimals: false,
          }),
          isGuaranteeFixed: false,
        },
        {
          name: 'Plafond d’indemnisation de vos locaux',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  Vandalisme: (state) => {
    return {
      ...mrpwGuaranteeDetails['Vandalisme'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: 'De 250 € à 1 000 €',
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond d’indemnisation du contenu de vos locaux',
          limit: amountToString(checkDefinedAndNotNull(state.mrpwAssetsValueFiftyPct), {
            addCurrency: true,
            displayDecimals: false,
          }),
          isGuaranteeFixed: false,
        },
        {
          name: 'Plafond d’indemnisation de vos locaux',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  EvenementsClim: (state) => {
    return {
      ...mrpwGuaranteeDetails['EvenementsClim'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: twoHundredFiftyText,
            description: 'minimum',
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond d’indemnisation du contenu de vos locaux',
          limit: amountToString(checkDefinedAndNotNull(state.mrpwAssetsValue), {
            addCurrency: true,
            displayDecimals: false,
          }),
          isGuaranteeFixed: false,
        },
        {
          name: 'Plafond d’indemnisation de vos locaux',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  CatastropheNat: (state) => {
    return {
      ...mrpwGuaranteeDetails['CatastropheNat'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: '10% des dommages avec un minimum de 1 140 € et de 3 050 € pour la sécheresse-réhydratation',
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond d’indemnisation du contenu de vos locaux',
          limit: amountToString(checkDefinedAndNotNull(state.mrpwAssetsValue), {
            addCurrency: true,
            displayDecimals: false,
          }),
          isGuaranteeFixed: false,
        },
        {
          name: 'Plafond d’indemnisation de vos locaux',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  Attentat: (state) => {
    return {
      ...mrpwGuaranteeDetails['Attentat'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: twoHundredFiftyText,
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond d’indemnisation du contenu de vos locaux',
          limit: amountToString(checkDefinedAndNotNull(state.mrpwAssetsValue), {
            addCurrency: true,
            displayDecimals: false,
          }),
          isGuaranteeFixed: false,
        },
        {
          name: 'Plafond d’indemnisation de vos locaux',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  MarchConservee: (state) => {
    if (!state.mrpwMarchandiseConservee) return undefined

    return {
      ...mrpwGuaranteeDetails['MarchConservee'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: twoHundredFiftyText,
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond',
          limit: amountToString(checkDefinedAndNotNull(state.mrpwRevenueFifteenPct), {
            addCurrency: true,
            displayDecimals: false,
          }),
          isGuaranteeFixed: false,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  BrisMateriel: (state) => {
    const limit = newAmount(state.mrpwOptionBoost ? '5000' : '3000')
    return {
      ...mrpwGuaranteeDetails['BrisMateriel'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: twoHundredFiftyText,
          },
          firstMonthDeductiblePolicyDescription,
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond',
          limit: amountToString(limit, { addCurrency: true, displayDecimals: false }),
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  RespCivOccupant: () => {
    return {
      ...mrpwGuaranteeDetails['RespCivOccupant'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: '250 €',
            description: '',
          },
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond',
          limit: amountToString(newAmount('7000000'), { addCurrency: true, displayDecimals: false }),
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['assuranceDommagesBiens'],
    }
  },
  publicLiability: (state) => {
    if (!state.mrpwRcp) return undefined

    return {
      ...mrpwGuaranteeDetails['publicLiability'],
      deductibles: {
        name: 'Franchise',
        descriptions: [
          {
            deductible: `${twoHundredFiftyText} minimum`,
            description: 'cas général',
          },
          {
            // deductible: undefined,
            description: 'dommages corporels',
          },
        ],
      },
      guaranteeLimits: [
        {
          name: 'Plafond',
          limit: fiveMillionText,
          isGuaranteeFixed: true,
        },
      ],
      offer: offersDescriptions['rcp'],
    }
  },
}
