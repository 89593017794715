import { AbstractDimension } from '../abstract-dimension.js'

export type RcphCyberAdditionalOptions = {
  dommagesPe: boolean
  dommagesTiers: boolean
  fraudePiratage: boolean
  defaillanceFournisseur: boolean
}

export type RcphCyberConfiguration = { selected: false } | { selected: true; options: RcphCyberAdditionalOptions }

export class RcphCyberConfigurationDimension<NAME extends string> extends AbstractDimension<
  NAME,
  RcphCyberConfiguration
> {}

export const rcphCyberConfigurationDimension = new RcphCyberConfigurationDimension({
  name: 'rcphCyberConfiguration',
  displayValues: { name: 'Configuration Cyber' },
  tags: ['RCPH'],
} as const)
