import { success, type Result } from '@orus.eu/result'
import { dimensionValidationFailure, type DimensionValidationProblem } from './abstract-dimension.js'
import { AbstractNumberDimension } from './abstract-number-dimension.js'

export class IntegerDimension<NAME extends string> extends AbstractNumberDimension<NAME> {
  validateNumber(value: number): Result<number, DimensionValidationProblem> {
    return Number.isSafeInteger(value)
      ? success(value)
      : dimensionValidationFailure(`Field ${this.name} is not a safe integer`)
  }
}
