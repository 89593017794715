import type { OperatingZone } from '@orus.eu/operating-zone'
import { z } from 'zod'
import { esRcphSelectedDimension } from './dimension/es-rcph/index.js'
import { mrphSelectedDimension } from './dimension/mrph/index.js'
import { mrpwSelectedDimension } from './dimension/mrpw/index.js'
import { mutaSelectedDimension } from './dimension/muta/index.js'
import type { ProductInformationId } from './dimension/quote-offer-information-dimension.js'
import { rcdaSelectedDimension } from './dimension/rcda/index.js'
import { rcphOptionCyberDimension, rcphOptionTdmiDimension, rcphSelectedDimension } from './dimension/rcph/index.js'
/**
 * The product is the biggest high level blocks the user (or sales) choses between. They are the only
 * thing that can be sold standalone.
 * - When customizing the subscription, the sales people first chose one or more “products”
 * - The “products” are the highest level of customization and drive :
 *     - Which risk carriers (and which partnerships with each of the risk carriers) will be used
 *     - What additional data we need
 * - The “products” can be sold standalone. If it doesn’t make sense to send something alone, it’s not a product, but an option of another product.
 * - Each product can have it’s options which triggers additional questions, at any level of depth, but the highest level deserves a special attention because it frames the discussion between Orus and the customer.
 *
 * For now we have 3 products :
 *
 * - **rcph** for “RC Pro Hiscox” (TECH, MAC, MAN, etc.)
 * - **mrph** for “MRP office Hiscox”
 * - **mrpw** for “MRP Wakam” (doesn’t include restaurants for now, as they can only be subscribed with the restaurants policy, but it could in the future if we decide so)
 * - no values are defined for legacy policies as of May 23, 2023
 *
 * See https://www.notion.so/orus-team/Technical-concepts-as-of-00ffc0d93c924f21b894a0a8407ed801 for more details
 */
export type Product = (typeof allProducts)[number]
export const allProducts = ['mrph', 'mrpw', 'rcph', 'muta', 'rcda', 'es-rcph'] as const
export const productSelectionDimensions = [
  mrphSelectedDimension,
  mrpwSelectedDimension,
  rcphSelectedDimension,
  mutaSelectedDimension,
  rcdaSelectedDimension,
  esRcphSelectedDimension,
] as const

export const displayNameByProduct: Record<Product, string> = {
  mrph: 'Multirisque local',
  mrpw: 'Multirisque',
  rcph: 'Responsabilité Civile Professionnelle',
  muta: 'Mutuelle',
  rcda: 'Responsabilité Civile Décennale',
  'es-rcph': 'Responsabilidad Civil Profesional',
}

export const productZodSchema = z.enum(allProducts)

export const activableProductInformationIdSelectionDimensions = [
  mrphSelectedDimension,
  mrpwSelectedDimension,
  rcphSelectedDimension,
  mutaSelectedDimension,
  rcdaSelectedDimension,
  rcphOptionCyberDimension,
  rcphOptionTdmiDimension,
  esRcphSelectedDimension,
] as const

export const productInformationIdToProduct: Record<ProductInformationId, Product> = {
  mrph: 'mrph',
  mrpw: 'mrpw',
  muta: 'muta',
  'rc-pro': 'rcph',
  'rcda-rc-pro': 'rcda',
  rcda: 'rcda',
  'rcph-cyber': 'rcph',
  'rcph-tdmi': 'rcph',
  'rcph-pj': 'rcph',
  restaurant: 'mrpw',
  'es-rcph': 'es-rcph',
}

/**
 * Defines which product is available in which operating zone.
 */
export const productOperatingZone: Record<Product, OperatingZone> = {
  mrph: 'fr',
  mrpw: 'fr',
  rcph: 'fr',
  muta: 'fr',
  rcda: 'fr',
  'es-rcph': 'es',
}
