import { newAmount, type Amount } from '@orus.eu/amount'
import type { TypeOfDimension } from '../abstract-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'

export type ActivityDomainData = {
  readonly lci: Amount
}

export const mrphActivityDomainDimension = new StringsWithDataEnumDimension({
  name: 'mrphActivityDomain',
  displayValues: { name: "Secteur d'activité" },
  entries: [
    ['ACTIVITES_BUREAU', { lci: newAmount('4 750 000') }],
    ['SERVICES_PERSONNE', { lci: newAmount('3 175 000') }],
    ['HYGIENE_SANTE_BEAUTE', { lci: newAmount('3 900 000') }],
    ['EVENEMENTIEL_AUDIOVISUEL', { lci: newAmount('3 175 000') }],
    ['ARTISANAT', { lci: newAmount('3 175 000') }],
  ],
  tags: ['MRPH'],
} as const)

export type MrphActivityDomain = TypeOfDimension<typeof mrphActivityDomainDimension>
