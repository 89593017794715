import { Global, css } from '@emotion/react'
import { TechnicalError } from '@orus.eu/error'
import { useState, type FC, type ReactNode } from 'react'

import palette from './color-palette.json'
import { colorTokens } from './color-tokens.json'
import { SkinContextProvider } from './pharaoh-theme-context'

type SkinName = keyof typeof colorTokens
const buildStyles = (skin: SkinName) => {
  if (!(skin in colorTokens)) {
    throw new TechnicalError(`Unknown Pharaoh Theme skin: ${skin}`)
  }

  const styles = {
    ...Object.entries(palette).reduce((styles, [key, value]) => ({ ...styles, [`--${key}`]: value }), {}),
    ...Object.entries(colorTokens[skin]).reduce((styles, [key, value]) => ({ ...styles, [`--${key}`]: value }), {}),
  }

  return css({
    ':root': styles,
  })
}

export const PharaohThemeProvider: FC<{
  children: ReactNode
  skin?: SkinName
}> = ({ skin = 'light', children }) => {
  const [currentSkin, setSkin] = useState<SkinName | null>(null)
  const styles = buildStyles(currentSkin ?? skin)

  return (
    <SkinContextProvider value={{ skin: currentSkin, setSkin }}>
      <Global styles={styles} />
      {children}
    </SkinContextProvider>
  )
}
