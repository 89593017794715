import { createContext, useContext, type RefObject } from 'react'

import { type DocViewerRef, type IDocument } from '@cyntler/react-doc-viewer'

type DocViewerContextType = {
  /** ref for the DocViewer component */
  docViewerRef: React.RefObject<DocViewerRef> | null
  /** ref for the image element */
  imageRef: RefObject<HTMLImageElement> | null
  /** ref for the div container */
  containerRef: RefObject<HTMLDivElement> | null
  /** The currently active document */
  activeDocument: IDocument | undefined
  /** Sets the active document */
  setActiveDocument: (document: IDocument) => void
  /** API for manipulating the document viewer */
  rotateClockwise: () => void
  rotateCounterClockwise: () => void
  downloadDocument: () => void
  nextDocument: () => void
  previousDocument: () => void
  getScaleToFit: () => number | undefined
}

// create a react context for the docViewerRef
export const DocViewerContext = createContext<DocViewerContextType>({
  docViewerRef: null,
  imageRef: null,
  containerRef: null,
  activeDocument: undefined,
  setActiveDocument: () => {},
  rotateClockwise: () => {},
  rotateCounterClockwise: () => {},
  downloadDocument: () => {},
  nextDocument: () => {},
  previousDocument: () => {},
  getScaleToFit: () => undefined,
})

export const useDocumentViewer = (): DocViewerContextType => useContext(DocViewerContext)
