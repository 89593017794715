import { css } from '@emotion/react'
import { memo } from 'react'

import { spacing } from '../../../foundation/spacing-tokens.js'
import { secondaryColor } from '../../../theme.js'
import { Avatar, Text } from '../../atoms/index.js'

type RowWithLinkProps = {
  text: string
  href: string
}

export const RowWithLink = memo<RowWithLinkProps>(function RowWithLink(props) {
  const { text, href } = props

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        padding: ${spacing[50]};
        cursor: pointer;
      `}
    >
      <a
        href={href}
        css={css`
          text-decoration: none;
          color: black;

          &:hover {
            color: ${secondaryColor};
          }
        `}
      >
        <Text variant="body2">{text}</Text>
      </a>
      <Avatar icon="angle-right-regular" size="30" />
    </div>
  )
})
