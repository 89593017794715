import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'
import { colors } from '../../../colors.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { Text } from '../../atoms/index.js'
import { SummaryTable } from '../../atoms/summary-table.js'

export type Feature = { title: string; content: ReactNode }

type FeaturesProps = {
  features: Feature[]
}

export const Features = memo<FeaturesProps>(function Features(props) {
  const { features } = props

  return (
    <SummaryTable>
      {features.map((feature) => (
        <div
          key={feature.title}
          css={css`
            display: flex;
            flex-direction: column;

            padding: ${spacing[50]} 0 ${spacing[50]} 0;
          `}
        >
          <Text variant="body2Medium">{feature.title}</Text>
          <Text variant="body2" color={colors.gray[700]}>
            {feature.content}
          </Text>
        </div>
      ))}
    </SummaryTable>
  )
})
