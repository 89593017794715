import { financialRateZodSchema, newFinancialRate, ratesEqual, type FinancialRate } from '@orus.eu/amount'
import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  getParmeterlessKeyTranslations,
  type DimensionValidationProblem,
} from './abstract-dimension'

export const installmentFeeRateCodes = ['discounted', 'standard'] as const

export type InstallmentFeeRateCode = (typeof installmentFeeRateCodes)[number]
export const installmentFeeRateCodeSchema = z.enum(installmentFeeRateCodes)

export const installmentFeeRateZodSchema = z.object({
  code: installmentFeeRateCodeSchema,
  rate: financialRateZodSchema,
})

export const INSTALLMENT_FEES_RATE_DISCOUNTED = newFinancialRate('0.05')
export const INSTALLMENT_FEES_RATE_STANDARD = newFinancialRate('0.1')

export const installmentFeeRate: Record<InstallmentFeeRateCode, { rate: FinancialRate }> = {
  discounted: { rate: INSTALLMENT_FEES_RATE_DISCOUNTED },
  standard: { rate: INSTALLMENT_FEES_RATE_STANDARD },
}

export const installmentFeeLabels: { [key in InstallmentFeeRateCode]: string } = {
  discounted: 'Réduits + 5%',
  standard: 'Standards + 10%',
}

export const installmentFeeRateValues = Object.keys(installmentFeeRate) as string[]

export type InstallmentFeeRates = z.infer<typeof installmentFeeRateZodSchema>

export const allInstallmentFeeRate: readonly InstallmentFeeRates[] = Object.entries(installmentFeeRate).map(
  ([code, def]) => ({
    code,
    rate: def.rate,
  }),
) as readonly InstallmentFeeRates[]

export function getInstallmentFeeRateByCode(code: string): InstallmentFeeRates | undefined {
  return allInstallmentFeeRate.find((installmentFeeRate) => installmentFeeRate.code === code) ?? undefined
}

export class InstallmentFeeRateDimension<NAME extends string> extends AbstractDimension<
  NAME,
  InstallmentFeeRates | 'none'
> {
  override readonly placeholders = getParmeterlessKeyTranslations('placeholder_no_fee')

  override validateData(value: unknown): Result<InstallmentFeeRates | 'none', DimensionValidationProblem> {
    if (value === 'none') return success('none')
    const parsedResult = installmentFeeRateZodSchema.safeParse(value)

    if (!parsedResult.success)
      return dimensionValidationFailure(`Field ${this.name} is not a valid installment fees rate`)

    const installmentFeeRateResult = parsedResult.data

    const { code, rate } = installmentFeeRateResult

    const installmentFeeRateDefinition = installmentFeeRate[code as InstallmentFeeRateCode]

    if (!installmentFeeRateDefinition || !ratesEqual(installmentFeeRateDefinition.rate, rate))
      return dimensionValidationFailure(`Field ${this.name} is not a valid discount`)

    return success(installmentFeeRateResult)
  }
}

export const installmentFeeRateDimension = new InstallmentFeeRateDimension({
  name: 'installmentFeeRate',
  displayValues: { name: 'Frais de fractionnement' },
} as const)
