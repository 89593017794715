export async function downloadFromOrus(link: string, acceptHeader?: string): Promise<Blob> {
  const headers = new Headers({
    Accept: acceptHeader ?? 'application/octet-stream',
  })

  const response = await fetch(link, { headers })

  return response.blob()
}

export async function downloadFile(link: string, fileName: string, acceptHeader?: string): Promise<void> {
  console.info(link, fileName, acceptHeader)
  const blob = await downloadFromOrus(link, acceptHeader)
  await downloadFileFromBlob(blob, fileName)
}

export async function downloadFileFromBuffer(data: Buffer, fileName: string): Promise<void> {
  const blob = new Blob([data])
  await downloadFileFromBlob(blob, fileName)
}

export async function downloadFileFromBlob(blob: Blob, fileName: string): Promise<void> {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
}
