import { type TranslationKey } from '@orus.eu/translations'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Text, type TextProps } from '../components/atoms/text/text.js'

export type LocalizedTextProps = Omit<TextProps, 'children'> & { keyId: TranslationKey }

/**
 * Works as the Text component but with the keyId of a translation key instead of the children.
 */
export const LocalizedText = memo<LocalizedTextProps>(function LocalizedText(props) {
  const { keyId, ...otherProps } = props
  const text = <FormattedMessage id={keyId as string /* key is a string enum so this cast should not be necessary*/} />
  return <Text {...otherProps}>{text}</Text>
})
