import { financialRateZodSchema, newFinancialRate, ratesEqual, type FinancialRate } from '@orus.eu/amount'
import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  getParmeterlessKeyTranslations,
  type DimensionValidationProblem,
} from './abstract-dimension.js'

export const allDiscountCodes = ['partner-disc221220', 'exceptional-20'] as const
export type DiscountCode = (typeof allDiscountCodes)[number]
export const discountCodeZodSchema = z.enum(allDiscountCodes)

export function isDiscountCode(value: string): value is DiscountCode {
  return discountCodeZodSchema.safeParse(value).success
}

export const discountDefinitionPerCode: Record<DiscountCode, { rate: FinancialRate; available: boolean }> = {
  'partner-disc221220': { rate: newFinancialRate('0.1'), available: true },
  'exceptional-20': { rate: newFinancialRate('0.2'), available: false },
}

export const discountZodSchema = z.object({
  code: discountCodeZodSchema,
  rate: financialRateZodSchema,
})

export type OrusDiscount = z.infer<typeof discountZodSchema>

export function getDiscount(discount?: OrusDiscount | 'none' | null): OrusDiscount | undefined {
  return discount === 'none' ? undefined : (discount ?? undefined)
}

export function getDiscountRate(discount?: OrusDiscount | 'none' | null): FinancialRate | undefined {
  return !discount || discount === 'none' ? undefined : discount.rate
}

export function getDiscountCode(discount?: OrusDiscount | 'none' | null): DiscountCode | undefined {
  return !discount || discount === 'none' ? undefined : discount.code
}

export const allDiscounts: readonly OrusDiscount[] = Object.entries(discountDefinitionPerCode).map(([code, def]) => ({
  code,
  rate: def.rate,
})) as readonly OrusDiscount[]

export const discountCodes = allDiscounts.map((discount) => discount.code)
export const discountCodeLabels: { [key in DiscountCode]: string } = {
  'partner-disc221220': 'Discount (approx. -10%)',
  'exceptional-20': 'Exceptionnel 20%',
}

export function getDiscountByCode(code: string): OrusDiscount | undefined {
  return allDiscounts.find((discount) => discount.code === code) ?? undefined
}

export class DiscountDimension<NAME extends string> extends AbstractDimension<NAME, OrusDiscount | 'none'> {
  override readonly placeholders = getParmeterlessKeyTranslations('placeholder_no_discount')

  override validateData(value: unknown): Result<OrusDiscount | 'none', DimensionValidationProblem> {
    if (value === 'none') return success('none')

    const orusDiscountResult = discountZodSchema.safeParse(value)

    if (!orusDiscountResult.success) return dimensionValidationFailure(`Field ${this.name} is not a valid discount`)

    const orusDiscount = orusDiscountResult.data
    const { code, rate } = orusDiscount

    const discountDefinition = discountDefinitionPerCode[code as DiscountCode]

    if (!discountDefinition || !ratesEqual(discountDefinition.rate, rate))
      return dimensionValidationFailure(`Field ${this.name} is not a valid discount`)

    return success(orusDiscount)
  }
}

export const discountDimension = new DiscountDimension({
  name: 'discount',
  displayValues: { name: 'Discount' },
} as const)
