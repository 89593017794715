import { isActivity, type ActivityInput } from '@orus.eu/activity'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class ActivityDimension<NAME extends string> extends AbstractDimension<NAME, ActivityInput> {
  override validateData(value: LooselyTypedValue): Result<ActivityInput, DimensionValidationProblem> {
    if (typeof value !== 'object' || value === null)
      return dimensionValidationFailure(`Field ${this.name} is not an ActivityInput`)
    if (!('activity' in value))
      return dimensionValidationFailure(
        `Field ${this.name} is not an ActivityInput because property "activity" is missing`,
      )
    if (!('displayName' in value))
      return dimensionValidationFailure(
        `Field ${this.name} is not an ActivityInput because property "displayName" is missing`,
      )
    const { activity, displayName } = value
    if (typeof activity !== 'string' || !isActivity(activity)) {
      return dimensionValidationFailure(
        `Field ${this.name} is not an ActivityInput because property "activity" is not an Activity`,
      )
    }
    if (typeof displayName !== 'string') {
      return dimensionValidationFailure(
        `Field ${this.name} is not an ActivityInput because property "displayName" is not a string`,
      )
    }
    return success({ activity, displayName })
  }
}
