import { newAmount } from '@orus.eu/amount'
import { formatRoundedAmountDimensionValue } from '../amount-dimension.js'
import { NumbersEnumDimension } from '../numbers-enum-dimension.js'

export const RCDA_DEDUCTIBLES = [500, 1_000] as const
export type RcdaDeductible = (typeof RCDA_DEDUCTIBLES)[number]
export function isRcdaDeductible(value: unknown): value is RcdaDeductible {
  return typeof value === 'number' && (RCDA_DEDUCTIBLES as readonly number[]).includes(value)
}

export const rcdaDeductibleDimension = new NumbersEnumDimension({
  name: 'rcdaDeductible',
  displayValues: { name: 'Franchise' },
  values: RCDA_DEDUCTIBLES,
  tags: ['RCDA'],
  formatter: (value) => formatRoundedAmountDimensionValue(newAmount(value.toFixed())),
} as const)
