import { translate, type Language, type LocalizableMessage, type TranslationKey } from '@orus.eu/translations'
import { AbstractDimension } from './abstract-dimension.js'

/**
 * @deprecated use ExclusionV2 instead
 * ExclusionV1 is deprecated because it stores translated text in the DB and hence can never
 * be translated.
 */
export type ExclusionV1 = {
  name: string
  description: string
  /**
   * This field is added to prevent manually exclusions creation. Instead, use the newExclusion factory
   * function to enforce centralization of exclusion definitions, so we can document them in the backoffice
   */
  __brand: string
}

/**
 * Describes an exclusion, with translation support
 */
export type ExclusionV2 = {
  /**
   * Category of exclusion, meant to internally discriminate between different types of exclusions
   */
  name: string
  /**
   * Localizable message to explain the exclusion to the end user
   */
  message: LocalizableMessage<TranslationKey>
  /**
   * @deprecated for backward compatibility with client, can be removed after 24h in prod
   */
  description: string
}

export function getExclusionDescription(exclusion: Exclusion, language: Language): string {
  if ('message' in exclusion) {
    return translate(exclusion.message, language)
  }
  return exclusion.description
}

export type Exclusion = ExclusionV1 | ExclusionV2

export class ExclusionDimension<NAME extends string> extends AbstractDimension<NAME, readonly Exclusion[]> {}
