import type { Result } from '@orus.eu/result'
import { AbstractDimension } from '../abstract-dimension.js'
import type { TemporaryWakamProblem } from './wakam-util.js'

export type WakamAllowedCityCodesResult = Result<string[], TemporaryWakamProblem>

export class MrpwWakamAllowedCityCodesDimension<NAME extends string> extends AbstractDimension<
  NAME,
  WakamAllowedCityCodesResult
> {}
