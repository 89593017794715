import { createContext, useContext } from 'react'
import { defaultSkin } from './default-skin/index.js'
import type { Skin } from './skin.js'

export type SetSkin = (skin: Skin | null) => void

const skinContext = createContext<{ setSkin: SetSkin; skin: Skin | null }>({
  skin: null,
  setSkin: () => {},
})
export const SkinContextProvider = skinContext.Provider

export function useSetSkin(): SetSkin {
  return useContext(skinContext).setSkin
}

export function useSkin(): Skin {
  return useContext(skinContext).skin ?? defaultSkin
}
