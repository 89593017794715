import { AmountDimension } from '../amount-dimension.js'
import { BooleanDimension } from '../boolean-dimension.js'
import { ExclusionDimension } from '../exclusion-dimension.js'

export const mutaSelectedDimension = new BooleanDimension({
  name: 'mutaSelected',
  displayValues: { name: 'Mutuelle TNS' },
  tags: ['MUTA'],
} as const)

export const mutaYearlyBasePremiumDimension = new AmountDimension({
  name: 'mutaYearlyBasePremium',
  displayValues: { name: 'Cotisation annuelle HT' },
  tags: ['MUTA'],
} as const)

export const mutaYearlyTaxesDimension = new AmountDimension({
  name: 'mutaYearlyTaxes',
  displayValues: { name: 'Taxes annuelles' },
  tags: ['MUTA'],
} as const)

export const mutaYearlyTotalPremiumDimension = new AmountDimension({
  name: 'mutaYearlyTotalPremium',
  displayValues: { name: 'Cotisation annuelle TTC' },
  tags: ['MUTA'],
} as const)

export const mutaYearlyTotalAssistancePremiumDimension = new AmountDimension({
  name: 'mutaYearlyTotalAssistancePremium',
  displayValues: { name: "Cotisation annuelle TTC pour l'assistance" },
  tags: ['MUTA'],
} as const)

export const mutaYearlyTotalNetworkPremiumDimension = new AmountDimension({
  name: 'mutaYearlyTotalNetworkPremium',
  displayValues: { name: 'Cotisation annuelle TTC pour les réseaux de soin' },
  tags: ['MUTA'],
} as const)

export const mutaYearlyTotalAssociationPremiumDimension = new AmountDimension({
  name: 'mutaYearlyTotalAssociationPremium',
  displayValues: { name: "Cotisation annuelle TTC pour l'association" },
  tags: ['MUTA'],
} as const)

export const mutaExclusionsDimension = new ExclusionDimension({
  name: 'mutaExclusions',
  displayValues: { name: 'Exclusions spécifiques Mutuelle Acheel' },
  tags: ['MUTA'],
})
