import styled from '@emotion/styled'
import { Button, FlexColumn, LegacyDialog, Text, spacing, useEnqueueTemporaryNotificationAlert } from '@orus.eu/pharaoh'
import { memo, useEffect, useMemo, useRef, type ReactNode } from 'react'
import { useApplicationUpdate } from './lib/hooks/use-application-update'

export const UpdateHandler = memo<{ children: ReactNode }>(function UpdateHandler({ children }) {
  const { updateState, update } = useApplicationUpdate()
  const { enqueueTemporaryNotificationAlert } = useEnqueueTemporaryNotificationAlert()

  const closeUpdateToastRef = useRef<() => void | undefined>()

  const updateButton = useMemo(
    () => (
      <Button
        onClick={() => {
          update()
          closeUpdateToastRef.current?.()
        }}
        variant="secondary"
      >
        Mettre à jour
      </Button>
    ),
    [update],
  )

  useEffect(() => {
    if (updateState === 'available') {
      closeUpdateToastRef.current = enqueueTemporaryNotificationAlert(
        'Une nouvelle version de l’application est disponible',
        {
          variant: 'info',
          extra: updateButton,
        },
      )
    }
  }, [updateState, update, enqueueTemporaryNotificationAlert, updateButton])

  if (updateState === 'force') {
    return (
      <LegacyDialog
        maxWidth="sm"
        fullWidth
        title="Mise à jour de l’application"
        style="problem"
        hideCloseButton
        onClose={() => {}}
        submitLabel="Mettre à jour"
        onSubmit={update}
      >
        <Container>
          <Text variant="body1">L’application doit être mise à jour pour continuer à fonctionner correctement.</Text>
        </Container>
      </LegacyDialog>
    )
  }

  return children
})

const Container = styled(FlexColumn)`
  padding-top: ${spacing[60]};
`
