import type { Amount, FinancialRate } from '@orus.eu/amount'

import { type RcphQuoteV2 } from '@orus.eu/quote'
import { AbstractDimension } from '../abstract-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'

export const rcProCyberOptionalGuaranteeNames = [
  'cyberDommagesPe',
  'cyberDefaillanceFournisseur',
  'cyberDommagesTiers',
  'cyberFraudePiratage',
] as const
export type RcProCyberOptionalGuaranteeType = (typeof rcProCyberOptionalGuaranteeNames)[number]

export const policyOptionalGuaranteesChildrenNames = [
  'mrpSmallOfficeIncendies',
  'mrpSmallOfficeDegatsDesEaux',
  'mrpSmallOfficeVol',
  'mrpSmallOfficeVandalisme',
  'mrpSmallOfficeEvenementsClimatiques',
  'mrpSmallOfficeBrisDeGlace',
  'mrpSmallOfficeCatastrophesNaturelles',
  'mrpSmallOfficeAttentats',
  'mrpSmallOfficeOccupant',
  'mrpSmallOfficeAssistance',
] as const
export type PolicyOptionalGuaranteesChildrenNames = (typeof policyOptionalGuaranteesChildrenNames)[number]

export const rcProOptionalGuaranteeNames = ['rce', 'world', 'tdmi', 'cyber', 'mrpSmallOffice'] as const
export type RcProOptionalGuaranteeType = (typeof rcProOptionalGuaranteeNames)[number]
export const rcProAllGuaranteeNames = [
  'rcp',
  'pj',
  ...rcProOptionalGuaranteeNames,
  ...rcProCyberOptionalGuaranteeNames,
] as const

export type RcProGuaranteeType = (typeof rcProAllGuaranteeNames)[number]

export type RcProOptionActive = {
  isOption: boolean
  /**
   * Whether the quarantee is active or not, always true when it's an action (we could represent this
   * with typescript, but we don't because TSOA doesn't support it)
   */
  active: boolean
}

export type RcProGuaranteeQuote = RcProOptionActive & {
  name: RcProGuaranteeType
  label: string
} & (
    | {
        paymentRecurrence: 'monthly'
        yearlyBasePremium: Amount
        yearlyTaxes: Amount
        yearlyTotalPremium: Amount
        monthlyBasePremium: Amount
        monthlyTaxes: Amount
        monthlyTotalPremium: Amount
      }
    | {
        paymentRecurrence: 'yearly'
        yearlyBasePremium: Amount
        yearlyTaxes: Amount
        yearlyTotalPremium: Amount
      }
  )

export type FreelanceYearlyGuaranteeQuote = RcProGuaranteeQuote & { paymentRecurrence: 'yearly' }
export type YearlyFreelanceQuote = {
  orusFeeRate: FinancialRate
  yearlyBasePremium: Amount
  /**
   * Orus yearly fee, already included in the base premium
   */
  yearlyOrusFee: Amount
  /**
   * Total taxes
   */
  yearlyTaxes: Amount
  yearlyTotalPremium: Amount
  rcp: FreelanceYearlyGuaranteeQuote
  rce: FreelanceYearlyGuaranteeQuote
  pj: FreelanceYearlyGuaranteeQuote
  world: FreelanceYearlyGuaranteeQuote
  tdmi?: FreelanceYearlyGuaranteeQuote
  cyber?: FreelanceYearlyGuaranteeQuote
  cyberDommagesPe?: FreelanceYearlyGuaranteeQuote
  cyberDefaillanceFournisseur?: FreelanceYearlyGuaranteeQuote
  cyberDommagesTiers?: FreelanceYearlyGuaranteeQuote
  cyberFraudePiratage?: FreelanceYearlyGuaranteeQuote
}

type FreelanceMonthlyGuaranteeQuote = RcProGuaranteeQuote & { paymentRecurrence: 'monthly' }
export type MonthlyFreelanceQuote = {
  orusFeeRate: FinancialRate
  yearlyBasePremium: Amount
  /**
   * Orus yearly fee, already included in the base premium
   */
  yearlyOrusFee: Amount
  /**
   * Total taxes
   */
  yearlyTaxes: Amount
  yearlyTotalPremium: Amount
  monthlyBasePremium: Amount
  /**
   * Orus monthly fee, already included in the base premium
   */
  monthlyOrusFee: Amount
  /**
   * Monthly taxes for subsequent months
   */
  monthlyTaxes: Amount
  monthlyTotalPremium: Amount
  rcp: FreelanceMonthlyGuaranteeQuote
  rce: FreelanceMonthlyGuaranteeQuote
  pj: FreelanceMonthlyGuaranteeQuote
  world: FreelanceMonthlyGuaranteeQuote
  tdmi?: FreelanceMonthlyGuaranteeQuote
  cyber?: FreelanceMonthlyGuaranteeQuote
  cyberDommagesPe?: FreelanceMonthlyGuaranteeQuote
  cyberDefaillanceFournisseur?: FreelanceMonthlyGuaranteeQuote
  cyberDommagesTiers?: FreelanceMonthlyGuaranteeQuote
  cyberFraudePiratage?: FreelanceMonthlyGuaranteeQuote
}

export class RcphQuoteV2Dimension<NAME extends string> extends AbstractDimension<NAME, RcphQuoteV2> {}

export const rcphQuoteV2Dimension = new RcphQuoteV2Dimension({
  name: 'rcphQuoteV2',
  displayValues: { name: 'Devis RC Pro v2' },
  tags: ['RCPH'],
} as const)

export const rcphQuoteExclusionDimension = new StringsWithDataEnumDimension({
  name: 'rcphQuoteExclusion',
  displayValues: { name: 'Exclusion devis RC Pro' },
  entries: [['pricing-issue', { label: 'Erreur lors du calcul du prix' }]],
  tags: ['RCPH'],
} as const)
