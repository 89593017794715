import { css } from '@emotion/react'
import { TechnicalError, ensureError } from '@orus.eu/error'
import { memo, useCallback, useEffect, useMemo, useRef, useState, type ReactNode } from 'react'
import { useMeasure } from 'react-use'
import { assert } from 'ts-essentials'
import { colors } from '../../colors.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { useCrash, type ScreenType } from '../../hooks/index.js'
import { getPdfJs, type PDFDocumentProxy, type PDFPageProxy } from '../../lib/pdf-js.js'
import { Text } from '../atoms/index.js'

const pdfjs = getPdfJs()

export type PdfViewerProps = {
  screenType: ScreenType
  urlOrBytes: string | Uint8Array
  loader: ReactNode

  className?: string
}

export const PdfViewer = memo<PdfViewerProps>(function PdfViewer({ screenType, urlOrBytes, loader, className }) {
  const pdfDocumentProxy = usePDFDocumentProxy(urlOrBytes)

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const [currentPageNumber, setCurrentPageNumber] = useState(1)

  const handleScroll = useCallback(() => {
    if (wrapperRef.current && pdfDocumentProxy !== 'loading') {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current
      const pageNumber = Math.round((scrollTop / (scrollHeight - clientHeight)) * pdfDocumentProxy.numPages)
      setCurrentPageNumber(Math.max(pageNumber, 1))
    }
  }, [pdfDocumentProxy])

  if (pdfDocumentProxy === 'loading') {
    return <>{loader}</>
  }

  return (
    <div
      ref={wrapperRef}
      css={css`
        overflow-y: auto;
        max-height: 100vh;
        background-color: ${colors.gray[100]};
      `}
      onScroll={handleScroll}
      className={className}
    >
      <div
        css={css`
          position: sticky;
          top: ${spacing[40]};
          left: ${spacing[40]};

          display: flex;
          justify-content: center;
          align-items: center;

          width: 68px;
          height: 36px;
          margin-top: -36px;

          background-color: rgb(255 255 255 / 60%);
          border-radius: 4px;
          backdrop-filter: blur(16px);
        `}
      >
        <Text variant="body2Medium">
          {currentPageNumber} sur {pdfDocumentProxy.numPages}
        </Text>
      </div>

      {Array(pdfDocumentProxy.numPages)
        .fill(0)
        .map((_, index) => (
          <PdfPage key={index} screenType={screenType} pageIndex={index} pdfDocumentProxy={pdfDocumentProxy} />
        ))}
    </div>
  )
})

type PdfDocumentProxyHook = PDFDocumentProxy | 'loading'

function usePDFDocumentProxy(urlOrBytes: string | Uint8Array): PdfDocumentProxyHook {
  const crash = useCrash()
  const [pdfDocumentProxy, setPdfDocumentProxy] = useState<PdfDocumentProxyHook>('loading')
  // pdf.js takes ownership of the Uint8Array in the worker, and we cannot access it anymore after
  // therefore, we create a copy, so the original byte array stays available
  const resolvedUrlOrBytes = useMemo(
    () => (typeof urlOrBytes === 'string' ? urlOrBytes : new Uint8Array(urlOrBytes)),
    [urlOrBytes],
  )

  useEffect(() => {
    const loadingTask = pdfjs.getDocument(resolvedUrlOrBytes)
    setPdfDocumentProxy('loading')

    let cancelled = false
    loadingTask.promise.then(
      (pdf) => {
        if (cancelled) return
        setPdfDocumentProxy(pdf)
      },
      (err) => {
        if (cancelled) return
        crash({ type: 'unexpected-error', err: ensureError(err) })
      },
    )
    return () => {
      cancelled = true
      loadingTask.destroy().catch(console.error)
    }
  }, [crash, resolvedUrlOrBytes])

  return pdfDocumentProxy
}

type PdfPageProps = {
  screenType: ScreenType
  pageIndex: number
  pdfDocumentProxy: PDFDocumentProxy
}

export const PdfPage = memo<PdfPageProps>(function PdfPage({ screenType, pageIndex, pdfDocumentProxy }) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const pdfPageProxy = usePdfPageProxy(pdfDocumentProxy, pageIndex)
  const [wrapperRef, { width: wrapperWidth }] = useMeasure<HTMLDivElement>()

  useEffect(() => {
    let cancelled = false
    if (pdfPageProxy === 'loading' || cancelled) {
      return
    }

    const canvas = canvasRef.current
    if (!canvas) {
      // Component has been unmounted, nothing else to do
      return
    }

    const pageWidth = pdfPageProxy.getViewport({ scale: 1 }).width
    const scale = wrapperWidth / pageWidth
    const viewport = pdfPageProxy.getViewport({ scale })
    const canvasContext = canvas.getContext('2d')
    assert(canvasContext, 'canvas should have had a 2d context')

    // Taking into account the device pixel ratio allows for a sharper rendering on high DPI screens
    const outputScale = window.devicePixelRatio
    canvas.width = Math.floor(viewport.width * outputScale)
    canvas.height = Math.floor(viewport.height * outputScale)
    canvas.style.width = Math.floor(viewport.width) + 'px'
    canvas.style.height = Math.floor(viewport.height) + 'px'
    const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : undefined

    const renderContext = {
      canvasContext,
      transform,
      viewport,
    }

    const renderTask = pdfPageProxy.render(renderContext)

    renderTask.promise.catch(function (reason: unknown) {
      if (reason instanceof pdfjs.RenderingCancelledException) {
        //Nothing to do, this is expected
      } else {
        cancelled = true
      }
    })

    return () => {
      renderTask.cancel()
      cancelled = true
    }
  }, [pdfPageProxy, wrapperWidth])

  return (
    <div
      ref={wrapperRef}
      css={css`
        display: flex;
        justify-content: center;
        margin-inline: ${screenType === 'mobile' ? spacing[30] : '96px'};
        margin-block: ${screenType === 'mobile' ? spacing[30] : spacing[60]};
      `}
    >
      <canvas
        ref={canvasRef}
        css={css`
          border: 1px solid ${colors.gray[100]};
        `}
      ></canvas>
    </div>
  )
})

type PdfPageProxyHook = PDFPageProxy | 'loading'

function usePdfPageProxy(pdfDocumentProxy: PDFDocumentProxy, pageIndex: number): PDFPageProxy | 'loading' {
  const [pdfPageProxy, setPdfPageProxy] = useState<PdfPageProxyHook | 'error'>('loading')

  useEffect(() => {
    let cancelled = false
    pdfDocumentProxy
      .getPage(pageIndex + 1)
      .then((page) => {
        if (cancelled) return
        setPdfPageProxy(page)
      })
      .catch(() => {
        if (cancelled) return
        setPdfPageProxy('error')
      })

    return () => {
      cancelled = true
    }
  }, [pageIndex, pdfDocumentProxy])

  if (pdfPageProxy === 'error') {
    throw new TechnicalError("Couldn't load the pdf page", { context: { pageIndex } })
  }

  return pdfPageProxy
}
