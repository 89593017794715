import styled from '@emotion/styled'
import { Switch as MuiSwitch } from '@mui/material'
import { memo, type ChangeEvent, type InputHTMLAttributes } from 'react'
import { borderStroke, colorTokens } from '../../foundation/'

type SwitchProps = {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  disabled?: boolean
  className?: string
}

export const Switch = memo<SwitchProps>(function Switch(props) {
  const { checked, onChange, inputProps, className, disabled } = props

  return (
    <StyledMuiSwitch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      inputProps={inputProps}
      color="secondary"
      className={className}
    />
  )
})

const StyledMuiSwitch = styled(MuiSwitch)`
  & .MuiSwitch-thumb {
    background-color: ${colorTokens['color-bg-base-normal']};
    border: ${borderStroke[10]} solid ${colorTokens['color-stroke-base']};
    box-shadow: 0 0 8px -2px rgb(24 39 75 / 12%);
  }

  & .MuiSwitch-track {
    background-color: ${colorTokens['color-bg-base-disable']};
    opacity: 1;
  }

  & .MuiSwitch-switchBase {
    &.Mui-checked {
      & + .MuiSwitch-track {
        background-color: ${colorTokens['color-bg-info-tertiary-active']};
        opacity: 1;
      }

      & .MuiSwitch-thumb {
        background-color: ${colorTokens['color-fg-base-active-inverse']};
        border: none;
        box-shadow: 0 0 8px -2px rgb(24 39 75 / 12%);
      }
    }
  }
`
