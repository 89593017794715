import { OptionsListDimension } from '../options-list-dimension.js'

export const rcdaOptionTypeNames = ['HISTORY_TAKEOVER', 'SWIMMING_POOL'] as const
export type RcdaOptionType = (typeof rcdaOptionTypeNames)[number]

export const rcdaOptionsDimension = new OptionsListDimension<'rcdaOptions', RcdaOptionType>({
  name: 'rcdaOptions',
  displayValues: { name: 'Active RCDA options' },
  tags: ['RCDA'],
} as const)
