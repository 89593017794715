import styled from '@emotion/styled'
import { memo, useState, type ReactElement, type ReactNode } from 'react'
import { spacing } from '../../foundation'
import { Divider, Text } from '../atoms'
import type { TabProps } from '../tabbar/tab'
import { Tabbar } from '../tabbar/tabbar'

export type TabWithContent = {
  tab: ReactElement<TabProps>
  content: ReactNode
}

export type LayoutTabbedProps = {
  header: ReactNode
  isSidePanelOpen?: boolean
  sidePanel?: ReactNode
  tabs: TabWithContent[]
}

export const LayoutTabbed = memo<LayoutTabbedProps>(function LayoutTabbed(props: LayoutTabbedProps) {
  const { header, isSidePanelOpen = true, sidePanel, tabs } = props

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <LayoutTabbedContainer>
      {/* <NavbarPlaceHolder /> */}
      <LayoutContainer>
        <HeaderContainer>
          {header}
          <Tabbar size="medium" activeTabIndex={activeTabIndex} onClick={(index) => setActiveTabIndex(index)}>
            {tabs.map((tab) => tab.tab)}
          </Tabbar>
        </HeaderContainer>
        <Divider orientation="horizontal" />
        <BodyContainer>
          <ContentContainer role="tabpanel">
            {tabs.map((tab, index) => (
              <ContentWrapper key={index} className={index !== activeTabIndex ? 'hidden' : undefined}>
                {tab.content}
              </ContentWrapper>
            ))}
          </ContentContainer>
          <Divider orientation="vertical" />
          <SidePanelContainer isSidePanelOpen={!!isSidePanelOpen}>{sidePanel}</SidePanelContainer>
        </BodyContainer>
      </LayoutContainer>
    </LayoutTabbedContainer>
  )
})

const LayoutTabbedContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`

const LayoutContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const BodyContainer = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  overflow: hidden;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: none;

  .hidden {
    display: none;
  }
`

const ContentWrapper = styled.div`
  padding: ${spacing[60]};
  padding-bottom: ${spacing[100]};
  max-width: 1440px;
`

const SidePanelContainer = styled.div<{ isSidePanelOpen: boolean }>`
  width: ${({ isSidePanelOpen }) => (isSidePanelOpen ? '368px' : 'fit-content')};
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: none;
`

export const HeaderPlaceHolder = memo(function HeaderPlaceHolder() {
  return (
    <HeaderPlaceHolderContainer>
      <Text variant="subtitle1">Header</Text>
    </HeaderPlaceHolderContainer>
  )
})

const HeaderPlaceHolderContainer = styled.div`
  padding: ${spacing[50]} 0;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
  padding: ${spacing[60]};
  padding-bottom: 0;
`
