import { CalendarDateDimension } from '../calendar-date-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'
import { TextDimension } from '../text-dimension.js'

export const rcdaInsuranceInformationDimension = new StringsWithDataEnumDimension({
  name: 'rcdaInsuranceInformation',
  displayValues: {
    name: 'Relevé de sinistralité',
    placeholder: 'Votre réponse',
    hint: "Document fourni par votre ancienne compagnie d'assurance qui contient tout votre historique d’assurance et de sinistralité.",
  },
  entries: [
    ['YES', { label: "Oui, j'ai ce document" }],
    ['PREVIOUS_INSURER_DEFAULT', { label: "Non, je suis assuré mais l'assureur a disparu ou fait défaut" }],
    ['REFUSAL', { label: "Non, je suis assuré mais l'assureur ou moi-même refuse de le fournir" }],
  ],
  tags: ['RCDA'],
} as const)

export const rcdaPreviousInsurersNamesDimension = new TextDimension({
  name: 'rcdaPreviousInsurersNames',
  displayValues: {
    name: 'Précédents assureurs',
    placeholder: 'AXA, Allianz, Generali',
  },
  tags: ['RCDA'],
})

export const rcdaInsurancePeriodStartDateDimension = new CalendarDateDimension({
  name: 'rcdaInsurancePeriodStartDate',
  displayValues: {
    name: 'Date de début de votre 1er contrat',
    hint: `Date de début de votre premier contrat d'assurance décennale`,
  },
  tags: ['RCDA'],
})

export const rcdaMaxInsurancePeriodStartDateDimension = new CalendarDateDimension({
  name: 'rcdaMaxInsurancePeriodStartDate',
  displayValues: { name: 'Date de début maximum' },
  tags: ['RCDA'],
})
