import { amountToString, newAmount } from '@orus.eu/amount'
import { AmountDimension, formatRoundedAmountDimensionValue } from '../amount-dimension.js'
import { BooleanDimension } from '../boolean-dimension.js'
import { ExclusionDimension } from '../exclusion-dimension.js'
import { NumbersEnumDimension } from '../numbers-enum-dimension.js'
import { StringsDimension } from '../strings-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'
import { SurfaceDimension } from '../surface-dimension.js'
import { MrphQuoteV2Dimension } from './mrph-quote-dimension.js'

export * from './mrph-activity-domain.js'
export * from './mrph-code-departement-dimension.js'
export * from './mrph-guarantee-text.js'
export * from './mrph-offer-model.js'
export * from './mrph-options-dimension.js'
export * from './mrph-pe-configuration-dimension.js'
export * from './mrph-quote-dimension.js'

export const mrphSelectedDimension = new BooleanDimension({
  name: 'mrphSelected',
  displayValues: { name: 'MRP bureau / local' },
  tags: ['MRPH'],
} as const)

export const mrphSurfaceDimension = new SurfaceDimension({
  name: 'mrphSurface',
  displayValues: {
    name: 'Surface (m²)',
    placeholder: '400',
    hint: 'Surface totale (y compris terrasses, sous-sols et espaces de stockage).',
  },
  tags: ['MRPH'],
} as const)

export const mrphAmenagementLimitDimension = new AmountDimension({
  name: 'mrphAmenagementLimit',
  displayValues: { name: 'Plafond de remboursement pour les aménagements' },
  tags: ['MRPH'],
} as const)

export const mrphAssetsValueDimension = new AmountDimension({
  name: 'mrphAssetsValue',
  displayValues: {
    name: 'Valeur du contenu (€)',
    placeholder: amountToString(newAmount('100 000'), { addCurrency: true, displayDecimals: false }),
    hint: 'Ce qui se trouve dans vos locaux : votre matériel professionnel, votre mobilier et vos stocks.',
  },
  tags: ['MRPH'],
} as const)

export const mrphItAssetsValueDimension = new AmountDimension({
  name: 'mrphItAssetsValue',
  displayValues: {
    name: 'Valeur du matériel informatique (€)',
    placeholder: amountToString(newAmount('25 000'), { addCurrency: true, displayDecimals: false }),
  },
  tags: ['MRPH'],
} as const)

export const mrphFormulaDimension = new StringsWithDataEnumDimension({
  name: 'mrphFormula',
  displayValues: { name: 'Formule' },
  entries: [
    ['Basique', { label: 'Basique' }],
    ['Confort', { label: 'Confort' }],
    ['Premium', { label: 'Premium' }],
  ],
  tags: ['MRPH'],
} as const)

export const mrphAssetsPctLimitOneDimension = new AmountDimension({
  name: 'mrphAssetsPctLimitOne',
  displayValues: {
    name: 'Plafond sur le % du montant contenu 1',
    hint: 'Frais de retrait ou de dépose-repose',
  },
  tags: ['MRPH'],
} as const)

export const mrphAssetsPctLimitTwoDimension = new AmountDimension({
  name: 'mrphAssetsPctLimitTwo',
  displayValues: {
    name: 'Plafond sur le % du montant contenu 2',
    hint: 'Dégât des eaux, Vol, Vandalisme',
  },
  tags: ['MRPH'],
} as const)

export const mrphRevenuePctLimitDimension = new AmountDimension({
  name: 'mrphRevenuePctLimit',
  displayValues: {
    name: 'Plafond sur le % du CA',
    hint: "Perte d'exploitation",
  },
  tags: ['MRPH'],
} as const)

export const mrphFixedLimitOneDimension = new AmountDimension({
  name: 'mrphFixedLimitOne',
  displayValues: {
    name: 'Plafond fixe 1',
    hint: 'Bris de glace',
  },
  tags: ['MRPH'],
} as const)

export const mrphFixedLimitTwoDimension = new AmountDimension({
  name: 'mrphFixedLimitTwo',
  displayValues: {
    name: 'Plafond fixe 2',
    hint: 'Bris de machine / Tous risques informatiques, Détériorations de marchandises sous température dirigée, Dommages matériels en cours de transport',
  },
  tags: ['MRPH'],
} as const)

export const mrphLciDimension = new AmountDimension({
  name: 'mrphLci',
  displayValues: { name: "Limite contractuelle d'indemnité" },
  tags: ['MRPH'],
} as const)

export const mrphMonumentDimension = new BooleanDimension({
  name: 'mrphMonument',
  displayValues: { name: 'Monument historique (manoir, château, classé)' },
  tags: ['MRPH'],
} as const)

export const mrphDeductibleCurrentValues = [300, 500, 750, 1000, 1500, 2000, 2500, 3000, 5000] as const
/** The values that have been used in the past, but is no longer accepted for new subscriptions */
export const mrphDeductibleDeprecatedValues = [10000] as const
export const mrphDeductibleSupportedValues = [
  ...mrphDeductibleCurrentValues,
  ...mrphDeductibleDeprecatedValues,
] as const
export type MrphDeductible = (typeof mrphDeductibleSupportedValues)[number]
export function isMrphDeductible(value: unknown): value is MrphDeductible {
  return typeof value === 'number' && (mrphDeductibleSupportedValues as readonly number[]).includes(value)
}

export const mrphDeductibleDimension = new NumbersEnumDimension({
  name: 'mrphDeductible',
  displayValues: { name: 'Franchise (€)' },
  values: mrphDeductibleCurrentValues,
  supportedValues: mrphDeductibleSupportedValues,
  formatter: (value: MrphDeductible) => formatRoundedAmountDimensionValue(newAmount(value.toFixed())),
  tags: ['MRPH'],
} as const)

export const mrphDeductibleDaysDimension = new NumbersEnumDimension({
  name: 'mrphDeductibleDays',
  displayValues: { name: 'Franchise (Jours)' },
  values: [0, 3, 5],
  tags: ['MRPH'],
} as const)

export const mrphIndemnityDurationMonthsDimension = new NumbersEnumDimension({
  name: 'mrphIndemnityDurationMonths',
  displayValues: { name: "Période d'indemnité (Mois)" },
  values: [12, 18, 24, 36],
  tags: ['MRPH'],
} as const)

export const mrphOccupationStatusDimension = new StringsWithDataEnumDimension({
  name: 'mrphOccupationStatus',
  displayValues: { name: 'Qualité', placeholder: 'LOCATAIRE' },
  entries: [
    ['LOCATAIRE', { label: 'Locataire' }],
    [
      'LOCATAIRE AGISSANT POUR LE COMPTE DU PROPRIETAIRE',
      { label: 'Locataire agissant pour le compte du propriétaire' },
    ],
    ['OCCUPANT A TITRE GRATUIT', { label: 'Occupant à titre gratuit' }],
    ['PROPRIETAIRE', { label: 'Propriétaire' }],
    ['SOUS LOCATAIRE', { label: 'Sous-locataire' }],
  ],
  tags: ['MRPH'],
} as const)

export const mrphHardBuildingDimension = new BooleanDimension({
  name: 'mrphHardBuilding',
  displayValues: { name: 'Bâtiment construit et couvert en dur' },
  tags: ['MRPH'],
} as const)

export const mrphOwnerDimension = new BooleanDimension({
  name: 'mrphOwner',
  displayValues: { name: 'Propriétaire ou locataire agissant pour le compte du propriétaire' },
  tags: ['MRPH'],
} as const)

export const mrphLocataireAgissantProprietaireDimension = new BooleanDimension({
  name: 'mrphLocataireAgissantProprietaire',
  displayValues: { name: 'Locataire agissant pour le compte du propriétaire' },
  tags: ['MRPH'],
} as const)

export const mrphAssetsClaimsThreeYearsDimension = new StringsWithDataEnumDimension({
  name: 'mrphAssetsClaimsThreeYears',
  oldNames: ['mrphClaimsThreeYears'],
  displayValues: {
    name: 'Sinistres pour vos locaux professionnels ou leur contenu',
    hint: 'Le nombre de fois où vos locaux ou leur contenu ont subi des dommages que votre assureur vous a remboursés : dégâts des eaux, bris de glace, incendies, etc.',
  },
  entries: [
    ['0', { label: '0' }],
    ['1', { label: '1' }],
    ['2', { label: '2' }],
    ['3+', { label: '3+' }],
  ],
  tags: ['MRPH'],
} as const)

export const mrphOptionPeDimension = new StringsWithDataEnumDimension({
  name: 'mrphOptionPe',
  displayValues: { name: 'Option pertes financières' },
  entries: [
    ['Non', { label: 'Non' }],
    ['Standard', { label: 'Standard' }],
    ['Avantage', { label: 'Avantage' }],
  ],
  tags: ['MRPH'],
} as const)

export const mrphOptionPeEntries = mrphOptionPeDimension.values

// 'Non' was used when mrphOptionPeDimension was a computed dimension, we don't need it anymore.
export const mrphOptionPeValues: string[] = mrphOptionPeEntries.filter((entry) => entry !== 'Non')

export type MrphOptionPeEntries = (typeof mrphOptionPeDimension.values)[number]

export const mrphOptionPeLabels: Record<MrphOptionPeEntries, string> = Object.fromEntries(
  mrphOptionPeDimension.entries
    .filter(([key, _]) => key !== 'Non')
    .map(([key, def]) => {
      return [key, def.label]
    }),
) as Record<MrphOptionPeEntries, string>

export const mrphOptionPeActiveDimension = new BooleanDimension({
  name: 'mrphOptionPeActive',
  displayValues: { name: 'Option pertes financières active' },
  tags: ['MRPH'],
} as const)

export const mrphAvantageDimension = new BooleanDimension({
  name: 'mrphAvantage',
  displayValues: { name: 'Option avantage liberté' },
  tags: ['MRPH'],
} as const)

export const mrphOptionPeAvantageAvailableDimension = new BooleanDimension({
  name: 'mrphOptionPeAvantageAvailable',
  displayValues: { name: 'Option avantage PE disponible' },
  tags: ['MRPH'],
})

export const mrphOptionRcalDimension = new BooleanDimension({
  name: 'mrphOptionRcal',
  displayValues: { name: 'RC après livraison' },
  tags: ['MRPH'],
} as const)
export const mrphOptionRceDimension = new BooleanDimension({
  name: 'mrphOptionRce',
  displayValues: { name: 'RCE' },
  tags: ['MRPH'],
} as const)
export const mrphOptionAssistanceDimension = new BooleanDimension({
  name: 'mrphOptionAssistance',
  displayValues: { name: 'Option assistance' },
  tags: ['MRPH'],
} as const)
export const mrphOptionPjDimension = new BooleanDimension({
  name: 'mrphOptionPj',
  displayValues: { name: 'Option protection juridique' },
  tags: ['MRPH'],
} as const)
export const mrphYearlyBasePremiumDimension = new AmountDimension({
  name: 'mrphYearlyBasePremium',
  displayValues: { name: 'Cotisation annuelle HT' },
  tags: ['MRPH'],
} as const)
export const mrphYearlyTaxesDimension = new AmountDimension({
  name: 'mrphYearlyTaxes',
  displayValues: { name: 'Taxes annuelles' },
  tags: ['MRPH'],
} as const)
export const mrphYearlyTotalPremiumDimension = new AmountDimension({
  name: 'mrphYearlyTotalPremium',
  displayValues: { name: 'Cotisation annuelle TTC' },
  tags: ['MRPH'],
} as const)
export const mrphTerrorismTaxDimension = new AmountDimension({
  name: 'mrphTerrorismTax',
  displayValues: { name: 'Contribution attentats' },
  tags: ['MRPH'],
} as const)

export const mrphNaturalDisasterPremiumDimension = new AmountDimension({
  name: 'mrphNaturalDisasterPremium',
  displayValues: { name: 'Dont catasrophes naturelles' },
  tags: ['MRPH'],
} as const)

export const mrphAssistancePremiumDimension = new AmountDimension({
  name: 'mrphAssistancePremium',
  displayValues: { name: 'Dont assistance' },
  tags: ['MRPH'],
} as const)

export const mrphOptionFreedomAdvantageDimension = new BooleanDimension({
  name: 'mrphOptionFreedomAdvantage',
  displayValues: { name: 'Option "Avantage liberté"' },
  tags: ['MRPH'],
} as const)

export const mrphQuoteV2Dimension = new MrphQuoteV2Dimension({
  name: 'mrphQuoteV2',
  displayValues: { name: 'Devis MRP v2' },
  tags: ['MRPH'],
} as const)

export const mrphExclusionsDimension = new ExclusionDimension({
  name: 'mrphExclusions',
  displayValues: { name: 'Exclusions spécifiques MPR Hiscox' },
  tags: ['MRPH'],
})

export const mrphHiscoxActivitiesNamesDimension = new StringsDimension({
  name: 'mrphHiscoxActivitiesNames',
  displayValues: { name: 'Activités HISCOX MRP' },
  tags: ['MRPH'],
} as const)
