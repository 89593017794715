import { useMediaQuery } from '@mui/material'
import { largeScreenBreakpointName, theme } from '@orus.eu/pharaoh'

const mediaQueryVeryLargeScreenBreakpoint = `screen and (min-width:${theme.breakpoints.values[largeScreenBreakpointName]}px)`

/**
 * @deprecated Use useScreenType() from pharaoh instead
 */
export function useLargeScreen(): boolean {
  return useMediaQuery(mediaQueryVeryLargeScreenBreakpoint)
}
