import { type EsRcphQuote } from '@orus.eu/quote'
import { AbstractDimension } from '../abstract-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'

export class EsRcphQuoteDimension<NAME extends string> extends AbstractDimension<NAME, EsRcphQuote> {}

export const esRcphQuoteDimension = new EsRcphQuoteDimension({
  name: 'esRcphQuote',
  displayValues: { name: 'Devis RCPH Espagne' },
  tags: ['ES-RCPH'],
} as const)

export const esRcphQuoteExclusionDimension = new StringsWithDataEnumDimension({
  name: 'esRcphQuoteExclusion',
  displayValues: { name: 'Exclusion devis RCPH Espagne' },
  entries: [['pricing-issue', { label: 'Erreur lors du calcul du prix' }]],
  tags: ['ES-RCPH'],
} as const)
