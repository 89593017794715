import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { lazy, memo, Suspense, useEffect, useState } from 'react'

import { router } from './router'

export const DevTools = memo(function DevTools() {
  const [showDevTools, setShowDevTools] = useState(false)

  useEffect(() => {
    window.toggleDevTools = () => setShowDevTools((old) => !old)
  }, [])

  if (process.env['NODE_ENV'] !== 'production') {
    return (
      <>
        <ReactQueryDevtools initialIsOpen={false} />
        <TanStackRouterDevtools initialIsOpen={false} router={router} />
      </>
    )
  }

  if (showDevTools) {
    return (
      <Suspense fallback={null}>
        <ReactQueryDevtoolsProductionLazy initialIsOpen={false} />
        <TanStackRouterDevtoolsLazy initialIsOpen={false} router={router} />
      </Suspense>
    )
  }

  return null
})

/**
 * As opposed to router dev tools, the default export is tree-shaked in production,
 * so we muse use this special import as mentioned in the docs
 */
const ReactQueryDevtoolsProductionLazy = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((i) => ({
    default: i.ReactQueryDevtools,
  })),
)

const TanStackRouterDevtoolsLazy = lazy(() =>
  import('@tanstack/router-devtools').then((i) => ({
    default: i.TanStackRouterDevtools,
  })),
)
