import { TextDimension } from '../text-dimension.js'

export const mutaSocialSecurityNumberDimension = new TextDimension({
  name: 'mutaSocialSecurityNumber',
  displayValues: {
    name: 'Numéro de sécurité sociale',
    placeholder: '1 90 06 75 123 987 00',
    ariaLabel: 'Votre numéro de sécurité sociale',
  },
  tags: ['MUTA'],
} as const)

export const mutaSocialSecurityOrganismNumberDimension = new TextDimension({
  name: 'mutaSocialSecurityOrganismNumber',
  displayValues: {
    name: "Numéro d'organisme sécurité sociale",
    hint: 'Vous pouvez trouver ce numéro sur votre attestation de droits téléchargeable sur votre espace ameli.fr. Vous pouvez également appeler le 36 46.',
  },
  tags: ['MUTA'],
})
