import { useTranslate } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { openChat } from '../../lib/hubspot-util'
import { GenericProblemMessage } from './generic-problem-message'

export const CrashPage: FunctionComponent = memo(function CrashPage() {
  const translate = useTranslate()
  return (
    <GenericProblemMessage
      title={translate('problem_oops_title')}
      principalMessage={translate('problem_principal_message')}
      firstSubText={translate('problem_first_subtext')}
      secondSubText={translate('problem_second_subtext')}
      buttonText={translate('contact_customer_support')}
      onButtonClick={openChat}
      trackingId="contact_button"
    />
  )
})
