import { createTRPCProxyClient, httpBatchLink, type CreateTRPCClientOptions, type TRPCLink } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'
import { observable } from '@trpc/server/observable'
import type { TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc'
import superjson from 'superjson'

import type { AppRouter } from '@orus.eu/backend'
import { TechnicalError } from '@orus.eu/error'
import { enqueueTemporaryNotificationAlert } from '@orus.eu/pharaoh'
import { logger } from './lib/logger'

const errorLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next: (value) => {
          observer.next(value)
        },
        error: (err) => {
          // If there's no data, it's a network error, and we don't want to report it
          if (!err.data) {
            enqueueTemporaryNotificationAlert('Une erreur réseau est survenue, êtes-vous bien connecté à Internet ?', {
              variant: 'danger',
            })
            observer.error(err)
            return
          }

          const { httpStatus, code, path } = err.data

          if (SHOULD_REPORT_SENTRY_PER_CODE[code]) {
            logger.error(
              new TechnicalError(`Received tRPC ${code}${path ? ` on ${path}` : ''}`, {
                context: {
                  trpcPath: path,
                  trpcCode: code,
                  httpStatus,
                  errMessage: err.message,
                  clientAppVersion: __CODE_VERSION_ID__,
                },
                cause: err,
              }),
            )

            enqueueTemporaryNotificationAlert('Une erreur est survenue, nos équipes ont été informées', {
              variant: 'danger',
            })
          }

          observer.error(err)
        },
        complete: () => {
          observer.complete()
        },
      })

      return unsubscribe
    })
  }
}

const clientConfig: CreateTRPCClientOptions<AppRouter> = {
  links: [errorLink, httpBatchLink<AppRouter>({ url: '/trpc', maxURLLength: 4096, transformer: superjson })],
}

export const trpc = createTRPCProxyClient<AppRouter>(clientConfig)

export const trpcReact = createTRPCReact<AppRouter>()

/** @deprecated This is the client for the tRPC provider, you're probably looking for {@link trpcReact}.  */
export const trpcReactClient = trpcReact.createClient(clientConfig)

logger.addTransport({
  event(event, metadata) {
    trpc.log.sendEvent.mutate({ event, metadata }).catch((err: unknown) => {
      // not much more we can do, and we don't want this to propagate, to avoid an infinite loop of error reporting
      console.error(err)
    })
  },
})

/**
 * tRPC status codes that are relevant to report to Sentry, due to being caused by our code.
 * For example, a NOT_FOUND is not relevant to report, as it is a normal case that can happen.
 */
const SHOULD_REPORT_SENTRY_PER_CODE: Record<TRPC_ERROR_CODE_KEY, boolean> = {
  PARSE_ERROR: true,
  BAD_REQUEST: true,
  INTERNAL_SERVER_ERROR: true,
  NOT_IMPLEMENTED: true,
  UNAUTHORIZED: false,
  FORBIDDEN: false,
  NOT_FOUND: false,
  METHOD_NOT_SUPPORTED: true,
  TIMEOUT: false,
  CONFLICT: false,
  PRECONDITION_FAILED: false,
  PAYLOAD_TOO_LARGE: true,
  UNPROCESSABLE_CONTENT: true,
  TOO_MANY_REQUESTS: true,
  CLIENT_CLOSED_REQUEST: false,
  BAD_GATEWAY: true,
  SERVICE_UNAVAILABLE: true,
  GATEWAY_TIMEOUT: true,
  UNSUPPORTED_MEDIA_TYPE: true,
}
