import { CrashContext, type CrashReason } from '@orus.eu/pharaoh'
import { lazy, memo, useCallback, useState, type FunctionComponent, type ReactNode } from 'react'
import { useAppSelector } from '../../lib/hooks/store'
import { logger } from '../../lib/logger'
import { handleTrpcError } from '../../lib/trpc-handler'
import { CrashPage } from '../organisms/crash-page'
import { GenericProblemMessage } from '../organisms/generic-problem-message'
import { InvalidLinkMessage } from '../organisms/invalid-link-message'
import { NotFound } from '../organisms/not-found'

const TemporaryFailureScreen = lazy(() => import('../organisms/temporary-failure-screen'))

export type CrashHandlerProps = {
  children: ReactNode
}

export const CrashHandler: FunctionComponent<CrashHandlerProps> = memo(function CrashHandler({ children }) {
  const [currentCrashReason, setCurrentCrashReason] = useState<CrashReason | undefined>(undefined)

  // TODO : remove this channel for problem reporting and merge with useCrash
  const globalProblemState = useAppSelector((state) => state.globalState.globalProblem)

  const crash = useCallback((reason: CrashReason) => {
    if ('err' in reason) {
      logger.error(reason.err)
    }
    setCurrentCrashReason(reason)
  }, [])

  if (globalProblemState?.type === 'temporary-api-failure') {
    return <TemporaryFailureScreen />
  }

  if (!currentCrashReason) {
    return <CrashContext.Provider value={crash}>{children}</CrashContext.Provider>
  }

  if (globalProblemState) {
    return <CrashPage />
  }

  if (currentCrashReason.type === 'unexpected-error') {
    const trpcErrorHandler = handleTrpcError(currentCrashReason.err)
    if (trpcErrorHandler) {
      return trpcErrorHandler
    }
  }

  switch (currentCrashReason.type) {
    case 'unexpected-error':
      return <CrashPage />
    case 'temporary-problem':
      return <TemporaryFailureScreen />
    case 'not-found':
      return <NotFound />
    case 'functionnal-problem':
      return <GenericProblemMessage {...currentCrashReason} />
    case 'invalid-link':
      return <InvalidLinkMessage />
  }
})
