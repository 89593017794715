import { memo } from 'react'
import { TextField, type TextFieldProps } from '../../../inputs/index.js'
import { TextInputLabelWrapper, type TextInputLabelWrapperProps } from '../../input-label-wrapper/index.js'

export type TextFieldFormFieldProps = TextFieldProps & Omit<TextInputLabelWrapperProps, 'children'>

export const TextFieldFormField = memo<TextFieldFormFieldProps>(function TextFieldFormField(props) {
  return (
    <TextInputLabelWrapper
      variant={props.variant}
      label={props.label}
      caption={props.caption}
      infoTooltip={props.infoTooltip}
      required={props.required}
    >
      <TextField {...props} />
    </TextInputLabelWrapper>
  )
})
