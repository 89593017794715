import { OptionsListDimension } from '../options-list-dimension.js'

export const mrpwOptionTypeNames = ['PJ', 'PE', 'PVV', 'BDG', 'RCT', 'BOOST'] as const
export type MrpwOptionType = (typeof mrpwOptionTypeNames)[number]

export const mrpwOptionsDimension = new OptionsListDimension<'mrpwOptions', MrpwOptionType>({
  name: 'mrpwOptions',
  displayValues: { name: 'Active MPRW options' },
  tags: ['MRPW'],
} as const)
