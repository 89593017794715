import { type DimensionOptions } from '../abstract-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'

export const mrpwWakamNullActivityName = 'NULL'

export const mrpwWakamActivityNames = [
  mrpwWakamNullActivityName,

  'Alimentation générale, Epicerie, Surface de vente inférieure à 120 m²',
  'Boucherie charcuterie (artisan) avec ou sans organisation de réceptions, avec ou sans plats cuisinés',
  'Boulangerie, Pâtisserie (artisan)',
  'Vente de pain ou de viennoiseries sans fabrication, avec ou sans terminal de cuisson (détaillant)',
  'Vente de cafés et thés, avec ou sans torréfaction (détaillant)',
  'Vente de confiserie, chocolats (détaillant) avec ou sans fabrication',
  'Crémerie, fromagerie (détaillant)',
  'Vente au détail de produits de diététique fabriqués en Union Européenne et conformes aux normes en vigueur en France',
  'Vente de fruits, légumes, primeurs (détaillant)',
  'Poissonnerie, vente de coquillages et crustacés (détaillant)',
  'Vente au détail de produits surgelés',
  'Vente au détail de vins et spiritueux',
  'Vente de conserves, huiles et autres produits régionaux (détaillant)',
  "Vente, location d'articles de bricolage, outillage (détaillant)",
  "Cadeaux et objets de décoration sans vente de meubles d'une valeur unitaire d'achat > 300 € (détaillant)",
  "Vente au détail, location d'appareils électroménagers sans produits audiovisuels, multimédias ni de téléphonie:",
  'Vente au détail de linge de maison',
  "Vente au détail de luminaires, d'appareils d'éclairage domestiques sans produits audiovisuels, multimédias ni de téléphonie",
  'Vente au détail de machines à coudre ou à tricoter',
  'Quincaillier, droguiste, sans laboratoire ni préparation de produits chimiques (détaillant)',
  'Services minute (Clés, Imprimerie, Talons minute, ...)',
  'Vente au détail de vaisselle et arts de la table (cristaux, faïence, porcelaine, verrerie, coutellerie)',
  "Dépanneur, réparateur d'électroménager, de produits audiovisuels et multimédias",
  "Vente au détail d'accessoires de l'habillement (gants, cravates, ceintures, chapeaux,...) sans bijoux",
  "Vente au détail de bijoux fantaisie sans objets d'une valeur unitaire d'achat > 300 € sans perçage corporel autre que le pavillon de l'oreille et l'aile du nez par pistolet perce-oreille",
  "Vente au détail de chaussures avec chaussures de sport n'excédant pas 20% de la valeur totale des marchandises",
  'Cordonnier sans vente de chaussures, ni de maroquinerie',
  'Vente au détail de lingerie',
  'Vente au détail de maroquinerie et articles de voyage',
  'Vente au détail de mercerie et/ou laines à tricoter sans vente de vêtements et de lingerie',
  'Vente au détail de mercerie et/ou laines à tricoter avec vente de vêtements et de lingerie',
  'Vente au détail de vêtements prêt-à-porter sans cuirs et peaux excédant 20% de la valeur totale du stock',
  'Vente au détail de vêtements enfants, layette',
  'Coiffeur (exercice en salon)',
  "Institut de beauté, prothésiste et styliste d'ongles",
  "Vente au détail d'articles de puériculture",
  "Prothésiste et styliste d'ongles",
  'Fleuriste (vente au détail, location de fleurs et plantes)',
  "Vente au détail et réparation d'instruments de musique",
  'Vente au détail de jeux, jouets et modèles réduits',
  'Laboratoire de développement et de tirage photo',
  'Librairie, papeterie (détaillant)',
  "Toilettage d'animaux",
  'Vente au détail de matériels pour loisirs créatifs (peinture, chevalets,...)',
  "Photographe, vidéaste sans vente d'appareils ni fournitures",
  "Photographe, vidéaste avec vente d'appareils et de fournitures",
  'Carterie, vente de gadgets (détaillant)',
  'Vente de souvenirs',
  "Vente au détail d'articles funéraires sans activité de pompes funèbres",
  "Vente au détail d'articles de bureau sans vente de matériel bureautique / informatique",
  'Vente de matériel bureautique / informatique (détaillant) sans conception de progiciels et/ou de logiciels',
  'Meuble et Literie',
  'Artisans lunetiers - Opticiens',
  "Vente de livres et de disques d'occasions",
  'Pharmacie',
  'Laverie Automatique',
  'Accessoires et alimentation pour animaux',
  'Supérette',
  "Vêtements et chaussures d'occasions (Friperie)",
  'Retoucherie',
  "Vente d'ustensiles pour cuisines (détaillant)",
  'Copie-minute en libre service',
  // restaurants

  'Pizzeria sans four à bois',
  'Bar-Café sans bureau de tabac',
  'Crêperie',
  'Glacier (détaillant)',
  'Salon de thé, tarterie',
  'Pizzeria sans four à bois',
  'Restaurant sans activité de pizzeria',
  'Pizzeria sans four à bois',
  'Kebab',
  'Restauration rapide (hors kebab) avec matériel de cuisson',
  'Restauration rapide (hors kebab) sans matériel de cuisson',
  'Bar à sushis avec ou sans livraison de plats préparés avec matériel de cuisson',
  'Bar à sushis avec ou sans livraison de plats préparés sans matériel de cuisson autre que pour le riz',
  'Livraison à domicile de plats préparés sans consommation sur place',
  'Epicerie fine (détaillant)',
] as const

const mrpwActivitiesLabelsSet = new Set<string>(mrpwWakamActivityNames)
export function isWakamActivityName(activityLabel: string): activityLabel is MrpwWakamActivityName {
  return mrpwActivitiesLabelsSet.has(activityLabel)
}

export type MrpwWakamActivityName = (typeof mrpwWakamActivityNames)[number]

export class MrpwWakamActivityDimension<NAME extends string> extends StringsWithDataEnumDimension<
  NAME,
  { label: string },
  ReadonlyArray<readonly [MrpwWakamActivityName, { label: string }]>
> {
  constructor(options: DimensionOptions<NAME>) {
    super({
      ...options,
      entries: mrpwWakamActivityNames.map((name) => [name, { label: getWakamActivityNameLabel(name) }] as const),
    })
  }
}

function getWakamActivityNameLabel(name: MrpwWakamActivityName): string {
  return name === 'NULL' ? '-- Aucune --' : name
}
