import { Outlet, createRootRoute, type RegisteredRouter, type RouteIds } from '@tanstack/react-router'

import { operatingZoneSchema } from '@orus.eu/operating-zone'
import { z } from 'zod'
import { useTracking } from '../lib/tracking/tracking'

const searchParams = z.object({
  discount_code: z.string().optional(),
  embeddingPartner: z.string().optional(),
  activity: z.string().optional(),
  category: z.string().optional(),
  firstname: z.string().optional(),
  lastname: z.string().optional(),
  siret: z.coerce.string().optional(),
  siren: z.coerce.string().optional(),
  cif: z.string().optional(),
  nif: z.string().optional(),
  email: z.string().optional(),
  phone: z.coerce.string().optional(),
  revenue: z.coerce.string().optional(),
  redirect: z.string().optional(),
  token: z.string().optional(),
  login: z.string().optional(),
  arrives_receive_quote: z.boolean().optional(),
  redirect_status: z.string().optional(),
  embedInIframe: z.boolean().optional(),
  description: z.string().optional(),
  stepId: z.string().optional(),
  validate: z.boolean().optional(),
  continueSubscriptionId: z.string().optional(),
  discount: z.string().optional(),
  rcphSelected: z.boolean().optional(),
  mrphSelected: z.boolean().optional(),
  mrpwSelected: z.boolean().optional(),
  mutaSelected: z.boolean().optional(),
  rcdaSelected: z.boolean().optional(),
  esRcphSelected: z.boolean().optional(),
  operatingZone: operatingZoneSchema.optional(),
  q: z.string().optional(),
})

export type SearchParams = z.infer<typeof searchParams>

export const Route = createRootRoute({
  validateSearch: searchParams,
  component: function Root() {
    useTracking(TRACKING_IGNORED_ROUTE_IDS)

    return <Outlet />
  },
})

const TRACKING_IGNORED_ROUTE_IDS = new Set<RouteIds<RegisteredRouter['routeTree']>>([
  '__root__',
  '/receive-quote',
  '/s/q/$token',
  '/s/c/$token',
  '/bak/troubleshooting/session/$sessionId',
  '/bak/pending-subscriptions/',
])
