import type { MutaGuaranteeGroupType } from '../quote-offer-information-dimension.js'
import type { MutaGuarantee } from './guarantees/muta-guarantees.js'

export type ExposedMutaGuarantee = Exclude<
  MutaGuarantee,
  | 'HO_FJ'
  | 'SC_HM_MS'
  | 'SC_HM_MS'
  | 'DEN_PLAF_REMB_FY'
  | 'DEN_PLAF_REMB_SY'
  | 'DEN_PLAF_REMB_TY'
  | 'OPT_SIMP_COMP_FY'
  | 'OPT_SIMP_COMP_SY'
  | 'OPT_SIMP_COMP_TY'
  | 'OPT_SIMP_T_COMP_FY'
  | 'OPT_SIMP_T_COMP_SY'
  | 'OPT_SIMP_T_COMP_TY'
  | 'OPT_COMP_T_COMP_FY'
  | 'OPT_COMP_T_COMP_SY'
  | 'OPT_COMP_T_COMP_TY'
  | 'AUD_ACCESS'
  | 'CUR_HO_CONV'
  | 'CUR_HO_HCONV'
  | 'CUR_FRAIS_ETAB'
  | 'CUR_TRANS_HEB'
  | 'SER_TIERS_PAY'
  | 'SER_ASSIST'
  | 'SER_AUTO_REMB'
  | 'HO_FDS_NCO'
  | 'SC_MEDIC_NPEC'
>

export const mutaGroupPerGuarantee: Record<ExposedMutaGuarantee, MutaGuaranteeGroupType> = {
  AUD_APP_PROT: 'AppareillageEtProthesesAuditives',
  AUD_FORF_AUD: 'AidesAuditives',
  DEN_HPS: 'SoinsDentaires',
  DEN_NPC_RB: 'DentaireNonPrisEnCharge',
  DEN_ORT_RB_FY: 'Orthodontie',
  DEN_ORT_RB_SY: 'Orthodontie',
  DEN_ORT_RB_TY: 'Orthodontie',
  DEN_PROT_RB_FY: 'ProthesesDentaires',
  DEN_PROT_RB_SY: 'ProthesesDentaires',
  DEN_PROT_RB_TY: 'ProthesesDentaires',
  HO_CH_FY: 'ChambreParticuliere',
  HO_CH_SY: 'ChambreParticuliere',
  HO_CH_TY: 'ChambreParticuliere',
  HO_CO_HO: 'ConfortHospitalier',
  HO_FDS_CO: 'FraisDeSejour',
  HO_HAD: 'HospitalisationDomicile',
  HO_HO_HO: 'HonorairesEtablissements',
  HO_HO: 'HonorairesPraticiens',
  HO_SA: 'SejourAccompagnant',
  MD_MN: 'MedecineNaturelle',
  MD_PREV: 'ActesDePrevention',
  MD_PROT: 'ProthesesEtAppareillages',
  OPT_COMP_MONT_FY: 'MontureVerresComplexes',
  OPT_COMP_MONT_SY: 'MontureVerresComplexes',
  OPT_COMP_MONT_TY: 'MontureVerresComplexes',
  OPT_FORF_LENT: 'Lentilles',
  OPT_SIMP_MONT_FY: 'MontureVerresSimples',
  OPT_SIMP_MONT_SY: 'MontureVerresSimples',
  OPT_SIMP_MONT_TY: 'MontureVerresSimples',
  OPT_T_COMP_MONT_FY: 'MontureVerresComplexes',
  OPT_T_COMP_MONT_SY: 'MontureVerresComplexes',
  OPT_T_COMP_MONT_TY: 'MontureVerresComplexes',
  SC_HM_ATM: 'MedecinGeneraliste',
  SC_HM_IM: 'MedecinGeneraliste',
  SC_HM_MG: 'MedecinGeneraliste',
  SC_HP: 'Paramedicaux',
  SC_LAB: 'AnalysesExamsLaboratoires',
  ASS_TELE_CONSULT_MEDI: 'TeleconsultationMedicale',
  ASS_COACH_WELLNESS: 'CoachingBienEtre',
  ASS_ACCOMP_PSY: 'AccompagnementPsychologique',
  ASS_HOME_HELP_FROM: 'AideDomicile',
  ASS_HOME_HELP_TO: 'AideDomicile',
  ASS_TRANS_TAXI_FROM: 'TransportTaxi',
  ASS_TRANS_TAXI_TO: 'TransportTaxi',

  SC_MEDIC_PEC: 'Medicaments',
  SC_PAPP: 'PetitAppareillage',
  SC_TRANS: 'Transport',
}
