import type { ButtonProps, SxProps } from '@mui/material'
import { Button } from '@mui/material'

const defaultSx: SxProps = {
  border: 'none',
  padding: 0,
  background: 'none',
  color: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  margin: 0,
  minWidth: 'auto',
  '&:hover': {
    background: 'none',
  },
}

export function UnstyledButton({ children, sx, ...props }: ButtonProps): JSX.Element {
  const passedSx: SxProps = { ...defaultSx, ...sx } as SxProps
  return (
    <Button sx={passedSx} {...props}>
      {children}
    </Button>
  )
}
