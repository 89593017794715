import { StringsWithDataEnumDimension } from './strings-with-data-enum-dimension.js'

export const civilLiabilitiesFiveYearsDimension = new StringsWithDataEnumDimension({
  name: 'civilLiabilitiesFiveYears',
  oldNames: ['mrphClaimsFiveYears'],
  displayValues: {
    name: 'Sinistres en responsabilité civile durant les 5 dernières années',
    hint: 'Le nombre de fois où un tiers (un client, un fournisseur...) vous a demandé réparation pour un dommage que vous lui avez causé.',
  },
  entries: [
    ['0', { label: '0' }],
    ['1', { label: '1' }],
    ['2', { label: '2' }],
    ['3+', { label: '3+' }],
  ],
} as const)
