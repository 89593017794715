import { failure, success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import { AbstractDimension, type DimensionValidationProblem, type LooselyTypedValue } from './abstract-dimension.js'

export const embeddingPartnerValueSchema = z.union([z.literal('none'), z.object({ id: z.string() })])

export type EmbeddingPartner = z.infer<typeof embeddingPartnerValueSchema>

export class EmbeddingPartnerDimension extends AbstractDimension<'embeddingPartner', EmbeddingPartner> {
  override validateData(value: LooselyTypedValue): Result<EmbeddingPartner, DimensionValidationProblem> {
    const parsingResult = embeddingPartnerValueSchema.safeParse(value)
    if (!parsingResult.success) {
      return failure({ type: 'dimension-validation-problem', explanation: parsingResult.error.message })
    }
    return success(parsingResult.data === 'none' ? 'none' : { id: parsingResult.data.id })
  }
}
