import { TechnicalError } from '@orus.eu/error'
import { z } from 'zod'
import activities from './activities-data.js'

export { activities }
export type Activity = keyof typeof activities

export type ActivitiesShares = {
  id: Activity
  share: number
}[]

export type ActivitiesDistribution =
  | { type: 'single' }
  | { type: 'multiple'; distribution: Partial<Record<Activity, { sharePercentage: number }>> }
  | undefined

export type ActivityInput = {
  activity: string
  /**
   * The name of the activity as it was displayed to the user who picked it.
   * Used only for display purpose.
   */
  displayName: string
}

export function isActivity(value: string): value is Activity {
  return Object.hasOwn(activities, value)
}

export const allActivities: readonly Activity[] = Object.keys(activities).map(assertActivity)

export const activityZodSchema = z.custom<Activity>((value) => typeof value === 'string' && isActivity(value))

export function assertActivity(value: string): Activity {
  if (!isActivity(value)) {
    throw new TechnicalError('Invalid activity id', { context: { value } })
  }
  return value
}

export function isInActivitesSubset<T extends ReadonlyArray<Activity>>(
  value: string,
  activitesSubset: T,
): value is T[number] {
  const activitesSubsetStrings: ReadonlyArray<string> = activitesSubset
  return activitesSubsetStrings.includes(value)
}

/**
 * Return the name of the activity according to the risk carrier supporting it, important in some
 * legal documents.
 */
export function getActivityRiskCarrierLabel(activity: Activity): string {
  return activities[activity]?.riskCarrierLabel
}

export function isSecondaryActivity(activity: Activity): boolean {
  const thisActivity = activities[activity]
  return 'isSecondaryActivity' in thisActivity && thisActivity ? thisActivity.isSecondaryActivity : false
}

export function computeDefaultShares(
  activities: Activity[],
  activitiesDistribution: ActivitiesDistribution,
  forceSecondaryActivitiesMaxShare: boolean = true,
): ActivitiesShares {
  const defaultSharePercentage = Math.floor(100 / activities.length)
  const defaultSharePercentagePlusRemainder = defaultSharePercentage + (100 % activities.length)
  const secondaryActivities: [Activity, number][] = []
  const maxSecondaryShare = forceSecondaryActivitiesMaxShare ? 30 : 100

  let totalSecondaryActivitiesShare = 0
  let primaryActivitiesCount = 0

  // First pass to identify secondary activities and their shares
  const preliminaryActivities = activities.map((activity, index) => {
    const defaultSharePercentageToApply =
      index === activities.length - 1 ? defaultSharePercentagePlusRemainder : defaultSharePercentage
    const currentActivityDistribution =
      activitiesDistribution?.type === 'multiple' && activitiesDistribution.distribution[activity]
    const sharePercentage = currentActivityDistribution
      ? currentActivityDistribution.sharePercentage
      : defaultSharePercentageToApply

    const isSecondary = isSecondaryActivity(assertActivity(activity))

    if (isSecondary) {
      const adjustedSharePercentage = Math.min(sharePercentage, maxSecondaryShare)
      totalSecondaryActivitiesShare += adjustedSharePercentage / 100
      secondaryActivities.push([activity, adjustedSharePercentage / 100])
      return { id: activity, share: parseFloat((adjustedSharePercentage / 100).toFixed(2)) }
    } else {
      primaryActivitiesCount += 1
      return { id: activity, share: 0 } // We'll adjust this in the second pass
    }
  })

  // Calculate remaining share to distribute among main activities
  const remainingShare = 1 - totalSecondaryActivitiesShare
  const primaryDefaultShare = remainingShare / primaryActivitiesCount

  // Second pass to assign shares to primary activities
  let remainingRoundingError = 0
  preliminaryActivities.forEach((activityShare) => {
    if (activityShare.share === 0) {
      // This indicates a primary activity
      const shareToApply = parseFloat((primaryDefaultShare + remainingRoundingError / 100).toFixed(2))
      remainingRoundingError += primaryDefaultShare - shareToApply
      activityShare.share = shareToApply
    }
  })

  // Adjust the last primary activity to ensure the total is exactly 100%
  const totalShare = preliminaryActivities.reduce((sum, activityShare) => sum + activityShare.share, 0)
  const lastActivity = preliminaryActivities[preliminaryActivities.length - 1]
  if (lastActivity) {
    lastActivity.share = parseFloat((lastActivity.share + (1 - totalShare)).toFixed(2))
  }

  return preliminaryActivities
}

type Distribution = Record<string, { sharePercentage: number }>
export function getActivitiesDefaultDistribution(activities: ActivityInput[]): Distribution {
  const defaultShares = computeDefaultShares(
    activities.map(({ activity }) => assertActivity(activity)),
    undefined,
  )

  const distribution: Distribution = {}

  defaultShares.forEach(({ share, id }) => {
    distribution[id] = {
      sharePercentage: Math.floor(share * 100),
    }
  })

  return distribution
}
