import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionOptions,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class StringsEnumDimension<NAME extends string, VALUES extends readonly string[]> extends AbstractDimension<
  NAME,
  VALUES[number]
> {
  readonly values: VALUES

  constructor(options: DimensionOptions<NAME> & { values: VALUES }) {
    super(options)
    this.values = options.values
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<VALUES[number]> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(
        m.enumWithoutValueDescription(
          valueDocumentation,
          Object.fromEntries(this.values.map((value) => [value, null])) as { [key in VALUES[number]]: null },
        ),
      )
    },
    convertStateValueToTrackingValue: (stateValue: VALUES[number] | null | undefined): string | null => {
      if (!stateValue) return null
      return stateValue ?? null
    },
  }

  override validateData(value: LooselyTypedValue): Result<VALUES[number], DimensionValidationProblem> {
    for (const validValue of this.values) {
      if (validValue === value) return success(validValue)
    }
    return dimensionValidationFailure(`Field ${this.name} has an invalid value`)
  }
}
