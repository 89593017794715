import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class ActivitiesDistributionDimension<NAME extends string> extends AbstractDimension<
  NAME,
  ActivitiesDistribution
> {
  override validateData(value: LooselyTypedValue): Result<ActivitiesDistribution, DimensionValidationProblem> {
    const parsingResult = SCHEMA.safeParse(value)

    if (!parsingResult.success) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }

    return success(parsingResult.data)
  }
}

const SCHEMA = z.union([
  z.object({ type: z.literal('single') }),
  z.object({
    type: z.literal('multiple'),
    distribution: z.record(z.object({ sharePercentage: z.number().int().min(1).max(100) })),
  }),
])
export type ActivitiesDistribution = z.infer<typeof SCHEMA>
