import { BooleanDimension } from '../boolean-dimension.js'
import { ExclusionDimension } from '../exclusion-dimension.js'
import { FinancialRateDimension } from '../financial-rate-dimension.js'
import { IntegerDimension } from '../integer-dimension.js'
import type { EsRcphProduct } from './es-rcph-product-dimension.js'

export * from './es-rcph-deductible-dimensions.js'
export * from './es-rcph-guarantee-data.js'
export * from './es-rcph-loi-dimension.js'
export * from './es-rcph-product-dimension.js'
export * from './es-rcph-quote-dimension.js'
export * from './es-rcph-quote-offer-information.js'

export const esRcphSelectedDimension = new BooleanDimension({
  name: 'esRcphSelected',
  displayKeys: {
    name: 'dimension_name_es_rcph_selected',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphCanadaOrUsaRevenuePercentageDimension = new FinancialRateDimension({
  name: 'esRcphCanadaOrUsaRevenuePercentage',
  displayKeys: {
    name: 'dimension_name_es_rcph_canada_or_usa_revenue_percentage',
    placeholder: 'dimension_placeholder_es_rcph_canada_or_usa_revenue_percentage',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphOptionWorldDimension = new BooleanDimension({
  name: 'esRcphOptionWorld',
  displayKeys: {
    name: 'dimension_name_es_rcph_option_world',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphHeavySingleCustomerDimension = new BooleanDimension({
  name: 'esRcphHeavySingleCustomer',
  displayKeys: {
    name: 'dimension_name_es_rcph_heavy_single_customer',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphIsSecuDimension = new BooleanDimension({
  name: 'esRcphIsSecu',
  displayKeys: {
    name: 'dimension_name_es_rcph_is_secu',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphIsOngDimension = new BooleanDimension({
  name: 'esRcphIsOng',
  displayKeys: {
    name: 'dimension_name_es_rcph_is_ong',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphIsTravelAgencyDimension = new BooleanDimension({
  name: 'esRcphIsTravelAgency',
  displayKeys: {
    name: 'dimension_name_es_rcph_is_travel_agency',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphMoreThanTwoYearsOfXpDimension = new BooleanDimension({
  name: 'esRcphMoreThanTwoYearsOfXp',
  displayKeys: {
    name: 'dimension_name_es_rcph_more_than_two_years_of_xp',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphInstallsSecurityMaterialDimension = new BooleanDimension({
  name: 'esRcphInstallsSecurityMaterial',
  displayKeys: {
    name: 'dimension_name_es_rcph_installs_security_material',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphHeadcountRequiredDimension = new BooleanDimension({
  name: 'esRcphHeadcountRequired',
  displayKeys: {
    name: 'dimension_name_es_rcph_headcount_required',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphHeadcountDimension = new IntegerDimension({
  name: 'esRcphHeadcount',
  displayKeys: {
    name: 'dimension_name_es_rcph_headcount',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphVehicleCountRequiredDimension = new BooleanDimension({
  name: 'esRcphVehicleCountRequired',
  displayKeys: {
    name: 'dimension_name_es_rcph_vehicle_count_required',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphPartialVehicleCountRequiredDimension = new BooleanDimension({
  name: 'esRcphPartialVehicleCountRequired',
  displayKeys: {
    name: 'dimension_name_es_rcph_partial_vehicle_count_required',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphVehicleCountDimension = new IntegerDimension({
  name: 'esRcphVehicleCount',
  displayKeys: {
    name: 'dimension_name_es_rcph_vehicle_count',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphPartialVehicleCountDimension = new IntegerDimension({
  name: 'esRcphPartialVehicleCount',
  displayKeys: {
    name: 'dimension_name_es_rcph_partial_vehicle_count',
  },
  tags: ['ES-RCPH'],
} as const)

/**
 * Whether or not the GL guarantee is included or optional.
 * https://orus-insurance.slack.com/archives/C06CUGUV54N/p1724334070233989?thread_ts=1724333256.292449&cid=C06CUGUV54N
 */
export const esRcphGlOptionalDimension = new BooleanDimension({
  name: 'esRcphGlOptional',
  displayKeys: {
    name: 'dimension_name_es_rcph_gl_optional',
  },
  tags: ['ES-RCPH'],
} as const)
export const glOptionalForEsRcphProduct: Record<EsRcphProduct, boolean> = {
  MAC: true,
  MAN: true,
  MEDIA: true,
  MISC: true,
  TECH: false,
  GEST: true,
  ECO: false,
  ENO: false,
  ETG: false,
}

export const esRcphOptionGlDimension = new BooleanDimension({
  name: 'esRcphOptionGl',
  displayKeys: {
    name: 'dimension_name_es_rcph_option_gl',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphOptionDPPDimension = new BooleanDimension({
  name: 'esRcphOptionDPP',
  displayKeys: {
    name: 'dimension_name_es_rcph_option_dpp',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphOptionRezpCruzDimension = new BooleanDimension({
  name: 'esRcphOptionRezpCruz',
  displayKeys: {
    name: 'dimension_name_es_rcph_option_rezp_cruz',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphExclusionsDimension = new ExclusionDimension({
  name: 'esRcphExclusions',
  displayKeys: {
    name: 'dimension_name_es_rcph_exclusions',
  },
  tags: ['ES-RCPH'],
} as const)
