import { type CustomContentProps } from 'notistack'

import styled from '@emotion/styled'
import { forwardRef, memo, type ReactNode } from 'react'
import { colorTokens, spacing } from '../../foundation'
import { Avatar, type CompoundIconName } from '../atoms'
import { colorsPerVariant, type NotificationVariant } from './notification/util'

export type ToastVariant = NotificationVariant | 'neutral'

export type ToastOrusProps = {
  orusVariant?: ToastVariant
  extra?: ReactNode | undefined
}

type ToastProps = CustomContentProps & ToastOrusProps

// notistack expects custom alerts to:
// - forward the ref
// - be a single element (no fragment)
export const Toast = memo(
  forwardRef<HTMLDivElement, ToastProps>(function Alert(props, ref) {
    // Props passed by notistack
    const { message, orusVariant = 'neutral' } = props

    return (
      <ToastContainer ref={ref}>
        {orusVariant !== 'neutral' ? (
          <Avatar
            size="30"
            icon={avatarPerVariant[orusVariant].icon}
            secondaryColor={avatarPerVariant[orusVariant].color}
            noTransparency
            color={colorTokens['color-text-base-primary']}
          />
        ) : null}
        {message}
        {props.extra}
      </ToastContainer>
    )
  }),
)

const ToastContainer = styled.div`
  display: flex;
  padding: ${spacing['50']};
  align-items: center;
  gap: ${spacing['40']};
  border-radius: ${spacing['30']};
  background-color: ${colorTokens['color-bg-base-inverse']};
  color: ${colorTokens['color-text-base-primary']};
  width: max-content;
`

const avatarPerVariant: Record<ToastVariant, { icon?: CompoundIconName; color?: string }> = {
  neutral: {},
  info: { icon: 'circle-info-duotone', color: colorsPerVariant['info'].light.accentColor },
  success: { icon: 'circle-check-duotone', color: colorsPerVariant['success'].light.accentColor },
  warning: { icon: 'triangle-exclamation-duotone', color: colorsPerVariant['warning'].light.accentColor },
  danger: { icon: 'diamond-exclamation-duotone', color: colorsPerVariant['danger'].light.accentColor },
}
