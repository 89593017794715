import type { Amount } from '@orus.eu/amount'
import { type Result, success } from '@orus.eu/result'
import {
  AbstractDimension,
  type DimensionOptions,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from '../abstract-dimension'
import { AmountDimension } from '../amount-dimension'

export type PartnerApplicationFee = Amount | 'none'

export class PartnerApplicationFeeDimension<NAME extends string> extends AbstractDimension<
  NAME,
  PartnerApplicationFee
> {
  private readonly amountDimension: AmountDimension<NAME>
  constructor(options: DimensionOptions<NAME>) {
    super(options)
    this.amountDimension = new AmountDimension<NAME>(options)
  }

  override validateData(value: LooselyTypedValue): Result<PartnerApplicationFee, DimensionValidationProblem> {
    if (value === 'none') return success(value)
    return this.amountDimension.validateData(value)
  }
}

export const partnerApplicationFeeDimension = new PartnerApplicationFeeDimension({
  name: 'partnerApplicationFee',
  displayValues: { name: 'Frais de dossier' },
} as const)
