import { memo, type FunctionComponent } from 'react'
import { GenericProblemMessage } from './generic-problem-message'

export const Unauthorized: FunctionComponent<{ message?: string }> = memo(function NotFound({ message }) {
  return (
    <GenericProblemMessage
      title="Unauthorized"
      principalMessage="Accès restreint"
      firstSubText={
        message ?? "Il semblerait que vous tentiez d'accéder à une ressource à laquelle vous n'avez pas les droits."
      }
      secondSubText="Nous vous suggérons de revenir à la page précédente."
    />
  )
})
