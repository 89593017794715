import { X0, amountToString, rateToPercentString, zeroAmount } from '@orus.eu/amount'
import { AmountDimension } from '../amount-dimension.js'
import { BooleanDimension } from '../boolean-dimension.js'
import { ExclusionDimension } from '../exclusion-dimension.js'
import { FinancialRateDimension } from '../financial-rate-dimension.js'
import { IntegerDimension } from '../integer-dimension.js'
import { TextDimension } from '../text-dimension.js'

export * from './rcda-boss-experience.js'
export * from './rcda-deductible.js'
export * from './rcda-department-dimension.js'
export * from './rcda-guarantee-text.js'
export * from './rcda-insurance-history.js'
export * from './rcda-option-history-takeover-years.js'
export * from './rcda-optional-guarantees.js'
export * from './rcda-options-dimension.js'
export * from './rcda-quote-dimension.js'

export const rcdaAvailableDimension = new BooleanDimension({
  name: 'rcdaAvailable',
  displayValues: { name: 'Décennale Axeria disponible' },
} as const)

export const rcdaSelectedDimension = new BooleanDimension({
  name: 'rcdaSelected',
  displayValues: { name: 'Décennale Axeria' },
  tags: ['RCDA'],
} as const)

export const rcdaYearlyBasePremiumDimension = new AmountDimension({
  name: 'rcdaYearlyBasePremium',
  displayValues: { name: 'Cotisation annuelle HT' },
  tags: ['RCDA'],
} as const)

export const rcdaYearlyTaxesDimension = new AmountDimension({
  name: 'rcdaYearlyTaxes',
  displayValues: { name: 'Taxes annuelles' },
  tags: ['RCDA'],
} as const)

export const rcdaYearlyTotalPremiumDimension = new AmountDimension({
  name: 'rcdaYearlyTotalPremium',
  displayValues: { name: 'Cotisation annuelle TTC' },
  tags: ['RCDA'],
} as const)

export const rcdaYearlyInstallmentFeeDimension = new AmountDimension({
  name: 'rcdaYearlyInstallmentFee',
  displayValues: { name: 'Frais de fractionnement sur une année' },
  tags: ['RCDA'],
} as const)

export const rcdaExclusionsDimension = new ExclusionDimension({
  name: 'rcdaExclusions',
  displayValues: { name: 'Exclusions spécifiques Mutuelle Acheel' },
  tags: ['RCDA'],
})

export const rcdaCompanyLessThanOneYearDimension = new BooleanDimension({
  name: 'rcdaCompanyLessThanOneYear',
  displayValues: { name: "Entreprise de moins d'un an" },
  tags: ['RCDA'],
})

export const rcdaCompanyLessThanSixMonthsDimension = new BooleanDimension({
  name: 'rcdaCompanyLessThanSixMonths',
  displayValues: { name: 'Entreprise de moins de 6 mois' },
  tags: ['RCDA'],
})

export const rcdaCompanyInCreationDimension = new BooleanDimension({
  name: 'rcdaCompanyInCreation',
  displayValues: { name: 'Entreprise en création (moins de 6 mois et non précédemment assurée)' },
  tags: ['RCDA'],
})

export const rcdaCompanyLessThanThreeYearsDimension = new BooleanDimension({
  name: 'rcdaCompanyLessThanThreeYears',
  displayValues: { name: 'Entreprise de moins de 3 ans' },
  tags: ['RCDA'],
})

export const rcdaCompanyMoreThanSixMonthsWithoutInsuranceDimension = new BooleanDimension({
  name: 'rcdaCompanyMoreThanSixMonthsWithoutInsurance',
  displayValues: { name: 'Entreprise de plus de 6 mois, sans assurance' },
  tags: ['RCDA'],
})

export const rcdaPercentageSubcontractedDimension = new FinancialRateDimension({
  name: 'rcdaPercentageSubcontracted',
  displayValues: {
    name: 'Pourcentage de votre CA sous-traité',
    placeholder: rateToPercentString(X0, 0, true),
    hint: "Proportion de votre CA que vous sous-traitez à d'autres entreprises",
  },
  tags: ['RCDA'],
})

export const rcdaAxeriaActivityNamesDimension = new TextDimension({
  name: 'rcdaAxeriaActivityNames',
  displayValues: {
    name: "Liste d'activités compatibles dans le référentiel Axeria, séparées par des virgules",
  },
  tags: ['RCDA'],
})

export const rcdaHasProfessionalQualificationDimension = new BooleanDimension({
  name: 'rcdaHasProfessionalQualification',
  displayValues: { name: 'Qualification professionnelle (type Qualibat)' },
  tags: ['RCDA'],
})

export const rcdaPreviouslyInsuredDimension = new BooleanDimension({
  name: 'rcdaPreviouslyInsured',
  displayValues: { name: 'Déjà assuré en décennale' },
  tags: ['RCDA'],
})

export const rcdaClaimsCountDimension = new IntegerDimension({
  name: 'rcdaClaimsCount',
  displayValues: {
    name: 'Nombre de sinistres',
    placeholder: '0',
    hint: 'Nombre de sinistres déclarés depuis que vous êtes assuré en RC décennale et/ou RC professionnelle.',
  },
  tags: ['RCDA'],
})

export const rcdaHasClaimsDimension = new BooleanDimension({
  name: 'rcdaHasClaims',
  displayValues: { name: 'A déjà eu des sinistres' },
  tags: ['RCDA'],
})

export const rcdaClaimsTotalValueDimension = new AmountDimension({
  name: 'rcdaClaimsTotalValue',
  displayValues: {
    name: 'Valeur des sinistres',
    hint: "Valeur totale de l'ensemble des sinistres (frais et provisions inclus) qui ont fait l'objet d'une procédure d'indemnisation depuis votre premier contrat RC décennale et/ou RC professionnelle.",
    placeholder: amountToString(zeroAmount, { addCurrency: true, displayDecimals: false }),
  },
  tags: ['RCDA'],
})

export const rcdaHistoryTakeoverAvailableDimension = new BooleanDimension({
  name: 'rcdaHistoryTakeoverAvailable',
  displayValues: { name: 'Option reprise du passé disponible' },
  tags: ['RCDA'],
})

export const rcdaOptionHistoryTakeoverDimension = new BooleanDimension({
  name: 'rcdaOptionHistoryTakeover',
  displayValues: { name: 'Option reprise du passé' },
  tags: ['RCDA'],
})

export const rcdaDefaultOfPreviousInsurerDimension = new BooleanDimension({
  name: 'rcdaDefaultOfPreviousInsurer',
  displayValues: { name: 'Défaut de l’assureur précédent' },
  tags: ['RCDA'],
})

export const rcdaOptionSwimmingPoolDimension = new BooleanDimension({
  name: 'rcdaOptionSwimmingPool',
  displayValues: { name: 'Option piscine maçonnée' },
  tags: ['RCDA'],
})

export const rcdaOptionSwimmingPoolAvailableDimension = new BooleanDimension({
  name: 'rcdaOptionSwimmingPoolAvailable',
  displayValues: { name: 'Option piscine maçonnée disponible' },
  tags: ['RCDA'],
})

export const rcdaHasRevenueLimitExceededDimension = new BooleanDimension({
  name: 'rcdaHasRevenueLimitExceeded',
  displayValues: { name: 'Mon entreprise dépasse l’un des seuils ci-dessous' },
  tags: ['RCDA'],
})
