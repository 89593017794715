import { AbstractDimension } from '../dimension/abstract-dimension.js'

export type SubscriptionCondition =
  | AbstractDimension<string, boolean>
  | {
      readonly or: readonly SubscriptionCondition[]
    }
  | {
      readonly and: readonly SubscriptionCondition[]
    }
  | {
      readonly not: SubscriptionCondition
    }
  | {
      readonly defined: AbstractDimension
    }
  | {
      readonly notEmpty: AbstractDimension<string, ReadonlyArray<unknown>>
    }

export function getConditionDimensions(condition: SubscriptionCondition): AbstractDimension[] {
  if ('or' in condition) return condition.or.flatMap(getConditionDimensions)
  if ('and' in condition) return condition.and.flatMap(getConditionDimensions)
  if ('not' in condition) return getConditionDimensions(condition.not)
  if ('defined' in condition) return [condition.defined]
  if ('notEmpty' in condition) return [condition.notEmpty]
  return [condition]
}

export function evaluateCondition(state: Record<string, unknown>, condition: SubscriptionCondition): boolean {
  if ('and' in condition) return condition.and.every((subCondition) => evaluateCondition(state, subCondition))
  if ('or' in condition) return condition.or.some((subCondition) => evaluateCondition(state, subCondition))
  if ('not' in condition) return !evaluateCondition(state, condition.not)
  if ('defined' in condition) {
    // null and undefined both mean the the state field is not defined
    return state[condition.defined.name] != undefined
  }
  if ('notEmpty' in condition) {
    const arrayElements = state[condition.notEmpty.name]
    return Array.isArray(arrayElements) && arrayElements.length > 0
  }
  const value = condition.getPartialStateFieldValue(state as Record<string, boolean>)
  return !!value
}
