export type Observable<T> = {
  /**
   * Retrieve the current value of the observable
   */
  getValue(): T

  /**
   * Subscribe to value changes
   * @param subscriber Called when the value changes
   * @return a callback to call to unsubscribe
   */
  subscribe(subscriber: (value: T) => void): () => void
}

export type Subscribable<T> = Observable<T> & {
  /**
   * Sets the value and triggers the listeners
   * @param value
   */
  setValue(newValueOrFunction: T | ((value: T) => T)): void
}

export function newSubscribable<T>(initialValue: T): Subscribable<T> {
  let value = initialValue
  let subscribers = new Array<(value: T) => void>()
  return {
    getValue(): T {
      return value
    },
    setValue(newValueOfFunction: T | ((value: T) => T)) {
      const newValue =
        typeof newValueOfFunction === 'function'
          ? ((newValueOfFunction as (value: T) => T)(value) as T)
          : newValueOfFunction

      if (value === newValue) {
        return
      }
      value = newValue
      subscribers.forEach((s) => s(newValue))
    },
    subscribe(subscriber: (value: T) => void): () => void {
      subscribers.push(subscriber)
      return () => {
        subscribers = subscribers.filter((s) => s !== subscriber)
      }
    },
  }
}
