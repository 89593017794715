import { z } from 'zod'
import { StringsEnumDimension } from '../strings-enum-dimension.js'
import { TextDimension } from '../text-dimension.js'

const mrpwProducts = ['REST', 'RETA'] as const
export const MrpwProductSchema = z.enum(mrpwProducts)
export type MrpwProduct = z.infer<typeof MrpwProductSchema>

export const mrpwProductLabels: { [product in MrpwProduct]: string } = {
  REST: 'Cafés et Restaurants',
  RETA: 'Artisans Commerçants et Prestataires de service',
}

export const mrpwProductDimension = new StringsEnumDimension({
  name: 'mrpwProduct',
  displayValues: { name: 'Nom technique du produit' },
  values: mrpwProducts,
  tags: ['MRPW'],
} as const)

export const mrpwProductLabelDimension = new TextDimension({
  name: 'mrpwProductLabel',
  displayValues: { name: 'Nom commercial du produit' },
  tags: ['MRPW'],
} as const)
