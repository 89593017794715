import { TechnicalError } from '@orus.eu/error'
import { z } from 'zod'

export const operatingZones = ['fr', 'es'] as const

export function assertIsOperatingZone(value: string): asserts value is OperatingZone {
  if (!(operatingZones as readonly string[]).includes(value)) {
    throw new TechnicalError('Value is not a valid OperatingZone', { context: value })
  }
}

export const operatingZoneSchema = z.enum(operatingZones)

/**
 * Enum of the geographical zones in which Orus operates
 */
export type OperatingZone = z.infer<typeof operatingZoneSchema>
