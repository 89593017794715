import { TechnicalError } from '@orus.eu/error'
import { assert } from 'ts-essentials'
import { mutaGuaranteesGroups, type MutaGuaranteeGroupType } from '../quote-offer-information-dimension.js'
import { isGuaranteeLimit, type MutaGuaranteeLimit } from './guarantees/muta-guarantees-limit.js'
import type { MutaFormulaType } from './muta-formula-dimension.js'
import { mutaGroupPerGuarantee, type ExposedMutaGuarantee } from './muta-group-per-guarantee.js'
import { getMutaGuarantees } from './muta-guarantee.js'
import { formatGuaranteeLimit } from './muta-guarantees-dimension.js'

export const guaranteesPerGroup: Record<MutaGuaranteeGroupType, ExposedMutaGuarantee[]> = Object.entries(
  mutaGroupPerGuarantee,
).reduce(
  (acc, [guarantee, group]) => {
    acc[group].push(guarantee as ExposedMutaGuarantee)
    return acc
  },
  Object.fromEntries(mutaGuaranteesGroups.map((group) => [group, []])) as unknown as Record<
    MutaGuaranteeGroupType,
    ExposedMutaGuarantee[]
  >,
)

export function getMutaGuaranteesForGuaranteeLimit(
  mutaFormula: MutaFormulaType,
  group: MutaGuaranteeGroupType,
): { type: 'single'; value: string } | { type: 'optam'; value: { optam: string; notOptam: string } } {
  const guaranteesPerFomula = getMutaGuarantees({ mutaFormula })

  const isOptamGuarantee = guaranteesPerGroup[group].some((guarantee) => {
    const guarantees = guaranteesPerFomula[guarantee]
    return guarantees.type === 'optam' || guarantees.type === 'optamThreeYears'
  })

  if (!isOptamGuarantee) {
    const guaranteeLimits = guaranteesPerGroup[group].map((guarantee) => {
      const guarantees = guaranteesPerFomula[guarantee]
      assert(isGuaranteeLimit(guarantees))
      return guarantees
    })
    const limit = formatMutaGuaranteeLimit(guaranteeLimits)
    return { type: 'single', value: limit }
  }

  const optamGuaranteeLimits: MutaGuaranteeLimit[] = []
  const notOptamGuaranteeLimits: MutaGuaranteeLimit[] = []

  guaranteesPerGroup[group].forEach((guarantee) => {
    const guarantees = guaranteesPerFomula[guarantee]

    if (guarantees.type === 'optam') {
      optamGuaranteeLimits.push(guarantees.PA)
      notOptamGuaranteeLimits.push(guarantees.PNA)
    }

    if (guarantees.type === 'optamThreeYears') {
      optamGuaranteeLimits.push(guarantees.PA.FY, guarantees.PA.SY, guarantees.PA.TY)
      notOptamGuaranteeLimits.push(guarantees.PNA)
    }
  })

  return {
    type: 'optam',
    value: {
      optam: formatMutaGuaranteeLimit(optamGuaranteeLimits),
      notOptam: formatMutaGuaranteeLimit(notOptamGuaranteeLimits),
    },
  }
}

export function formatMutaGuaranteeLimit(guaranteeLimits: MutaGuaranteeLimit[]): string {
  const firstGuaranteeLimit = guaranteeLimits[0]

  assert(firstGuaranteeLimit, 'First guarantee limit should exist')
  const expectedType = firstGuaranteeLimit.type

  const allTypeMatch = guaranteeLimits.every((guaranteeLimit) => guaranteeLimit?.type === expectedType)

  if (!allTypeMatch) {
    throw new TechnicalError('All types are not matching', { context: { guaranteeLimits } })
  }

  if (
    firstGuaranteeLimit.type === 'yes' ||
    firstGuaranteeLimit.type === 'notReimbursed' ||
    firstGuaranteeLimit.type === 'realCost' ||
    firstGuaranteeLimit.type === 'percentage-and-amount' ||
    firstGuaranteeLimit.type === 'sessionPerInsured' ||
    firstGuaranteeLimit.type === 'session'
  ) {
    return formatGuaranteeLimit(firstGuaranteeLimit)
  }

  let min = firstGuaranteeLimit
  let max = firstGuaranteeLimit

  guaranteeLimits.forEach((guaranteeLimit) => {
    assert(guaranteeLimit, 'guaranteeLimit should exist')
    if (guaranteeLimit.type === 'percentage') {
      assert(min.type === 'percentage')
      if (guaranteeLimit.percentage < min.percentage) min = guaranteeLimit
      assert(max.type === 'percentage')
      if (guaranteeLimit.percentage > max.percentage) max = guaranteeLimit
    }
    if (guaranteeLimit.type === 'amount') {
      assert(min.type === 'amount')
      if (guaranteeLimit.amount < min.amount) min = guaranteeLimit
      assert(max.type === 'amount')
      if (guaranteeLimit.amount > max.amount) max = guaranteeLimit
    }
  })

  if (firstGuaranteeLimit.type === 'percentage') {
    assert(min.type === 'percentage')
    assert(max.type === 'percentage')
    if (min.percentage === max.percentage) return formatGuaranteeLimit(min)

    return `De ${formatGuaranteeLimit(min)} à ${formatGuaranteeLimit(max)}`
  }

  assert(min.type === 'amount')
  assert(max.type === 'amount')
  if (min.amount === max.amount) return formatGuaranteeLimit(min)

  return `De ${formatGuaranteeLimit(min)} à ${formatGuaranteeLimit(max)}`
}
