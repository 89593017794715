import { addAmounts, divideByNumber, multiplyByNumber, type Amount } from '@orus.eu/amount'
import type { PaymentRecurrence, RcdaQuote } from '@orus.eu/quote'
import { AbstractDimension, type TypeOfDimension } from '../abstract-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'

export class RcdaQuoteDimension<NAME extends string> extends AbstractDimension<NAME, RcdaQuote> {}

export const rcdaQuoteDimension = new RcdaQuoteDimension({
  name: 'rcdaQuote',
  displayValues: { name: 'Devis Décennale Axeria' },
  tags: ['RCDA'],
} as const)

export const rcdaQuoteExclusionDimension = new StringsWithDataEnumDimension({
  name: 'rcdaQuoteExclusion',
  displayValues: { name: 'Exclusion devis décennale Axeria' },
  entries: [
    ['insurance-history', { label: "Antécédants d'assurance" }],
    ['employees-count', { label: "Nombre d'employés" }],
  ],
  tags: ['RCDA'],
} as const)

export type RcdaQuoteExclusion = TypeOfDimension<typeof rcdaQuoteExclusionDimension>

export function getRcdaInstallmentFeePerMonth(rcdaQuote: RcdaQuote): Amount | undefined {
  return rcdaQuote.monthlyFees && rcdaQuote.monthlyFees.installment
    ? divideByNumber(rcdaQuote.monthlyFees.installment, 12)
    : undefined
}

export function getRcdaInstallmentFeePerYear(rcdaQuote: RcdaQuote): Amount | undefined {
  return rcdaQuote.monthlyFees && rcdaQuote.monthlyFees.installment ? rcdaQuote.monthlyFees.installment : undefined
}

export function getRcdaEffectiveYearlyTotalPremium({
  rcdaQuote,
  paymentRecurrence,
}: {
  rcdaQuote: RcdaQuote
  paymentRecurrence: PaymentRecurrence
}): Amount {
  return paymentRecurrence === 'monthly'
    ? getRcdaCummulatedMonthlyTotalPremiumPerYear(rcdaQuote)
    : rcdaQuote.yearly.final.total.total
}

export function getRcdaCummulatedMonthlyTotalPremiumPerYear(rcdaQuote: RcdaQuote): Amount {
  return addAmounts(
    rcdaQuote.monthly.final.total.firstMonthTotal,
    multiplyByNumber(rcdaQuote.monthly.final.total.subsequentMonthsTotal, 11),
  )
}
