import { z } from 'zod'
import { BooleanDimension } from './boolean-dimension.js'
import { TextDimension } from './text-dimension.js'

/**
 * Address of the activity being insured
 */
export const activityAddressDimension = new TextDimension({
  name: 'activityAddress',
  displayValues: { name: 'Address du bureau assuré' },
} as const)

export const activityAddressStreetDimension = new TextDimension({
  name: 'activityAddressStreet',
  oldNames: ['mrphAddressStreet'],
  displayKeys: {
    name: 'address_street',
    placeholder: 'address_street_placeholder',
  },
} as const)

export const activityAddressPostCodeDimension = new TextDimension({
  name: 'activityAddressPostCode',
  oldNames: ['mrphAddressPostCode'],
  displayKeys: {
    name: 'address_postcode',
    hint: 'dimension_hint_activity_address_post_code',
    placeholder: 'address_postcode_placeholder',
  },
} as const)

export const activityAddressCityDimension = new TextDimension({
  name: 'activityAddressCity',
  oldNames: ['mrphAddressCity'],
  displayKeys: {
    name: 'address_city',
    placeholder: 'address_city_placeholder',
  },
} as const)

/**
 * Address of the legal entity owning the contract
 */
export const companyAddressDimension = new TextDimension({
  name: 'companyAddress',
  displayValues: { name: 'Addresse du siège social' },
} as const)

export const companyAddressStreetDimension = new TextDimension({
  name: 'companyAddressStreet',
  displayValues: {
    name: 'Numéro et nom de la rue',
    placeholder: '2 rue Jules Lefebvre',
  },
} as const)
export const companyAddressPostCodeDimension = new TextDimension({
  name: 'companyAddressPostCode',
  displayValues: {
    name: 'Code postal',
    hint: 'Le code postal de votre entreprise',
    placeholder: '75009',
  },
} as const)
export const companyAddressCityDimension = new TextDimension({
  name: 'companyAddressCity',
  displayValues: {
    name: 'Ville',
    placeholder: 'Paris',
  },
} as const)

/**
 * Address of the physical person signing the contract
 */
export const personalAddressDimension = new TextDimension({
  name: 'personalAddress',
  displayValues: { name: 'Addresse personnelle du souscripteur' },
} as const)

export const personalAddressStreetDimension = new TextDimension({
  name: 'personalAddressStreet',
  displayValues: {
    name: 'Numéro et nom de la rue',
    placeholder: '2 rue Jules Lefebvre',
    ariaLabel: 'Numéro et nom de la rue de votre adresse personnelle',
  },
} as const)
export const personalAddressIsNotActivityAddress = new BooleanDimension({
  name: 'personalAddressIsNotActivityAddress',
  displayValues: { name: 'Mon adresse personnelle est différente de celle de mon entreprise' },
} as const)
export const personalAddressPostCodeDimension = new TextDimension({
  name: 'personalAddressPostCode',
  displayValues: {
    name: 'Code postal',
    placeholder: '75009',
    ariaLabel: 'Code postal de votre adresse personnelle',
  },
} as const)
export const personalAddressCityDimension = new TextDimension({
  name: 'personalAddressCity',
  displayValues: {
    name: 'Ville',
    placeholder: 'Paris',
    ariaLabel: 'Ville de votre adresse personnelle',
  },
} as const)

export const cityFormats = ['standard', 'wakam'] as const
export const cityFormatZodSchema = z.enum(cityFormats)
export type CityFormat = (typeof cityFormats)[number]
