import {
  addAmounts,
  divideByNumber,
  gt,
  multiplyByNumber,
  substractAmounts,
  zeroAmount,
  type Amount,
  type FinancialRate,
} from '@orus.eu/amount'
import { TechnicalError } from '@orus.eu/error'
import { AbstractDimension } from './abstract-dimension.js'
import type { PaymentRecurrence } from './payement-recurrence-dimension.js'

/**
 * Aggregated pricing information from multiple products. The quote contains pricing information
 * for both payment recurrence, regardless of the selected payment recurrence, to allow comparison
 * in the UI.
 */
export type AggregatedQuote = {
  /**
   * Payment recurrence that was selected at the time this quote was computed.
   */
  paymentRecurrence: PaymentRecurrence

  /**
   * Amount of the first payment.
   * Can differ from monthlyTotalPremium if there is a terrorism tax
   * Can differ from yearlyTotalPremium if there is a partner application
   */
  firstPaymentAmount: Amount

  /**
   * Yearly base premium that is applicable if the payment recurrence is yearly
   */
  yearlyBasePremium: Amount

  /**
   * Yearly taxes that is applicable if the payment recurrence is yearly, including terrorism tax
   */
  yearlyTaxes: Amount

  /**
   * Part of the yearlyBasePremium that is the orus fee
   */
  yearlyOrusFee: Amount

  /**
   * Yearly total amount to pay, applicable if the payment recurrence is yearly
   */
  yearlyTotalPremium: Amount

  /**
   * Monthly base premium that is applicable if the payment recurrence is monthly
   */
  monthlyBasePremium: Amount

  /**
   * Monthly taxes that is applicable if the payment recurrence is yearly
   */
  monthlyTaxes: Amount

  /**
   * Part of the monthlyBasePremium that is the orus fee
   */
  monthlyOrusFee: Amount

  /**
   * Monthly total amount to pay, applicable if the payment recurrence is monthly
   */
  subsequentMonthsTotalPremium: Amount

  /**
   * Monthly total amount specific to the first month, applicable if the payment recurrence is monthly
   */
  firstMonthTotalPremium: Amount

  /**
   * Terrorism taxes, already included
   */
  terrorismTax: Amount

  /**
   * Effective yearly installment fees if any, included in subsequentMonthsTotalPremium and firstMonthTotalPremium
   */
  installmentFee?: Amount

  /**
   * Rcda history takeover premium if any, not included in monthly nor yearly premiums
   */
  historyTakeoverPremium?: Amount

  /**
   * Monthly partner management premium part if any, included in monthly and yearly premiums
   */
  monthlyPartnerManagementPremium?: Amount

  /**
   * Yearly partner management premium part if any, included in monthly and yearly premiums
   */
  yearlyPartnerManagementPremium?: Amount

  /**
   * Partner application premium part if any, not included in monthly nor yearly premiums
   */
  partnerApplicationPremium?: Amount

  /** The applicable partner commission rate for the quote*/
  partnerCommissionRate?: FinancialRate
}

/**
 * Returns the total yearly cost of the monthly payments described by a quote
 */
export function getCummulatedMonthlyTotalPremiumPerYear(quote: AggregatedQuote): Amount {
  return addAmounts(quote.firstMonthTotalPremium, multiplyByNumber(quote.subsequentMonthsTotalPremium, 11))
}

/**
 * Returns the yearly cost of the monthly payments described by a quote, excluding taxes
 */
export function getCummulatedMonthlyBasePremiumPerYear(quote: AggregatedQuote): Amount {
  return multiplyByNumber(quote.monthlyBasePremium, 12)
}

/**
 * Returns the yearly cost of the monthly taxes described by a quote
 */
export function getCummulatedMonthlyTaxesPerYear(quote: AggregatedQuote): Amount {
  return multiplyByNumber(quote.monthlyTaxes, 12)
}
/**
 * Returns the yearly cost of the monthly orus fees described by a quote
 */
export function getCumulatedMonthlyOrusFeePerYear(quote: AggregatedQuote): Amount {
  return multiplyByNumber(quote.monthlyOrusFee, 12)
}

/**
 * Returns the total yearly cost of the monthly payments described by a quote
 */
export function getYearlyTotalPremiumSplitPerMonth(quote: AggregatedQuote): Amount {
  return divideByNumber(quote.yearlyTotalPremium, 12)
}

/**
 * Returns the effective yearly total premium, taking into account the payment recurrence
 */
export function getEffectiveYearlyTotalPremium(quote: AggregatedQuote): Amount {
  return quote.paymentRecurrence === 'monthly'
    ? getCummulatedMonthlyTotalPremiumPerYear(quote)
    : quote.yearlyTotalPremium
}

/**
 * Returns the effective yearly premium, taking into account the payment recurrence, excluding taxes
 */
export function getEffectiveYearlyBasePremium(quote: AggregatedQuote): Amount {
  return quote.paymentRecurrence === 'monthly' ? getCummulatedMonthlyBasePremiumPerYear(quote) : quote.yearlyBasePremium
}

/**
 * Returns the effective yearly taxes, taking into account the payment recurrence
 */
export function getEffectiveYearlyTaxes(quote: AggregatedQuote): Amount {
  return quote.paymentRecurrence === 'monthly' ? getCummulatedMonthlyTaxesPerYear(quote) : quote.yearlyBasePremium
}
/**
 * Returns the effective yearly orus fees, taking into account the payment recurrence
 */
export function getEffectiveYearlyOrusFee(quote: AggregatedQuote): Amount {
  return quote.paymentRecurrence === 'monthly' ? getCumulatedMonthlyOrusFeePerYear(quote) : quote.yearlyOrusFee
}

export function getNormalizedFirstPaymentAmount(
  partnerApplicationPremiumsLength: number,
  partnerApplicationPremium: Amount | undefined,
  firstPaymentAmount: Amount,
): Amount {
  if (partnerApplicationPremiumsLength > 1) {
    for (let i = 1; i < partnerApplicationPremiumsLength; i++) {
      firstPaymentAmount = substractAmounts(firstPaymentAmount, partnerApplicationPremium ?? zeroAmount)
    }
  }
  return firstPaymentAmount
}

export function aggregateQuotes(quotes: Array<AggregatedQuote | undefined>): AggregatedQuote | undefined {
  if (quotes.includes(undefined)) {
    // cannot compute the total quote if some of the product quote are undefined
    return undefined
  }
  const definedQuotes = quotes as AggregatedQuote[]

  const [firstDefinedQuote, ...otherQuotes] = definedQuotes

  if (!firstDefinedQuote) {
    // cannot compute the total quote if no products are selected
    return undefined
  }

  const paymentRecurrence = firstDefinedQuote.paymentRecurrence
  if (otherQuotes.some((quote) => quote.paymentRecurrence !== paymentRecurrence)) {
    // we do not support this for now
    throw new TechnicalError('Inconsistent payment recurrences', { context: { definedQuotes } })
  }

  //Partner application premium is included in each quotes, with the same value
  //It needs to be only added once to the first payment amount
  let firstPaymentAmount = addAmounts(...definedQuotes.map((quote) => quote.firstPaymentAmount))
  const partnerApplicationPremiumsLength = definedQuotes.filter(
    (quote) => quote.partnerApplicationPremium && gt(quote.partnerApplicationPremium, zeroAmount),
  ).length

  firstPaymentAmount = getNormalizedFirstPaymentAmount(
    partnerApplicationPremiumsLength,
    firstDefinedQuote.partnerApplicationPremium,
    firstPaymentAmount,
  )

  return {
    firstPaymentAmount,
    yearlyBasePremium: addAmounts(...definedQuotes.map((quote) => quote.yearlyBasePremium)),
    yearlyTaxes: addAmounts(...definedQuotes.map((quote) => quote.yearlyTaxes)),
    yearlyOrusFee: addAmounts(...definedQuotes.map((quote) => quote.yearlyOrusFee)),
    yearlyTotalPremium: addAmounts(...definedQuotes.map((quote) => quote.yearlyTotalPremium)),
    monthlyBasePremium: addAmounts(...definedQuotes.map((quote) => quote.monthlyBasePremium)),
    monthlyOrusFee: addAmounts(...definedQuotes.map((quote) => quote.monthlyOrusFee)),
    monthlyTaxes: addAmounts(...definedQuotes.map((quote) => quote.monthlyTaxes)),
    subsequentMonthsTotalPremium: addAmounts(...definedQuotes.map((quote) => quote.subsequentMonthsTotalPremium)),
    firstMonthTotalPremium: addAmounts(...definedQuotes.map((quote) => quote.firstMonthTotalPremium)),
    terrorismTax: addAmounts(...definedQuotes.map((quote) => quote.terrorismTax)),
    paymentRecurrence,
    installmentFee: addAmounts(...definedQuotes.map((quote) => quote.installmentFee ?? zeroAmount)),
    monthlyPartnerManagementPremium: addAmounts(
      ...definedQuotes.map((quote) => quote.monthlyPartnerManagementPremium ?? zeroAmount),
    ),
    partnerApplicationPremium: firstDefinedQuote.partnerApplicationPremium ?? zeroAmount,
    yearlyPartnerManagementPremium: addAmounts(
      ...definedQuotes.map((quote) => quote.yearlyPartnerManagementPremium ?? zeroAmount),
    ),
  }
}

export class QuoteDimension<NAME extends string> extends AbstractDimension<NAME, AggregatedQuote> {}

export const quoteDimension = new QuoteDimension({
  name: 'quote',
  displayValues: { name: 'Devis du total des produits' },
} as const)
