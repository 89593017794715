import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'
import { spacing } from '../../foundation/spacing-tokens'

type ButtonGroupProps = {
  children: ReactNode
}

export const ButtonGroup = memo<ButtonGroupProps>(function Switch(props) {
  const { children } = props

  return (
    <div
      css={css`
        display: inline-flex;
        align-items: center;
        gap: ${spacing['30']};
      `}
    >
      {children}
    </div>
  )
})
