import styled from '@emotion/styled'
import { PARIS, formatDdMmYyyy, getCalendarDateFromTimestamp } from '@orus.eu/calendar-date'
import { memo } from 'react'
import { colorTokens } from '../../../foundation/color-tokens'
import { spacing } from '../../../foundation/spacing-tokens'
import { Avatar } from '../../atoms/avatar/avatar'
import { Text } from '../../atoms/text'
import { Button } from '../../button/button'
import { Chip } from '../../chip'
import { Spinner } from '../../spinner'
import type { FileRejectionReason, RejectedFile, UploadedFile, UploaderFile } from './uploader-file'

export type UploaderFileRowProps = {
  file: UploaderFile
  onFileRemoved: (file: UploadedFile | RejectedFile) => void
  onDownloadFile: (file: UploadedFile) => void
}

export const UploaderFileRow = memo<UploaderFileRowProps>(function UploaderFileRow({
  file,
  onFileRemoved,
  onDownloadFile,
}) {
  return (
    <FlexWrapper $file={file}>
      <Avatar size="30" icon="file-light" />
      <NameAndStatusContainer onClick={() => (file.type === 'uploaded' ? onDownloadFile(file) : null)}>
        <Text variant="body2Medium">{file.name}</Text>
        <Text variant="body2" color={file.type === 'rejected' ? colorTokens['color-bg-danger-primary'] : undefined}>
          {getStatusText(file)}
        </Text>
      </NameAndStatusContainer>
      {file.type === 'uploading' || file.type === 'removing' ? <Spinner size="30" /> : null}
      {file.type === 'rejected' ? (
        <Avatar size="30" color={colorTokens['color-fg-danger']} icon="circle-exclamation-solid" />
      ) : null}
      {file.type === 'uploaded' ? <Chip size="small">Envoyé</Chip> : null}
      {file.type === 'uploaded' || file.type === 'rejected' ? (
        <Button icon="xmark-regular" variant="text" onClick={() => onFileRemoved(file)} />
      ) : null}
    </FlexWrapper>
  )
})

const FlexWrapper = styled.div<{ $file: UploaderFile }>`
  display: flex;
  padding: ${spacing['40']} ${spacing['50']};
  align-items: center;
  gap: ${spacing['50']};

  border-radius: ${spacing['30']};
  border: ${({ $file }) =>
    $file.type === 'rejected'
      ? `2px solid ${colorTokens['color-stroke-danger']}`
      : `1px solid ${colorTokens['color-stroke-base']}`};
  background: ${colorTokens['color-bg-base-normal']};

  &:hover {
    border: ${({ $file }) =>
      $file.type === 'rejected'
        ? `2px solid ${colorTokens['color-stroke-danger']}`
        : `1px solid ${colorTokens['color-stroke-base-hover']}`};
    background: ${colorTokens['color-bg-base-secondary']};
  }
`

const NameAndStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 0; /* https://stackoverflow.com/a/66689926 */
  white-space: nowrap;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  cursor: pointer;
`

function getStatusText(file: UploaderFile) {
  switch (file.type) {
    case 'loaded':
    case 'uploading':
      return 'En cours de chargement...'
    case 'removing':
      return 'En cours de suppression...'
    case 'rejected':
      return rejectionReasonStatusText[file.reason]
    case 'uploaded':
      return `Importé le ${formatDdMmYyyy(getCalendarDateFromTimestamp(file.uploadTimestamp, PARIS))}`
  }
}

const rejectionReasonStatusText: Record<FileRejectionReason, string> = {
  'too-big': 'Fichier trop volumineux',
  'invalid-format': 'Format de fichier non accepté',
  'load-failed': 'Erreur lors du chargement',
  'upload-failed': "Erreur lors de l'envoi",
}
