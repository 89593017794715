import styled from '@emotion/styled'
import { colors } from '../../colors.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { FlexColumn } from './container.js'

export const SummaryTable = styled(FlexColumn)<{ withMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding: 0 ${spacing[50]} 0 ${spacing[50]};

  border: 1px solid ${colors.gray[100]};
  border-radius: 8px;

  & > * {
    border-bottom: 1px solid ${colors.gray[100]};

    &:last-child {
      border-bottom: none;
    }
  }
`
