import type { MutaQuote } from '@orus.eu/quote'
import { AbstractDimension } from '../abstract-dimension.js'

export class MutaQuoteDimension<NAME extends string> extends AbstractDimension<NAME, MutaQuote> {}

export const mutaQuoteDimension = new MutaQuoteDimension({
  name: 'mutaQuote',
  displayValues: { name: 'Devis Mutuelle Acheel' },
  tags: ['MUTA'],
} as const)
