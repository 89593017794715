import { css } from '@emotion/react'
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
  type DesktopDatePickerProps,
} from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { esES, frFR, type PickersInputLocaleText } from '@mui/x-date-pickers/locales'
import {
  PARIS,
  calendarDateGt,
  calendarDateLt,
  getStartOfCalendarDate,
  type CalendarDate,
} from '@orus.eu/calendar-date'
import type { Language } from '@orus.eu/translations'
import { DateTime } from 'luxon'
import { memo, useCallback, useMemo, useState } from 'react'
import { colorTokens } from '../../foundation/color-tokens.js'
import { useScreenType } from '../../hooks/index.js'
import { useLanguage, useTranslate } from '../../localization/language-context.js'
import { Avatar } from './avatar/avatar.js'

export type DatepickerProps = {
  calendarDate?: CalendarDate | null | undefined
  onChange: (newValue: CalendarDate | null) => void
  minDate?: CalendarDate
  maxDate?: CalendarDate
  maxDateExplanation?: string
  disabled?: boolean
  errorMessage?: string
  error?: boolean
  autoFocus?: boolean
  size?: 'small' | 'large'

  className?: string
}

const format = 'dd/MM/yyyy'
const inputFormatDisplays: Record<Language, string> = {
  fr: 'JJ/MM/AAAA',
  es: 'DD/MM/AAAA',
}

export const Datepicker = memo(function CalendarDateField(props: DatepickerProps): JSX.Element {
  const language = useLanguage()
  const translate = useTranslate()
  const screenType = useScreenType()
  const [validationActive, setValidationActive] = useState(false)
  const {
    calendarDate,
    onChange,
    disabled,
    minDate,
    maxDate,
    autoFocus,
    size = 'large',
    className,
    error,
    maxDateExplanation,
  } = props
  const externalErrorMessage = props.errorMessage

  const [internalErrorMessage, setInternalErrorMessage] = useState<string | null>(null)
  const value = calendarDate
    ? DateTime.local(calendarDate.year, calendarDate.oneBasedMonth, calendarDate.oneBasedDay, 12)
    : null

  const setValue = useCallback(
    (date: DateTime | null) => {
      // The DatePicker component from MUI may send invalid dates during typing.
      // Since CalendarDate is not supposed to store invalid values, we prefer to send null values to the upper component
      if (!date) {
        onChange(null)
        setInternalErrorMessage(null)
        return
      }

      if (!date.isValid) {
        onChange(null)
        setInternalErrorMessage(translate('datepicker_error_format', { format: inputFormatDisplays[language] }))
        return
      }

      const selectedCalendarDate: CalendarDate = {
        year: date.year,
        oneBasedMonth: date.month,
        oneBasedDay: date.day,
      }
      if (minDate && calendarDateLt(selectedCalendarDate, minDate)) {
        const minDateValue = DateTime.local(minDate.year, minDate.oneBasedMonth, minDate.oneBasedDay, 12)
        const formattedDate = minDateValue.toFormat(format)
        onChange(null)
        setInternalErrorMessage(translate('datepicker_error_too_soon', { date: formattedDate }))
        return
      }
      if (maxDate && calendarDateGt(selectedCalendarDate, maxDate)) {
        const maxDateValue = DateTime.local(maxDate.year, maxDate.oneBasedMonth, maxDate.oneBasedDay, 12)
        onChange(null)
        setInternalErrorMessage(
          maxDateExplanation ?? translate('datepicker_error_too_late', { date: maxDateValue.toFormat(format) }),
        )
        return
      }

      onChange(selectedCalendarDate)
      setInternalErrorMessage(null)
    },
    [language, maxDate, maxDateExplanation, minDate, onChange, translate],
  )

  const errorMessage = validationActive ? internalErrorMessage || externalErrorMessage : null

  const datePickerProps: DesktopDatePickerProps<DateTime> = useMemo(
    () => ({
      className,
      slots: {
        openPickerIcon: OpenPickerIcon,
      },
      disabled,
      value,
      onChange: (newValue) => {
        setValue(newValue)
      },
      slotProps: {
        textField: {
          fullWidth: true,
          error: !!errorMessage,
          helperText: errorMessage,
          onBlur: () => setValidationActive(true),
          inputProps: {
            placeholder: inputFormatDisplays[language],
          },
          autoFocus,
          // Orus size is "large" or "small" but Mui size is "medium" or "small"
          size: size === 'large' ? 'medium' : 'small',
        },
      },
      minDate: minDate ? getStartOfCalendarDate(minDate, PARIS) : undefined,
      maxDate: maxDate ? getStartOfCalendarDate(maxDate, PARIS) : undefined,
    }),
    [className, disabled, value, errorMessage, language, autoFocus, size, minDate, maxDate, setValue],
  )

  const MuiDatePicker = MuiDatePickers[screenType]

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={adapterLocales[language]}
      localeText={pickersInputLocaleTexts[language]}
    >
      <MuiDatePicker
        {...datePickerProps}
        css={css`
          & fieldset {
            border: ${error ? `2px solid ${colorTokens['color-stroke-danger']}` : ''};
          }
        `}
      />
    </LocalizationProvider>
  )
})

const OpenPickerIcon = memo(function OpenPickerIcon(): JSX.Element {
  return <Avatar icon="calendar-regular" size="30" />
})

const MuiDatePickers = {
  desktop: DesktopDatePicker,
  mobile: MobileDatePicker,
}

const pickersInputLocaleTexts: Record<Language, PickersInputLocaleText<DateTime>> = {
  fr: frFR.components.MuiLocalizationProvider.defaultProps.localeText,
  es: esES.components.MuiLocalizationProvider.defaultProps.localeText,
}

const adapterLocales: Record<Language, string> = {
  fr: 'fr',
  es: 'es',
}
