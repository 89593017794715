import { z } from 'zod'
import { StringsEnumDimension } from '../strings-enum-dimension.js'
import { TextDimension } from '../text-dimension.js'

export const rcphProducts = ['TECH', 'MAC', 'MAN', 'MEDIA', 'MISC', 'MISCBIE', 'SECU', 'TAG', 'ECOM'] as const

export const RcphProductSchema = z.enum(rcphProducts)

/**
 * Enumerated the "Products" according to Hiscox RC Pro
 */
export type RcphProduct = z.infer<typeof RcphProductSchema>

export const rcphProductLabels: { [product in RcphProduct]: string } = {
  TECH: "métiers de l'informatique",
  MAC: 'métiers du marketing et de la communication',
  MAN: 'métiers du conseil en entreprise',
  MEDIA: 'métiers des médias et de la culture',
  MISC: 'métiers du service',
  MISCBIE: 'métiers du bien-être',
  SECU: 'métiers de la sécurité',
  TAG: 'métiers du tourisme',
  ECOM: 'e-commerce',
}

export const rcphProductDimension = new StringsEnumDimension({
  name: 'rcphProduct',
  displayValues: { name: 'Produit RC Pro Hiscox (identifiant technique)' },
  values: rcphProducts,
  tags: ['RCPH'],
} as const)

export const rcphProductLabelDimension = new TextDimension({
  name: 'rcphProductLabel',
  displayValues: { name: 'Produit RC Pro Hiscox (nom affiché aux clients)' },
  tags: ['RCPH'],
} as const)
