import { BaseDimension, type TypeOfDimension } from '../abstract-dimension.js'
import { NumbersEnumDimension } from '../numbers-enum-dimension.js'

// from https://docs.google.com/spreadsheets/d/1dcvZTqCaDkVlN0XhpHvM0c2FewNGaajQ/edit?gid=1697027762#gid=1697027762
export const esRcphLoiValues = [
  50_000, 100_000, 150_000, 300_000, 600_000, 900_000, 1_000_000, 1_200_000, 1_500_000, 2_000_000, 3_000_000,
] as const
export const esRcphLoiDimension = new NumbersEnumDimension({
  name: 'esRcphLoi',
  displayKeys: {
    name: 'dimension_name_es_rcph_loi',
  },
  values: esRcphLoiValues,
  tags: ['ES-RCPH'],
} as const)
export type EsRcphLoi = TypeOfDimension<typeof esRcphLoiDimension>
export function isEsRcphLoi(value: unknown): value is EsRcphLoi {
  return typeof value === 'number' && (esRcphLoiValues as readonly number[]).includes(value)
}

export const esRcphAllowedLoisDimension = new BaseDimension<'esRcphAllowedLois', EsRcphLoi[]>({
  name: 'esRcphAllowedLois',
  displayKeys: {
    name: 'dimension_name_es_rcph_allowed_lois',
  },
  tags: ['ES-RCPH'],
} as const)
