import { memo } from 'react'

export const OrusHorizontalLogoImage = memo<{ width: number; height: number; color?: string; className?: string }>(
  function OrusHorizontalLogo(props) {
    const { width, height, color, className } = props

    return (
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 143 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.6621 12.0267V31.6252C75.6621 35.4628 76.6561 38.2778 78.5352 40.1888C80.4205 42.0629 83.0796 43.0419 86.6128 43.0419C88.9137 43.0419 90.9091 42.745 92.6107 42.1669C94.3441 41.5781 95.8029 40.828 96.9988 39.9254L98.1686 39.0426V43.216H106.881V12.0267H97.7457V31.2109C97.7457 32.7483 97.1901 34.282 95.9188 35.1463C95.4728 35.4495 95.0027 35.7223 94.5298 35.9394C93.2954 36.5062 91.9881 36.7896 90.6154 36.7896C88.8091 36.7896 87.3144 36.3758 86.2563 35.4283L86.242 35.4156L86.2285 35.4021C85.2062 34.385 84.7682 32.8777 84.7682 31.0377V12.0267H75.6621Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.1953 11.8453V42.672H59.1122L59.1044 23.7435L59.4527 23.5324C60.8688 22.6739 62.6064 21.9178 64.6534 21.2572C66.4962 20.6238 68.3433 20.201 70.1938 19.9907V12.1409C68.5079 12.3448 66.8187 12.7372 65.1256 13.3196L65.1189 13.3219L65.1121 13.3241C63.1375 13.961 61.5091 14.6752 60.2146 15.4599L59.1018 16.1345L59.111 11.8453H50.1953Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.785 12.063C121.804 12.063 118.317 12.8875 116.186 14.4078L116.184 14.4093C114.122 15.8688 113.078 17.9651 113.078 20.8033C113.078 23.2769 113.757 25.0359 115.005 26.2074C116.395 27.4246 118.461 28.5362 121.249 29.5182C123.695 30.3437 125.611 30.972 127.002 31.4044C128.47 31.8606 129.613 32.6757 129.613 34.0551C129.613 34.5872 129.461 35.0898 129.096 35.4896C128.74 35.8784 128.252 36.0951 127.719 36.2017C126.808 36.3921 125.387 36.4777 123.504 36.4777C121.877 36.4777 120.04 36.2981 117.998 35.9447C116.367 35.6624 114.879 35.2802 113.537 34.7949V41.2563C114.73 41.6774 116.291 42.0436 118.236 42.3472C120.426 42.6889 122.75 42.8606 125.21 42.8606C129.913 42.8606 133.352 42.0414 135.627 40.5051C137.866 38.9941 138.982 36.8534 138.982 33.9898C138.982 32.2765 138.708 30.9131 138.201 29.8654C137.705 28.8404 136.852 27.9361 135.59 27.1663L135.583 27.1619L135.576 27.1574C134.3 26.3384 132.469 25.5569 130.051 24.8266L130.045 24.8249L130.04 24.8232C127.725 24.0846 125.972 23.5682 124.771 23.2695L124.759 23.2666L124.747 23.2632C124.072 23.0715 123.488 22.7933 123.063 22.3862C122.618 21.9594 122.381 21.4188 122.381 20.8033C122.381 20.2292 122.605 19.723 123.051 19.3503C123.464 19.0051 124.017 18.8158 124.624 18.718C125.818 18.4905 127.241 18.3806 128.884 18.3806C130.202 18.3806 131.665 18.5161 133.269 18.7822C134.477 18.9825 135.595 19.2709 136.62 19.6506V13.3764C135.52 13.0604 134.153 12.7709 132.51 12.5109C130.624 12.2124 128.715 12.063 126.785 12.063Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2062 0.965332C15.0814 0.965332 10.065 2.63677 6.66178 5.85739C3.28486 9.0531 1.50781 14.4048 1.50781 22.1088C1.50781 29.361 3.27709 34.5527 6.66842 37.8443C10.0721 41.148 15.0859 42.8606 22.2062 42.8606C28.9681 42.8606 34.3434 41.1449 37.7439 37.8443C41.1352 34.5527 42.9045 29.361 42.9045 22.1088C42.9045 14.4048 41.1275 9.0531 37.7506 5.85739C34.3504 2.63965 28.9724 0.965332 22.2062 0.965332ZM13.4106 11.7183C15.2717 9.52568 18.5197 8.52331 22.2062 8.52331C25.8462 8.52331 29.0525 9.52436 30.871 11.7188C32.7076 13.8398 33.536 17.3711 33.536 22.1088C33.536 26.485 32.6963 29.8609 30.8808 32.0952C29.0646 34.3407 25.8541 35.3679 22.2062 35.3679C18.5152 35.3679 15.2653 34.3413 13.4056 32.1016L13.4001 32.0951L13.3948 32.0883C11.6275 29.855 10.8108 26.4822 10.8108 22.1088C10.8108 17.369 11.6182 13.8389 13.4096 11.7194L13.4106 11.7183Z"
          fill={color}
        />
      </svg>
    )
  },
)
