import { useScreenType, type ScreenType } from './use-screen-type'

export type ScreenVariant = ScreenType | 'backoffice'

export function useScreenVariant(): ScreenVariant {
  const screenType = useScreenType()

  const firstUrlSlot = window.location.pathname.split('/')[1]

  if (firstUrlSlot === 'bak' || firstUrlSlot === 'partner') return 'backoffice'

  return screenType
}
