import { createContext, useContext } from 'react'

export function useCrash(): (reason: CrashReason) => void {
  return useContext(CrashContext)
}

/**
 * Wraps information about the cause of a crash, to be displayed by the CrashHandler
 */
export type CrashReason =
  | {
      /**
       * Use this when something unexpected happened, usually a bug.
       */
      type: 'unexpected-error'
      err: Error
    }
  | {
      /**
       * Use this for temporary problems, like a network failure, or a 3rd party service being down.
       */
      type: 'temporary-problem'
    }
  | {
      /**
       * Use this when the requested resource was not found.
       */
      type: 'not-found'
    }
  | {
      /**
       * Use this form to display an expected functionnal problem that need to be communicated to the user.
       */
      type: 'functionnal-problem'
      title: string
      principalMessage: string
      firstSubText: string
      secondSubText: string
      trackingId?: string
      buttonText?: string
      onButtonClick?: () => void
    }
  | {
      /**
       * Use this when the link contains invalid parameters.
       */
      type: 'invalid-link'
    }

export const CrashContext = createContext<(reason: CrashReason) => void>((reason) => {
  console.log('useCrash has been used but the context was not available to handle the call to crash()', reason)
})
