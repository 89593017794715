import { spacing } from '../../foundation'

export type ButtonSize = 'small' | 'medium' | 'large'

export const buttonDimensionsPerButtonSize: {
  [size in ButtonSize]: { height: string; padding: string; iconPadding: string }
} = {
  small: { height: '32px', padding: `0 ${spacing[40]}`, iconPadding: `${spacing[30]}` },
  medium: { height: '40px', padding: `0 ${spacing[50]}`, iconPadding: `${spacing[40]}` },
  large: { height: '48px', padding: `0 ${spacing[50]}`, iconPadding: `${spacing[50]}` },
}
