import { TechnicalError } from '@orus.eu/error'
import { operatingZones } from '@orus.eu/operating-zone'
import equal from 'fast-deep-equal'
import { StringsWithDataEnumDimension } from './strings-with-data-enum-dimension'

/**
 * This dimension defines the {@link OperatingZone} to which a subscription will belong
 */
export const operatingZoneDimension = new StringsWithDataEnumDimension({
  name: 'operatingZone',
  displayValues: { name: 'Zone de souscription' },
  entries: [
    ['fr', { label: '🇫🇷 France' }],
    ['es', { label: '🇪🇸 España' }],
  ],
} as const)

// This assertion enforces the consistency that I couldn't achive through the typing system
if (!equal(operatingZones, operatingZoneDimension.values)) {
  throw new TechnicalError('operatingZones and operatingZoneDimension.values must be equal')
}
