import { TechnicalError, type Jsonable } from '@orus.eu/error'
import type { FieldSpecification } from '@orus.eu/message'
import { failure, isFailure, success, type Failure, type Result } from '@orus.eu/result'
import { languages, translate, type Language, type ParameterlessTranslationKey } from '@orus.eu/translations'
import deepEqual from 'fast-deep-equal'
import type { Duration } from 'luxon'
import type { DimensionTag } from './tags.js'

/**
 * A dimension specifies the type and features of a field useful for a policy
 */
export abstract class AbstractDimension<NAME extends string = string, TYPE = unknown> {
  readonly name: NAME
  readonly oldNames: Readonly<string[]>
  readonly displayNames: Record<Language, string>
  readonly placeholders?: Record<Language, string> | undefined
  readonly ariaLabels?: Record<Language, string> | undefined
  readonly hints?: Record<Language, string> | undefined
  readonly tags?: Readonly<DimensionTag[]> | undefined
  constructor(public readonly options: DimensionOptions<NAME>) {
    if (!/^[a-zA-Z\d]+$/.test(options.name)) {
      throw new TechnicalError(
        'Invalid dimension name : For all features to work properly, dimension names must not be empty and must contain only ascii letters or numbers',
        { context: { offendingDimensionName: options.name } },
      )
    }
    this.name = options.name
    this.oldNames = options.oldNames ?? []

    this.displayNames =
      'displayValues' in options
        ? getTranslationsFallback(options.displayValues.name)
        : getParmeterlessKeyTranslations(options.displayKeys.name)

    this.placeholders =
      'displayValues' in options && options.displayValues.placeholder
        ? getTranslationsFallback(options.displayValues.placeholder)
        : 'displayKeys' in options && options.displayKeys.placeholder
          ? getParmeterlessKeyTranslations(options.displayKeys.placeholder)
          : undefined

    this.ariaLabels =
      'displayValues' in options && options.displayValues.ariaLabel
        ? getTranslationsFallback(options.displayValues.ariaLabel)
        : 'displayKeys' in options && options.displayKeys.ariaLabel
          ? getParmeterlessKeyTranslations(options.displayKeys.ariaLabel)
          : undefined

    this.hints =
      'displayValues' in options && options.displayValues.hint
        ? getTranslationsFallback(options.displayValues.hint)
        : 'displayKeys' in options && options.displayKeys.hint
          ? getParmeterlessKeyTranslations(options.displayKeys.hint)
          : undefined

    this.tags = options.tags
  }

  readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter | undefined = undefined

  validateData(_value: LooselyTypedValue): Result<TYPE, DimensionValidationProblem> {
    throw new TechnicalError(
      'Unsupported operation : this dimension is meant to be only used as a computed dimension. Override this method before using the dimension as an input dimension.',
      { context: { name: this.name } },
    )
  }

  /**
   * Directly sets the fields value in a state compatible with this dimension
   * @param state
   * @param value
   */
  setFieldValue(state: { [name in NAME]?: TYPE }, value: TYPE | null): void {
    if (value === null) {
      delete state[this.name]
      return
    }

    state[this.name] = value
  }

  /**
   * Sets the value of the field in a partial state that represents an update, allowing
   * null value to represent the removal of the field.
   */
  setUpdateFieldValue(update: { [name in NAME]?: TYPE | null }, value: TYPE | null): void {
    update[this.name] = value
  }
  /**
   * Return the field value corresponding to the dimension in a partial state
   * @param state
   * @param value
   */
  getPartialStateFieldValue(state: { [name in NAME]?: TYPE | null }): TYPE | undefined {
    return state[this.name] ?? undefined
  }

  isFieldMissingInPartialState(state: { [name in NAME]?: TYPE | null }): boolean {
    return this.getPartialStateFieldValue(state) == undefined
  }

  mergePartialStates(a: { [name in NAME]?: TYPE | null }, b: { [name in NAME]?: TYPE | null }): void {
    if (b[this.name] != undefined) a[this.name] = b[this.name]
  }

  /**
   * Return the field value from a state object
   * @param state
   * @param value
   */
  getFieldValue(state: { [name in NAME]: TYPE }): TYPE {
    return state[this.name]
  }

  copyField(input: { [key in NAME]?: TYPE }, output: { [key in NAME]?: TYPE }): void {
    output[this.name] = input[this.name]
  }

  updateField(state: { [key in NAME]?: TYPE }, update: { [key in NAME]?: TYPE | null }): void {
    const newValue = update[this.name]

    if (newValue === undefined) {
      // dimension irrelevant for this update
      return
    }

    if (newValue === null) {
      // null in updates means that we want to delete the field
      delete state[this.name]
      return
    }

    state[this.name] = newValue
  }

  async updatePartialStateFieldWithComputedDimensionField<Dependencies extends readonly AbstractDimension[], Context>(
    state: PartialDimensionnedState<[...Dependencies, this]>,
    dimensionFunctions: {
      [key in NAME]?: (
        state: DimensionnedState<Dependencies>,
        context: Context,
        utils: Utils,
      ) => TYPE | Promise<TYPE> | Skip
    },
    partialDimensionFunctions: {
      [key in NAME]?: (
        state: PartialDimensionnedState<Dependencies>,
        context: Context,
        utils: Utils,
      ) => TYPE | Promise<TYPE> | Skip
    },
    dependencyMatrix: Record<NAME, Dependencies>,
    context: Context,
  ): Promise<void> {
    const dimensionFunction = dimensionFunctions[this.name]

    if (dimensionFunction) {
      const dependencies = dependencyMatrix[this.name]
      for (const dependency of dependencies) {
        const value = dependency.getPartialStateFieldValue(state)
        if (value == undefined) {
          // We don't attempt computing the dimension because one dependency is missing or null.
          // and we need to erase it if it was already set in the updated state
          const stateToUpdate = state as { [key in NAME]?: TYPE }
          if (value == undefined && this.getPartialStateFieldValue(stateToUpdate) != undefined) {
            this.setFieldValue(stateToUpdate, null)
          }
          return
        }
      }
      const value =
        (await dimensionFunction(state as unknown as DimensionnedState<Dependencies>, context, utils)) ?? null

      if (value !== skip) {
        this.setFieldValue(state as { [key in NAME]?: TYPE }, value)
      }

      return
    }

    const partialDimensionFunction = partialDimensionFunctions[this.name]

    if (!partialDimensionFunction) {
      throw new TechnicalError('Dimension function neither in dimensionFunctions nor in partialDimensionFunctions', {
        context: {
          dimensionName: this.name,
          dimensionFunctions: Object.keys(dimensionFunctions),
          partialDimensionFunctions: Object.keys(partialDimensionFunctions),
        },
      })
    }

    const value = await partialDimensionFunction(state as PartialDimensionnedState<Dependencies>, context, utils)
    if (value !== skip) {
      this.setFieldValue(state as { [key in NAME]?: TYPE }, value)
    }
  }

  updateDifference(
    before: { [key in NAME]?: TYPE | null },
    after: { [key in NAME]?: TYPE | null },
    difference: { [key in NAME]?: TYPE | null },
  ): void {
    const valueBefore = before[this.name]
    const valueAfter = after[this.name]

    if (valueBefore == undefined) {
      if (valueAfter == undefined) {
        // nothing changed, field must be absent from difference
        delete difference[this.name]
        return
      }

      // field was absent but now exits, must create
      difference[this.name] = valueAfter
      return
    }

    if (valueAfter == undefined) {
      // field existed but not anymore, must set to null to delete the field
      difference[this.name] = null
      return
    }

    if (deepEqualAssimilateNullUndefined(valueBefore, valueAfter)) {
      // nothing changed, field must be absent from difference
      delete difference[this.name]
      return
    }

    // field was updated, must set to after
    difference[this.name] = valueAfter
  }
}

/**
 * If a dimension returns this value, it means that the field should be skipped,
 * effectively "returning" the previous value
 */
export const skip = Symbol('skip')
export type Skip = typeof skip
export const utils = { skip } as const
export type Utils = typeof utils

export type DimensionTrackingMessageFieldAdapter<T = unknown> = {
  getTrackingFieldSpecification(): FieldSpecification

  convertStateValueToTrackingValue(stateValue: T | null | undefined): number | string | boolean | null
}

export function deepEqualAssimilateNullUndefined(a: unknown, b: unknown): boolean {
  if (deepEqual(a, b)) return true
  if (a == undefined) {
    return b == undefined
  }
  if (b == undefined) return false
  if (typeof a === 'object') {
    if (typeof b !== 'object') return false
    for (const aKey in a) {
      const aValue = (a as Record<string, unknown>)[aKey]
      const bValue = aKey in b ? (b as Record<string, unknown>)[aKey] : undefined
      if (!deepEqualAssimilateNullUndefined(aValue, bValue)) return false
    }
    for (const bKey in b) {
      if (!(bKey in a)) {
        const bValue = bKey in b ? (b as Record<string, unknown>)[bKey] : undefined
        if (bValue != undefined) return false
      }
    }
    return true
  }
  return false
}

export type DimensionDisplayTextsSpec =
  | {
      /**
       * Directly set values for the display texts (no translation, same value no matter the language)
       */
      displayValues: {
        /**
         * Name as displayed in the UI and exports, seen by the end users
         */
        name: string
        /**
         * Example value that can be used as a placeholder in the UI
         */
        placeholder?: string
        /**
         * Aria label for the field in the UI
         */
        ariaLabel?: string
        /**
         * Hint displayed in the UI to explaine why this dimension is about
         */
        hint?: string
        /**
         * Tags related to the dimension displayed in the UI, e.g. `MRPW`
         */
      }
    }
  | {
      /**
       * Specifies translation keys for the display texts
       */
      displayKeys: {
        /**
         * Translation key for the name as displayed in the UI and exports, seen by the end users
         */
        name: ParameterlessTranslationKey
        /**
         * Translation key for an example value that can be used as a placeholder in the UI
         */
        placeholder?: ParameterlessTranslationKey
        /**
         * Translation key for the aria label for the field in the UI
         */
        ariaLabel?: ParameterlessTranslationKey
        /**
         * Translation key for a hint displayed in the UI to explaine why this dimension is about
         */
        hint?: ParameterlessTranslationKey
      }
    }

export type DimensionOptions<NAME> = {
  /**
   * Name of the field in state models
   */
  name: NAME
  /**
   * Old names of the field in state models.
   * This is used to support legacy state models without migrations.
   */
  oldNames?: Readonly<string[]>

  /**
   * Tags related to the dimension displayed in the UI, e.g. `MRPW`
   */
  tags?: Readonly<DimensionTag[]>
} & DimensionDisplayTextsSpec

export type TypeOfDimension<D extends AbstractDimension> =
  D extends AbstractDimension<string, infer TYPE> ? TYPE : never

export type DimensionFunction<
  D extends AbstractDimension,
  InputDimensions extends readonly AbstractDimension[],
  Context,
> =
  D extends AbstractDimension<string, infer TYPE>
    ? (
        inputState: DimensionnedState<InputDimensions>,
        context: Context,
        utils: Utils,
      ) => TYPE | undefined | Skip | Promise<TYPE | undefined | Skip>
    : never
export type SyncDimensionFunction<
  D extends AbstractDimension,
  InputDimensions extends readonly AbstractDimension[],
  Context,
> =
  D extends AbstractDimension<string, infer TYPE>
    ? (inputState: DimensionnedState<InputDimensions>, context: Context, utils: Utils) => TYPE | undefined | Skip
    : never
export type SafeAsyncDimensionFunction<
  OUTPUT,
  PROBLEM,
  D extends AbstractDimension<string, Result<OUTPUT, PROBLEM>>,
  InputDimensions extends readonly AbstractDimension[],
  Context,
> =
  D extends AbstractDimension<string, infer TYPE>
    ? (
        inputState: DimensionnedState<InputDimensions>,
        context: Context,
        utils: Utils,
      ) => Promise<TYPE | undefined | Skip>
    : never
export type AsyncDimensionFunction<
  OUTPUT,
  D extends AbstractDimension<string, OUTPUT>,
  InputDimensions extends readonly AbstractDimension[],
  Context,
> =
  D extends AbstractDimension<string, infer TYPE>
    ? (
        inputState: DimensionnedState<InputDimensions>,
        context: Context,
        utils: Utils,
      ) => Promise<TYPE | undefined | Skip>
    : never

export type ConditionFunction<Dimensions extends readonly AbstractDimension[]> = (
  inputState: PartialDimensionnedState<Dimensions>,
) => boolean

export type PartialDimensionFunction<
  D extends AbstractDimension,
  InputDimensions extends readonly AbstractDimension[],
  Context,
> =
  D extends AbstractDimension<string, infer TYPE>
    ? (
        inputState: PartialDimensionnedState<InputDimensions>,
        context: Context,
        utils: Utils,
      ) => TYPE | undefined | Skip | Promise<TYPE | undefined | Skip>
    : never

export type SyncPartialDimensionFunction<
  D extends AbstractDimension,
  InputDimensions extends readonly AbstractDimension[],
  Context,
> =
  D extends AbstractDimension<string, infer TYPE>
    ? (inputState: PartialDimensionnedState<InputDimensions>, context: Context, utils: Utils) => TYPE | undefined | Skip
    : never
export type AsyncPartialDimensionFunction<
  OUTPUT,
  D extends AbstractDimension<string, OUTPUT>,
  InputDimensions extends readonly AbstractDimension[],
  Context,
> =
  D extends AbstractDimension<string, infer TYPE>
    ? (
        inputState: PartialDimensionnedState<InputDimensions>,
        context: Context,
        utils: Utils,
      ) => Promise<TYPE | undefined | Skip>
    : never
export type SafeAsyncPartialDimensionFunction<
  OUTPUT,
  PROBLEM,
  D extends AbstractDimension<string, Result<OUTPUT, PROBLEM>>,
  InputDimensions extends readonly AbstractDimension[],
  Context,
> =
  D extends AbstractDimension<string, infer TYPE>
    ? (
        inputState: PartialDimensionnedState<InputDimensions>,
        context: Context,
        utils: Utils,
      ) => Promise<TYPE | undefined | Skip>
    : never
export type DimensionnedState<Dimensions extends readonly AbstractDimension[]> = {
  [D in Dimensions[number] as D['name']]: TypeOfDimension<D>
}

/**
 * Describes an dimensionned state where all the dimensions are not known.
 * null, undefined, or missing indicate that a field is not known
 * This object is also used to reprensent an update to the state. In this case, null means that the field should be reset
 */
export type PartialDimensionnedState<Dimensions extends readonly AbstractDimension[]> = PartialNullable<
  DimensionnedState<Dimensions>
>

export type PartialNullable<T> = {
  [K in keyof T]?: T[K] | null
}

/**
 * Loosely typed data structure that matches any potential dimension data
 */
export type LooselyTypedData = { [key: string]: unknown }
/**
 * Losely typed data structure that matches any potential dimension value
 */
export type LooselyTypedValue = unknown

/**
 * Applies our conventions for document templates tags: replaces camel case with uppercase + snake case
 * @param name
 */
export function toDocumentTemplateTag(name: string): string {
  return name
    .split(/(?=[A-Z])/)
    .join('_')
    .toUpperCase()
}

export type DimensionValidationProblem = {
  type: 'dimension-validation-problem'
  /**
   * Human - readable reason for the validation problem, suitable for a frontend developer
   * (end users should not see this because of surface validation)
   */
  explanation: string
}

export function dimensionValidationFailure(explanation: string): Failure<DimensionValidationProblem> {
  return failure({
    type: 'dimension-validation-problem',
    explanation,
  })
}

export type CacheParams<OUTPUT, PROBLEM, Dimensions extends readonly AbstractDimension[], Context> = {
  version: number
  name: string
  dimensions: Dimensions
  callback: (
    input: PartialDimensionnedState<Dimensions>,
    context: Context,
    utils: Utils,
  ) => Promise<Result<OUTPUT, PROBLEM> | undefined | Skip>
  ttl: Duration | undefined
}

export type CacheService = {
  cache<OUTPUT, PROBLEM, Dimensions extends readonly AbstractDimension[], Context>(
    params: CacheParams<OUTPUT, PROBLEM, Dimensions, Context>,
  ): (
    input: PartialDimensionnedState<Dimensions>,
    context: Context,
    utils: Utils,
  ) => Promise<Result<OUTPUT, PROBLEM> | undefined | Skip>
  loggingWorker: {
    start(): void
    stop(): Promise<void>
  }
}

export class MockCacheService implements CacheService {
  cache<OUTPUT, PROBLEM, Dimensions extends readonly AbstractDimension[], Context>({
    callback,
  }: CacheParams<OUTPUT, PROBLEM, Dimensions, Context>): (
    input: PartialDimensionnedState<Dimensions>,
    context: Context,
    utils: Utils,
  ) => Promise<Result<OUTPUT, PROBLEM> | undefined | Skip> {
    return callback
  }
  loggingWorker = {
    start: (): void => {},
    stop: (): Promise<void> => Promise.resolve(),
  }
}

/**
 * Specified under which conditions dimensions should be re-computed when loading old states
 */
export type ReComputeOnLoadMode =
  /**
   * Value will be stored, and re-computed only when it's missing
   */
  | 'if-missing'
  /**
   * Value will always be computed (and hence will not be stored)
   */
  | 'always'

/**
 * A "Dimensionned State" is a object which properties and the relation between the properties are defined by dimensions.
 *
 * The DimensionedStateManager provides methods for interacting with this state.
 *
 * The DimensionedStateManagerBuilder provides as convenient way to build an instance of DimensionedStateManager
 */
export class DimensionedStateManager<
  InputDimensions extends readonly AbstractDimension[],
  ComputedDimensions extends readonly AbstractDimension[],
  Context,
> {
  readonly dimensions: readonly [...InputDimensions, ...ComputedDimensions]

  constructor(
    readonly inputDimensions: InputDimensions,
    private readonly inputDimensionsConditions: {
      [Dimension in InputDimensions[number] as Dimension['name']]: ConditionFunction<
        [...InputDimensions, ...ComputedDimensions]
      >
    },
    private readonly computedDimensions: ComputedDimensions,
    private readonly dimensionFunctions: {
      [ComputedDimension in ComputedDimensions[number] as ComputedDimension['name']]: DimensionFunction<
        ComputedDimension,
        [...InputDimensions, ...ComputedDimensions],
        Context
      >
    },
    private readonly partialDimensionFunctions: {
      [ComputedDimension in ComputedDimensions[number] as ComputedDimension['name']]: PartialDimensionFunction<
        ComputedDimension,
        readonly AbstractDimension[],
        Context
      >
    },
    private readonly dimensionComputeMode: Record<string, ReComputeOnLoadMode>,
    private readonly dependencyMatrix: {
      [ComputedDimension in ComputedDimensions[number] as ComputedDimension['name']]: readonly [
        ...InputDimensions,
        ...ComputedDimensions,
      ][number][]
    },
  ) {
    this.dimensions = [...inputDimensions, ...computedDimensions]
  }

  hasDimension(dimension: AbstractDimension): boolean {
    const dimensions: readonly AbstractDimension[] = this.dimensions
    return dimensions.includes(dimension)
  }

  getComputedDimensionMissingDependenciesNamesByDimensionName(computedDimensionName: string): string[] {
    return [...new Set(this.getComputedDimensionDependenciesRecursively(computedDimensionName, false))]
  }

  private getComputedDimensionDependenciesRecursively(computedDimensionName: string, isDependency: boolean): string[] {
    const dependencies = this.dependencyMatrix[
      computedDimensionName as ComputedDimensions[number]['name']
    ] as readonly [...InputDimensions, ...ComputedDimensions][number][]

    const res: string[] = []

    if (dependencies) {
      if (dependencies.length > 0) {
        for (const dependency of dependencies) {
          if (dependency) {
            const innerDependencies = this.dependencyMatrix[
              dependency.name as ComputedDimensions[number]['name']
            ] as readonly [...InputDimensions, ...ComputedDimensions][number][]

            if (innerDependencies && innerDependencies.length > 0) {
              for (const innerDependency of innerDependencies) {
                res.push(...this.getComputedDimensionDependenciesRecursively(innerDependency.name, true))
              }
            } else {
              res.push(dependency.name)
            }
          }
        }
      }
    } else if (isDependency) {
      res.push(computedDimensionName)
    }

    return [...new Set(res)]
  }

  /**
   * Apply the old names defined in the dimensions to the update if relevant
   *
   * @param update The update containing the potential old names values
   *
   * @returns The update with the potential old names values applied
   */
  applyOldNames<T extends PartialDimensionnedState<typeof this.dimensions>>(update: T & Record<string, unknown>): T {
    const updateWithOldNamesApplied = { ...update }

    this.dimensions.forEach((dimension) => {
      const oldNameValue = dimension.oldNames.reduce<unknown>((previousOldNameValue, oldName) => {
        if (update[oldName] !== undefined) return update[oldName]

        return previousOldNameValue
      }, undefined)

      if (oldNameValue !== undefined) {
        // @ts-expect-error To not complexify every dimension definition, we chose to implement fallbacks in a non-typesafe way
        updateWithOldNamesApplied[dimension.name] = oldNameValue
        dimension.oldNames.forEach((oldName) => delete updateWithOldNamesApplied[oldName])
      }
    })

    return updateWithOldNamesApplied
  }

  async applyInputDifference(
    state: PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>,
    inputUpdate: PartialDimensionnedState<InputDimensions>,
    context: Context,
  ): Promise<
    Result<PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>, DimensionValidationProblem>
  > {
    state = { ...state }

    const validationResult = this.validateInputUpdate(inputUpdate)
    if (isFailure(validationResult)) return validationResult

    for (const dimension of this.inputDimensions) {
      dimension.updateField(state, validationResult.output)
    }

    const updatedDimensionNames = new Set(Object.keys(inputUpdate))

    for (const computedDimension of this.computedDimensions) {
      const dependencies = this.dependencyMatrix[
        computedDimension.name as ComputedDimensions[number]['name']
      ] as readonly [...InputDimensions, ...ComputedDimensions][number][]

      if (dependencies.length == 0 || dependencies.some((dependency) => updatedDimensionNames.has(dependency.name))) {
        await computedDimension.updatePartialStateFieldWithComputedDimensionField(
          state,
          this.dimensionFunctions,
          this.partialDimensionFunctions,
          this.dependencyMatrix,
          context,
        )
        updatedDimensionNames.add(computedDimension.name)
      }
    }

    return success(state)
  }

  async refreshAllComputedDimensions(
    state: PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>,
    context: Context,
  ): Promise<PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>> {
    state = { ...state }

    for (const computedDimension of this.computedDimensions) {
      await computedDimension.updatePartialStateFieldWithComputedDimensionField(
        state,
        this.dimensionFunctions,
        this.partialDimensionFunctions,
        this.dependencyMatrix,
        context,
      )
    }

    return state
  }

  async resetComputedDimensionsAndComputePersistableDifference(
    state: PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>,
    dimensionsToReset: ReadonlyArray<ComputedDimensions[number]>,
    context: Context,
  ): Promise<PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>> {
    const updatedState = { ...state }

    const updatedDimensionNames = new Set<string>()

    for (const computedDimension of this.computedDimensions) {
      const dependencies = this.dependencyMatrix[
        computedDimension.name as ComputedDimensions[number]['name']
      ] as readonly [...InputDimensions, ...ComputedDimensions][number][]

      if (
        dimensionsToReset.includes(computedDimension) ||
        dependencies.some((dependency) => updatedDimensionNames.has(dependency.name))
      ) {
        await computedDimension.updatePartialStateFieldWithComputedDimensionField(
          updatedState,
          this.dimensionFunctions,
          this.partialDimensionFunctions,
          this.dependencyMatrix,
          context,
        )
        updatedDimensionNames.add(computedDimension.name)
      }
    }

    return this.computePersistableDifference(state, updatedState)
  }

  /**
   * Returns a copy of the state in which required dimensions are updated :
   *   - Missing computed dimensions in "missing" mode
   *   - Computed dimensions in "always" mode
   */
  async getStateWithRetroactiveDimensionsUpdated(
    state: PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>,
    context: Context,
  ): Promise<PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>> {
    state = { ...state }
    for (const computedDimension of this.computedDimensions) {
      const mode = this.dimensionComputeMode[computedDimension.name]
      if (mode === 'always' || (mode === 'if-missing' && computedDimension.isFieldMissingInPartialState(state))) {
        await computedDimension.updatePartialStateFieldWithComputedDimensionField(
          state,
          this.dimensionFunctions,
          this.partialDimensionFunctions,
          this.dependencyMatrix,
          context,
        )
      }
    }
    return state
  }

  async getCompleteStateFromInput(
    inputState: PartialDimensionnedState<InputDimensions>,
    context: Context,
  ): Promise<
    Result<PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>, DimensionValidationProblem>
  > {
    const validationResult = this.validateInputUpdate(inputState)
    if (isFailure(validationResult)) return validationResult

    const completedState = await this.refreshAllComputedDimensions(validationResult.output, context)

    const completionCheckResult = await this.checkStateInputComplete(completedState, context)
    if (isFailure(completionCheckResult)) return completionCheckResult

    return success(completedState)
  }

  /**
   * Check that the state is "input-complete", meaning that all the relevant input dimensions have a value
   *
   * @param state The state to check
   * @returns Whether the state is input-complete or not
   */
  async checkStateInputComplete(
    state: PartialDimensionnedState<InputDimensions>,
    context: Context,
  ): Promise<Result<undefined, DimensionValidationProblem>> {
    const missingDimensions = await this.getMissingInputDimensions(state, context)
    if (missingDimensions.length > 0) {
      return failure({
        type: 'dimension-validation-problem',
        explanation: 'Missing field(s): ' + missingDimensions.map((dimension) => dimension.name).join(', '),
      })
    }
    return success()
  }

  /**
   * Returns the list of all input dimensions, even if not missing
   */
  async getAllInputDimensions(
    inputState: PartialDimensionnedState<InputDimensions>,
    context: Context,
  ): Promise<AbstractDimension[]> {
    const state = await this.refreshAllComputedDimensions(inputState, context)

    return this.inputDimensions.filter((dimension: InputDimensions[number]) => {
      const dimensionName: InputDimensions[number]['name'] = dimension.name
      const conditionFunction = this.inputDimensionsConditions[dimensionName] as ConditionFunction<
        [...InputDimensions, ...ComputedDimensions]
      >
      const dimensionApplicable = conditionFunction(state)

      return dimensionApplicable
    })
  }

  async getAllInputDimensionsNames(
    inputState: PartialDimensionnedState<InputDimensions>,
    context: Context,
  ): Promise<string[]> {
    const dimensions = await this.getAllInputDimensions(inputState, context)
    return dimensions.map((dimension) => dimension.name)
  }

  async getMissingInputDimensions(
    inputState: PartialDimensionnedState<InputDimensions>,
    context: Context,
  ): Promise<AbstractDimension[]> {
    const state = await this.refreshAllComputedDimensions(inputState, context)

    const dimensions = await this.getAllInputDimensions(inputState, context)

    return dimensions.filter((dimension) => dimension.isFieldMissingInPartialState(state))
  }

  async getMissingInputDimensionsNames(
    inputState: PartialDimensionnedState<InputDimensions>,
    context: Context,
  ): Promise<string[]> {
    const dimensions = await this.getMissingInputDimensions(inputState, context)
    return dimensions.map((dimension) => dimension.name)
  }

  mergePartialInputStates(
    ...states: PartialDimensionnedState<InputDimensions>[]
  ): PartialDimensionnedState<InputDimensions> {
    const result: PartialDimensionnedState<InputDimensions> = {}
    for (const state of states) {
      for (const dimension of this.inputDimensions) {
        dimension.mergePartialStates(result, state)
      }
    }
    return result
  }

  mergePartialStates(
    ...states: PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>[]
  ): PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]> {
    const result: PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]> = {}
    for (const state of states) {
      for (const dimension of this.inputDimensions) {
        dimension.mergePartialStates(result, state)
      }
      for (const dimension of this.computedDimensions) {
        dimension.mergePartialStates(result, state)
      }
    }
    return result
  }

  async assertInputStateComplete(
    inputState: PartialDimensionnedState<InputDimensions>,
    context: Context,
  ): Promise<void> {
    const completionResult = await this.getCompleteStateFromInput(inputState, context)
    if (isFailure(completionResult)) {
      throw new TechnicalError('State is not complete', {
        context: { explanation: completionResult.problem.explanation },
      })
    }
  }

  /**
   * Assert that the state is "input complete", meaning that all the relevant input dimensions have a value
   *
   * @param state The state to check
   *
   * @throws {TechnicalError} If the state is not input-complete
   */
  async assertStateInputComplete(
    state: PartialDimensionnedState<[...InputDimensions, ...ComputedDimensions]>,
    context: Context,
  ): Promise<void> {
    const completionCheckResult = await this.checkStateInputComplete(state, context)
    if (isFailure(completionCheckResult)) {
      throw new TechnicalError('State is not complete', {
        context: { explanation: completionCheckResult.problem.explanation },
      })
    }
  }

  private validateInputUpdate(
    update: LooselyTypedData,
  ): Result<PartialDimensionnedState<InputDimensions>, DimensionValidationProblem> {
    const result: PartialDimensionnedState<InputDimensions> = {}
    for (const inputDimension of this.inputDimensions) {
      if (update[inputDimension.name] === null) {
        inputDimension.setUpdateFieldValue(result, null)
      } else if (update[inputDimension.name] !== undefined) {
        const fieldValidationResult = setFieldFromLooselyTypedValue(result, inputDimension, update[inputDimension.name])
        if (isFailure(fieldValidationResult)) return fieldValidationResult
      }
    }
    return success(result)
  }

  /**
   * Project an object to keep only fields correspnding to actual dimensions. Useful to remove
   * noise before storing in the database or sending to the frontend.
   * @param state
   */
  projectPartialStateOnDimensions(
    state: PartialDimensionnedState<InputDimensions>,
  ): PartialDimensionnedState<InputDimensions> {
    return projectPartialStateOnDimensions<readonly [...InputDimensions, ...ComputedDimensions]>(state, this.dimensions)
  }

  /**
   * Project an object to keep only fields correspnding to actual input dimensions.
   * @param state
   */
  projectPartialStateOnInputDimensions(
    state: PartialDimensionnedState<InputDimensions>,
  ): PartialDimensionnedState<InputDimensions> {
    return projectPartialStateOnDimensions<readonly [...InputDimensions]>(state, this.inputDimensions)
  }

  computeVisibleDifference(
    before: PartialNullable<DimensionnedState<InputDimensions>>,
    after: PartialNullable<DimensionnedState<InputDimensions>>,
  ): PartialNullable<DimensionnedState<InputDimensions>> {
    const difference: PartialNullable<DimensionnedState<InputDimensions>> = {}

    for (const dimension of this.dimensions) {
      dimension.updateDifference(before, after, difference)
    }

    return difference
  }

  computePersistableDifference(
    before: PartialNullable<DimensionnedState<InputDimensions>>,
    after: PartialNullable<DimensionnedState<InputDimensions>>,
  ): PartialNullable<DimensionnedState<InputDimensions>> {
    const difference: PartialNullable<DimensionnedState<InputDimensions>> = {}

    for (const dimension of this.dimensions) {
      const mode = this.dimensionComputeMode[dimension.name]
      if (mode !== 'always') dimension.updateDifference(before, after, difference)
    }

    return difference
  }
}

function setFieldFromLooselyTypedValue<NAME extends string, TYPE>(
  state: { [key in NAME]: TYPE },
  dimension: AbstractDimension<NAME, TYPE>,
  value: LooselyTypedValue,
): Result<null, DimensionValidationProblem> {
  const fieldValidationResult = dimension.validateData(value)
  if (isFailure(fieldValidationResult)) return fieldValidationResult
  state[dimension.name] = fieldValidationResult.output
  return success(null)
}

export function newDimensionedStateManagerBuilder<Context>(
  cacheService: CacheService = new MockCacheService(),
): DimensionedStateManagerBuilder<never[], never[], Context> {
  return new DimensionedStateManagerBuilder([], {}, [], {}, {}, {}, {}, cacheService)
}

export class DimensionedStateManagerBuilder<
  InputDimensions extends readonly AbstractDimension[],
  ComputedDimensions extends readonly AbstractDimension[],
  Context,
> {
  constructor(
    private readonly inputDimensions: InputDimensions,
    private readonly inputDimensionsConditions: {
      [Dimension in InputDimensions[number] as Dimension['name']]: ConditionFunction<
        [...InputDimensions, ...ComputedDimensions]
      >
    },
    private readonly computedDimensions: ComputedDimensions,
    private readonly dimensionFunctions: {
      [ComputedDimension in ComputedDimensions[number] as ComputedDimension['name']]: DimensionFunction<
        ComputedDimension,
        readonly AbstractDimension[],
        Context
      >
    },
    private readonly partialDimensionFunctions: {
      [ComputedDimension in ComputedDimensions[number] as ComputedDimension['name']]: PartialDimensionFunction<
        ComputedDimension,
        readonly AbstractDimension[],
        Context
      >
    },
    private readonly dimensionComputeMode: Record<string, ReComputeOnLoadMode>,
    private readonly dependencyMatrix: {
      [ComputedDimension in ComputedDimensions[number] as ComputedDimension['name']]: readonly [
        ...InputDimensions,
        ...ComputedDimensions,
      ][number][]
    },
    private readonly cacheService: CacheService,
  ) {}

  addInputDimension<NewInputDimension extends AbstractDimension>(
    newDimension: NewInputDimension,
    conditions: Array<(InputDimensions[number] | ComputedDimensions[number]) & AbstractDimension<string, boolean>> = [],
  ): DimensionedStateManagerBuilder<[...InputDimensions, NewInputDimension], ComputedDimensions, Context> {
    this.assertNoDimensionWithName(newDimension.name, newDimension.oldNames)
    const inputDimensions: [...InputDimensions, NewInputDimension] = [...this.inputDimensions, newDimension]

    const conditionFunction: ConditionFunction<InputDimensions> = (state) =>
      conditions.every((condition) => !!state[condition.name as InputDimensions[number]['name']])

    const inputDimensionsConditions = {
      ...this.inputDimensionsConditions,
      [newDimension.name]: conditionFunction,
    } as {
      [Dimension in InputDimensions[number] | NewInputDimension as Dimension['name']]: ConditionFunction<
        [...InputDimensions, NewInputDimension, ...ComputedDimensions]
      >
    }
    const dependencyMatrix = this.dependencyMatrix as {
      [ComputedDimension in ComputedDimensions[number] as ComputedDimension['name']]: readonly [
        ...InputDimensions,
        NewInputDimension,
        ...ComputedDimensions,
      ][number][]
    }
    return new DimensionedStateManagerBuilder<[...InputDimensions, NewInputDimension], ComputedDimensions, Context>(
      inputDimensions,
      inputDimensionsConditions,
      this.computedDimensions,
      this.dimensionFunctions,
      this.partialDimensionFunctions,
      this.dimensionComputeMode,
      dependencyMatrix,
      this.cacheService,
    )
  }

  addInputDimensions<NewInputDimensions extends readonly AbstractDimension[]>(
    newDimensions: NewInputDimensions,
    conditions: Array<(InputDimensions[number] | ComputedDimensions[number]) & AbstractDimension<string, boolean>> = [],
  ): DimensionedStateManagerBuilder<[...InputDimensions, ...NewInputDimensions], ComputedDimensions, Context> {
    let result = this as unknown as DimensionedStateManagerBuilder<
      [...InputDimensions, ...NewInputDimensions],
      ComputedDimensions,
      Context
    >
    for (const newDimension of newDimensions) {
      result = result.addInputDimension(newDimension, conditions) as unknown as DimensionedStateManagerBuilder<
        [...InputDimensions, ...NewInputDimensions],
        ComputedDimensions,
        Context
      >
    }
    return result
  }

  addComputedDimension<
    NewComputedDimension extends AbstractDimension,
    Dependencies extends readonly [...InputDimensions, ...ComputedDimensions][number][],
  >(
    newDimension: NewComputedDimension,
    dependencies: Dependencies,
    dimensionFunction: SyncDimensionFunction<NewComputedDimension, Dependencies, Context>,
    options: ComputedDimensionOptions,
  ): DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context> {
    return this.addSyncOrAsyncComputedDimension(newDimension, dependencies, dimensionFunction, options)
  }

  addSafeAsyncComputedDimension<
    OUTPUT,
    PROBLEM,
    NewComputedDimension extends AbstractDimension<string, Result<OUTPUT, PROBLEM>>,
    Dependencies extends readonly [...InputDimensions, ...ComputedDimensions][number][],
  >(
    newDimension: NewComputedDimension,
    dependencies: Dependencies,
    dimensionFunction: SafeAsyncDimensionFunction<OUTPUT, PROBLEM, NewComputedDimension, Dependencies, Context>,
    options: AsyncComputedDimensionOptions,
  ): DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context> {
    if (options.cacheEnabled) {
      const { cacheVersion = 0, cacheTtl } = options
      // @ts-expect-error cache accepts looser type than necessary to support both partial and complete states with a simple API
      dimensionFunction = this.cacheService.cache({
        version: cacheVersion,
        ttl: cacheTtl,
        name: newDimension.name,
        dimensions: dependencies,
        // @ts-expect-error cache accepts looser type than necessary to support both partial and complete states with a simple API
        callback: dimensionFunction,
      }) as DimensionFunction<NewComputedDimension, Dependencies, Context>
    }
    return this.addSyncOrAsyncComputedDimension(newDimension, dependencies, dimensionFunction, options)
  }

  addAsyncComputedDimension<
    OUTPUT,
    NewComputedDimension extends AbstractDimension<string, OUTPUT>,
    Dependencies extends readonly [...InputDimensions, ...ComputedDimensions][number][],
  >(
    newDimension: NewComputedDimension,
    dependencies: Dependencies,
    dimensionFunction: AsyncDimensionFunction<OUTPUT, NewComputedDimension, Dependencies, Context>,
    options: AsyncComputedDimensionOptions,
  ): DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context> {
    return this.addSyncOrAsyncComputedDimension(newDimension, dependencies, dimensionFunction, options)
  }

  private addSyncOrAsyncComputedDimension<
    NewComputedDimension extends AbstractDimension,
    Dependencies extends readonly [...InputDimensions, ...ComputedDimensions][number][],
  >(
    newDimension: NewComputedDimension,
    dependencies: Dependencies,
    dimensionFunction: DimensionFunction<NewComputedDimension, Dependencies, Context>,
    options: ComputedDimensionOptions,
  ): DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context> {
    dependencies = removeDuplicates(dependencies)
    this.assertNoDimensionWithName(newDimension.name, newDimension.oldNames)
    const inputDimensionsConditions = this.inputDimensionsConditions as {
      [Dimension in InputDimensions[number] as Dimension['name']]: ConditionFunction<
        [...InputDimensions, ...ComputedDimensions, NewComputedDimension]
      >
    }
    const dimensionFunctions = { ...this.dimensionFunctions, [newDimension.name]: dimensionFunction }
    const partialDimensionFunctions = { ...this.partialDimensionFunctions }
    const dependencyMatrix = { ...this.dependencyMatrix, [newDimension.name]: dependencies } as {
      [ComputedDimension in ComputedDimensions[number] | NewComputedDimension as ComputedDimension['name']]: readonly [
        ...InputDimensions,
        ...ComputedDimensions,
        NewComputedDimension,
      ][number][]
    }

    return new DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context>(
      this.inputDimensions,
      inputDimensionsConditions,
      [...this.computedDimensions, newDimension],
      dimensionFunctions,
      partialDimensionFunctions,
      { ...this.dimensionComputeMode, [newDimension.name]: options.reComputeOnLoad },
      dependencyMatrix,
      this.cacheService,
    )
  }

  addPartialComputedDimension<
    NewComputedDimension extends AbstractDimension,
    Dependencies extends readonly [...InputDimensions, ...ComputedDimensions][number][],
  >(
    newDimension: NewComputedDimension,
    dependencies: Dependencies,
    partialDimensionFunction: SyncPartialDimensionFunction<NewComputedDimension, Dependencies, Context>,
    options: ComputedDimensionOptions,
  ): DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context> {
    return this.addSyncOrAsyncPartialComputedDimension(newDimension, dependencies, partialDimensionFunction, options)
  }

  addAsyncPartialComputedDimension<
    OUTPUT,
    NewComputedDimension extends AbstractDimension<string, OUTPUT>,
    Dependencies extends readonly [...InputDimensions, ...ComputedDimensions][number][],
  >(
    newDimension: NewComputedDimension,
    dependencies: Dependencies,
    partialDimensionFunction: AsyncPartialDimensionFunction<OUTPUT, NewComputedDimension, Dependencies, Context>,
    options: AsyncComputedDimensionOptions,
  ): DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context> {
    return this.addSyncOrAsyncPartialComputedDimension(newDimension, dependencies, partialDimensionFunction, options)
  }

  addSafeAsyncPartialComputedDimension<
    OUTPUT,
    PROBLEM,
    NewComputedDimension extends AbstractDimension<string, Result<OUTPUT, PROBLEM>>,
    Dependencies extends readonly [...InputDimensions, ...ComputedDimensions][number][],
  >(
    newDimension: NewComputedDimension,
    dependencies: Dependencies,
    partialDimensionFunction: SafeAsyncPartialDimensionFunction<
      OUTPUT,
      PROBLEM,
      NewComputedDimension,
      Dependencies,
      Context
    >,
    options: AsyncComputedDimensionOptions,
  ): DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context> {
    if (options.cacheEnabled) {
      const { cacheVersion = 0, cacheTtl } = options
      partialDimensionFunction = this.cacheService.cache({
        version: cacheVersion,
        ttl: cacheTtl,
        name: newDimension.name,
        dimensions: dependencies,
        callback: partialDimensionFunction,
      }) as SafeAsyncPartialDimensionFunction<OUTPUT, PROBLEM, NewComputedDimension, Dependencies, Context>
    }
    return this.addSyncOrAsyncPartialComputedDimension(newDimension, dependencies, partialDimensionFunction, options)
  }

  private addSyncOrAsyncPartialComputedDimension<
    NewComputedDimension extends AbstractDimension,
    Dependencies extends readonly [...InputDimensions, ...ComputedDimensions][number][],
  >(
    newDimension: NewComputedDimension,
    dependencies: Dependencies,
    partialDimensionFunction: PartialDimensionFunction<NewComputedDimension, Dependencies, Context>,
    options: ComputedDimensionOptions,
  ): DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context> {
    dependencies = removeDuplicates(dependencies)
    this.assertNoDimensionWithName(newDimension.name, newDimension.oldNames)
    const inputDimensionsConditions = this.inputDimensionsConditions as {
      [Dimension in InputDimensions[number] as Dimension['name']]: ConditionFunction<
        [...InputDimensions, ...ComputedDimensions, NewComputedDimension]
      >
    }
    const dimensionFunctions = { ...this.dimensionFunctions }
    const partialDimensionFunctions = {
      ...this.partialDimensionFunctions,
      [newDimension.name]: partialDimensionFunction,
    }
    const dependencyMatrix = { ...this.dependencyMatrix, [newDimension.name]: dependencies } as {
      [ComputedDimension in ComputedDimensions[number] | NewComputedDimension as ComputedDimension['name']]: readonly [
        ...InputDimensions,
        ...ComputedDimensions,
        NewComputedDimension,
      ][number][]
    }

    return new DimensionedStateManagerBuilder<InputDimensions, [...ComputedDimensions, NewComputedDimension], Context>(
      this.inputDimensions,
      inputDimensionsConditions,
      [...this.computedDimensions, newDimension],
      dimensionFunctions,
      partialDimensionFunctions,
      { ...this.dimensionComputeMode, [newDimension.name]: options.reComputeOnLoad },
      dependencyMatrix,
      this.cacheService,
    )
  }

  build(): DimensionedStateManager<InputDimensions, ComputedDimensions, Context> {
    return new DimensionedStateManager(
      this.inputDimensions,
      this.inputDimensionsConditions,
      this.computedDimensions,
      this.dimensionFunctions,
      this.partialDimensionFunctions,
      this.dimensionComputeMode,
      this.dependencyMatrix,
    )
  }

  private assertNoDimensionWithName(name: string, oldNames: Readonly<string[]>) {
    const dimensionsNames = new Set(
      [...this.inputDimensions, ...this.computedDimensions].flatMap((d) => [d.name, ...d.oldNames]),
    )
    const names = [name, ...oldNames]

    const conflictingName = names.find((name) => dimensionsNames.has(name))

    if (conflictingName) {
      throw new TechnicalError('Sanity check failed: duplicate dimension name / oldNames', {
        context: { name: conflictingName },
      })
    }
  }
}

export function checkDimensionsDefined<Dimensions extends readonly AbstractDimension[]>(
  state: PartialDimensionnedState<Dimensions>,
  dimensions: Dimensions,
  assertionContext: Jsonable,
): DimensionnedState<Dimensions> {
  for (const dimension of dimensions) {
    if (dimension.isFieldMissingInPartialState(state)) {
      throw new TechnicalError('Missing field in partial state', {
        context: { assertionContext, missingField: dimension.name },
      })
    }
  }
  return state as unknown as DimensionnedState<Dimensions>
}

/**
 * Project an object to keep only fields correspnding to actual dimensions. Useful to remove
 * noise before storing in the database or sending to the frontend.
 * @param state
 */
export function projectPartialStateOnDimensions<Dimensions extends readonly AbstractDimension[]>(
  state: PartialDimensionnedState<Dimensions>,
  dimensions: Dimensions,
): PartialDimensionnedState<Dimensions> {
  const result: PartialDimensionnedState<Dimensions> = {}
  for (const dimension of dimensions) {
    if (dimension.name in state) {
      dimension.copyField(state, result)
    }
  }
  return result
}

export type ComputedDimensionOptions = {
  reComputeOnLoad: ReComputeOnLoadMode
}

export type AsyncComputedDimensionOptions = ComputedDimensionOptions & {
  /**
   * Whether or not value should be cached or not. Not optional, because it's important to think about it when
   * adding an async dimension.
   */
  cacheEnabled: boolean
  cacheTtl?: Duration
  /**
   * Bump this to ignore older version of the cache
   */
  cacheVersion?: number
}

export const LOCALE = 'fr'
export type GramaticalGender = 'feminine' | 'masculine'
export type Capitalization = 'capitalized' | 'normal'

export class BaseDimension<NAME extends string = string, TYPE = unknown> extends AbstractDimension<NAME, TYPE> {}

export function isDimensionnedStateUpdateEmpty(state: PartialDimensionnedState<readonly AbstractDimension[]>): boolean {
  // undefined values are accepted because they don't mean anything specific
  // null values are not accepted because they mean resetting the field
  return Object.values(state).every((value) => value === undefined)
}

function removeDuplicates<Dependencies extends readonly AbstractDimension[]>(dimensions: Dependencies): Dependencies {
  const uniqueDimensions: AbstractDimension[] = []
  for (const dimension of dimensions) {
    if (!uniqueDimensions.some((newDimension) => newDimension === dimension)) {
      uniqueDimensions.push(dimension)
    }
  }
  // @ts-expect-error This is not correct from a typing standpoint, but the states described by the array of dimensions
  // are still assignables, and we still need to deduplicate the arrays.
  return uniqueDimensions
}

export function getParmeterlessKeyTranslations(key: ParameterlessTranslationKey): Record<Language, string> {
  return Object.fromEntries(languages.map((language) => [language, translate(key, language)])) as Record<
    Language,
    string
  >
}

export function getTranslationsFallback(frenchValue: string): Record<Language, string> {
  return Object.fromEntries(languages.map((language) => [language, frenchValue])) as Record<Language, string>
}
