import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ensureError } from '@orus.eu/error'
import { memo, useCallback, useEffect, useState, type ChangeEvent } from 'react'
import { colorTokens } from '../../../foundation/color-tokens'
import { spacing } from '../../../foundation/spacing-tokens'
import { useCrash } from '../../../hooks'
import { FlexColumn } from '../../atoms'
import { Text } from '../../atoms/text'
import { Chip } from '../../chip'
import { TextField } from '../../inputs'
import { Spinner } from '../../spinner'
import type { LoadedFile, RejectedFile, UploadedFile, UploaderFile, UploaderStorageBackend } from './uploader-file'
import { UploaderFileRow } from './uploader-file-row'
import { UploaderInput } from './uploader-input'

export type UploaderProps = {
  storageBackend: UploaderStorageBackend
  setIsUploadInProgress: (isInProgress: boolean) => void
  isBackoffice?: boolean
}

export const Uploader = memo<UploaderProps>(function Uploader({ storageBackend, isBackoffice, setIsUploadInProgress }) {
  const crash = useCrash()
  const [files, setFiles] = useState<UploaderFile[] | 'loading'>('loading')

  useEffect(() => {
    let cancelled = false
    storageBackend.getFiles().then(
      (files) => {
        if (cancelled) return

        setFiles(files)
      },
      (err) => {
        if (cancelled) return

        crash({ type: 'unexpected-error', err: ensureError(err) })
      },
    )

    return () => {
      cancelled = true
    }
  }, [crash, storageBackend])

  const addNewLoadedFile = useCallback(
    (file: LoadedFile) => {
      setFiles((previousFiles) => (previousFiles !== 'loading' ? [...previousFiles, file] : [file]))
    },
    [setFiles],
  )

  const updateFiles = useCallback(() => {
    storageBackend.getFiles().then(
      (files) => {
        setFiles(files)
      },
      (err) => {
        crash({ type: 'unexpected-error', err: ensureError(err) })
      },
    )
  }, [crash, storageBackend])

  const replaceFile = useCallback(() => {
    updateFiles()
  }, [updateFiles])

  const handleFileLoaded = useCallback(
    (loadedFile: LoadedFile) => {
      setIsUploadInProgress(true)
      addNewLoadedFile(loadedFile)

      storageBackend.uploadFile(loadedFile).then(
        () => {
          setIsUploadInProgress(false)
          replaceFile()
        },
        () => {
          setIsUploadInProgress(false)
          replaceFile()
        },
      )
    },
    [replaceFile, storageBackend, addNewLoadedFile, setIsUploadInProgress],
  )

  const handleDownloadFile = useCallback(
    (file: UploadedFile) => {
      storageBackend.downloadFile(file).catch((err) => {
        const error = ensureError(err)
        crash({ type: 'unexpected-error', err: error })
      })
    },
    [crash, storageBackend],
  )

  const handleFileRemoved = useCallback(
    (file: RejectedFile | UploadedFile) => {
      if (file.type === 'uploaded')
        storageBackend
          .removeFile(file)
          .then(() => {
            updateFiles()
          })
          .catch((err) => {
            const error = ensureError(err)
            crash({ type: 'unexpected-error', err: error })
          })
    },
    [crash, storageBackend, updateFiles],
  )

  const handleFileDescriptionOnBlur = useCallback(
    (index: number) => {
      const file = files[index]
      if (!file || typeof file !== 'object' || file.type !== 'uploaded') return

      storageBackend
        .updateFile(file)
        .then(() => {
          updateFiles()
        })
        .catch((err) => {
          const error = ensureError(err)
          crash({ type: 'unexpected-error', err: error })
        })
    },
    [crash, files, storageBackend, updateFiles],
  )

  const handleFileDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, file: UploadedFile, index: number) => {
      if (files === 'loading') return
      const newFiles = [...files]
      newFiles[index] = {
        ...file,
        description: event.target.value,
      }
      setFiles(newFiles)
    },
    [files],
  )

  if (files === 'loading') {
    return (
      <FlexColumn
        css={css`
          align-items: center;
          justify-content: center;
          min-height: 192px; /* Same height as the uploader input, to minimize layout shifts after loading */
        `}
      >
        <Spinner size="60" />
      </FlexColumn>
    )
  }

  return (
    <MainColumn>
      <UploaderInput onFileSelected={handleFileLoaded} />
      {files.length > 0 ? (
        <FilesColumn>
          {files.map((file, index) => (
            <FileRowContent key={index}>
              {isBackoffice && (
                <FileRowContentName>
                  <Text>Fichier </Text>
                  <Chip
                    size="small"
                    textColor={colorTokens['color-text-base-secondary']}
                    backgroundColor={colorTokens['color-bg-neutral-inverse']}
                  >
                    {index + 1} sur {files.length}
                  </Chip>
                </FileRowContentName>
              )}
              <UploaderFileRow file={file} onFileRemoved={handleFileRemoved} onDownloadFile={handleDownloadFile} />
              {file.type === 'uploaded' && isBackoffice && (
                <TextField
                  size="small"
                  placeholder="Nom du fichier"
                  onChange={(e) => handleFileDescriptionChange(e, file, index)}
                  onBlur={() => handleFileDescriptionOnBlur(index)}
                  value={file.description ?? undefined}
                />
              )}
            </FileRowContent>
          ))}
        </FilesColumn>
      ) : null}
    </MainColumn>
  )
})

const FileRowContentName = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing['30']};
  align-self: stretch;
`

const FileRowContent = styled.div`
  display: flex;
  width: 720px;
  flex-direction: column;
  gap: ${spacing['50']};
`

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
`

const FilesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
`
