import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'

import { useScreenType } from '../../../hooks/index.js'
import { Text } from '../../atoms/index.js'
import { ListWrapper } from '../../features/quote-v3/list-wrapper/index.js'
import { LegacyDialog } from '../../templates/index.js'

type DialogWithRowsProps = {
  title: string
  onClose: () => void
  children: ReactNode
}

export const DialogWithRows = memo<DialogWithRowsProps>(function DialogWithRows(props) {
  const { onClose, title, children } = props
  const variant = useScreenType()

  return (
    <LegacyDialog
      header={
        <div>
          <Text variant="h4">{title}</Text>
        </div>
      }
      onClose={onClose}
    >
      <div
        css={css`
          min-width: ${variant === 'desktop' ? '600px' : ''};
        `}
      >
        <ListWrapper
          css={css`
            border-bottom: none;
          `}
        >
          {children}
        </ListWrapper>
      </div>
    </LegacyDialog>
  )
})
