import { TechnicalError } from '@orus.eu/error'

export type LogMetadata = Record<string, string | number | boolean | null>

export type LoggingTransport = {
  error?(error: Error): void
  event?(event: string, metadata: LogMetadata): void
}

// Very simple rate limiting mechanism to protect against accidental flooding of messages.
let remainingMessages = 10
setInterval(() => {
  remainingMessages = 10
}, 3600_000)

class Logger {
  private transports: Array<LoggingTransport> = []

  /**
   * Reports a problem. This is alwayes logged to the console, and additionnaly sent to sentry in production and review environments.
   */
  error(error: Error): void {
    this.transports.forEach((transport) => transport.error?.(error))

    // for dev
    console.error(error)
    if (error instanceof TechnicalError) {
      console.log('Context data of the error above :', error.context ?? 'No context attached to the error')
    }
  }

  /**
   * Adds a transport that will be notified of errors and events.
   */
  addTransport(transport: LoggingTransport): void {
    this.transports.push(transport)
  }

  /**
   * Sends an event to the server for logging purpose.
   * @param event
   * @param metadata
   */
  event(event: string, metadata: LogMetadata = {}): void {
    if (remainingMessages-- <= 0) return

    this.transports.forEach((transport) => transport.event?.(event, metadata))
  }

  /**
   * Sends an info to the server for logging purpose.
   * @param event
   * @param metadata
   */
  info(info: string): void {
    // same logic as on the backend ("logger.info is syntactic sugar")
    this.event('info', { info })
  }
}

export const logger = new Logger()
