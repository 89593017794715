import { css } from '@emotion/react'
import { Checkbox, type CheckboxProps } from '@mui/material'
import { memo, useCallback, useState, type ChangeEvent } from 'react'
import { Spinner } from '../spinner'

type ProcessingCheckboxProps = {
  /**
   * Actual value after potential processing is complete
   */
  value: boolean
  /**
   * Called when user attempts to change the value. Must return a boolean indicating if change
   * is possible
   */
  onChange: (newValue: boolean) => boolean
} & Omit<CheckboxProps, 'className' | 'checked' | 'onChange'>

/**
 * A checkbox that is replaced by a spinner while we wait for the value to change after the user
 * click
 */
export const ProcessingCheckbox = memo<ProcessingCheckboxProps>(function OptionCheckbox({ value, onChange }) {
  const [checked, setChecked] = useState(value)
  const processing = checked !== value

  const handleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      if (onChange(newValue)) setChecked(newValue)
    },
    [onChange],
  )

  return (
    <span
      css={css`
        position: relative;
      `}
    >
      <Checkbox
        css={css`
          padding: 0;
          visibility: ${processing ? 'hidden' : 'visible'};
        `}
        checked={checked}
        onChange={handleChange}
      />
      <div
        css={css`
          display: ${processing ? 'initial' : 'none'};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        <Spinner size="30" />
      </div>
    </span>
  )
})
