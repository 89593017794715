import { css, type SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { Select as MuiSelect, type SelectChangeEvent } from '@mui/material'
import { memo, useCallback, useMemo, type ReactNode } from 'react'
import { colorTokens } from '../../../foundation/color-tokens.js'
import { shadow } from '../../../foundation/shadow-tokens.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { useScreenVariant, type ScreenVariant } from '../../../hooks/use-screen-variant.js'
import { Icon, PIXELS_PER_ICON_SIZE } from '../../atoms/index.js'
import { cssPropsPerScreenVariantPerTextVariant, Text, type TextCssProps } from '../../atoms/text/index.js'
import { SelectMenuItem } from './select-menu.js'

const ICON_SIZE = PIXELS_PER_ICON_SIZE['30']

export type SelectSize = 'small' | 'large'

export type SelectProps = {
  value: string | null
  values: readonly string[] | readonly number[]
  placeholder?: string
  labels: { [key: string]: string }
  onChange: (newValue: string | null) => void
  disabled?: boolean
  size: SelectSize
  variant?: ScreenVariant
  /**
   * When provided add an extra entry at the beginning to allow clearing the field
   */
  nullLabel?: string
  className?: string
  ['aria-label']?: string

  error?: boolean
}

type SelectMenuRowProps = {
  key: string
  value: string
  text?: string
}

export const SelectMenuRow = memo<SelectMenuRowProps>(function SelectMenuRow(props) {
  const { key, text } = props
  return (
    <div
      key={key}
      css={css`
        display: flex;
        padding: ${spacing['30']} ${spacing['50']};
        align-items: center;
        align-content: center;
        gap: 16px ${spacing['50']};
        align-self: stretch;
        flex-wrap: wrap;
      `}
    >
      <Text variant="body2">{text}</Text>
    </div>
  )
})

const AngleDownIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <Icon
      icon="angle-down-solid"
      size={'30'}
      className={className}
      css={css`
        top: auto !important;
        right: ${spacing[40]} !important;
        transition: transform 0.2s;
      `}
    />
  )
}

export const Select = memo<SelectProps>(function Select(props) {
  const {
    value,
    placeholder,
    disabled,
    onChange,
    size,
    variant,
    labels,
    values,
    nullLabel,
    className,
    'aria-label': ariaLabel,
    error,
  } = props
  const screenVariant = useScreenVariant()

  const typography = cssPropsPerScreenVariantPerTextVariant['input'][variant ?? screenVariant]

  const handleChange = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      const newValueString = event.target.value
      const newValue = newValueString === nullValue ? null : newValueString
      onChange(newValue)
    },
    [onChange],
  )

  const placeHolderCss =
    placeholder && !value
      ? css`
          &::after {
            content: '${CSS.escape(placeholder)}';
            color: ${colorTokens['color-text-base-disable']};
            pointer-events: none;
            position: absolute;
            z-index: 1;
            padding: 0 calc(${spacing[40]} + ${ICON_SIZE}) 0 ${spacing[40]};
          }
        `
      : ''

  const options = useMemo(() => {
    const options: ReactNode[] = []
    if (nullLabel) {
      options.push(
        <SelectMenuItem key={nullValue} value={nullValue} typography={typography} size={size}>
          <em>{nullLabel}</em>
        </SelectMenuItem>,
      )
    }
    for (const value of values) {
      options.push(
        <SelectMenuItem key={value} value={value} typography={typography} size={size}>
          {labels[value]}
        </SelectMenuItem>,
      )
    }
    return options
  }, [nullLabel, typography, size, values, labels])

  return (
    <StyledSelect
      className={className}
      inputProps={{ 'aria-label': ariaLabel }}
      disabled={disabled}
      fullWidth
      value={value || ''}
      onChange={handleChange as (event: SelectChangeEvent<unknown>) => void}
      size={size === 'large' ? 'medium' : 'small'}
      orusSize={size}
      error={error}
      IconComponent={AngleDownIcon}
      typography={typography}
      placeholderCss={placeHolderCss}
      MenuProps={{
        style: {
          marginTop: spacing[30],
        },
        MenuListProps: {
          style: {
            padding: 0,
          },
        },
        PaperProps: {
          sx: {
            borderRadius: spacing['30'],
            border: `1px solid ${colorTokens['color-stroke-base']}`,
            boxShadow: shadow.bottom[30],
          },
          elevation: 0,
        },
      }}
    >
      {options}
    </StyledSelect>
  )
})

const selectDimensionsPerSize: {
  [size in SelectSize]: { height: string }
} = {
  small: {
    height: '32px',
  },
  large: {
    height: '48px',
  },
}

const StyledSelect = styled(MuiSelect, {
  shouldForwardProp: (propName) => !['placeholderCss'].includes(propName),
})<{
  typography: TextCssProps
  placeholderCss: SerializedStyles | ''
  value: string
  orusSize: SelectSize
}>`
  vertical-align: middle;
  border-radius: ${spacing[30]};
  ${({ typography }) => typography}
  color: ${({ value }) =>
    !value || value === '' ? colorTokens['color-text-base-disable'] : colorTokens['color-text-base-main']};

  height: ${({ orusSize }) => selectDimensionsPerSize[orusSize].height};
  box-shadow: ${shadow.bottom['05']} !important;

  & .MuiSelect-select {
    background-color: ${colorTokens['color-bg-base-normal']} !important;
    padding: 0 !important;
  }

  & .MuiSelect-outlined {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: ${spacing[40]} !important;
  }

  & fieldset {
    border-color: ${colorTokens['color-stroke-base']};
  }

  &.Mui-disabled {
    background-color: ${colorTokens['color-bg-base-disable']} !important;

    & .MuiSelect-select {
      background-color: ${colorTokens['color-bg-base-disable']} !important;
    }

    & fieldset {
      border-color: ${colorTokens['color-stroke-base-disable']} !important;
    }

    box-shadow: none !important;
    color: ${colorTokens['color-text-base-disable']};
    -webkit-text-fill-color: 'none';
  }

  &.Mui-error {
    & fieldset {
      border-color: ${colorTokens['color-stroke-danger']} !important;
    }
  }

  &:hover {
    & fieldset {
      border-color: ${colorTokens['color-stroke-base-hover']} !important;
    }
  }

  &.Mui-focused {
    & fieldset {
      border-color: ${colorTokens['color-stroke-base-selected']} !important;
    }
  }

  ${({ placeholderCss }) => placeholderCss}
`

const nullValue = '__NULL__'
