import { AbstractDimension, type TypeOfDimension } from '../abstract-dimension.js'
import { NumbersEnumDimension } from '../numbers-enum-dimension.js'

export const rcphEcomTdmiLoiValues = [15_000, 25_000, 50_000, 75_000] as const
export const rcphEcomTdmiLoiDimension = new NumbersEnumDimension({
  name: 'rcphEcomTdmiLoi',
  displayValues: { name: 'Limite de garanties TDMI' },
  values: rcphEcomTdmiLoiValues,
  tags: ['RCPH'],
} as const)
export type RcphEcomTdmiLoi = TypeOfDimension<typeof rcphEcomTdmiLoiDimension>

export type RcphTdmiLoi =
  // regular TDMI LOI
  | 15_000
  // possible options for ECOM
  | RcphEcomTdmiLoi

export class RcphTdmiLoiDimension<NAME extends string> extends AbstractDimension<NAME, RcphTdmiLoi> {}

export const rcphTdmiLoiDimension = new RcphTdmiLoiDimension({
  name: 'rcphTdmiLoi',
  displayValues: { name: 'LOI TDMI' },
  tags: ['RCPH'],
} as const)
