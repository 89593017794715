import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class BooleanDimension<NAME extends string> extends AbstractDimension<NAME, boolean> {
  override validateData(value: LooselyTypedValue): Result<boolean, DimensionValidationProblem> {
    return typeof value === 'boolean'
      ? success(value)
      : dimensionValidationFailure(`Field ${this.name} is not a boolean`)
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<boolean> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.boolean(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: boolean | null | undefined): boolean | null => {
      if (stateValue == undefined) return null
      return stateValue
    },
  }
}

export function formatRequiredBooleanForDocuments(value: boolean): string {
  return value ? 'Oui' : 'Non'
}
