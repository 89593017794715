import { AbstractDimension } from '../abstract-dimension.js'

export type MrphPeType = 'Standard' | 'Avantage'
export type MprhPeIndemnityDuration = 12 | 18 | 24 | 36
export type MrphPeDeductibleDays = 0 | 3 | 5

export type MrphPeConfiguration =
  | {
      enabled: false
      deductibleDays?: MrphPeDeductibleDays | undefined
      indemnityDuration?: MprhPeIndemnityDuration | undefined
      type?: MrphPeType | undefined
    }
  | {
      enabled: true
      type: MrphPeType
      deductibleDays: MrphPeDeductibleDays
      indemnityDuration: MprhPeIndemnityDuration
    }

export class MrphPeConfigurationDimension<NAME extends string> extends AbstractDimension<NAME, MrphPeConfiguration> {}

export const mrphPeConfigurationDimension = new MrphPeConfigurationDimension({
  name: 'mrphPeConfiguration',
  displayValues: { name: 'Configuration PE' },
  tags: ['MRPH'],
} as const)
