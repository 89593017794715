import type { AbstractDimension, PartialDimensionnedState } from './dimension/abstract-dimension.js'
import { emailDimension, firstNameDimension, lastNameDimension, phoneDimension } from './dimension/common-dimensions.js'

const nonEndorsementChangesKeys: Set<string> = new Set(
  [firstNameDimension, lastNameDimension, emailDimension, phoneDimension].map((dimension) => dimension.name),
)

export function hasOnlyNonEndorsementChanges<Dimensions extends readonly AbstractDimension[]>(
  changes: PartialDimensionnedState<Dimensions>,
): boolean {
  return Object.keys(changes).every((key) => nonEndorsementChangesKeys.has(key))
}
