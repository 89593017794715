import { Slider as MuiSlider } from '@mui/material'
import { memo, useCallback, type SyntheticEvent } from 'react'

export type SliderMark = {
  value: number
}

type SliderProps = {
  min: number
  max: number
  marks?: boolean | SliderMark[]
  step?: number | null
  value: number
  onChange: (value: number) => void
  onChangeCommitted?: (value: number) => void
  className?: string
  'aria-label'?: string
}

export const Slider = memo<SliderProps>(function Slider(props) {
  const { min, max, step, value, marks, onChange, onChangeCommitted, className, 'aria-label': ariaLabel } = props

  const handleChange = useCallback(
    (_event: Event, value: number | number[]) => {
      if (typeof value === 'number') onChange(value)
    },
    [onChange],
  )

  const handleCommittedChange = useCallback(
    (_event: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
      if (typeof value === 'number') onChangeCommitted?.(value)
    },
    [onChangeCommitted],
  )

  return (
    <MuiSlider
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleChange}
      onChangeCommitted={handleCommittedChange}
      marks={marks}
      className={className}
      aria-label={ariaLabel}
    />
  )
})
