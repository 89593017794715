import type { SerializedStyles } from '@emotion/react'
import { css } from '@emotion/react'
import type { Breakpoint } from '@mui/material'
import { Dialog as MuiDialog } from '@mui/material'
import { memo, useCallback, useMemo, type FormEvent, type ReactNode } from 'react'
import { colors } from '../../../colors.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { useScreenType, type ScreenType } from '../../../hooks/use-screen-type.js'
import { secondaryColor } from '../../../theme.js'
import { Avatar, Text, type AvatarProps } from '../../atoms/index.js'
import { Button, type ButtonAvatarPlacement, type ButtonSize, type ButtonStyle } from '../../button/index.js'

export type LegacyDialogStyle =
  /**
   * The default style, with a neutral look and feel
   */
  | 'normal'
  /**
   * A style that indicates a dangerous situation
   */
  | 'danger'
  /**
   * A style that indicates a problem
   */
  | 'problem'

export type LegacyDialogVariant =
  /**
   * The default variaant, with comfortable spacing
   */
  | 'app'
  /**
   * The variant intended for backoffice use, with more compact buttons
   */
  | 'backoffice'

export type LegacyDialogProps = {
  /**
   * The dialog variant to use, defaults to backoffice
   */
  variant?: LegacyDialogVariant
  style?: LegacyDialogStyle
  maxWidth?: Breakpoint
  fullWidth?: boolean
  /**
   * When a title is provided the default header is used with this title
   */
  title?: string
  /**
   * A custom header to replace the default one, useful is some contexts.
   */
  header?: ReactNode
  children: ReactNode
  hideHeader?: boolean
  hideCloseButton?: boolean

  onClose: () => void
  /**
   * When provided, the whole dialog is wrapped in a form, and this callback is called when the form is sumbitted, after stopping the propagation.
   * Use this when you use the dialog to trigger actions.
   */
  onSubmit?: () => void

  /**
   * When provided, a primary submit button is added in the footer.
   */
  submitLabel?: string

  /**
   * Controls the possibility to submit the form, and the footer submit button if one is added. Defaults to false (submit enabled)
   */
  submitDisabled?: boolean
  submitAvatar?: React.ReactNode
  submitAvatarPosition?: ButtonAvatarPlacement

  /**
   * When provided, a secondaty button is added in the footer, with this label
   */
  secondaryActionLabel?: string
  onSecondaryAction?: () => void
  secondaryActionDisabled?: boolean
  secondaryActionAvatar?: React.ReactNode
  secondaryActionAvatarPosition?: ButtonAvatarPlacement
  submitAriaLabel?: string
  /**
   * When provided, a tertiary button is added in the footer, with this label
   */
  tertiaryActionLabel?: string
  onTertiaryAction?: () => void
}

/**
 * @deprecated use `Dialog` instead
 */
export const LegacyDialog = memo<LegacyDialogProps>(function LegacyDialog(props) {
  const {
    variant = 'app',
    style = 'normal',
    maxWidth,
    fullWidth,
    title,
    header,
    children,
    hideHeader,
    hideCloseButton,
    onClose,
    onSubmit,
    submitLabel,
    submitDisabled,
    submitAvatar,
    submitAvatarPosition,
    secondaryActionLabel,
    onSecondaryAction,
    secondaryActionAvatar,
    secondaryActionDisabled,
    secondaryActionAvatarPosition,
    submitAriaLabel,
    tertiaryActionLabel,
    onTertiaryAction,
  } = props
  const screenType = useScreenType()

  const dialogContent = (
    <>
      {hideHeader ? null : (
        <div css={{ ...headerWrapperCssByScreenType[screenType], marginBottom: spacing[70] }}>
          <div>{header ? header : title ? <DefaultDialogHeader style={style} title={title} /> : <></>}</div>
          {hideCloseButton ? null : <DialogCloseButton onClose={onClose} />}
        </div>
      )}

      <div
        css={css`
          flex-grow: 1;
        `}
      >
        {children}
      </div>

      {submitLabel || secondaryActionLabel ? (
        <div
          css={css`
            margin-top: ${spacing[70]};
          `}
        >
          <DialogFooter
            style={style}
            variant={variant}
            onSubmit={onSubmit}
            submitLabel={submitLabel}
            submitDisabled={submitDisabled}
            submitAvatar={submitAvatar}
            submitAvatarPosition={submitAvatarPosition}
            secondaryActionLabel={secondaryActionLabel}
            onSecondaryAction={onSecondaryAction}
            secondaryActionAvatar={secondaryActionAvatar}
            secondaryActionDisabled={secondaryActionDisabled}
            secondaryActionAvatarPosition={secondaryActionAvatarPosition}
            submitAriaLabel={submitAriaLabel}
            tertiaryActionLabel={tertiaryActionLabel}
            onTertiaryAction={onTertiaryAction}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )

  const wrapperCss = wrapperCssByScreenType[screenType]

  const onSubmitCallback = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      onSubmit?.()
      /**
       * I don't know why but when we have two dialog (which are form)
       * in different DOM tree (so not nested) they have an impact on each other
       * It was the only way I found to stop this behaviour as of now
       */
      event.stopPropagation()
    },
    [onSubmit],
  )

  const contentWrapper = onSubmit ? (
    <form css={wrapperCss} onSubmit={onSubmitCallback}>
      {dialogContent}
    </form>
  ) : (
    <div css={wrapperCss}>{dialogContent}</div>
  )

  return (
    <MuiDialog
      open
      onClose={onClose}
      fullScreen={screenType === 'mobile'}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      transitionDuration={
        screenType === 'mobile'
          ? {
              appear: 0,
              exit: 0,
            }
          : undefined
      }
    >
      {contentWrapper}
    </MuiDialog>
  )
})

type DialogCloseButtonProps = {
  onClose: () => void
}

const DialogCloseButton = memo<DialogCloseButtonProps>(function DialogCloseButton({ onClose }) {
  const handleClose = useCallback(() => onClose(), [onClose])
  const screenType = useScreenType()

  return (
    <button
      onClick={handleClose}
      type="button"
      css={css`
        border: none;
        padding: 0;
        background: none;
        cursor: pointer;
        position: relative;
        color: ${colors.gray[900]};
        align-self: ${screenType === 'mobile' ? 'flex-end' : undefined};

        &::after {
          content: ' ';
          position: absolute;
          inset: -${spacing[50]} -${spacing[50]} -${spacing[50]} -${spacing[50]};
        }
      `}
    >
      <Avatar icon="xmark-solid" size="40" />
    </button>
  )
})

type DefaultDialogHeaderProps = {
  style?: LegacyDialogStyle
  title: string
}

const DefaultDialogHeader = memo<DefaultDialogHeaderProps>(function DefaultDialogHeader(props) {
  const { style = 'normal', title } = props

  return (
    <>
      <Avatar size="60" {...avatarPropsByDialogStyle[style]} />
      <Text
        variant="h4"
        css={css`
          margin-top: ${spacing[60]};
          word-break: break-word;
        `}
      >
        {title}
      </Text>
    </>
  )
})

type DialogFooterProps = {
  style: LegacyDialogStyle
  variant: LegacyDialogVariant
  onSubmit?: () => void
  submitLabel?: string
  submitDisabled?: boolean
  submitAvatar?: React.ReactNode
  submitAvatarPosition?: ButtonAvatarPlacement
  secondaryActionLabel?: string
  onSecondaryAction?: () => void
  secondaryActionDisabled?: boolean
  secondaryActionAvatar?: React.ReactNode
  secondaryActionAvatarPosition?: ButtonAvatarPlacement
  submitAriaLabel?: string
  tertiaryActionLabel?: string
  onTertiaryAction?: () => void
}

const DialogFooter = memo<DialogFooterProps>(function DialogFooter(props) {
  const screenType = useScreenType()
  const buttonSize = buttonSizeByVariantByScreenType[props.variant][screenType]
  const buttonStyle = buttonStyleByDialogStyle[props.style]

  const handleSecondaryActionClick = useMemo(() => {
    const onSecondaryAction = props.onSecondaryAction
    return onSecondaryAction ? () => onSecondaryAction() : undefined
  }, [props.onSecondaryAction])

  const buttonCss = buttonCssByScreenType[screenType]

  return (
    <div css={buttonBarCssByScreenType[screenType]}>
      {props.submitLabel ? (
        <Button
          variant="primary"
          style={buttonStyle}
          type="submit"
          disabled={props.submitDisabled}
          avatar={props.submitAvatar}
          avatarPosition={props.submitAvatarPosition}
          size={buttonSize}
          css={buttonCss}
          ariaLabel={props.submitAriaLabel}
        >
          {props.submitLabel}
        </Button>
      ) : (
        <></>
      )}
      {props.secondaryActionLabel ? (
        <Button
          variant="secondary"
          disabled={props.secondaryActionDisabled}
          avatar={props.secondaryActionAvatar}
          avatarPosition={props.secondaryActionAvatarPosition}
          onClick={handleSecondaryActionClick}
          size={buttonSize}
          css={buttonCss}
        >
          {props.secondaryActionLabel}
        </Button>
      ) : (
        <></>
      )}

      {props.tertiaryActionLabel ? (
        <>
          {screenType === 'desktop' ? (
            <div
              css={css`
                flex: 1;
              `}
            />
          ) : (
            <></>
          )}
          <Button
            variant="secondary"
            style="danger"
            onClick={props.onTertiaryAction}
            size={buttonSize}
            css={{ ...buttonCss, alignSelf: 'flex-start' }}
            fullWidth={screenType === 'mobile'}
          >
            {props.tertiaryActionLabel}
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  )
})

const wrapperCssByScreenType: { readonly [key in ScreenType]: SerializedStyles } = {
  mobile: css`
    padding: ${spacing[50]} ${spacing[60]};
    display: flex;
    min-height: 100%;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
  `,
  desktop: css`
    padding: ${spacing[70]};
    min-width: 400px;
  `,
}

const headerWrapperCssByScreenType: { readonly [key in ScreenType]: SerializedStyles } = {
  mobile: css`
    display: flex;
    flex-direction: column-reverse;
    gap: ${spacing[60]};
  `,
  desktop: css`
    display: flex;
    gap: ${spacing[70]};
    align-items: flex-start;
    justify-content: space-between;
  `,
}

const buttonBarCssByScreenType: { readonly [key in ScreenType]: SerializedStyles } = {
  mobile: css`
    display: flex;
    flex-direction: column;
    gap: ${spacing[40]};
  `,
  desktop: css`
    display: flex;
    flex-direction: row-reverse;
    gap: ${spacing[40]};
    button: {
      max-width: 40%;
    }
  `,
}

const buttonCssByScreenType: { readonly [key in ScreenType]: SerializedStyles | null } = {
  mobile: null,
  desktop: css`
    max-width: 50%;
  `,
}

const buttonSizeByVariantByScreenType: {
  readonly [key in LegacyDialogVariant]: { readonly [key in ScreenType]: ButtonSize }
} = {
  app: {
    mobile: 'large',
    desktop: 'medium',
  },
  backoffice: {
    mobile: 'large',
    desktop: 'small',
  },
}

const buttonStyleByDialogStyle: { [key in LegacyDialogStyle]: ButtonStyle } = {
  normal: 'base',
  problem: 'base',
  danger: 'danger',
}

const avatarPropsByDialogStyle: { [key in LegacyDialogStyle]: Pick<AvatarProps, 'icon' | 'color'> } = {
  normal: {
    icon: 'circle-info-solid',
    color: secondaryColor,
  },
  problem: {
    icon: 'triangle-exclamation-solid',
    color: colors.orange[500],
  },
  danger: {
    icon: 'diamond-exclamation-solid',
    color: colors.red[500],
  },
}
