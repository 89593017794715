import type { Activity } from '@orus.eu/activity'
import { AbstractDimension } from '../abstract-dimension.js'

export class MrpwWakamActivities extends AbstractDimension<'mrpwWakamActivities', string[]> {
  constructor() {
    super({
      name: 'mrpwWakamActivities',
      displayValues: { name: "Liste d'activités correspondantes du référentiel wakam" },
      tags: ['MRPW'],
    })
  }
}

export const mrpwWakamActivitiesDimension = new MrpwWakamActivities()

export const mrpwRestaurantActivities = [
  'traditionalRestaurant',
  'pizzeria',
  'coffeeBar',
  'fastFood',
  'teaRoom',
  'creperie',
  'sushi',
  'iceCream',
  'caterer',
  'delicatessen',
] as const satisfies readonly Activity[]
