import { type DimensionOptions } from './abstract-dimension.js'
import { StringsEnumDimension } from './strings-enum-dimension.js'

export class CommitmentDimension<NAME extends string> extends StringsEnumDimension<
  NAME,
  readonly ['monthly', 'yearly']
> {
  constructor(options: DimensionOptions<NAME>) {
    super({ ...options, values: ['monthly', 'yearly'] })
  }
}
export type Commitment = 'monthly' | 'yearly'
