import { memo } from 'react'
import { colors } from '../../../colors.js'
import type { CompoundIconName } from '../icon/names.js'
import type { IconSize } from '../icon/sizes.js'
import { Avatar } from './avatar.js'

type AvatarDecorativeBackgroundColor = 'sky' | 'mindaro' | 'jasmine' | 'peach' | 'periwinkle'

const colorPerAvatarDecorativeBackgroundColor: {
  [backgroundColor in AvatarDecorativeBackgroundColor]: string
} = {
  jasmine: colors.yellow[800],
  mindaro: colors.green[800],
  peach: colors.red[800],
  periwinkle: colors.blue[800],
  sky: colors.blue[800],
}

export type AvatarDecorativeProps = {
  size: IconSize
  icon: CompoundIconName
  backgroundColor: AvatarDecorativeBackgroundColor
  className?: string
}

export const AvatarDecorative = memo<AvatarDecorativeProps>(function AvatarDecorative(props) {
  const { size, icon, backgroundColor, className } = props
  return (
    <Avatar
      icon={icon}
      size={size}
      color={colorPerAvatarDecorativeBackgroundColor[backgroundColor]}
      className={className}
      variant="contained"
      containerColor={colors[backgroundColor]?.gradient}
    />
  )
})
