import { css } from '@emotion/react'
import { memo } from 'react'
import { colors } from '../../../colors.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { useScreenType } from '../../../hooks/index.js'
import { CheckboxFormField, type CheckboxFieldProps } from '../../molecules/index.js'

type CheckboxContainerLegacyProps = {
  displayBackground: boolean
  className?: string
} & Omit<CheckboxFieldProps, 'variant'>

export const CheckboxContainerLegacy = memo<CheckboxContainerLegacyProps>(function CheckboxContainerLegacy(props) {
  const backgroundColor = props.checked ? colors.blue[200] : colors.gray[100]
  const background = props.displayBackground ? backgroundColor : 'none'
  const padding = props.displayBackground ? spacing[50] : 0
  const screenType = useScreenType()
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: ${padding};
        border-radius: ${spacing[30]};
        background: ${background};
      `}
    >
      <CheckboxFormField {...props} variant={screenType} />
    </div>
  )
})
