import { success, type Result } from '@orus.eu/result'
import { isValidBIC, isValidIBAN } from 'ibantools'
import { z } from 'zod'
import { AbstractDimension, dimensionValidationFailure, type DimensionValidationProblem } from './abstract-dimension.js'

const bankAccountSchema = z.object({
  iban: z.string(),
  bic: z.string(),
})

export type BankAccount = z.infer<typeof bankAccountSchema>

export class BankAccountDimension<NAME extends string> extends AbstractDimension<NAME, BankAccount> {
  override validateData(value: unknown): Result<BankAccount, DimensionValidationProblem> {
    const validObject = bankAccountSchema.safeParse(value)

    if (!validObject.success) {
      return dimensionValidationFailure(`Field ${this.name} is not a BankAccount`)
    }

    const { data } = validObject

    if (!isValidIBAN(data.iban)) {
      return dimensionValidationFailure(`Field ${this.name} is not a BankAccount because of invalid IBAN`)
    }

    if (!isValidBIC(data.bic)) {
      return dimensionValidationFailure(`Field ${this.name} is not a BankAccount because of invalid BIC`)
    }

    return success(data)
  }
}
