import { amountToString, newAmount, zeroAmount, type Amount } from '@orus.eu/amount'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { RcphDeductibles } from '@orus.eu/quote'
import type {
  OfferType,
  PolicyDeductible,
  PolicyDeductibleDescription,
  PolicyGuaranteeLimit,
  PolicyGuaranteeVisualPriority,
  PolicyOffer,
  RcProGuaranteesTypes,
} from '../quote-offer-information-dimension.js'
import { offersDescriptions } from '../shared-quote-offer-information.js'
import type { RcphLoi } from './rcph-loi-dimension.js'
import type { RcphProduct } from './rcph-product-dimension.js'

const twentyFiveThousand = 25_000
const fiftyThousand = 50_000
const twoHundredFiftyThousand = 250_000

function getGuaranteeLimitForMax(limit: RcphLoi): PolicyGuaranteeLimit {
  const currentLimitAmount = newAmount(limit)
  return {
    name: `Plafond d’indemnisation`,
    limit: amountToString(currentLimitAmount, { displayDecimals: false, addCurrency: true }),
    description: '',
    isGuaranteeFixed: false,
  }
}

function getGuaranteeLimitPerClaim(limit: Amount): PolicyGuaranteeLimit {
  return {
    name: `Plafond d’indemnisation`,
    limit: amountToString(limit, { displayDecimals: false, addCurrency: true }),
    description: 'par sinistre et par an',
    isGuaranteeFixed: true,
  }
}

export const rcphProtectionJuridiqueLimit = newAmount(fiftyThousand)

export function getRcProGuaranteeLimitFactories(
  product: RcphProduct,
  type: RcProGuaranteesTypes,
  currentLimit: RcphLoi,
): PolicyGuaranteeLimit {
  switch (type) {
    case 'RecoursJuridique':
      return {
        name: `Plafond d’indemnisation`,
        limit: amountToString(rcphProtectionJuridiqueLimit, { displayDecimals: false, addCurrency: true }),
        description: 'par sinistre',
        isGuaranteeFixed: true,
      }
    case 'AtteinteReputation':
    case 'ContestationCreance':
    case 'CoutsdeProjet':
    case 'PerteDocuments':
    case 'PiratageSiteInternet':
    case 'RemplacementHommeCle':
      return getGuaranteeLimitPerClaim(
        product === 'TECH' ? newAmount(twoHundredFiftyThousand) : newAmount(twentyFiveThousand),
      )
    case 'RemboursementPresta':
    case 'FautesProOuNegligence':
    case 'ManquementsContract':
    case 'AtteinteDroitPropriete':
    case 'BienDocumentConfies':
      return getGuaranteeLimitForMax(currentLimit)
  }
}

function getGeneralDeductible(amount: Amount): PolicyDeductibleDescription {
  return {
    deductible: amountToString(amount, { displayDecimals: false, addCurrency: true }),
    description: '',
  }
}

function getBodilyHarmDeductible(deductibles: RcphDeductibles): PolicyDeductibleDescription {
  const amount = checkDefinedAndNotNull(deductibles.rcp.bodilyHarm)
  return {
    deductible: amountToString(amount, { displayDecimals: false, addCurrency: true }),
    description: 'pour les dommages corporels',
  }
}

function getUsOrCanadaDeductible(): PolicyDeductibleDescription {
  const amount = newAmount('15 000')
  return {
    deductible: amountToString(amount, { displayDecimals: false, addCurrency: true }),
    description: 'pour les USA & le Canada',
  }
}

function computeDeductible(amount: Amount, description: string): PolicyDeductibleDescription {
  return {
    deductible: amountToString(amount, { displayDecimals: false, addCurrency: true }),
    description: description,
  }
}

export const rcphProtectionJuridiqueDeductible = zeroAmount

export const rcProDeductibleDescriptionsFactories: {
  [key in RcProGuaranteesTypes]: (deductibles: RcphDeductibles) => PolicyDeductible
} = {
  RemboursementPresta: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [
        getGeneralDeductible(deductibles.rcp.materialDamage),
        getBodilyHarmDeductible(deductibles),
        getUsOrCanadaDeductible(),
      ],
    }
  },
  RecoursJuridique: (_deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [computeDeductible(rcphProtectionJuridiqueDeductible, '')],
    }
  },
  CoutsdeProjet: (deductibles) => {
    const projectCostAmount = checkDefinedAndNotNull(deductibles.rcp.projectCostGuarantee)
    return {
      name: 'Franchise',
      descriptions: [computeDeductible(projectCostAmount, '')],
    }
  },
  ContestationCreance: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [getGeneralDeductible(deductibles.rcp.immaterialDamage)],
    }
  },
  AtteinteReputation: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [getGeneralDeductible(deductibles.rcp.immaterialDamage)],
    }
  },
  PerteDocuments: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [getGeneralDeductible(deductibles.rcp.immaterialDamage)],
    }
  },
  PiratageSiteInternet: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [getGeneralDeductible(deductibles.rcp.immaterialDamage)],
    }
  },
  ManquementsContract: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [
        getGeneralDeductible(deductibles.rcp.materialDamage),
        getBodilyHarmDeductible(deductibles),
        getUsOrCanadaDeductible(),
      ],
    }
  },
  FautesProOuNegligence: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [
        getGeneralDeductible(deductibles.rcp.materialDamage),
        getBodilyHarmDeductible(deductibles),
        getUsOrCanadaDeductible(),
      ],
    }
  },
  BienDocumentConfies: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [
        getGeneralDeductible(deductibles.rcp.materialDamage),
        getBodilyHarmDeductible(deductibles),
        getUsOrCanadaDeductible(),
      ],
    }
  },
  AtteinteDroitPropriete: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [
        getGeneralDeductible(deductibles.rcp.materialDamage),
        getBodilyHarmDeductible(deductibles),
        getUsOrCanadaDeductible(),
      ],
    }
  },
  RemplacementHommeCle: (deductibles) => {
    return {
      name: 'Franchise',
      descriptions: [getGeneralDeductible(deductibles.rcp.immaterialDamage)],
    }
  },
}

export type RcProVisibleGuaranteesForPolicy = Partial<{
  [key in RcProGuaranteesTypes]: PolicyGuaranteeVisualPriority
}>

export const rcProPolicyOffers: Pick<
  {
    [offer in OfferType]: PolicyOffer
  },
  'rcp' | 'avantagePlus' | 'protectionJuridique'
> = {
  rcp: offersDescriptions['rcp'],
  avantagePlus: offersDescriptions['avantagePlus'],
  protectionJuridique: offersDescriptions['protectionJuridique'],
}

export const rcProGuaranteeOfferFactory: {
  [key in RcProGuaranteesTypes]: PolicyOffer
} = {
  RecoursJuridique: rcProPolicyOffers['protectionJuridique'],
  AtteinteReputation: rcProPolicyOffers['avantagePlus'],
  PerteDocuments: rcProPolicyOffers['avantagePlus'],
  RemplacementHommeCle: rcProPolicyOffers['avantagePlus'],
  PiratageSiteInternet: rcProPolicyOffers['avantagePlus'],
  ContestationCreance: rcProPolicyOffers['avantagePlus'],
  AtteinteDroitPropriete: rcProPolicyOffers['rcp'],
  BienDocumentConfies: rcProPolicyOffers['rcp'],
  CoutsdeProjet: rcProPolicyOffers['rcp'],
  FautesProOuNegligence: rcProPolicyOffers['rcp'],
  ManquementsContract: rcProPolicyOffers['rcp'],
  RemboursementPresta: rcProPolicyOffers['rcp'],
}
