import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { colorTokens, spacing } from '../../foundation'
import { largeContainerQuery } from '../../hooks'
import { Text } from '../atoms'

export type SubSectionProps = {
  title?: string
  subtitle?: string
  children: ReactNode
  className?: string
  required?: boolean
}

export const SubSection = memo<SubSectionProps>(function SubSection(props: SubSectionProps) {
  const { title, subtitle, children, className, required } = props

  return (
    <SubSectionContainer className={className}>
      <SubSectionContent>
        {title ? (
          <AnnotationContainer>
            <Text variant="captionMedium" color={colorTokens['color-text-base-main']}>
              {title}
              {required ? (
                <span
                  css={css`
                    color: ${colorTokens['color-text-danger-secondary']};
                  `}
                >
                  {' '}
                  *
                </span>
              ) : null}
            </Text>
            <Text variant="caption" color={colorTokens['color-text-base-basic']}>
              {subtitle}
            </Text>
          </AnnotationContainer>
        ) : null}
        <ChildrenContainer>{children}</ChildrenContainer>
      </SubSectionContent>
    </SubSectionContainer>
  )
})

const SubSectionContainer = styled.div`
  container-type: inline-size;
`

const SubSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${spacing[70]};

  ${largeContainerQuery} {
    flex-direction: row;
  }
`

const AnnotationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[20]};

  max-width: 240px;
  min-width: 160px;
  flex: 1 1;

  & * {
    overflow: hidden;
    white-space: wrap;
  }
`

const ChildrenContainer = styled.div`
  flex: 1 1;
  min-width: 0;
`

const SubSectionTestFormPlaceHolderContainer = styled.div`
  min-height: 200px;
  min-width: 240px;
  flex: 1 1;
  border-radius: ${spacing[40]};
  background-color: ${colorTokens['color-bg-base-disable']};
`

export const SubSectionTestFormPlaceHolder = memo(function SubSectionTestFormPlaceHolder() {
  return <SubSectionTestFormPlaceHolderContainer />
})
