import {
  type CanonicalCompanyIdNumberType,
  type CompanyIdNumber,
  companyIdNumberZodSchema,
} from '@orus.eu/company-id-number'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { type Result, success } from '@orus.eu/result'
import {
  AbstractDimension,
  type DimensionValidationProblem,
  type LooselyTypedValue,
  dimensionValidationFailure,
} from './abstract-dimension'

export class CompanyIdNumberDimension<NAME extends string> extends AbstractDimension<NAME, CompanyIdNumber> {
  override validateData(value: LooselyTypedValue): Result<CompanyIdNumber, DimensionValidationProblem> {
    const parsingResult = companyIdNumberZodSchema.safeParse(value)
    if (parsingResult.success) {
      return success(parsingResult.data)
    }
    return dimensionValidationFailure(parsingResult.error.message)
  }
}

export const allowedCompanyIdNumberTypesPerZone: Record<OperatingZone, Set<CanonicalCompanyIdNumberType>> = {
  fr: new Set(['siren', 'later']),
  es: new Set(['cif', 'nif', 'later']),
}
