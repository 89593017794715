import { openChat } from '../../lib/hubspot-util'
import { GenericProblemMessage } from './generic-problem-message'

export default function TemporaryFailureScreen(): JSX.Element {
  return (
    <GenericProblemMessage
      title="Problème technique temporaire"
      principalMessage="Désolé, un problème technique nous empêche de continuer."
      firstSubText="Ce problème est temporaire, il peut être résolu en ré-essayant plus tard, ou en rafrachissant la page."
      secondSubText="Vous pouvez également nous contacter pour toute question."
      buttonText="Contacter le support"
      onButtonClick={openChat}
      secondaryButtonText="Ré-essayer"
      onSecondaryButtonClick={() => window.location.reload()}
    />
  )
}
