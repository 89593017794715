import { translateIn, type Language, type TranslateInSpecificLanguageFunction } from '@orus.eu/translations'
import { createContext, useContext } from 'react'

const languageContext = createContext<Language>('fr')

export function useLanguage(): Language {
  return useContext(languageContext)
}

export const LanguageProvider: React.Provider<Language> = languageContext.Provider

export function useTranslate(): TranslateInSpecificLanguageFunction {
  return translateIn[useLanguage()]
}
