import styled from '@emotion/styled'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { typedMemo } from '../../../util.js'
import { type AvatarDecorativeProps } from '../../atoms/index.js'
import { RowV2DynamicButton } from '../../molecules/button/row-v2-dynamic-button.js'

export type ChoiceGridProps<VALUES extends readonly string[]> = {
  values: VALUES
  labels: Record<VALUES[number], string>
  avatarProps: Record<VALUES[number], ChoiceGridItemAvatarProps>
  onItemClicked: (value: VALUES[number]) => void
}

export type ChoiceGridItemAvatarProps = Pick<AvatarDecorativeProps, 'icon' | 'backgroundColor'>

export const ChoiceGrid = typedMemo(function ChoiceGrid<VALUES extends readonly string[]>({
  values,
  labels,
  avatarProps,
  onItemClicked,
}: ChoiceGridProps<VALUES>) {
  return (
    <ChoiceGridContainer>
      {values.map((value: VALUES[number]) => (
        <RowV2DynamicButton
          key={value}
          avatarProps={avatarProps[value]}
          label={labels[value]}
          onItemClicked={onItemClicked}
          value={value}
        />
      ))}
    </ChoiceGridContainer>
  )
})

const ChoiceGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${spacing[30]};

  /* switches before the real media query to prevent text going out of bounds */
  ${window.self !== window.top ? `@media (width <= 600px) {` : `@media (width <= 1200px)`} {
    display: flex;
    flex-direction: column;
    gap: ${spacing[30]};
  }
`
