import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class ActivitiesDimension<NAME extends string> extends AbstractDimension<NAME, Activities> {
  override validateData(value: LooselyTypedValue): Result<Activities, DimensionValidationProblem> {
    const parsingResult = SCHEMA.safeParse(value)

    if (!parsingResult.success) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }

    return success(parsingResult.data)
  }
}

const SCHEMA = z.array(z.object({ activity: z.string(), displayName: z.string() }))
type Activities = z.infer<typeof SCHEMA>
