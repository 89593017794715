import { z } from 'zod'

const cifRegex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
export function parseCifValue(input: string): string | null {
  const trimmedInput = input.replace(/\s/g, '').trim()
  if (cifRegex.test(trimmedInput)) {
    return trimmedInput
  }
  return null
}

export const cifValueZodSchema = z.string().refine((s) => parseCifValue(s) !== null, {
  message: 'Invalid cif',
})
