import { type TypeOfDimension } from '../abstract-dimension.js'
import { NumbersEnumDimension } from '../numbers-enum-dimension.js'

export const rcphLoiValues = [
  50_000, 100_000, 200_000, 300_000, 400_000, 500_000, 750_000, 1_000_000, 1_500_000, 2_000_000, 3_000_000,
] as const
export const rcphLoiDimension = new NumbersEnumDimension({
  name: 'rcphLoi',
  displayValues: { name: 'Limite de garanties (en €)' },
  values: rcphLoiValues,
  tags: ['RCPH'],
} as const)
export type RcphLoi = TypeOfDimension<typeof rcphLoiDimension>
export function isRcphLoi(value: unknown): value is RcphLoi {
  return typeof value === 'number' && (rcphLoiValues as readonly number[]).includes(value)
}
