import { css } from '@emotion/react'
import { memo, type CSSProperties, type ReactNode } from 'react'
import { colors } from '../../colors.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { useScreenType } from '../../hooks/index.js'

export type MenuLayoutProps = {
  /**
   * Always displayed
   */
  menuTitle: ReactNode
  /**
   * Displayed when the menu is open (always open on desktop)
   */
  menuBody: ReactNode
  /**
   * Content of the page
   */
  pageContent: ReactNode
  /**
   * A react node contaning the button to open the menu
   */
  openMenuButton: ReactNode
  /**
   * A react node contaning the button to close the menu
   */
  closeMenuButton: ReactNode
  /**
   * Open
   */
  menuOpen: boolean
  /**
   * Optional borders to apply to the menu
   */
  border?: string
  /**
   * Exposed just for Storybook stories, as I didn't find any other method to constrain the
   * size of the component.
   */
  styleForTests?: CSSProperties
}

/**
 * The usual menu layout, with a menu on the left that moves to the top on mobile. This component
 * handles just the layout, not the natigation.
 * It operates by just changing the styles, which means that states of the children won't be lost during
 * window resizes.
 */
export const MenuLayout = memo<MenuLayoutProps>(function MenuLayout(props: MenuLayoutProps) {
  const { menuTitle, menuBody, openMenuButton, closeMenuButton, pageContent, menuOpen, styleForTests } = props
  const screenType = useScreenType()

  const configuration = screenType === 'desktop' ? 'desktop' : menuOpen ? 'mobileOpen' : 'mobileClosed'

  const menuBlockCss =
    configuration === 'desktop'
      ? css`
          flex-grow: 0;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: auto;
          border-right: ${props.border};
          background-color: ${colors.white};
          box-shadow: 0 1px 4px 0 rgb(34 40 47 / 10%);
        `
      : configuration === 'mobileOpen'
        ? css`
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: ${colors.white};
          `
        : css`
            position: absolute;
            z-index: 1;
            align-self: flex-end;
            flex-grow: 0;
            flex-shrink: 0;
            border-bottom: ${props.border};
            background-color: ${colors.white};
          `

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${screenType === 'desktop' ? 'row' : 'column'};
        width: 100%;
        height: 100%;
      `}
      style={styleForTests}
    >
      <div css={menuBlockCss}>
        <div
          css={css`
            flex-grow: ${configuration === 'desktop' ? 0 : 1};
            justify-content: flex-end;
            flex-shrink: 0;
            display: flex;
            padding: ${configuration === 'desktop' ? '0' : `${spacing[40]} ${spacing[60]}`};
          `}
        >
          {configuration === 'desktop' ? (
            <div
              css={css`
                flex-grow: 1;
                flex-shrink: 0;
              `}
            >
              {menuTitle}
            </div>
          ) : (
            <></>
          )}

          {configuration === 'mobileClosed' ? (
            <div
              css={css`
                flex-grow: 0;
                flex-shrink: 0;
              `}
            >
              {openMenuButton}
            </div>
          ) : (
            <></>
          )}

          {configuration === 'mobileOpen' ? (
            <div
              css={css`
                flex-grow: 0;
                flex-shrink: 0;
              `}
            >
              {closeMenuButton}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          css={css`
            display: ${configuration === 'desktop' || configuration === 'mobileOpen' ? 'block' : 'none'};
            flex-grow: 1;
          `}
        >
          {menuBody}
        </div>
      </div>
      <div
        css={css`
          display: ${configuration === 'desktop' || configuration === 'mobileClosed' ? 'block' : 'none'};
          flex-grow: 1;
          height: 100%;
          overflow: auto;
        `}
      >
        {pageContent}
      </div>
    </div>
  )
})
