import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { spacing } from '../../foundation'
import { SectionTitle, type SectionTitleProps } from './section-title'

export type SectionProps = {
  title: SectionTitleProps
  children: ReactNode[]
}

export const Section = memo<SectionProps>(function Section(props: SectionProps) {
  const { title, children } = props

  return (
    <SectionContainer>
      <SectionTitle {...title} />
      {children}
    </SectionContainer>
  )
})

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
`
