import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { colors } from '../../../colors.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { Text } from '../../atoms/index.js'
import type { TextVariant } from '../../atoms/text/variant.js'

const borderRadius = '8px'

type TabBarSize = 'small' | 'large'

type TabBarProps = {
  size: TabBarSize
  fullWidth?: boolean

  tabs: Record<string, string>
  selectedTabId: string
  onTabChange: (tabId: string) => void

  className?: string
}

export const TabBar = memo<TabBarProps>(function TabBar(props) {
  const { size, fullWidth, tabs, selectedTabId, onTabChange, className } = props

  const { textVariant, height } = sizesPerTabBarSize[size]

  return (
    <div
      className={className}
      css={css`
        display: ${fullWidth ? 'flex' : 'inline-flex'};

        border-radius: ${borderRadius};
        border: 1px solid ${colors.gray[300]};
      `}
    >
      {Object.entries(tabs).map(([id, label]) => (
        <TabButton type="button" key={id} height={height} active={id === selectedTabId} onClick={() => onTabChange(id)}>
          <Text
            variant={textVariant}
            css={css`
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            `}
          >
            {label}
          </Text>
        </TabButton>
      ))}
    </div>
  )
})

const TabButton = styled.button<{ height: string; active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;
  height: ${({ height }) => height};

  padding: 0 ${spacing[60]} 0 ${spacing[60]};

  color: ${({ active }) => (active ? colors.blue[500] : colors.gray[900])};

  transition: all 0.3s;
  cursor: pointer;
  background-color: ${({ active }) => (active ? colors.blue[200] : colors.white)};
  border: 1px solid transparent;
  border-radius: ${borderRadius};

  &:hover {
    color: ${colors.blue[500]};
  }

  &:active {
    background-color: ${colors.blue[200]};
    color: ${colors.blue[500]};
  }
`

const sizesPerTabBarSize: {
  [type in TabBarSize]: {
    textVariant: TextVariant
    height: string
  }
} = {
  small: { textVariant: 'captionMedium', height: '32px' },
  large: { textVariant: 'body2Medium', height: '40px' },
}
