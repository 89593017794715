import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'
import { colors } from '../../../../colors.js'

export type ListWrapperProps = {
  children: ReactNode
  className?: string | undefined
}

export const ListWrapper = memo<ListWrapperProps>(function ListWrapper(props) {
  const { children, className } = props

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;

        & > * {
          border-bottom: 1px solid ${colors.gray[100]};

          &:last-child {
            border-bottom: none;
          }
        }
      `}
    >
      {children}
    </div>
  )
})
