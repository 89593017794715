import type { EmbeddingPartner, SubscriptionStepId } from '@orus.eu/dimensions'
import { createContext, useCallback, useContext } from 'react'

const embeddingPartnerContext = createContext<{
  setEmbeddingPartnerId: (embeddingPartnerId: string | undefined) => void
  embeddingPartnerId: string | undefined
}>({
  embeddingPartnerId: undefined,
  setEmbeddingPartnerId: () => {
    console.log('default set embedding partner')
  },
})
export const EmbeddingPartnerProvider = embeddingPartnerContext.Provider

export type SetEmbeddingPartner = (embeddingPartner: EmbeddingPartner) => void

export function useSetEmbeddingPartner(): SetEmbeddingPartner {
  const setEmbeddingPartnerId = useContext(embeddingPartnerContext).setEmbeddingPartnerId
  return useCallback(
    (embeddingPartner) => {
      const embeddingPartnerId = embeddingPartner === 'none' ? undefined : embeddingPartner.id
      setEmbeddingPartnerId(embeddingPartnerId)
    },
    [setEmbeddingPartnerId],
  )
}

export type EmbeddingPartnerConfiguration = {
  name: string
  /**
   * The URL to redirect to when the user clicks on the "Retourner à <name>" button
   * in the customer menu.
   * If not set, the button will not be displayed.
   */
  returnUrl?: string
  partnerStepSubtitles: Partial<Record<SubscriptionStepId, string>>
}

export function useEmbeddingPartnerConfiguration(): EmbeddingPartnerConfiguration | undefined {
  const embeddingPartnerId = useContext(embeddingPartnerContext).embeddingPartnerId
  return embeddingPartnerId ? embedingPartnersConfigurations[embeddingPartnerId] : undefined
}

const embedingPartnersConfigurations: Record<string, EmbeddingPartnerConfiguration> = {
  sidecare: {
    name: 'Sidecare',
    returnUrl: 'https://www.sidecare.com/espace-entreprise/vos-contrats',
    partnerStepSubtitles: {
      'personal-data': 'Nous allons créer un compte sur l’espace SideCare dédié aux assurances responsabilités civiles',
    },
  },
  superindep: {
    name: 'Superindep',
    partnerStepSubtitles: {},
  },
}

export function getEmbeddingPartnerConfiguration(embeddingPartnerId: string): EmbeddingPartnerConfiguration {
  return embedingPartnersConfigurations[embeddingPartnerId]
}

export function isValidEmbeddingPartnerId(embeddingPartnerId: string): boolean {
  return embeddingPartnerId in embedingPartnersConfigurations
}
